import {
  AccessToken,
  AuthState,
  IDToken,
  RefreshToken,
  UserClaims,
} from '@okta/okta-auth-js';
import {Dispatch} from 'react';
import {ActionTypes} from './AuthReducer';
import authClient from './config';

type AuthProps = {
  isAuthenticated?: boolean;
  accessToken?: AccessToken | undefined;
  idToken?: IDToken | undefined;
  refreshToken?: RefreshToken | undefined;
  user?: UserClaims | undefined;
  authState?: AuthState;
};

const tokenManager = async () => authClient.tokenManager;
const accessToken = async () =>
  (await (await tokenManager()).get('accessToken')) as AccessToken;
const idToken = async () =>
  (await (await tokenManager()).get('idToken')) as IDToken;
const userInfo = async () => {
  try {
    const at = await accessToken();
    const token = await idToken();
    if (at && token) {
      return await authClient.token.getUserInfo(at, token);
    } else {
      return undefined;
    }
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

const AuthLogin = async (dispatch: Dispatch<any>, state: AuthProps) => {
  const user = await userInfo();
  //@ts-ignore
  const queryParams = window.location.search;
  const params = new URLSearchParams(queryParams);
  const initialState = Object.fromEntries(params.entries());
  dispatch({
    type: ActionTypes.AUTHENTICATION_SUCCESS,
    ...state,
    user,
    initialState,
  });
};

const UpdateUser = async (dispatch: Dispatch<any>) => {
  try {
    const user = await userInfo();
    dispatch({type: ActionTypes.UPDATE_USER, user});
  } catch (error) {
    dispatch({type: ActionTypes.UPDATE_USER, user: undefined});
    console.log(error);
  }
};
const UpdateState = async (dispatch: Dispatch<any>, state: string) => {
  try {
    const initialState = JSON.parse(state);
    dispatch({type: ActionTypes.UPDATE_STATE, initialState});
  } catch (error) {
    console.log(error);
  }
};

const AuthLogout = async (dispatch: Dispatch<any>) => {
  dispatch({type: ActionTypes.AUTHENTICATION_LOGOUT});
};

export {AuthLogin, UpdateUser, UpdateState, AuthLogout};

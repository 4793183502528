const getCookieValue = (name) => {
  //@ts-ignore
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + '=')) {
      const value = cookie.substring(name.length + 1);
      return value;
    }
  }
  return null;
};

const getDecodedCookie = (name) => {
  const cookieValue = getCookieValue(name);
  if (cookieValue) {
    try {
      const decodedValue = JSON.parse(atob(cookieValue));
      return decodedValue;
    } catch (e) {
      console.error('Error decoding cookie:', e);
      return null;
    }
  } else {
    return null;
  }
};

const getGreetings = (firstName) => {
  if (firstName) {
    return 'Welcome, ' + firstName;
  } else {
    return 'Welcome';
  }
};

export {getCookieValue, getDecodedCookie, getGreetings};

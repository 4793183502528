import React, {ReactElement, useState} from 'react';
import {Formik} from 'formik';
import {View} from 'react-native';
import withAuth from '../../../../auth/withAuth';
import {Components, Theme} from '@oneamerica/dxp-ui-components';
import SaveChanges from '../SaveChanges';
import * as Yup from 'yup';
import {ProfileCardProps} from '../../types';
import responsiveStyle from './style';
import {useProfileDetailsSelector} from '../../hooks/profileDetails';
import {useDispatch} from 'react-redux';
import {
  updateProfileData,
  updateProfileDataReset,
} from '../../../../redux/profileDetails/action';
import {AlertLevel} from '../../../../constants/UtilsConstants';

const ContactInfoCard = (props: ProfileCardProps): ReactElement | null => {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const {showMenu, activeMenu, scrollToTop} = props;
  const {width} = React.useContext(Theme.ResponsiveContext);
  const style = responsiveStyle(width);
  const {isError, loading, getProfileResponsiveData, isProfileUpdated} =
    useProfileDetailsSelector(props);
  const dispatch = useDispatch();
  const [password, setPassword] = useState<string>('');
  const contactInfoSchema = Yup.object().shape({
    email: Yup.string()
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Please enter a valid email')
      .required('Please enter email'),
    // Note: this needs to have the + stripped at form submission
    phone: Yup.string()
      .max(10, 'Please enter a valid phone number')
      .min(10, 'Please enter minimum 10 digits')
      .matches(/^\d{10}$/, 'Please enter a valid phone number'),
  });

  const cancelForm = (resetForm) => {
    resetForm();
    typeof showMenu === 'function' && showMenu();
  };

  const handleSubmit = (values) => {
    const dataValue = {
      password: password,
      contactInfo: {
        email: {
          value: values.email,
        },
        phone: {
          value: values.phone,
        },
      },
    };
    dispatch(updateProfileData(dataValue, props?.accessToken?.accessToken));
  };

  const onSetValue = (value) => {
    setPassword(value);
  };

  const closeButton = () => {
    dispatch(updateProfileDataReset());
  };

  return (
    <Components.Card>
      {loading && <Components.LoadingOverlay loading={loading} />}
      <Components.Text.H2 style={style.cardHeaderTitle} className="lg:mt-4">
        Contact Information
      </Components.Text.H2>
      <Formik
        validateOnChange={true}
        enableReinitialize
        initialValues={{
          email: loading
            ? ''
            : String(getProfileResponsiveData?.data?.emailAddress),
          phone: loading
            ? ''
            : String(getProfileResponsiveData?.data?.mobilePhone),
        }}
        validateOnMount={!loading}
        validationSchema={contactInfoSchema}
        onSubmit={(values) => {
          setSubmitSuccess(true);
          handleSubmit(values);
        }}>
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          errors,
          isValid,
          resetForm,
        }) => (
          <>
            <View className="z-10 lg:flex-row">
              <View className="mb-8 lg:flex-1 lg:mr-8">
                <View className="mb-8 lg:my-8">
                  <Components.TextInput
                    id="email"
                    label="Email"
                    onBlur={() => handleBlur('email')}
                    onChangeText={handleChange('email')}
                    placeholder="Email (name@company.com)"
                    value={values?.email}
                    autoCapitalize="none"
                    autoComplete="off"
                    autoCorrect={false}
                    ErrorList={errors.email ? [{errorItem: errors.email}] : []}
                    keyboardType="email-address"
                    testID="emailInputTestId"
                  />
                </View>
                <View>
                  <Components.TextInput
                    id="phone"
                    autoCorrect={false}
                    keyboardType="phone-pad"
                    label="Phone"
                    placeholder="Phone (555 555 5555 (no dashes))"
                    onBlur={() => handleBlur('phone')}
                    value={values?.phone}
                    onChangeText={handleChange('phone')}
                    autoComplete="off"
                    ErrorList={errors.phone ? [{errorItem: errors.phone}] : []}
                    testID="phoneInputTestId"
                  />
                </View>
              </View>
            </View>
            {submitSuccess && isProfileUpdated ? (
              <Components.AlertMessage
                className="mb-4"
                title="Your contact information has successfully been updated"
                testID="successBanner"
                onCloseMessage={closeButton}
              />
            ) : (
              isError && (
                <Components.AlertMessage
                  className="mb-4"
                  title="The password you entered is incorrect."
                  alertLevel={AlertLevel.ERROR}
                  onCloseMessage={closeButton}
                />
              )
            )}
            <Components.Divider />
            <SaveChanges
              disabled={!isValid}
              onCancel={() => {
                cancelForm(resetForm);
                closeButton();
                scrollToTop();
              }}
              onSave={handleSubmit}
              setValue={onSetValue}
              activeMenu={activeMenu}
            />
          </>
        )}
      </Formik>
    </Components.Card>
  );
};

export default withAuth(ContactInfoCard);

import React, {useContext} from 'react';
import {View} from 'react-native';
import {Components, Theme, Font} from '@oneamerica/dxp-ui-components';
import responsiveStyle from '../../style';
import InfoWrapper from '../../../../components/InfoWrapper/InfoWrapper';
import {formatAddress, getRepresentative} from '../../helpers';
import moment from 'moment';
import BreakpointConstants from '../../../../constants/BreakpointConstants';
import RouteConstants from '../../../../constants/RouteConstants';
import {useNavigation} from '@react-navigation/native';
import {dividendOptions} from '../../constants';
import {COBDetails} from '../COBDetails';
import {formatCurrency} from '../../../../utils';
import {analyticsTags} from '../../../../utils/Analytics/AnalyticsTags';
import {trackCustomEvent} from '../../../../utils/Analytics/AdobeAnalyticsUtils';

const style = responsiveStyle();

export const PolicyHighlightsCard = ({
  className,
  getType,
  validateElement,
  getTaxQualification,
  titleCase,
  getAffiliate,
  policyStatusButton,
  policyHighlights,
  generalPolicyDataRender,
  buttonStatus,
  getTerminatedReason,
  terminatedReason,
  account,
  showCOBInPolicyHighlights,
  cobPolicyNumber,
  cobPolicyStatus,
  cobStatusButton,
  policyTerminationDate,
  cobPolicyStatusReason,
  cobTerminationDate,
  linkStyle,
  navigateToCOBDetails,
  row,
  popupData,
}) => {
  const {width} = useContext(Theme.ResponsiveContext);
  const navigation = useNavigation<any>();
  const insuredPersonData = popupData?.filter(
    (item) =>
      item.relationType === 'Primary Insured' ||
      item.relationType === 'Annuitant',
  );
  const insuredPerson =
    insuredPersonData && insuredPersonData[0]?.personDetails?.name?.first;
  const insuredLabel =
    insuredPersonData &&
    insuredPersonData[0]?.relationType === 'Primary Insured'
      ? 'Insured'
      : insuredPersonData[0]?.relationType;
  const navigateToContactUs = () => {
    navigation.navigate(RouteConstants.contactus);
  };
  const linkStyleContactUs = {
    style: style.linkContactUs,
    linkStyle: style.linkContactUs,
    linkHoverStyle: style.linkContactUs,
    hoverStyle: style.linkContactUsHover,
    focusStyle: style.linkContactUsFocus,
  };

  const suspendedButtonView = () =>
    buttonStatus === 'Suspended' ? (
      <View className="w-full md:w-2/3">
        <View style={{flexDirection: 'row', marginRight: 20}}>
          <Components.Text style={style.suspendedContent} className="py-2 px-2">
            Account information is not currently available for this policy. For
            additional information, please reference the{' '}
            <Components.Link
              onPress={() => {
                trackCustomEvent(
                  analyticsTags.CaptureContactUsPolicyHighlightsTags,
                );
                navigateToContactUs();
              }}
              accessibilityLabel="Contact Us"
              accessibilityRole="button"
              {...linkStyleContactUs}>
              Contact Us
            </Components.Link>{' '}
            page.
          </Components.Text>
        </View>
      </View>
    ) : (
      <View className="w-full md:w-2/3">
        {getRepresentative(policyHighlights) && (
          <InfoWrapper
            subTitle
            title={'Your Representative is:'}
            subTitleText={
              getRepresentative(policyHighlights)?.personDetails?.name?.first ??
              ''
            }
            information={formatAddress(policyHighlights)}
            infoTextStyle={{fontFamily: Font.regular.fontFamily}}
          />
        )}
      </View>
    );

  const generalPolicyDataRenderer = () => {
    const dividents = generalPolicyDataRender?.dividendOption ? (
      <InfoWrapper
        width="w-1/2 md:w-1/3"
        title={'Dividend Option'}
        subTitle
        information={
          dividendOptions[
            policyHighlights[0]?.generalPolicyData?.dividendOption
          ] || policyHighlights[0]?.generalPolicyData?.dividendOption
        }
      />
    ) : null;

    const withdrawlsAndDividents = generalPolicyDataRender?.withdrawals ? (
      <InfoWrapper
        width="w-1/2 md:w-1/3"
        title={'Cash Withdrawals'}
        subTitle
        information={formatCurrency(
          policyHighlights[0]?.generalPolicyData?.withdrawals,
        )}
      />
    ) : (
      dividents
    );

    return generalPolicyDataRender?.expiryDate ? (
      <InfoWrapper
        width="w-1/2 md:w-1/3"
        title={'Expiry Date'}
        subTitle
        information={moment(
          policyHighlights[0]?.generalPolicyData?.expiryDate,
        ).format('MMM DD, YYYY')}
      />
    ) : (
      withdrawlsAndDividents
    );
  };

  return Object.keys(account).length !== 0 ? (
    <View className={className}>
      <View
        style={[
          style.cardShadow,
          style.paddingHorizontalXSmall,
          row > 1 && style.flex,
        ]}>
        <View style={style.cardHeader}>
          <Components.Text.H3
            className="px-2"
            style={[style.cardHeaderTitle, style.flex]}>
            Policy Highlights
          </Components.Text.H3>
        </View>
        <View className="my-4" style={style.horizontalDivider} />
        <View style={style.contentWrapper}>
          <Components.Text.H1 className="px-2" style={style.contentHeader}>
            {getType(account?.type, account?.affiliate)}
          </Components.Text.H1>
          <View className="pt-2" style={style.column}>
            <View className="flex-row flex-wrap">
              {validateElement(
                account?.name,
                <InfoWrapper
                  width="w-1/2 md:w-1/3"
                  title={'Product Name'}
                  subTitle
                  information={account?.name ?? ''}
                />,
              )}
              {validateElement(
                account?.number,
                <InfoWrapper
                  width="w-1/2 md:w-1/3"
                  title={'Policy Number'}
                  subTitle
                  information={account?.number ?? ''}
                />,
                BreakpointConstants.isMD(width) ? undefined : null,
              )}
              {buttonStatus === 'Terminated' || buttonStatus === 'Suspended'
                ? validateElement(
                    getAffiliate(account?.affiliate),
                    <InfoWrapper
                      width="w-1/2 md:w-1/3"
                      title={'Company Name'}
                      subTitle
                      information={getAffiliate(account?.affiliate)}
                    />,
                  )
                : validateElement(
                    getTaxQualification(policyHighlights),
                    <InfoWrapper
                      width="w-1/2 md:w-1/3"
                      title={'Tax Qualification'}
                      subTitle
                      information={getTaxQualification(policyHighlights)}
                    />,
                  )}
            </View>
            <View className="my-2" style={style.horizontalDivider} />
            {buttonStatus !== 'Terminated' && buttonStatus !== 'Suspended' && (
              <>
                <View className="flex-row flex-wrap">
                  {validateElement(
                    getAffiliate(account?.affiliate),
                    <InfoWrapper
                      width="w-1/2 md:w-1/3"
                      title={'Company Name'}
                      subTitle
                      information={getAffiliate(account?.affiliate)}
                    />,
                  )}
                  {validateElement(
                    generalPolicyDataRender?.issueDate,
                    <InfoWrapper
                      width="w-1/2 md:w-1/3"
                      title={'Issue Date'}
                      subTitle
                      information={
                        policyHighlights[0]?.generalPolicyData?.issueDate
                          ? moment(
                              policyHighlights[0]?.generalPolicyData?.issueDate,
                            ).format('MMM DD, YYYY')
                          : ''
                      }
                    />,
                  )}
                  {generalPolicyDataRenderer()}
                </View>
                <View className="my-2" style={style.horizontalDivider} />
              </>
            )}

            <View className="flex-row flex-wrap">
              <View
                className={
                  buttonStatus !== 'Terminated'
                    ? `pt-2 w-full md:w-1/3`
                    : `pt-2 w-1/2 md:w-1/3 `
                }>
                <Components.Text className="px-2" style={style.statusButton}>
                  Contract Status
                </Components.Text>
                <View
                  className="px-2"
                  style={{
                    alignItems: 'flex-start',
                    maxWidth: 150,
                    paddingTop: 4,
                  }}>
                  <Components.Text
                    style={{
                      paddingHorizontal: 8,
                      textAlign: 'center',
                      paddingVertical: 4,
                      borderRadius: 4,
                      borderWidth: 1,
                      borderStyle: 'solid',
                      fontSize: 14,
                      borderColor: policyStatusButton?.borderColor,
                      backgroundColor: policyStatusButton?.backgroundColor,
                    }}>
                    {buttonStatus}
                  </Components.Text>
                </View>
                {buttonStatus === 'Terminated' ||
                buttonStatus === 'Back Billed' ? (
                  <InfoWrapper
                    subTitle={
                      buttonStatus === 'Terminated' ||
                      buttonStatus === 'Back Billed'
                        ? true
                        : false
                    }
                    information={
                      buttonStatus === 'Terminated'
                        ? getTerminatedReason(terminatedReason)
                        : 'Premium Required'
                    }
                    infoTextStyle={{
                      fontFamily: Font.regular.fontFamily,
                      fontSize: 14,
                    }}
                  />
                ) : null}
              </View>
              {buttonStatus === 'Terminated' ? (
                <>
                  <View className="w-1/2 md:w-1/3">
                    <InfoWrapper
                      title={'Termination Date'}
                      subTitle
                      information={policyTerminationDate}
                    />
                  </View>
                  <View className="w-1/2 md:w-1/3">
                    <InfoWrapper
                      title={insuredLabel}
                      subTitle
                      information={insuredPerson}
                    />
                  </View>
                </>
              ) : (
                suspendedButtonView()
              )}
            </View>
            {buttonStatus === 'Terminated' ? (
              <View>
                <View className="my-2" style={style.horizontalDivider} />
                <View className="flex-row flex-wrap">
                  <Components.Text
                    className="py-2 px-2"
                    style={style.terminatedContent}>
                    The status of this policy is either terminated or the
                    premium payments have discontinued, and the policy is now in
                    its non-forfeiture option. For additional information,
                    please reference the{' '}
                    <Components.Link
                      onPress={() => {
                        trackCustomEvent(
                          analyticsTags.CaptureContactUsPolicyHighlightsTerminatedTags,
                        );
                        navigateToContactUs();
                      }}
                      accessibilityLabel="Contact Us"
                      accessibilityRole="button"
                      {...linkStyleContactUs}>
                      Contact Us
                    </Components.Link>{' '}
                    page.
                  </Components.Text>
                </View>
              </View>
            ) : null}
            {showCOBInPolicyHighlights ? (
              <COBDetails
                validateElement={validateElement}
                cobPolicyStatus={cobPolicyStatus}
                cobStatusButton={cobStatusButton}
                cobPolicyStatusReason={cobPolicyStatusReason}
                cobPolicyNumber={cobPolicyNumber}
                cobTerminationDate={cobTerminationDate}
                linkStyle={linkStyle}
                navigateToCOBDetails={navigateToCOBDetails}
              />
            ) : null}
          </View>
        </View>
      </View>
    </View>
  ) : null;
};

import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {ImageSrcSet, Images} from './../../../assets';
import React, {ReactElement} from 'react';
import {
  Dimensions,
  Image,
  ImageBackground,
  Linking,
  Platform,
  Text,
  TextStyle,
  View,
} from 'react-native';
import {Components, Theme, Utils} from '@oneamerica/dxp-ui-components';
import {AEM_API_BASE_URL} from '../../config/getEnvironmentVars';

const SVGBackground = ({children}) => {
  const window = Dimensions.get('window');
  const screenSize = Theme.getSize(window.width, window.height);
  let mobileHeight: number;
  // Adjust for height variations across mobile devices
  switch (screenSize) {
    case 'X_SMALL':
      mobileHeight = 760;
      break;
    case 'SMALL':
      mobileHeight = 700;
      break;
    default:
      mobileHeight = 720;
  }

  if (Platform.OS === 'web') {
    return (
      <ImageBackground
        source={Images.footerBG}
        className={`w-full h-[${mobileHeight}px] lg:h-[590px]`}
        resizeMode="cover">
        {children}
      </ImageBackground>
    );
  }
  return (
    <View className="w-full relative z-0">
      <View className="absolute -z-10 min-h-[850px] lg:min-h-[590px] top-0 -left-full">
        <Images.footerBG />
      </View>
      {children}
      <View
        className="absolute bottom-0 w-full h-[300px] -z-10"
        style={{backgroundColor: Theme.Color.oaBlue}}
      />
    </View>
  );
};

const Footer = (): ReactElement | null => {
  const {facebook, xTwitter, linkedin, youtube, instagram} = Utils.icons;

  // Replace this with AEM Fragment blocks
  const footerContentMock = {
    mainLinks: {
      id: 'mainLinks',
      links: [
        {
          id: 'oali',
          uri:
            AEM_API_BASE_URL + '/products-services/individuals-life-insurance',
          title: 'Life Insurance',
          external: true,
        },
        {
          id: 'oars',
          uri: 'https://www.oaretirement.com/',
          title: 'Retirement',
          external: true,
        },
        {
          id: 'oaben',
          uri: AEM_API_BASE_URL + '/',
          title: 'Employee Benefits',
          external: true,
        },
        {
          id: 'oacare',
          uri: AEM_API_BASE_URL + '/',
          title: 'Long-Term Care',
          external: true,
        },
      ],
    },
    contactUs: {
      id: 'contactUs',
      title: 'Connect with Us',
      address: {
        line1: 'OneAmerica Financial Partners, Inc.',
        line2: 'One American Square',
        line3: 'P.O. Box 368',
        line4: 'Indianapolis, IN 46206-0368',
        phone: {
          title: 'Our Phone Numbers',
          uri:
            AEM_API_BASE_URL +
            '/contact-us/contact-information/contact-information',
        },
      },
      socials: [
        {
          id: 'oafb',
          uri: 'https://www.facebook.com/oneamerica',
          title: 'OneAmerica Facebook',
          icon: facebook,
        },
        {
          id: 'oax',
          uri: 'https://twitter.com/OneAmerica',
          title: 'OneAmerica X',
          icon: xTwitter,
        },
        {
          id: 'oali',
          uri: 'https://www.linkedin.com/company/oneamerica-financial-partners-inc',
          title: 'OneAmerica LinkedIn',
          icon: linkedin,
        },
        {
          id: 'oayt',
          uri: 'https://www.youtube.com/channel/UCAiy_de2R5QZ_xYKWZIqNsg',
          title: 'OneAmerica YouTube',
          icon: youtube,
        },
        {
          id: 'oainst',
          uri: 'https://www.instagram.com/oneamerica/',
          title: 'OneAmerica Instagram',
          icon: instagram,
        },
      ],
    },
    disclaimerBlock: {
      disclaimer1:
        'Provided content is for overview and informational purposes only and is not intended and should not be relied upon as individualized tax, legal, fiduciary, or investment advice.',
      copyright:
        '© 2023 OneAmerica Financial Partners, Inc. All rights reserved. OneAmerica® and the OneAmerica banner are all registered trademarks of OneAmerica Financial Partners, Inc.',
      companies: (
        <Text>
          OneAmerica Financial
          <Text
            style={{
              fontSize: 10,
              position: 'relative',
              top: -6,
            }}>
            SM{' '}
          </Text>
          is the marketing name for the companies of OneAmericaFinancial
          including: American United Life Insurance Company®, The State Life
          Insurance Company®, OneAmerica Retirement Services LLC, McCready and
          Keene, Inc., OneAmerica Securities, Inc., Pioneer Mutual Life
          Insurance Company®, OneAmerica Asset Management, LLC, and AUL
          Reinsurance Management Services, LLC®.
        </Text>
      ),
      links: [
        {
          id: 'privacyPolicy',
          uri: AEM_API_BASE_URL + '/legal/privacy-policy',
          title: 'Privacy Policy',
          external: true,
        },
        {
          id: 'legalDisclaimers',
          uri: AEM_API_BASE_URL + '/legal/legal-disclaimers',
          title: 'Legal Disclaimers',
          external: true,
        },
        {
          id: 'socialMediaPolicy',
          uri: AEM_API_BASE_URL + '/legal/social-media-policy',
          title: 'Social Media Policy',
          external: true,
        },
        {
          id: 'securityPolicy',
          uri: AEM_API_BASE_URL + '/legal/security',
          title: 'Security',
          external: true,
        },
        {
          id: 'accessibilityPolicy',
          uri: AEM_API_BASE_URL + '/legal/accessibility',
          title: 'Accessibility',
          external: true,
        },
        {
          id: 'cookieNoticePolicy',
          uri: AEM_API_BASE_URL + '/legal/cookie-notice',
          title: 'Cookie Notice',
          external: true,
        },
      ],
    },
    links: [
      {
        id: 'siteMap',
        title: 'Site Map',
        items: [
          {
            id: 'au1',
            uri: AEM_API_BASE_URL + '/individuals',
            title: 'For Individuals',
            external: true,
          },
          {
            id: 'au2',
            uri: AEM_API_BASE_URL + '/employers',
            title: 'For Employers',
            external: true,
          },
          {
            id: 'au3',
            uri: AEM_API_BASE_URL + '/financial-professionals',
            title: 'For Financial Professionals',
            external: true,
          },
          {
            id: 'au4',
            uri: AEM_API_BASE_URL + '/about-us',
            title: 'About Us',
            external: true,
          },
          {
            id: 'au5',
            uri: AEM_API_BASE_URL + '/careers',
            title: 'Careers',
            external: true,
          },
          {
            id: 'au6',
            uri: AEM_API_BASE_URL + '/contact-us',
            title: 'Contact Us',
            external: true,
          },
        ],
      },
      {
        id: 'quicklinks',
        title: 'Quick Access',
        items: [
          {
            id: 'ql1',
            uri: AEM_API_BASE_URL + '/file-a-claim',
            title: 'Claims',
            external: true,
          },
          {
            id: 'ql2',
            uri: AEM_API_BASE_URL + '/policy-service-request',
            title: 'Policy Premium Payment',
            external: true,
          },
          {
            id: 'ql3',
            uri: AEM_API_BASE_URL + '/policy-service-request',
            title: 'Individual Policy Service Request',
            external: true,
          },
          {
            id: 'ql4',
            uri: 'https://www.employeebenefits.aul.com/public/index.html#forms',
            title: 'Employee Benefits Forms',
            external: true,
          },
          {
            id: 'ql5',
            uri: AEM_API_BASE_URL + '/legal/prospectuses',
            title: 'Prospectuses',
            external: true,
          },
        ],
      },
    ],
  };

  const openURL = (url: string) => {
    try {
      Linking.openURL(url);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <SVGBackground>
      <View className=" w-full mx-auto max-w-[1250px] px-4 pt-[52px] lg:pt-[92px] lg:px-8">
        <View
          className={`${Platform.OS === 'web' ? 'pb-6' : 'pb-12'} border-b`}
          style={{borderColor: Theme.Color.ice4}}>
          <Image
            source={Images.bannerLogoWhite}
            style={{
              width: ImageSrcSet.bannerLogoWhite.width,
              height: ImageSrcSet.bannerLogoWhite.height,
              objectFit: 'cover',
            }}
            resizeMode="contain"
            alt="OneAmerica Financial"
            className={Platform.OS === 'web' ? 'mb-6' : 'mb-0'}
          />
          <View className="flex-row flex-wrap">
            {footerContentMock?.mainLinks?.links.map((item) => {
              if (!item.uri || !item.title) {
                return;
              }

              return (
                <Components.Text.Label
                  className="mr-8"
                  key={item.id}
                  color={Theme.Color.white}
                  size={18}
                  font="bold">
                  {item.title}
                </Components.Text.Label>                
              );
            })}
          </View>
        </View>
        <View
          className="lg:flex-row py-6 lg:border-b"
          style={{borderColor: Theme.Color.ice4}}>
          <View className="lg:flex-1 lg:flex-row">
            <View className="lg:flex-1 pr-4">
              <Components.Text.H5 color={Theme.Color.white} className="mb-3">
                {footerContentMock?.contactUs.title}
              </Components.Text.H5>
              <Components.Text
                color={Theme.Color.white}
                font="bold"
                size={12}
                className="mb-3"
                lineHeight={14}>
                {footerContentMock?.contactUs.address?.line1}
              </Components.Text>
              <Components.Text
                color={Theme.Color.white}
                size={12}
                lineHeight={14}>
                {footerContentMock?.contactUs.address?.line2}
              </Components.Text>
              <Components.Text
                color={Theme.Color.white}
                size={12}
                lineHeight={14}>
                {footerContentMock?.contactUs.address?.line3}
              </Components.Text>
              <Components.Text
                className="lg:mb-3"
                color={Theme.Color.white}
                size={12}
                lineHeight={14}>
                {footerContentMock?.contactUs.address?.line4}
              </Components.Text>
              {Platform.OS === 'web' && (
                <View className="hidden lg:flex">
                  <Components.Link
                    color={Theme.Color.white}
                    size={12}
                    accessibilityRole="button"
                    accessibilityLabel={
                      footerContentMock?.contactUs.address?.phone?.title
                        ? footerContentMock?.contactUs.address?.phone?.title
                        : 'Our Phone numbers'
                    }
                    linkHoverStyle={{color: Theme.Color.white}}
                    onPress={() =>
                      openURL(footerContentMock?.contactUs.address?.phone?.uri)
                    }>
                    {footerContentMock?.contactUs.address?.phone?.title}
                  </Components.Link>
                </View>
              )}
              <View className="flex-row gap mt-3">
                {footerContentMock?.contactUs?.socials?.map((item) => {
                  if (!item.uri || !item.title) {
                    return;
                  }

                  return (
                    <Components.Link
                      className="mr-1 lg:mr-2 outline outline-offset-2 outline-blue-500"
                      key={item.id}
                      onPress={() => openURL(item.uri)}
                      linkHoverStyle={{color: Theme.Color.white}}
                      focusStyle={
                        {
                          outlineWidth: 1,
                          outlineColor: Theme.Color.white,
                          outlineStyle: 'solid',
                          outlineOffset: 1,
                        } as TextStyle
                      }
                      accessibilityLabel={item.title}
                      accessibilityRole="button">
                      <FontAwesomeIcon
                        icon={item.icon as IconProp}
                        color={Theme.Color.white}
                        size={24}
                      />
                    </Components.Link>
                  );
                })}
              </View>
            </View>
            {Platform.OS === 'web' ? (
              <>
                {footerContentMock?.links?.map((linkBlock) => (
                  <View
                    className="hidden lg:flex pr-4 lg:flex-1"
                    key={linkBlock.id}>
                    <Components.Text.H5
                      className="mb-3"
                      color={Theme.Color.white}>
                      {linkBlock.title}
                    </Components.Text.H5>
                    {linkBlock?.items?.map((item) => {
                      if (!item.uri || !item.title) {
                        return;
                      }

                      return (
                        <Components.Link
                          className="mb-1"
                          color={Theme.Color.white}
                          key={item.id}
                          size={13}
                          onPress={() => openURL(item.uri)}
                          accessibilityRole="button"
                          accessibilityLabel={item.title}
                          linkHoverStyle={{color: Theme.Color.white}}>
                          {item.title}
                        </Components.Link>
                      );
                    })}
                  </View>
                ))}
                <View className="hidden lg:flex" />
              </>
            ) : null}
          </View>
          <View className="mt-6 lg:mt-0 lg:flex-1">
            <Components.Text
              size={12}
              color={Theme.Color.white}
              lineHeight={14}>
              {footerContentMock?.disclaimerBlock?.companies}
            </Components.Text>
            <Components.Text
              className="my-3"
              size={13}
              color={Theme.Color.white}
              font="bold"
              lineHeight={14}>
              {footerContentMock?.disclaimerBlock?.disclaimer1}
            </Components.Text>
            <Components.Text
              size={12}
              color={Theme.Color.white}
              lineHeight={14}>
              {footerContentMock?.disclaimerBlock?.copyright}
            </Components.Text>
          </View>
        </View>
        <View className="flex-row flex-wrap mb-6 lg:pt-6">
          {footerContentMock?.disclaimerBlock?.links?.map((item, i) => {
            if (!item.uri || !item.title) {
              return;
            }
            const isNotLastItem =
              i + 1 < footerContentMock?.disclaimerBlock?.links?.length;
            return (
              <View key={item.id} className="flex-row flex-wrap">
                <Components.Link
                  color={Theme.Color.white}
                  size={12}
                  onPress={() => openURL(item.uri)}
                  linkHoverStyle={{color: Theme.Color.white}}
                  lineHeight={12}
                  accessibilityRole="button"
                  accessibilityLabel={item.title}
                  className="h-[12px] mb-2 lg:mb-0">
                  {item.title}
                </Components.Link>
                {isNotLastItem && (
                  <Components.Text
                    size={12}
                    lineHeight={12}
                    className="mx-2"
                    color={Theme.Color.white}>
                    |
                  </Components.Text>
                )}
              </View>
            );
          })}
        </View>
      </View>
    </SVGBackground>
  );
};

export default Footer;

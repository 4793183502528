import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigation} from '@react-navigation/native';
import {
  displableBillingAndPremiumKeys,
  getButtonColors,
  termOrder,
  aulOrPmWLPolicies,
  flattenObject,
  removeFalsyFromObject,
  slWLPolicies,
  annuityPolicies,
  otherPolicies,
} from '../helpers';
import {NetworkManager} from '../../../api/NetworkManager';
import aemModalContent from '../../../mock/aemModalContent.json';
import RouteConstants from '../../../constants/RouteConstants';
import {getPoliciesList} from '../../../redux/policies/action';
import moment from 'moment';
import {formatCurrency} from '../../../utils';
import {DISCLAIMER_NOTE_KEY_TO_HIDE_FOR_COB} from '../constants';
import {getUserDetails} from '../../../redux/profileDetails/action';
interface DisclaimerNote {
  disclaimerText: string;
}
const getWLTitleAndValue = (item, finalAulOrPmlUserObject) => {
  switch (item) {
    case 'totalDeathBenefit':
      return {
        title: 'Total Death Benefit',
        value: finalAulOrPmlUserObject[item],
      };
    case 'guaranteedCashValue':
      return {
        title: 'Guaranteed Cash Value',
        value: `${formatCurrency(finalAulOrPmlUserObject[item])}`,
      };
    case 'totalCashSurrenderValue':
      return {
        title: 'Total Cash Surrender Value',
        value: `${formatCurrency(finalAulOrPmlUserObject[item])}`,
      };
    case 'netCashValue':
      return {
        title: 'Net Cash Value',
        value: `${formatCurrency(finalAulOrPmlUserObject[item])}`,
      };
    case 'otherFields.Cash Value of Paid Up Additions':
      return {
        title: 'Cash Value of Paid Up Additions',
        value: `${formatCurrency(finalAulOrPmlUserObject[item])}`,
      };
    case 'otherFields.Paid Up Insurance Rider':
      return {
        title: 'Paid Up Insurance Rider',
        value: `${formatCurrency(finalAulOrPmlUserObject[item])}`,
      };
    case 'otherFields.Dividend Last Anniversary':
      return {
        title: 'Dividend Last Anniversary',
        value: `${formatCurrency(finalAulOrPmlUserObject[item])}`,
      };
    case 'otherFields.Dividend on Deposit':
      return {
        title: 'Dividend Accumulation',
        value: `${formatCurrency(finalAulOrPmlUserObject[item])}`,
      };
    case 'otherFields.Premium Deposit Fund':
      return {
        title: 'Premium Deposit Fund',
        value: `${formatCurrency(finalAulOrPmlUserObject[item])}`,
      };
    case 'otherFields.Projected Dividend Next Anniversary':
      return {
        title: 'Projected Dividend Next Anniversary',
        value: `${formatCurrency(finalAulOrPmlUserObject[item])}`,
      };
    case 'otherFields.Accelerator ONE':
      return {
        title: 'Accelerator ONE',
        value: `${formatCurrency(finalAulOrPmlUserObject[item])}`,
      };
    case 'otherFields.Term Deposits':
      return {
        title: 'Term Deposits',
        value: `${formatCurrency(finalAulOrPmlUserObject[item])}`,
      };
    case 'otherFields.Endowment Coverage':
      return {
        title: 'Endowment Coverage',
        value: `${formatCurrency(finalAulOrPmlUserObject[item])}`,
      };
    default:
      return {
        title: '',
        value: '',
      };
  }
};
const getSlWlTitleAndValue = (item, finalSlWlObject) => {
  switch (item) {
    case 'monthlyLTCBenefit':
      return {
        title: 'Monthly LTC Benefit',
        value: `${formatCurrency(finalSlWlObject[item])}`,
      };
    case 'deathBenefit':
      return {
        title: 'Death Benefit',
        value: `${formatCurrency(finalSlWlObject[item])}`,
      };
    case 'currentAccountValue':
      return {
        title: 'Account Value',
        value: `${formatCurrency(finalSlWlObject[item])}`,
      };
    case 'totalCashSurrenderValue':
      return {
        title: 'Cash Surrender Value',
        value: `${formatCurrency(finalSlWlObject[item])}`,
      };
    default:
      return {
        title: '',
        value: '',
      };
  }
};
const getAnnuityValue = (item, finalannuityObject) => {
  switch (item) {
    case 'ltcBenefitBalance':
      return {
        title: 'Current LTC Benefit Balance ',
        value: `${formatCurrency(finalannuityObject[item])}`,
      };
    case 'ltcMonthlyMaximum':
      return {
        title: 'LTC Monthly Maximum ',
        value: `${formatCurrency(finalannuityObject[item])}`,
      };
    case 'accountValue':
      return {
        title: 'Account Value',
        value: `${formatCurrency(finalannuityObject[item])}`,
      };
    case 'totalCashSurrenderValue':
      return {
        title: 'Cash Surrender Value',
        value: `${formatCurrency(finalannuityObject[item])}`,
      };
    case 'otherFields.Cost Basis:':
      return {
        title: 'Cost Basis',
        value: `${formatCurrency(finalannuityObject[item])}`,
      };
    case 'otherFields.Net Cash Value':
      return {
        title: 'Net Cash Value',
        value: `${formatCurrency(finalannuityObject[item])}`,
      };
    default:
      return {
        title: '',
        value: '',
      };
  }
};
const getOtherPolicyValue = (item, finalOtherPolicyObject) => {
  switch (item) {
    case 'accountValue':
      return {
        title: 'Account Value',
        value: `${formatCurrency(finalOtherPolicyObject[item])}`,
      };
    case 'netCashValue':
      return {
        title: 'Net Cash Value',
        value: `${formatCurrency(finalOtherPolicyObject[item])}`,
      };
    case 'otherFields.Accumulated Value':
      return {
        title: 'Accumulated Value',
        value: `${formatCurrency(finalOtherPolicyObject[item])}`,
      };
    case 'otherFields.Cash Surrender Value':
      return {
        title: 'Cash Surrender Value',
        value: `${formatCurrency(finalOtherPolicyObject[item])}`,
      };
    case 'otherFields.Accumulated Value LTC Fund':
      return {
        title: 'Accumulated Value LTC Fund',
        value: `${formatCurrency(finalOtherPolicyObject[item])}`,
      };
    case 'otherFields.Cash Value':
      return {
        title: 'Cash Value',
        value: `${formatCurrency(finalOtherPolicyObject[item])}`,
      };
    case 'otherFields.Guaranteed Cash Value':
      return {
        title: 'Guaranteed Cash Value',
        value: `${formatCurrency(finalOtherPolicyObject[item])}`,
      };
    case 'otherFields.Premium Deposit Account':
      return {
        title: 'Premium Deposit Account',
        value: `${formatCurrency(finalOtherPolicyObject[item])}`,
      };
    case 'otherFields.Guaranteed Income Benefit Amount (GIB)':
      return {
        title: 'Guaranteed Income Benefit Amount (GIB)',
        value: `${formatCurrency(finalOtherPolicyObject[item])}`,
      };
    case 'Freedom Builder Plus Benefit Base':
      return {
        title: 'Freedom Builder Plus Benefit Base',
        value: `${formatCurrency(finalOtherPolicyObject[item])}`,
      };
    case 'Freedom Builder Plus Income Rate':
      return {
        title: 'Freedom Builder Plus Income Rate',
        value: `${finalOtherPolicyObject[item].percent.toString()}%`,
      };
    case 'Freedom Builder Plus Index Multiplier':
      return {
        title: 'Freedom Builder Plus Index Multiplier',
        value: `${finalOtherPolicyObject[item].decimal.toString()}`,
      };
    case 'Freedom Builder Plus Lifetime Annual Income':
      return {
        title: 'Freedom Builder Plus Lifetime Annual Income',
        value: `${formatCurrency(finalOtherPolicyObject[item])}`,
      };
    case 'otherFields.ADB for Chronic Illness total lien balance':
      return {
        title: 'ADB for Chronic Illness total lien balance',
        value: `${formatCurrency(finalOtherPolicyObject[item])}`,
      };
    case 'otherFields.ADB for Terminal Illness total lien balance':
      return {
        title: 'ADB for Terminal Illness total lien balance',
        value: `${formatCurrency(finalOtherPolicyObject[item])}`,
      };

    case 'otherFields.GMWB Benefit Base Amount':
      return {
        title: 'GMWB Benefit Base Amount',
        value: `${formatCurrency(finalOtherPolicyObject[item])}`,
      };
    case 'otherFields.Guar Annual Withdrawal Amount':
      return {
        title: 'Guar Annual Withdrawal Amount',
        value: `${formatCurrency(finalOtherPolicyObject[item])}`,
      };
    case 'otherFields.Guar Annual Withdrawal Amount remaining to next anniversary':
      return {
        title: 'Guar Annual Withdrawal Amount remaining to next anniversary',
        value: `${formatCurrency(finalOtherPolicyObject[item])}`,
      };
    case 'otherFields.Guar Annual Withdrawal Amount (Benefit Base times the 5.00  % GAWA Rate)':
      return {
        title:
          'Guar Annual Withdrawal Amount (Benefit Base times the 5.00  % GAWA Rate)',
        value: `${formatCurrency(finalOtherPolicyObject[item])}`,
      };
    case 'otherFields.Guar Annual Withdrawal Amount (Benefit Base times the 6.00  % GAWA Rate)':
      return {
        title:
          'Guar Annual Withdrawal Amount (Benefit Base times the 6.00  % GAWA Rate)',
        value: `${formatCurrency(finalOtherPolicyObject[item])}`,
      };
    case 'otherFields.Guar Annual Withdrawal Amount (Benefit Base times the 7.00  % GAWA Rate)':
      return {
        title:
          'Guar Annual Withdrawal Amount (Benefit Base times the 7.00  % GAWA Rate)',
        value: `${formatCurrency(finalOtherPolicyObject[item])}`,
      };
    default:
      return {
        title: '',
        value: '',
      };
  }
};
const getVal = (data) => {
  let firstValue, secondValue, thirdValue;
  switch (data.length) {
    case 0:
      return [];
    case 1:
      [firstValue] = data;
      return [firstValue];
    case 2:
      [firstValue, secondValue] = data;
      return [firstValue, secondValue];
    case 3:
      [firstValue, secondValue, thirdValue] = data;
      return [firstValue, secondValue, thirdValue];
    default:
      [firstValue, secondValue, thirdValue] = data;
      return [firstValue, secondValue, thirdValue];
  }
};

export const useHomeSelector = (props) => {
  const dispatch = useDispatch();
  const navigation = useNavigation<any>();
  const [isModalVisible, setModalVisibility] = useState(false);
  const [isModalViewAllRelationShip, setModalViewAllRelationShip] =
    useState(false);
  const [isInfoModalVisible, setInfoModalVisiblity] = useState(false);
  const [account, setAccount] = useState<AccountType>({});
  const [policyHighlights, setPolicyHighlights] = useState<any>({});
  const [infoModalContent, setInfoModalContent] = useState<any>([]);
  const [buttonStatus, setButtonStatus] = useState('');
  const [terminatedReason, setTerminatedReason] = useState('');
  const [generalPolicyData, setGeneralPolicyData] = useState<any>({});
  const [generalPolicyDataRender, setGeneralPolicyDataRender] = useState<any>(
    {},
  );
  const [initialPolicyData, setInitialPolicyData] = useState<string[]>([]);
  const [policyValue, setPolicyValue] = useState<any>({
    policyValues: [],
    valueAsOfDate: '',
  });
  const [policyValueCardData, setPolicyValueCardData] = useState<any>([]);
  const [hasTotalDeathBenefit, setHasTotalDeathBenefit] = useState(false);
  const [policyStatusButton, setPolicyStatusButton] = useState({});
  const [moreBillingAndPremium, setMoreBillingAndPremium] = useState<string[]>(
    [],
  );
  const [morePolicyValue, setMorePolicyValue] = useState<string[]>([]);
  const [modalTitles, setModalTitles] = useState('');
  const [policyRelationshipsData, setPolicyRelationshipsData] = useState<
    PolicyRelationship | string | undefined[]
  >([]);
  const [relationType, setRelationType] = useState<
    PolicyRelationshipClick | undefined
  >(undefined);
  const [popupData, setPopupData] = useState<
    Array<PolicyRelationship | string | undefined>
  >([]);
  const [popupDataAll, setPopupDataAll] = useState<
    Array<PolicyRelationship | string | undefined>
  >([]);
  const [showCOBInPolicyHighlights, setShowCOBInPolicyHighlights] =
    useState(false);
  const [cobPolicyStatus, setCobPolicyStatus] = useState('');
  const [cobPolicyNumber, setCobPolicyNumber] = useState('');
  const [cobStatusButton, setCobStatusButton] = useState({});
  const [cobPolicyStatusReason, setCobPolicyStatusReason] = useState('');
  const [cobTerminationDate, setCobTerminationDate] = useState('');
  const [policyTerminationDate, setPolicyTerminationDate] = useState('');
  const policyData = useSelector((state: any) => state.policies);
  const [policyNotes, setPolicyNotes] = useState<DisclaimerNote[]>([]);
  
  const {
    // token = {},
    getPoliciesListData = {},
    isError = false,
    loading = true,
    // error = '',
  } = policyData || {};
  useEffect(() => {
    dispatch(getUserDetails(props.user));
  }, []);



  useEffect(() => {
    if (Object.keys(getPoliciesListData).length > 0) {
      try {
        const {policyHighlights = [], account = {}} =
          getPoliciesListData?.data?.policySummary || {};
        const {
          statusInfo = {},
          generalPolicyData = {},
          policyRelationships = [],
          policyValue = {},
          coverageInformation = {},
          ltcSection = {},
          continuationOfBenefit = {},
          disclaimersNotes = [],
        } = policyHighlights[0] || {};
        const {render: generalPolicyDataRender} = generalPolicyData || {};
        const {policyValues = [], valueAsOfDate = ''} = policyValue || {};
        const {addCoverages = [], baseCoverages = []} =
          coverageInformation || {};
        setGeneralPolicyDataRender(generalPolicyDataRender);
        const filteredObject = {};
        displableBillingAndPremiumKeys.forEach((key) => {
          if (generalPolicyData[key] && generalPolicyDataRender[key]) {
            filteredObject[key] = generalPolicyData[key];
          }
        });
        const filteredNotes = disclaimersNotes.filter(
          (notes: any) =>
            notes.disclaimerKey !== DISCLAIMER_NOTE_KEY_TO_HIDE_FOR_COB,
        );
        setPolicyNotes(filteredNotes);
        const {policyStatus, policyStatusReason, terminationDate} =
          statusInfo || {};
        const displayStatus =
          policyStatus === 'Extended Insurance'
            ? 'Extended Term Insurance'
            : policyStatus === 'Reinstatement pendin' ||
              policyStatus === 'Reinstatement pending'
            ? 'Reinstatement Pending'
            : policyStatus;
        setButtonStatus(displayStatus);
        setTerminatedReason(policyStatusReason);
        const colors = getButtonColors(policyStatus);
        setPolicyStatusButton(colors);
        setAccount(account);
        setGeneralPolicyData(generalPolicyData);
        setMoreBillingAndPremium(Object.keys(filteredObject));
        setInitialPolicyData(Object.keys(filteredObject).splice(0, 3));
        setPolicyHighlights(policyHighlights);
        if (terminationDate) {
          setPolicyTerminationDate(
            moment(terminationDate).format('MMM DD, YYYY'),
          );
        }

        if (
          continuationOfBenefit &&
          Object.keys(continuationOfBenefit).length > 0
        ) {
          const {policyStatus, policyStatusReason, terminationDate} =
            continuationOfBenefit.statusInfo || {};
          setShowCOBInPolicyHighlights(true);
          setCobPolicyNumber(continuationOfBenefit.account.number);
          setCobPolicyStatus(policyStatus);
          setCobPolicyStatusReason(policyStatusReason);
          if (terminationDate) {
            setCobTerminationDate(
              moment(terminationDate).format('MMM DD, YYYY'),
            );
          }
          const colors = getButtonColors(policyStatus);
          setCobStatusButton(colors);
        } else setShowCOBInPolicyHighlights(false);
        const policyRelationshipResponse = policyRelationships;
        const policyValuesData = policyValues || [];
        const policyCoverages = addCoverages;

        const filteredPolicyValues = policyValuesData.filter(
          (item) =>
            item.amount !== null &&
            item.amount !== undefined &&
            item.type !== 'Loan Balance' &&
            item.text !== '',
        );
        const hasTotalDeathBenefit = policyCoverages?.some(
          (item) => item.name === 'Total Death Benefit',
        );
        if (
          account.type === 'WL' &&
          (account.affiliate === 'AUL' || account.affiliate === 'PML')
        ) {
          const aulOrPmlUser = aulOrPmWLPolicies(addCoverages, policyValue);

          const flatObject = flattenObject(aulOrPmlUser);
          const finalAulOrPmlUserObject = removeFalsyFromObject(flatObject);
          const data = Object.keys(finalAulOrPmlUserObject).map((item) => {
            return getWLTitleAndValue(item, finalAulOrPmlUserObject);
          });
          const val = getVal(data);

          setPolicyValueCardData(val);
          setMorePolicyValue(Object.keys(data));
        } else if (account.type === 'WL' && account.affiliate === 'SL') {
          const slWLUser = slWLPolicies(baseCoverages, policyValue);

          const flatObject = flattenObject(slWLUser);
          const finalSlWLObject = removeFalsyFromObject(flatObject);
          const data = Object.keys(finalSlWLObject).map((item) => {
            return getSlWlTitleAndValue(item, finalSlWLObject);
          });
          const val = getVal(data);
          setPolicyValueCardData(val);
          setMorePolicyValue(Object.keys(data));
        } else if (account.type === 'INXAN') {
          const annuityUser = annuityPolicies(ltcSection, policyValue);
          const flatObject = flattenObject(annuityUser);

          const finalannuityObject = removeFalsyFromObject(flatObject);
          const data = Object.keys(finalannuityObject).map((item) => {
            return getAnnuityValue(item, finalannuityObject);
          });
          const val = getVal(data);
          setPolicyValueCardData(val);
          setMorePolicyValue(Object.keys(data));
        } else if (
          account.type === 'ISL' ||
          account.type === 'UL' ||
          account.type === 'SPVA' ||
          account.type === 'SPVUL' ||
          account.type === 'FPVA' ||
          account.type === 'FPVUL' ||
          account.type === 'VUL' ||
          account.type === 'SPFA' ||
          account.type === 'FPFA' ||
          account.type === 'TERM'
        ) {
          const otherPolicyUser = otherPolicies(policyValue);
          const flatObject = flattenObject(otherPolicyUser);
          const finalOtherPolicyObject = removeFalsyFromObject(flatObject);
          const data = Object.keys(finalOtherPolicyObject).map((item) => {
            return getOtherPolicyValue(item, finalOtherPolicyObject);
          });
          const val = getVal(data);
          setPolicyValueCardData(val);
          setMorePolicyValue(Object.keys(data));
        } else {
          setHasTotalDeathBenefit(hasTotalDeathBenefit);
          if (hasTotalDeathBenefit) {
            const pvDeathBenefit = [...filteredPolicyValues];
            const pvDeathBenefit2 = [
              ...filteredPolicyValues,
              policyCoverages.find(
                (item) => item.name === 'Total Death Benefit',
              ),
            ];
            pvDeathBenefit
              .splice(
                1,
                0,
                policyCoverages.find(
                  (item) => item.name === 'Total Death Benefit',
                ),
              )
              .filter((item) => item);
            setPolicyValueCardData(pvDeathBenefit.splice(0, 3));
            setMorePolicyValue(pvDeathBenefit2);
          } else {
            const tempPolicyValue = [...filteredPolicyValues];
            setPolicyValueCardData(filteredPolicyValues.splice(0, 3));
            setMorePolicyValue(tempPolicyValue);
          }
        }
        setPolicyValue({
          policyValues: policyValuesData,
          valueAsOfDate: valueAsOfDate,
        });
        const apiResponse = policyRelationshipResponse;
        const zeroIndexData = {
          relationType: 'Relationship',
          personDetails: {
            name: {
              first: 'Name',
            },
          },
        };
        apiResponse.unshift(zeroIndexData);

        const reorderedApiResponse = termOrder
          .flatMap((term) =>
            apiResponse.filter((item) => item.relationType === term),
          )
          .filter((item) => item !== undefined);

        reorderedApiResponse.unshift(zeroIndexData);

        const subsetArray = reorderedApiResponse.slice(0, 5);
        const popupDataAll = reorderedApiResponse.filter(
          (item) => item.relationType != 'Current Servicing Agent',
        );
        setPopupData(subsetArray);
        setPopupDataAll(popupDataAll);
        setPolicyRelationshipsData(policyRelationshipResponse);
      } catch (error) {
        console.log('ERROR: ' + JSON.stringify(error));
      }
    }
  }, [policyData]);

  const getModalDetails = () => {
    NetworkManager.getPolicyRelationshipModal()
      .then((response) => {
        setInfoModalContent(
          response.data.policyRelationshipsModelByPath.item
            .descriptionForPolicyRelationshipInformation.json,
        );
      })
      .catch((error) => {
        console.log('Error fetching policy relationship:', error);
        setInfoModalContent(
          aemModalContent.data.policyRelationshipsModelByPath.item
            .descriptionForPolicyRelationshipInformation.json,
        );
      });
    setInfoModalVisiblity(true);
  };
  const navigateToPolicyDetails = (cardName: string) => {
    navigation.navigate(RouteConstants.policydetails, {target: cardName});
  };

  const navigateToMyInvestments = () => {
    navigation.navigate(RouteConstants.myinvestments);
  };

  const navigateToMyStrategies = () => {
    navigation.navigate(RouteConstants.mystrategies);
  };

  const navigateToCOBDetails = (cardName: string) => {
    navigation.navigate(RouteConstants.policydetails, {target: cardName});
  };

  return {
    loading,
    isError,
    policyValue,
    account,
    policyHighlights,
    policyStatusButton,
    buttonStatus,
    terminatedReason,
    policyValueCardData,
    hasTotalDeathBenefit,
    morePolicyValue,
    initialPolicyData,
    generalPolicyData,
    generalPolicyDataRender,
    moreBillingAndPremium,
    popupData,
    popupDataAll,
    isInfoModalVisible,
    infoModalContent,
    modalTitles,
    isModalVisible,
    relationType,
    isModalViewAllRelationShip,
    policyRelationshipsData,
    showCOBInPolicyHighlights,
    cobPolicyNumber,
    cobPolicyStatus,
    cobStatusButton,
    policyTerminationDate,
    cobPolicyStatusReason,
    cobTerminationDate,
    setModalTitles,
    setRelationType,
    setModalVisibility,
    setModalViewAllRelationShip,
    setInfoModalVisiblity,
    getModalDetails,
    navigateToPolicyDetails,
    navigateToMyInvestments,
    navigateToMyStrategies,
    navigateToCOBDetails,
    policyNotes,
  };
};

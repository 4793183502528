import React, {useRef} from 'react';
import {View} from 'react-native';
import {
  EmptyState,
  Text,
  LoadingIndicator,
  Link,
} from '@oneamerica/dxp-ui-components';
import {useSelector} from 'react-redux';
import responsiveStyle from './style';
import {useNavigation} from '@react-navigation/native';
import RouteConstants from '../../constants/RouteConstants';
import {trackCustomEvent} from '../../utils/Analytics/AdobeAnalyticsUtils';
import {analyticsTags} from '../../utils/Analytics/AnalyticsTags';

const style = responsiveStyle();

const ErrorWrapper = ({
  children = null,
  hasError = false,
  isLoading = false,
}: {
  children?: React.ReactNode;
  hasError?: boolean;
  isLoading?: boolean;
}): React.JSX.Element => {
  const {isError = false, loading} = useSelector(
    (state: any) => state.policies,
  );
  const {selectedPolicy, error} = useSelector(
    (state: any) => state.policyLists,
  );

  const containerRef = useRef(null);
  const navigation = useNavigation<any>();
  const linkStyleContactUs = {
    style: style.linkContactUs,
    linkStyle: style.linkContactUs,
    linkHoverStyle: style.linkContactUs,
    hoverStyle: style.linkContactUsHover,
    focusStyle: style.linkContactUsFocus,
  };

  const navigateToContactUs = () => {
    navigation.navigate(RouteConstants.contactus);
  };

  if (loading || isLoading) {
    return <LoadingIndicator loading={loading || isLoading} />;
  }

  return isError || error !== undefined || hasError ? (
    <View
      ref={containerRef}
      className="w-1/2 self-center mt-12"
      style={[style.cardShadow, style.paddingHorizontalXSmall]}>
      <EmptyState
        //@ts-ignore
        height={'auto'}>
        <React.Fragment>
          <View style={style.horizontalDivider} />
          {selectedPolicy?.policyPlanNumber && (
            <View className="mt-4 items-center">
              <Text style={style.policyNumberTitle}>Policy Number</Text>
              <Text style={style.policyNumberText}>
                {selectedPolicy?.policyPlanNumber}
              </Text>
            </View>
          )}
          <Text style={style.contactUsText}>
            Account information is not currently available for this policy. If
            you have any questions, please reference the{' '}
            <Link
              onPress={() => {
                trackCustomEvent(
                  analyticsTags.CaptureContactUsErrorsMessageTags,
                );
                navigateToContactUs();
              }}
              accessibilityLabel="Contact Us"
              accessibilityRole="button"
              {...linkStyleContactUs}>
              Contact Us
            </Link>{' '}
            page.
          </Text>
        </React.Fragment>
      </EmptyState>
    </View>
  ) : (
    (children as React.JSX.Element)
  );
};

export default ErrorWrapper;

import React, {ReactElement, useState} from 'react';
import {View} from 'react-native';
import {Components, Theme} from '@oneamerica/dxp-ui-components';
import ChangePassword from '../../../components/ChangePassword';
import ChangeUserId from '../../../components/ChangePassword/ChangeUserId';
import {ProfileCardProps} from '../../types';
import withAuth from '../../../../auth/withAuth';
import responsiveStyle from './style';
import {useProfileDetailsSelector} from '../../hooks/profileDetails';
import {useDispatch} from 'react-redux';
import {
  updateProfileData,
  updateProfileDataReset,
} from '../../../../redux/profileDetails/action';
import {AlertLevel} from '../../../../constants/UtilsConstants';
import {ChangePasswordContents} from '../../../../constants/ProfileConstants';
import {trackCustomEvent} from '../../../../utils/Analytics/AdobeAnalyticsUtils';
import {analyticsTags} from '../../../../utils/Analytics/AnalyticsTags';

const PasswordCard = (props: ProfileCardProps): ReactElement | null => {
  const [success, setSuccess] = useState(false);
  const {width} = React.useContext(Theme.ResponsiveContext);
  const style = responsiveStyle(width);
  const {showMenu, authLogout, activeMenu, scrollToTop} = props;
  const [tabIndex, setTabIndex] = useState<number>(0);
  const {isError, loading, getProfileResponsiveData, isProfileUpdated} =
    useProfileDetailsSelector(props);
  const dispatch = useDispatch();

  const onSavePassword = (newPass, currpass) => {
    const dataValue = {
      password: currpass,
      logonInfo: {
        newPassword: newPass,
      },
    };
    dispatch(updateProfileData(dataValue, props?.accessToken?.accessToken));
    setSuccess(true);
  };

  const passwordContent = {
    changePasswordToggleLabel: ChangePasswordContents.changePasswordToggleLabel,
    passwordHeader: ChangePasswordContents.passwordHeader,
    currentPasswordLabel: ChangePasswordContents.currentPasswordLabel,
    incorrectPasswordText: ChangePasswordContents.incorrectPasswordText,
    newPasswordLabel: ChangePasswordContents.newPasswordLabel,
    weakLabel: ChangePasswordContents.weakLabel,
    fairLabel: ChangePasswordContents.fairLabel,
    goodLabel: ChangePasswordContents.goodLabel,
    greatLabel: ChangePasswordContents.greatLabel,
    strongLabel: ChangePasswordContents.strongLabel,
    passwordMustBeMinimum8CharactersText:
      ChangePasswordContents.passwordMustBeMinimum8CharactersText,
    confirmNewPasswordLabel: ChangePasswordContents.confirmNewPasswordLabel,
    passwordDoesNotMatchText: ChangePasswordContents.passwordDoesNotMatchText,
    passwordRequirementsHeader:
      ChangePasswordContents.passwordRequirementsHeader,
    passwordRequirementsTextLine1:
      ChangePasswordContents.passwordRequirementsTextLine1,
    passwordRequirementsTextLine2:
      ChangePasswordContents.passwordRequirementsTextLine2,
    passwordRequirementsTextLine3:
      ChangePasswordContents.passwordRequirementsTextLine3,
    passwordRequirementsTextLine4:
      ChangePasswordContents.passwordRequirementsTextLine4,
    passwordRequirementsTextLine5:
      ChangePasswordContents.passwordRequirementsTextLine5,
    passwordRequirementsTextLine6:
      ChangePasswordContents.passwordRequirementsTextLine6,
    passwordRequirementsTextLine7:
      ChangePasswordContents.passwordRequirementsTextLine7,
    passwordRequirementsTextLine8:
      ChangePasswordContents.passwordRequirementsTextLine8,
    passwordRequirementsTextLine9:
      ChangePasswordContents.passwordRequirementsTextLine9,
    passwordRequirementsTextLine10:
      ChangePasswordContents.passwordRequirementsTextLine10,
    confirmPasswordChangesText:
      ChangePasswordContents.confirmPasswordChangesText,
  };

  const userIdContent = {
    confirmUserIdChangesText: 'Your User ID has been successfully updated',
    currentUserIdLabel: 'Current User ID',
    newUserIdLabel: 'New User ID',
    availabilityLabel: 'Check User ID Availability',
    confirmUserIdLabel: 'Confirm User ID',
    title: 'User ID Requirements',
    useridRequirementsTextLine1: '6 characters minimum',
    useridRequirementsTextLine2: '64 characters maximum',
    useridRequirementsTextLine3: 'Must be end with a letter or number',
    useridRequirementsTextLine4: 'Allowable Special Characters include:-_.@',
    useridRequirementsTextLine5: 'Cannot contain 9 numbers',
    useridRequirementsTextLine6: 'Cannot contain spaces',
  };

  const closeButton = () => {
    dispatch(updateProfileDataReset());
  };

  const passwordCard = (
    <Components.Card>
      {loading && <Components.LoadingOverlay loading={loading} />}
      <Components.Text.H2 style={style.cardHeaderTitle} className="lg:mt-2">
        Password
      </Components.Text.H2>
      <ChangePassword
        modalTitleText={passwordContent.confirmPasswordChangesText}
        closeButton={closeButton}
        isError={isError}
        success={success}
        isProfileUpdatedValue={isProfileUpdated}
        alertLevel={AlertLevel.ERROR}
        activeMenu={activeMenu}
        Index={tabIndex}
        content={passwordContent}
        onSubmit={(newPass, currPass) => onSavePassword(newPass, currPass)}
        onCancel={() => {
          typeof showMenu === 'function' && showMenu('contact');
          closeButton();
          scrollToTop();
        }}
        userId={getProfileResponsiveData?.data?.logonID}
        hideCurrentPasswordField={false}
        newPasswordTestID="changePass-new"
        currentPasswordTestID="changePass-current"
        confirmPasswordTestID="changePass-confirm"
      />
    </Components.Card>
  );

  const userID = (
    <ChangeUserId
      accessToken={props.accessToken}
      content={userIdContent}
      authLogout={authLogout}
      onCancel={() => {
        typeof showMenu === 'function' && showMenu('contact');
        scrollToTop();
      }}
    />
  );

  return (
    <View>
      <Components.CardTabs
        content={[passwordCard, userID]}
        setIndex={(index) => {
          setTabIndex(index ?? 0);
          if (index === 0) {
            trackCustomEvent(analyticsTags.CaptureChangePasswordIDTags);
          } else {
            trackCustomEvent(analyticsTags.CaptureChangeUserIDTags);
          }

          closeButton();
        }}
        tabs={[
          {
            key: 'password',
            testID: 'password-tab',
            title: 'Change Password',
          },
          {
            key: 'userid',
            testID: 'userid-tab',
            title: 'Change User ID',
          },
        ]}
      />
    </View>
  );
};

export default withAuth(PasswordCard);

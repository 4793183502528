import {Theme, Font} from '@oneamerica/dxp-ui-components';
import ColorConstants from '../../../../constants/ColorConstants';

export default () =>
  Theme.Responsive.create({
    placeholderWrapper: {
      minHeight: 24,
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    placeholderBold: {
      fontSize: 14,
      fontWeight: 'bold',
      fontFamily: Font.regular.fontFamily,
      color: ColorConstants.iron,
    },
    placeholderItalic: {
      fontSize: 13,
      fontFamily: Font.regular.fontFamily,
      fontStyle: 'italic',
      color: ColorConstants.iron,
    },
    placeholderParagraphText: {
      fontSize: 14,
      lineHeight: 20,
      marginVertical: 8,
      fontFamily: Font.regular.fontFamily,
      fontWeight: 'normal',
      color: ColorConstants.iron,
    },
    disclaimerText: {
      fontSize: 13,
      color: ColorConstants.iron,
      fontFamily: Font.regular.fontFamily,
      lineHeight: 21,
    },
    modalTitle: {
      fontFamily: Font.regular.fontFamily,
      fontSize: 20,
      fontWeight: '400',
      zIndex: -1,
      padding: 2,
    },
    smModalSize: {
      margin: 'auto',
      maxHeight: '70%',
      maxWidth: '90%',
    },
    mdModalSize: {
      marginVertical: '365px',
      marginHorizontal: 'auto',
      maxHeight: '80%',
      minWidth: '50%',
    },
    lgModalSize: {
      marginVertical: '365px',
      marginHorizontal: 'auto',
      maxHeight: '80%',
      minWidth: '50%',
    },
    xlModalSize: {
      marginVertical: '365px',
      marginHorizontal: 'auto',
      maxHeight: '80%',
      minWidth: '50%',
      maxWidth: '90%',
    },
  })();

import React from 'react';
import {NativeWindStyleSheet} from 'nativewind';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {Provider} from 'react-redux';
import Store from './src/redux/store/store';
import AuthProvider from './src/auth/AuthProvider';
import Navigation from './src/navigation/Navigation';
import {Notification} from './src/components/Notification';

NativeWindStyleSheet.setOutput({
  default: 'native',
});

const App = () => {
  return (
    <SafeAreaProvider>
      <AuthProvider>
        <Provider store={Store}>
          <Notification />
          <Navigation />
        </Provider>
      </AuthProvider>
    </SafeAreaProvider>
  );
};

export default App;

import {Theme} from '@oneamerica/dxp-ui-components';

const styles = () =>
  Theme.Responsive.create({
    linkStyle: {
      color: Theme.Color.oaBlueLight,
      fontSize: 10,
      fontWeight: 'bold',
      letterSpacing: 1.5,
    },
    slashStyle: {
      color: Theme.Color.oaBlueLight,
      fontSize: 10,
      fontWeight: 'bold',
      letterSpacing: 1.5,
    },
    linkHoverStyle: {
      color: Theme.Color.oaBlueLight,
      fontSize: 10,
      fontWeight: 'bold',
      letterSpacing: 1.5,
    },
    hoverStyle: {
      textDecorationLine: 'none',
    },
    textStyle: {
      color: Theme.Color.gray4,
      fontSize: 10,
      fontWeight: 'bold',
      letterSpacing: 1.5,
    },
  })();

export default styles;

import {View} from 'react-native';
import {Theme} from '@oneamerica/dxp-ui-components';
import responsiveStyle from './../style';
import withAuth from '../../../../auth/withAuth';
import React, {useContext} from 'react';
import BreakpointConstants from '../../../../constants/BreakpointConstants';
import {prepareTransferInvestmentOptionsDataReview} from '../../../../utils';
import InvestmentChart from '../../../../components/InvestmentTable';
import {useSelector} from 'react-redux';
import {
  columnConfiguration,
  columnWidthArr,
} from '../ReviewDollarTransfer/columnConfig';

const ConfirmDollarTransfer = (props) => {
  const {width} = useContext(Theme.ResponsiveContext);
  const style = responsiveStyle(width);
  const transferBalanceResponseData = useSelector(
    (state: any) => state.transferBalance.getDollarTransferData,
  );
  const transferBalanceData =
    transferBalanceResponseData.data?.items[0].investmentOptionChanges || [];
  const selectedTab = props?.route?.params?.tab == 'Current-Balance' ? 1 : 0;
  const screenType = BreakpointConstants.isLG(width)
    ? 'LG'
    : BreakpointConstants.isMD(width)
    ? 'MD'
    : 'SM';

  const getPieChartSize = (width) => {
    if (BreakpointConstants.isLG(width)) {
      return {height: 319, width: 319};
    } else if (BreakpointConstants.isMD(width)) {
      return {height: 290, width: 290};
    }
    return {height: 290, width: 290};
  };

  const renderCards = React.useMemo(() => {
    const elements: React.JSX.Element[] = [];

    if (transferBalanceData.length) {
      const investmentFormattedData =
        prepareTransferInvestmentOptionsDataReview(props.editedData, false);
      props.previousData = investmentFormattedData;
      const mappedData = investmentFormattedData.AllAssets;

      elements.push(
        <InvestmentChart
          className="mt-0"
          key={'confirm-dollar-transfer-table'}
          data={mappedData}
          accountBalance={100}
          showChildRows={false}
          columnWidthArr={columnWidthArr}
          columnConfig={columnConfiguration}
          assetTotal={investmentFormattedData.percentRoundTotal}
          horizontal={BreakpointConstants.isLG(width) ? false : true}
          legendTableTitle={{
            text: '',
            titleStyle: style.headingTable,
          }}
          showPieChart={false}
          showAlternateRowColors={false}
          fullHeight={true}
          showBorder={false}
          setIsErrorInTable={props.setIsErrorInTable}
          setIsDirty={props.setIsDirty}
          isDollarTransfer={true}
          showLegendTable={true}
          isMobileTableFooter={BreakpointConstants.isLG(width) ? false : true}
        />,
      );
    }
    return elements;
  }, [screenType, selectedTab, transferBalanceData]);

  const renderCharts = React.useMemo(() => {
    const elements: React.JSX.Element[] = [];
    if (transferBalanceData.length) {
      const tempEditedData = [...props.editedData];
      const investmentFormattedData =
        prepareTransferInvestmentOptionsDataReview(props.editedData, false);
      const newElectionsData = prepareTransferInvestmentOptionsDataReview(
        tempEditedData,
        true,
      );
      props.previousData = investmentFormattedData;
      const mappedData = investmentFormattedData.mappedData;
      const newElectionsMappedData = newElectionsData.mappedData;
      const {width} = useContext(Theme.ResponsiveContext);
      const screenType = BreakpointConstants.isLG(width)
        ? 'LG'
        : BreakpointConstants.isMD(width)
        ? 'MD'
        : 'SM';

      const pieChartSize = getPieChartSize(width);
      const isMobile = screenType === 'SM';

      elements.push(
        <View
          className="lg:pr-5"
          key={'review-dollar-current-allocation-chart'}
          style={style.pieChartColumn}>
          <View style={style.piechartHeading}>Current Allocations</View>
          <InvestmentChart
            className="mt-0"
            data={mappedData}
            accountBalance={100}
            showChildRows={false}
            pieChartHeight={pieChartSize.height}
            pieChartWidth={pieChartSize.width}
            columnWidthArr={columnWidthArr}
            columnConfig={columnConfiguration}
            assetTotal={investmentFormattedData.percentRoundTotal}
            horizontal={isMobile}
            legendTableTitle={{
              text: '',
              titleStyle: style.headingTable,
            }}
            showPieChart={true}
            showDollarSymbol={true}
            showLegendTable={false}
            showAlternateRowColors={false}
            fullHeight={true}
            setIsErrorInTable={props.setIsErrorInTable}
            setIsDirty={props.setIsDirty}
          />
        </View>,
      );
      elements.push(
        <View
          className="lg:pl-5"
          key={'review-dollar-new-allocation-chart'}
          style={style.pieChartColumn}>
          <View style={style.piechartHeading}>New Allocations</View>
          <InvestmentChart
            className="mt-0"
            data={newElectionsMappedData}
            accountBalance={100}
            showChildRows={false}
            pieChartHeight={pieChartSize.height}
            pieChartWidth={pieChartSize.width}
            columnWidthArr={columnWidthArr}
            columnConfig={columnConfiguration}
            assetTotal={investmentFormattedData.percentRoundTotal}
            horizontal={isMobile}
            legendTableTitle={{
              text: '',
              titleStyle: style.headingTable,
            }}
            showPieChart={true}
            showDollarSymbol={true}
            showLegendTable={false}
            showAlternateRowColors={false}
            fullHeight={true}
            setIsErrorInTable={props.setIsErrorInTable}
            setIsDirty={props.setIsDirty}
          />
        </View>,
      );
    }
    return elements;
  }, [screenType, selectedTab, transferBalanceData]);

  return (
    <View>
      <div id="confirmpagetable">{renderCards}</div>
      <View
        className="flex-col md:flex-row"
        style={{
          flexDirection: props.flexDirection,
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {renderCharts}
      </View>
    </View>
  );
};

export default withAuth(ConfirmDollarTransfer);

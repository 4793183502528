import React from 'react';
import {useWindowDimensions, View} from 'react-native';
import responsiveStyle from './styles';
const style = responsiveStyle();
import {Components, Font} from '@oneamerica/dxp-ui-components';
import InfoWrapper from '../../../../components/InfoWrapper/InfoWrapper';
import FlatListTable, {
  FlatListColProps,
} from '../../../../components/FlatListTable/FlatListTable';

const ContinuationOfBenefit = ({
  coverageInformationData,
  continuationOfBenefitGeneralInfo,
  continuationofBenefitAddCoverage,
}) => {
  const {width} = useWindowDimensions();
  const maxMobileScreenWidth = 768;
  const headerColumnRider: FlatListColProps[] = [
    {
      key: 'name',
      title: 'Rider/Benefit Name',
      className: 'pl-4 md:pl-4',
    },
  ];
  const horizontalScrollEnabled = width < maxMobileScreenWidth;
  return (
    <View className="lg:flex-row">
      <View className="w-full p-0 mt-8">
        <View style={style.cardShadow}>
          <View style={style.cardHeader}>
            <Components.Text.H3 style={[style.cardHeaderTitle, style.flex]}>
              Continuation of Benefits
            </Components.Text.H3>
          </View>
          <View>
            <Components.Text style={style.contentOfCob}>
              The Continuation of Benefits rider/option provides extended
              benefits after your base policy has been depleted through
              long-term care withdrawals.
            </Components.Text>
            <Components.Text style={style.contentOfCob}>
              What follows is an overview of the additional benefits you have
              elected.
            </Components.Text>
          </View>
          <View className="mt-4" style={style.horizontalDivider} />
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
            className="mt-2 w-full">
            {continuationOfBenefitGeneralInfo.map((data, index) => {
              return (
                <View
                  key={`${data.title}-${index}`}
                  style={{
                    paddingBottom: 20,
                  }}
                  className="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4">
                  <InfoWrapper
                    title={data.title}
                    titleTextStyle={{
                      paddingBottom: 8,
                    }}
                    subTitle
                    information={data.value || '-'}
                    infoTextStyle={{
                      fontWeight: '500',
                    }}
                  />
                </View>
              );
            })}
          </View>
          {coverageInformationData != null &&
            coverageInformationData.length > 0 && (
              <View className="lg:flex-row">
                <View className="w-full p-0">
                  <View style={style.cardHeader}>
                    <Components.Text.H3
                      style={[style.coverageInformTitle, style.flex]}>
                      Coverage Information
                    </Components.Text.H3>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                    }}
                    className="mt-4 w-full">
                    {coverageInformationData.map((data, index) => {
                      return (
                        <View
                          key={`${data.title}-${index}`}
                          style={{
                            paddingBottom: 20,
                          }}
                          className="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4">
                          <InfoWrapper
                            title={data.title}
                            titleTextStyle={{
                              paddingBottom: 8,
                            }}
                            subTitle
                            information={data.value || '-'}
                            infoTextStyle={{
                              fontWeight: '500',
                            }}
                          />
                        </View>
                      );
                    })}
                  </View>
                </View>
              </View>
            )}
          {continuationofBenefitAddCoverage != null &&
            continuationofBenefitAddCoverage.length > 0 && (
              <>
                <Components.Text
                  style={{
                    fontSize: 16,
                    fontFamily: Font.medium.fontFamily,
                    marginLeft: 8,
                  }}>
                  Additional Coverage
                </Components.Text>
                <View className="mt-4 lg:mx-2 md:mb-2">
                  <FlatListTable
                    columns={headerColumnRider}
                    data={continuationofBenefitAddCoverage}
                    scrollContainerStyle={
                      horizontalScrollEnabled && style.scrollView
                    }
                    altRow
                  />
                </View>
              </>
            )}
        </View>
      </View>
    </View>
  );
};

export default ContinuationOfBenefit;

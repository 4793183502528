import {Theme} from '@oneamerica/dxp-ui-components';

export default () =>
  Theme.Responsive.create({
    pieChartColumn: {
      minWidth: '35%',
      display: 'flex',
      alignItems: 'center',
      flexGrow: 0,
      flexShrink: 1,
      height: 'auto',
    },
    tableColumn: {
      minWidth: '65%',
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: 'auto',
      zIndex: -1,
    },
    tableColumnFullWidth: {
      minWidth: '65%',
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: 'auto',
      zIndex: -1,
    },
  })();

import {ANALYTICS_SCRIPT} from '../../config/getEnvironmentVars';
import {analyticsTags} from './AnalyticsTags';
import {EventType, PageRoute} from './enums';

declare global {
  interface Window {
    oneAmerDataLayer: Array<any>;
  }
}

//Get environment specific script
const analyticsScript = ANALYTICS_SCRIPT;

let isEmbed = false;
//Includes Adobe Analytics DTM script in <head> tag to capture events
const initalizeAnaltyics = (embed) => {
  if (typeof embed === 'string' && embed.indexOf('/') >= 0) {
    const embedCheck = embed.split('/');
    const embedID = embedCheck[embedCheck.length - 1];
    const embedIDxmin = embedID.replace('min.', '');

    const existingScript1 = document.querySelector(`script[src*='${embedID}']`);
    const existingScript2 = document.querySelector(
      `script[src*='${embedIDxmin}']`,
    );

    if (existingScript1 || existingScript2) {
      isEmbed = true;
    } else {
      const script = document.createElement('script');
      script.setAttribute('src', analyticsScript);
      document.head.appendChild(script);
    }
  }
};
const getPageTagsForCurrentRoute = (route) => {
  if (route === PageRoute.Home) {
    return analyticsTags.createHomePageViewTags;
  } else if (route === PageRoute.PolicyDetails) {
    return analyticsTags.createPolicyDetailsViewTags;
  } else if (route === PageRoute.MyInvestment) {
    return analyticsTags.createMyInvestmentPageViewTags;
  } else if (route === PageRoute.MyStrategies) {
    return analyticsTags.createMyStrategiesPageViewTags;
  } else if (route === PageRoute.ContactUs) {
    return analyticsTags.createContactusPageViewTags;
  } else if (route === PageRoute.PolicyServiceRequest) {
    return analyticsTags.createPolicyServiceRequestPageViewTags;
  } else if (route === PageRoute.MyProfile) {
    return analyticsTags.createMyProfilePageViewTags;
  } else if (route === PageRoute.TransferBalance) {
    return analyticsTags.createMyTransferBalancePageViewTags;
  } else if (route === PageRoute.PercentTransfer) {
    return analyticsTags.createPercentTransferPageViewTags;
  } else if (route === PageRoute.DollarTransfer) {
    return analyticsTags.createDollarTransferPageViewTags;
  } else if (route === PageRoute.MyInvestmentElections) {
    return analyticsTags.createMyInvestmentElectionsPageViewTags;
  }
  // Add other routes and their corresponding tags
  else {
    return analyticsTags.createHomePageViewTags;
  } // Default to home tags if route not found
};
//Custom Events Tracking
export const trackCustomEvent = (customEventTags) => {
  initalizeAnaltyics(analyticsScript);
  window.oneAmerDataLayer = window.oneAmerDataLayer || [];
  // Get the current route
  const currentRoute = window?.location?.pathname;
  // Get the corresponding page tags based on the current route
  const pageTags = getPageTagsForCurrentRoute(currentRoute);
  const eventObj = {
    event: EventType.CustomEvent,
    data: {
      page: pageTags,
      target: {
        name: customEventTags?.name,
        value: customEventTags?.value,
        type: customEventTags?.type,
      },
    },
  };
  window?.oneAmerDataLayer?.push(eventObj);
};

//PageView Events tracking
export const trackPage = (pagepayload) => {
  initalizeAnaltyics(analyticsScript);
  window.oneAmerDataLayer = window.oneAmerDataLayer || [];
  if (window?.oneAmerDataLayer?.data?.page?.name !== pagepayload?.name) {
    const eventObj = {
      event: EventType.PageView,
      data: {
        page: {
          name: pagepayload.name,
          type: pagepayload.type,
          section: pagepayload.section,
          section1: pagepayload.section1,
          section2: pagepayload.section2,
          section3: pagepayload.section3,
          lob: pagepayload.lob,
          persona: pagepayload.persona,
          productCategory: pagepayload.productCategory,
          productType: pagepayload.productType,
        },
        environment: {
          language: pagepayload.language,
          sitetype: pagepayload.siteType,
        },
      },
    };
    window.oneAmerDataLayer.push(eventObj);
  }
};

export const displableBaseCoverageTitle = [
  // Determines the order that title are displayed in the Billing and Premiums.
  'planOfInsurance',
  'name',
  'personInsured',
  'coverageBenefit',
  'deathBenefit',
  'deathBenefitOption',
  'annualPremium',
  'faceAmount',
  'monthlyLTCBenefit',
  'monthlyCOBBenefit',
  'COBMonths',
  'guaranteedInterestRatePeriod',
  'baseAnnualPremium',
  'monthlyBenefitAmt',
  'numberMonth',
];

export const displableIndexedDividendKeys = [
  'currentAllocationPercent',
  'dividendMaximizerRate',
  'maximumMultiplier',
  'scheduledAllocationPercent',
  'dividendOption',
];

export const displableIncomeInfoKeys = [
  'incomeOption',
  'incomeStartDate',
  'incomeSelected',
];

export const displableAvailableIncomeInfoKeys = [
  'monthly',
  'quarterly',
  'annually',
];

export const displableIncomePaymentInformationKeys = [
  'nextPayment',
  'nextPaymentDate',
  'lastPayment',
  'lastPaymentDate',
  'totalPayment',
  'incomeOption',
  'incomeStartDate',
];
export const displableCobBaseCoverageTitle = [
  // Determines the order that title are displayed in the Continuation of Benefits Base Coverage table
  'planOfInsurance',
  'annualPremium',
  'monthlyBenefitAmt',
  'numberMonth',
];

export const displableAnnuityRatesKeys = [
  'currRate',
  'wghtRate',
  'guaranteedRate',
  'ltcRate',
  'render',
  'labels',
];

export const dividendOptions = {
  'Paid-up additions': 'Paid-up Additions',
  'One year term additions, limit cash value': 'One Year Term Cash',
  'One year term addition (previous year term cost)/balance to PUAs':
    'Blended Term',
  'Loan reduction': 'Loan Reduction',
  'Deposit at Interest': 'Dividends on Deposit',
  'OYT Cash': 'One Year Term Cash',
};

export const DISCLAIMER_NOTE_KEY_TO_HIDE_FOR_COB = 'genPolicyData.ltc.note';

import {useState} from 'react';
import {NetworkManager} from '../../../../../api/NetworkManager';
import {trackCustomEvent} from '../../../../../utils/Analytics/AdobeAnalyticsUtils';
import {analyticsTags} from '../../../../../utils/Analytics/AnalyticsTags';

export const useIdSelector = (props) => {
  const [userAvailable, setUserAvailable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isError, setIsError] = useState(false);
  const [postApi, setPostApi] = useState(false);

  const getUserDetails = async (accessToken, newUserId) => {
    setIsLoading(true);
    setError('');

    try {
      const response = await NetworkManager.checkUserId(
        accessToken?.accessToken,
        newUserId,
      );
      setUserAvailable(response.data.allowed);
    } catch (err) {
      console.log('Error fetching user ID', err);
      setError('Error fetching user ID');
      setUserAvailable(false);
    } finally {
      setIsLoading(false);
    }
  };

  const getUserIdDetails = async (password, newId, accessToken) => {
    setIsLoading(true);
    setIsError(false);
    const dataValue = {
      password: password,
      logonInfo: {
        newLogonId: newId,
      },
    };
    try {
      const response = await NetworkManager.updateProfileData(
        dataValue,
        accessToken?.accessToken,
      );
      if (dataValue?.logonInfo?.newLogonId) {
        trackCustomEvent(
          analyticsTags.CaptureChangeUserIDSubmitSuccessEventTags,
        );
      }
      setPostApi(true);
      console.log(response);
    } catch (err) {
      console.log('Error fetching user ID', err);
      if (dataValue?.logonInfo?.newLogonId) {
        trackCustomEvent(
          analyticsTags.CaptureChangeUserIDSubmitFailureEventTags,
        );
      }
      setIsError(true);
      setPostApi(false);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    userAvailable,
    isLoading,
    error,
    getUserDetails,
    setUserAvailable,
    getUserIdDetails,
    isError,
    setIsError,
    postApi,
  };
};

import axios from 'axios';
import NetInfo from '@react-native-community/netinfo';
import {
  MULE_API_BASE_URL,
  AEM_API_BASE_URL,
  PROFILE_API_BASE_URL,
} from '../../config/getEnvironmentVars';

const createAxiosInstance = (baseURL, headers) => {
  const instance = axios.create({
    baseURL,
    headers,
  });

  const checkNetworkStatus = async () => {
    const state = await NetInfo.fetch();
    return state.isConnected;
  };

  instance.interceptors.request.use(async function (config) {
    const isOnline = await checkNetworkStatus();
    if (!isOnline) {
      throw new Error('Network is not available');
    }
    return config;
  });
  instance.interceptors.response.use(
    (response) => {
      return new Promise((resolve) => {
        resolve(response);
      });
    },

    (error) => {
      if (!error.response) {
        return new Promise((_, reject) => {
          reject(error);
        });
      }

      if (error.response === undefined) {
      } else if (
        error.response.status === 401 ||
        error.response.status === 400
      ) {
        return new Promise((_, reject) => {
          reject(error.response);
        });
      } else {
        return new Promise((_, reject) => {
          reject(error.response);
        });
      }
    },
  );
  return instance;
};

export const axiosInstanceAEM = createAxiosInstance(AEM_API_BASE_URL, {
  'content-Type': 'application/json',
});

export const axiosInstancePolicyHolder = createAxiosInstance(
  MULE_API_BASE_URL,
  {
    'X-OA-ClientId': 'CLIENTWEB',
    'Content-Type': 'application/json',
  },
);

export const axiosProfileHolder = createAxiosInstance(PROFILE_API_BASE_URL, {
  'X-OA-ClientId': 'CLIENTWEB',
  'Content-Type': 'application/json',
});

// Function to sanitize script content to prevent XSS
const sanitizeScript = (scriptContent) => {
  if (typeof scriptContent !== 'string') {
    scriptContent = String(scriptContent);
  }
  return scriptContent.replace(
    /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
    '',
  );
};
interface Window {
  qualtricsDataLayer: any[];
  givenName: string;
  familyName: string;
  email: string;
  policyNumber: string;
}
declare let window: Window;

export const initializeQualtrics = (
  embedQualtricsScript,
  qualtricsId,
  data,
) => {
  if (embedQualtricsScript) {
    // Sanitize embedQualtricsScript to prevent XSS
    const sanitizedScript = sanitizeScript(embedQualtricsScript);
    // Create and append the script element
    window.qualtricsDataLayer = window?.qualtricsDataLayer || [];
    window.familyName = data?.FamilyName;
    window.givenName = data?.GivenName;
    window.email = data?.email;
    window.policyNumber = data?.policyNumber;

    const qualtricsScript = document.createElement('script');
    qualtricsScript.type = 'text/javascript';
    qualtricsScript.async = true; //load script aynchronously
    qualtricsScript.textContent = sanitizedScript; // Use textContent to set script content safely

    const qualtricsDivElement = document.createElement('div');
    qualtricsDivElement.setAttribute('id', qualtricsId);
    document.head.appendChild(qualtricsScript);
    document.head.appendChild(qualtricsDivElement);
    window.qualtricsDataLayer.push(data);
  }
};

import {useEffect, useState} from 'react';
import {NetworkManager} from '../../../api/NetworkManager';
import contactusPageContent from '../../../mock/contactusPageContent.json';

export const useContactUsSelector = () => {
  const [contactUsData, setContactUsData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    setIsLoading(true);

    NetworkManager.getContactUsContent()
      .then((response) => {
        setContactUsData(response.data.data.contactusByPath);
        setIsLoading(false);
        setIsError(false);
      })
      .catch((error) => {
        console.log('Error fetching contact us:', error);
        setContactUsData(contactusPageContent.data.contactusByPath);
        setIsLoading(false);
        setIsError(true);
      });
  }, []);
  return {
    contactUsData,
    isLoading,
    isError,
  };
};

import {Components, Theme} from '@oneamerica/dxp-ui-components';
import {useEffect, useState} from 'react';
import {View, Pressable} from 'react-native';
import React, {useContext} from 'react';
import ScreenWrapper from '../../components/ScreenWrapper/ScreenWrapper';
import Breadcrumbs from '../../components/Breadcrumbs';
import withAuth from '../../auth/withAuth';
import PolicyDropdown from '../../components/PolicyDropdown';
import {Button} from '@oneamerica/dxp-ui-components';
import responsiveStyle from './style';
import TransferRules from './TransferRules';
import {
  FontAwesomeIcon,
  FontAwesomeIconStyle,
} from '@fortawesome/react-native-fontawesome';
import {useNavigation} from '@react-navigation/native';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {faMoneyBillTransfer} from '@fortawesome/pro-light-svg-icons/faMoneyBillTransfer';
import {faPercentage} from '@fortawesome/pro-light-svg-icons/faPercentage';
import ColorConstants from '../../constants/ColorConstants';
import RouteConstants from '../../constants/RouteConstants';
import {useSelector} from 'react-redux';
import {
  trackCustomEvent,
  trackPage,
} from '../../utils/Analytics/AdobeAnalyticsUtils';
import {analyticsTags} from '../../utils/Analytics/AnalyticsTags';

const TransferBalance = (props) => {
  const {width} = useContext(Theme.ResponsiveContext);
  const style = responsiveStyle(width);
  const [transferType, setTransferType] = useState('percent');
  const navigation = useNavigation<any>();
  const policyLists = useSelector((state: any) => state.policyLists);
  const navigateToPercentTransfer = () => {
    navigation.navigate(RouteConstants.percenttransfer, {
      account: policyLists?.selectedPolicy?.policyPlanNumber,
    });
  };
  useEffect(() => {
    trackPage(analyticsTags.createMyTransferBalancePageViewTags);
  }, []);

  const navigateToDollarTransfer = () => {
    navigation.navigate(RouteConstants.dollartransfer, {
      account: policyLists?.selectedPolicy?.policyPlanNumber,
    });
  };
  return (
    <ScreenWrapper scrollFooter paddingTop paddingBottom paddingHorizontal>
      <Breadcrumbs />
      <View style={style.cardHeader}>
        <PolicyDropdown
          className=""
          accessToken={props.accessToken}
          text={'Transfer Balance'}
        />
        <Components.Text style={[style.transferText, style.flex]}>
          Transfers are one-time transactions and affect only your current
          contribution allocations.
        </Components.Text>
        <View style={style.cardShadow}>
          <View className="mb-4">
            <Components.Text style={style.selectTransferText}>
              Select Transfer Type
            </Components.Text>
          </View>
          <View style={style.percentDollarTransferBoxes} className="ml-7">
            <Pressable
              onPress={() => {
                trackCustomEvent(analyticsTags.CapturePercentTransferTypeTags);
                setTransferType('percent');
              }}>
              <View
                style={
                  transferType !== 'percent'
                    ? style.percentDollarTransferBox
                    : style.selectedTransferBox
                }>
                <FontAwesomeIcon
                  style={style.outlineNone as FontAwesomeIconStyle}
                  icon={faPercentage as IconProp}
                  size={30}
                  color={
                    transferType === 'percent'
                      ? ColorConstants.lime
                      : ColorConstants.oaBlue
                  }
                />
                <Components.Text style={style.percentDollarTransferText}>
                  Percent Transfer
                </Components.Text>
              </View>
            </Pressable>
            <Pressable
              onPress={() => {
                trackCustomEvent(analyticsTags.CaptureDollarTransferTypeTags);
                setTransferType('dollar');
              }}>
              <View
                style={
                  transferType !== 'dollar'
                    ? style.percentDollarTransferBox
                    : style.selectedTransferBox
                }>
                <FontAwesomeIcon
                  style={style.outlineNone as FontAwesomeIconStyle}
                  icon={faMoneyBillTransfer as IconProp}
                  size={30}
                  color={
                    transferType === 'dollar'
                      ? ColorConstants.lime
                      : ColorConstants.oaBlue
                  }
                />
                <Components.Text style={style.percentDollarTransferText}>
                  Dollar Transfer
                </Components.Text>
              </View>
            </Pressable>
          </View>
          {transferType === 'dollar' && (
            <View style={style.dollarTransferInstBox}>
              <Components.Text style={style.dollarTransferText}>
                Dollar Transfer
              </Components.Text>
              <Components.Text style={style.dollarTransferContent}>
                Transfer your current account balance to different investment
                options by dollar amount. Dollar amounts will be translated to
                whole percentages.
              </Components.Text>
              <Components.Text style={style.dollarTransferContent}>
                The dollar amount you transfer to a new investment option or
                from a current investment option must be at least 1% of the
                investment in that option for the transfer to process.
              </Components.Text>
            </View>
          )}
          <View className="flex self-end mr-8 my-8">
            <Button
              className=""
              style={style.startButton}
              accessibilityRole="button"
              onPress={() => {
                if (transferType === 'dollar') {
                  trackCustomEvent(
                    analyticsTags.CaptureDollarTransferStartButton,
                  );
                } else {
                  trackCustomEvent(
                    analyticsTags.CapturePercentTransferStartButton,
                  );
                }
                transferType === 'dollar'
                  ? navigateToDollarTransfer()
                  : navigateToPercentTransfer();
              }}>
              Start
            </Button>
          </View>
          <TransferRules />
        </View>
      </View>
    </ScreenWrapper>
  );
};

export default withAuth(TransferBalance);

import {Theme, Font} from '@oneamerica/dxp-ui-components';
import ColorConstants from '../../../../constants/ColorConstants';

export default () =>
  Theme.Responsive.create({
    placeholderWrapper: {
      minHeight: 24,
      justifyContent: 'center',
    },
    modalTitle: {
      fontFamily: Font.medium.fontFamily,
      fontSize: 18,
      fontWeight: '500',
      zIndex: -1,
      padding: 2,
      marginBottom: 12,
    },
    smModalSize: {
      margin: 'auto',
      maxHeight: '70%',
      maxWidth: '90%',
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 'auto',
    },
    mdModalSize: {
      margin: 'auto',
      maxHeight: '80%',
      maxWidth: '70%',
    },
    lgModalSize: {
      marginVertical: 'auto',
      marginHorizontal: 'auto',
      maxHeight: '90%',
      maxWidth: '50%',
    },
    placeholderText: {
      fontFamily: Font.regular.fontFamily,
      fontSize: 14,
      color: ColorConstants.iron,
    },
    needToMakeChange: {
      color: ColorConstants.iron,
    },
    serviceRequestBtn: {
      fontSize: 15,
      fontFamily: Font.medium.fontFamily,
    },
    customeServiceRequestBtn: {
      backgroundColor: Theme.Color.ice4,
      borderRadius: 24,
      paddingHorizontal: 16,
      paddingVertical: 12,
    },
    tableRow: {
      backgroundColor: Theme.Color.table.row.background,
      flex: 1,
      flexDirection: 'row',
    },
    evenRow: {backgroundColor: Theme.Color.table.row.background},
    oddRow: {
      backgroundColor: Theme.Color.table.row.oddBackground,
    },
    tableCell: {
      paddingVertical: Theme.Metrics.xxSmall,
      paddingHorizontal: Theme.Metrics.xSmall,
    },
    tableCellLeft: {
      flex: 1,
      borderColor: Theme.Color.table.cell.borderColor,
      borderRightWidth: 1,
    },
    tableCellRight: {
      flex: 2,
    },
    tableCellLeftText: {
      color: ColorConstants.iron,
      fontFamily: Font.regular.fontFamily,
    },
    tableCellRightText: {
      fontFamily: Font.medium.fontFamily,
      color: ColorConstants.iron,
    },
  })();

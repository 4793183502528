export const columnWidthArrMob = ['60%', '16%', '24%'];
export const columnWidthArr = ['73%', '12%', '15%'];
export const columnConfiguration = [
  {
    columnName: 'Asset Class',
    format: 'string',
    showColumn: true,
    isTotal: true,
    textAlign: 'left',
    isCurrency: false,
    isPercentage: false,
  },
  {
    columnName: '%',
    format: 'string',
    showColumn: true,
    isTotal: false,
    textAlign: 'right',
    isCurrency: false,
    isPercentage: true,
  },
  {
    columnName: '$',
    format: 'string',
    showColumn: true,
    isTotal: true,
    textAlign: 'right',
    isCurrency: true,
    isPercentage: false,
  },
];

export const childRowsColumnConfig = {
  childRowsParentWidths: ['58%', '20%', '22%'],
  columnWidths: ['58%', '20%', '22%'],
  childTableColumnHeadings: ['Investment Name', 'Units', 'Unit Value'],
  isHyperlink: [true, false, false],
  showChildColumns: [true, true, true],
};

export default {
  grey200: '#D6D6D6',
  iron: '#333333',
  lightGreen: '#EFF4E7',
  darkGreen: '#438300',
  sun90: 'rgba(244, 181, 49, 0.9)',
  sun30: 'rgba(244, 181, 49, 0.3)',
  modalBG: '#474747CC',
  grey: '#FAFAFA',
  darkRed: '#E23D28',
  lightRed: '#F8D3CF',
  darkBlue: '#69869A',
  lightBlue: '#EEF7FC',
  lightGrey: '#EAEAEA',
  darkGrey: '#707070',
  oaBlueTint: '#2B4F8E',
  oaBlue: '#002c77',
  oaIceBlue: '#8BBFF3',
};

import React, {useEffect} from 'react';
import {Platform} from 'react-native';
import {Theme} from '@oneamerica/dxp-ui-components';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {createDrawerNavigator} from '@react-navigation/drawer';
import responsiveStyles from './styles';
import OAHeader from '../../components/Header';
import DrawerContent from '../../components/DrawerContent';
import StackNavigation from '../Stack';
import {initializeQualtrics} from '../../utils/QualtricsUtils';
import {useDispatch, useSelector} from 'react-redux';
import {getPoliciesList} from '../../redux/policies/action';

const DrawerNavigator = ({
  currentRoute,
  authLogout,
  initialState,
  accessToken,
}) => {
  const dispatch = useDispatch();
  const {top} = useSafeAreaInsets();
  const Drawer = createDrawerNavigator();
  const {width} = React.useContext(Theme.ResponsiveContext);
  const styles = responsiveStyles(top, width);

  const policyData = useSelector((state: any) => state.policies);
  const {getPoliciesListData = {}} = policyData || {};
  const policyList = useSelector((state: any) => state.policyLists);
  const {selectedPolicy} = policyList;
  const profileData = useSelector(
    (state: any) => state.profileData?.userDetails,
  );

  useEffect(() => {
    if (Object.keys(getPoliciesListData).length === 0 && selectedPolicy) {
      dispatch(getPoliciesList(selectedPolicy?.policyPlanNumber, accessToken));
    }
  }, [policyList]);
  useEffect(() => {
    const userData = {
      GivenName: profileData?.given_name,
      FamilyName: profileData?.family_name,
      email: profileData?.email,
      policyNumber: selectedPolicy?.policyPlanNumber,
    };
    if (selectedPolicy?.policyPlanNumber) {
      initializeQualtrics(QUALTRICS_SCRIPT, QUALTRICS_ID, userData);
    }
  }, [policyList]);
  const header = () => (
    <OAHeader
      currentRoute={currentRoute}
      isBackEnabled={false}
      collapse={false}
      headerVisible={true}
      menu={true}
      authLogout={authLogout}
    />
  );
  const Stack = React.useCallback(
    () => <StackNavigation initialState={initialState} />,
    [initialState],
  );

  return (
    <Drawer.Navigator
      drawerContent={() => (
        <DrawerContent width={width} authLogout={authLogout} />
      )}
      screenOptions={{
        header,
        drawerPosition: 'left',
        drawerType: 'front',
        swipeEnabled: false,
        lazy: true,
        overlayColor: Theme.Color.drawer.overlay,
        drawerStyle: [
          styles.drawerStyle,
          Platform.OS === 'web' && styles.drawerWeb,
        ],
      }}>
      <Drawer.Screen name="/" component={Stack} />
    </Drawer.Navigator>
  );
};
export default DrawerNavigator;
function dispatch(arg0: any) {
  throw new Error('Function not implemented.');
}
function QUALTRICS_SCRIPT(QUALTRICS_SCRIPT: any, QUALTRICS_ID: any) {
  throw new Error('Function not implemented.');
}

function QUALTRICS_ID(
  QUALTRICS_SCRIPT: (QUALTRICS_SCRIPT: any, QUALTRICS_ID: any) => void,
  QUALTRICS_ID: any,
) {
  throw new Error('Function not implemented.');
}

import React, {useContext, useEffect, useState} from 'react';
import {TouchableHighlight, View, ViewStyle} from 'react-native';
import {Components, Theme} from '@oneamerica/dxp-ui-components';
import ScreenWrapper from '../../components/ScreenWrapper/ScreenWrapper';
import responsiveStyle from './style';
import {Table, TableWrapper, Cell} from 'react-native-reanimated-table';
import moment from 'moment';
import withAuth from '../../auth/withAuth';
import {AdditionalDetailCard} from './components/AdditionalDetailCard';
import InvestmentChart from './../../components/InvestmentTable';
import {prepareInvestmentData, preparestrategiesData} from './../../utils';
import {
  getAffiliate,
  getTaxQualification,
  getTerminatedReason,
  getType,
  validateElement,
  makepolicyRelationShipData,
  getLoanItem,
  truncatedText,
  getEleSpacing,
} from './helpers';
import {useHomeSelector} from './hooks/home';
import {titleCase} from '../../utils';
import {PolicyValueCard} from './components/PolicyValueCard';
import {BillingAndPremiumCard} from './components/BillingAndPremiumCard';
import {LoansCard} from './components/LoansCard';
import {PolicyHighlightsCard} from './components/PolicyHighlightsCard';
import PolicyRelationshipsInfoModal from './components/PolicyRelationshipsInfoModal';
import PolicyRelationshipsTypeModal from './components/PolicyRelationshipsTypeModal';
import {
  columnConfiguration,
  childRowsColumnConfig,
  columnWidthArr,
  stratergyColumnConfiguration,
  columnWidthArrStrategy,
} from './columnConfig';
import {PolicyNotes} from './components/PolicyNotes';
import BreakpointConstants from '../../constants/BreakpointConstants';
import PolicyDropdown from '../../components/PolicyDropdown';
import ErrorWrapper from '../../components/ErrorWrapper';
import StrategiesInfoModal from './components/StrategiesInfoModal';
import {useRef} from 'react';
import {
  trackCustomEvent,
  trackPage,
} from '../../utils/Analytics/AdobeAnalyticsUtils';
import {analyticsTags} from '../../utils/Analytics/AnalyticsTags';
import {useNavigationState} from '@react-navigation/native';

const Home = (props) => {
  const {width} = useContext(Theme.ResponsiveContext);
  const style = responsiveStyle();
  const [isModalStaretgyVisible, setModalStaretgyVisible] = useState(false);
  const navigationState = useNavigationState((state) => state);
  const previousRouteNameRef = useRef<string | null>(null);

  useEffect(() => {
    const currentRouteName =
      navigationState.routes[navigationState.index]?.name;
    if (
      previousRouteNameRef.current !== currentRouteName &&
      currentRouteName === 'Home'
    ) {
      trackPage(analyticsTags.createHomePageViewTags);
    }
    previousRouteNameRef.current = currentRouteName;
  }, [navigationState]);

  // State for modal visibility
  const handleModalToggle = () => {
    setModalStaretgyVisible(!isModalStaretgyVisible); // Function to toggle modal visibility
  };

  const linkStyle = {
    style: style.cardActionButton,
    linkStyle: style.cardActionButton,
    linkHoverStyle: style.cardActionButton,
    focusStyle: style.cardActionButtonFocus,
  };

  const linkStylePRName = {
    style: [
      style.tableCellText1,
      {
        fontSize: BreakpointConstants.isMD(width) ? 16 : 14,
      },
    ] as ViewStyle,

    textStyle: [
      style.tableCellText1,
      {
        fontSize: BreakpointConstants.isMD(width) ? 16 : 14,
      },
    ] as ViewStyle,

    linkStyle: [
      style.tableCellText1,
      {
        fontSize: BreakpointConstants.isMD(width) ? 16 : 14,
      },
    ] as ViewStyle,

    hoverStyle: [
      style.tableCellText1,
      {
        fontSize: BreakpointConstants.isMD(width) ? 16 : 14,
      },
    ] as ViewStyle,
  };
  const {
    account,
    buttonStatus,
    generalPolicyData,
    generalPolicyDataRender,
    hasTotalDeathBenefit,
    initialPolicyData,
    moreBillingAndPremium,
    morePolicyValue,
    policyHighlights,
    policyRelationshipsData,
    policyStatusButton,
    policyValue,
    policyValueCardData,
    popupData,
    popupDataAll,
    terminatedReason,
    isInfoModalVisible,
    setInfoModalVisiblity,
    infoModalContent,
    getModalDetails,
    navigateToPolicyDetails,
    setModalTitles,
    setModalViewAllRelationShip,
    setModalVisibility,
    setRelationType,
    relationType,
    modalTitles,
    isModalVisible,
    showCOBInPolicyHighlights,
    cobPolicyNumber,
    cobPolicyStatus,
    cobStatusButton,
    policyTerminationDate,
    cobPolicyStatusReason,
    cobTerminationDate,
    navigateToMyInvestments,
    navigateToMyStrategies,
    navigateToCOBDetails,
    policyNotes,
  } = useHomeSelector(props);

  const trackRelationshipType = (relationType) => {
    if (relationType === 'Annuitant') {
      trackCustomEvent(analyticsTags.CaptureRelationshipsAnnuitantTags);
    } else if (relationType === 'Primary Insured') {
      trackCustomEvent(analyticsTags.CaptureRelationshipsPrimaryInsuredTags);
    } else if (relationType === 'Owner') {
      trackCustomEvent(analyticsTags.CaptureRelationshipsOwnerTags);
    } else if (relationType === 'Payor') {
      trackCustomEvent(analyticsTags.CaptureRelationshipsPayorTags);
    } else if (relationType === 'Beneficiary') {
      trackCustomEvent(analyticsTags.CaptureRelationshipsBeneficiaryTags);
    } else if (relationType === 'First Beneficiary') {
      trackCustomEvent(
        analyticsTags.CaptureRelationshipsPrimaryBeneficiaryTags,
      );
    }
  };
  const renderCards = React.useMemo(() => {
    const elements: React.JSX.Element[] = [];
    let eleRow = 0;

    if (Object.keys(account).length !== 0) {
      eleRow += BreakpointConstants.isLG(width) ? 1 / 2 : 1;
      elements.push(
        <PolicyHighlightsCard
          key={'policy-highlights-card'}
          className={`w-full lg:w-1/2
           ${getEleSpacing(eleRow)}`}
          getType={getType}
          validateElement={validateElement}
          getTaxQualification={getTaxQualification}
          titleCase={titleCase}
          getAffiliate={getAffiliate}
          policyStatusButton={policyStatusButton}
          policyHighlights={policyHighlights}
          buttonStatus={buttonStatus}
          getTerminatedReason={getTerminatedReason}
          account={account}
          terminatedReason={terminatedReason}
          showCOBInPolicyHighlights={showCOBInPolicyHighlights}
          cobPolicyNumber={cobPolicyNumber}
          cobPolicyStatus={cobPolicyStatus}
          cobStatusButton={cobStatusButton}
          policyTerminationDate={policyTerminationDate}
          linkStyle={linkStyle}
          navigateToCOBDetails={navigateToCOBDetails}
          row={eleRow}
          popupData={popupData}
          cobPolicyStatusReason={cobPolicyStatusReason}
          cobTerminationDate={cobTerminationDate}
          generalPolicyDataRender={generalPolicyDataRender}
        />,
      );
    }
    if (buttonStatus !== 'Terminated' && buttonStatus !== 'Suspended') {
      /*Investments */

      if (
        policyHighlights[0]?.investments &&
        policyHighlights[0]?.accountConfig &&
        policyHighlights[0]?.accountConfig.allowMyInvestments === true
      ) {
        const investmentFormattedData = prepareInvestmentData(
          policyHighlights[0]?.investments,
          policyHighlights[0]?.accountBalance,
          policyHighlights[0]?.assertClass,
        );
        const mappedData = investmentFormattedData.mappedData;
        const assetTotal = investmentFormattedData.assetTotal;
        eleRow += BreakpointConstants.isLG(width) ? 1 / 2 : 1;
        elements.push(
          <View
            key={'investments-card'}
            className={`w-full lg:w-1/2 ${getEleSpacing(eleRow)}`}>
            <View style={[style.cardShadow, eleRow > 1 && style.flex]}>
              <View
                className="flex-col md:flex-row"
                testID={'investmentCardHeader'}>
                <Components.Text.H3 style={[style.cardHeaderTitle, style.flex]}>
                  My Investments
                </Components.Text.H3>
                <Components.Text
                  style={[style.investmentCardDate]}
                  className="md:self-end">
                  As of{' '}
                  {policyValue.valueAsOfDate
                    ? moment(policyValue.valueAsOfDate).format('MMM DD, YYYY')
                    : '-'}
                </Components.Text>
              </View>
              <View className="mt-4" style={style.horizontalDivider} />
              <InvestmentChart
                data={mappedData}
                accountBalance={policyHighlights[0]?.accountBalance}
                showChildRows={false}
                pieChartHeight={191}
                pieChartWidth={191}
                columnWidthArr={columnWidthArr}
                columnConfig={columnConfiguration}
                childRowsColumnConfig={childRowsColumnConfig}
                assetTotal={assetTotal}
                legendTableTitle={{text: 'Current Balance by Asset Class'}}
                showPieChart={true}
                showLegendTable={true}
                showAlternateRowColors={true}
                showBorder={true}
              />
              <Components.Link
                className="mt-4"
                onPress={() => {
                  trackCustomEvent(
                    analyticsTags.CaptureInvestmentCardViewDetailsTags,
                  );
                  navigateToMyInvestments();
                }}
                accessibilityLabel="View Investment Details"
                accessibilityRole="button"
                {...linkStyle}>
                View Details
              </Components.Link>
            </View>
          </View>,
        );
      }

      /*Stratergies */
      if (
        policyHighlights[0]?.investments &&
        policyHighlights[0]?.accountConfig &&
        policyHighlights[0]?.accountConfig.allowMyStrategies === true
      ) {
        const investmentFormattedData = preparestrategiesData(
          policyHighlights[0]?.investments,
          policyHighlights[0]?.accountBalance,
          policyHighlights[0]?.assertClass,
        );
        const mappedData = investmentFormattedData.mappedData;
        const assetTotal = investmentFormattedData.assetTotal;
        eleRow += BreakpointConstants.isLG(width) ? 1 / 2 : 1;
        elements.push(
          <View
            key={'investments-card'}
            className={`w-full lg:w-2/4 ${getEleSpacing(eleRow)}`}>
            <View style={[style.cardShadow, eleRow > 1 && style.flex]}>
              <View
                className="flex-col md:flex-col"
                testID={'investmentCardHeader'}>
                <View style={[style.titleContainer]}>
                  <Components.Text.H3
                    style={[style.cardHeaderTitle, style.flex]}>
                    My Strategies
                  </Components.Text.H3>
                  <TouchableHighlight
                    underlayColor="#fff"
                    style={style.Details}
                    accessible={true}
                    accessibilityLabel="My Strategies"
                    onPress={() => {
                      trackCustomEvent(
                        analyticsTags.CaptureMyStrategiesCardInfoModalTags,
                      );
                      handleModalToggle();
                    }}>
                    <React.Fragment> i </React.Fragment>
                  </TouchableHighlight>
                </View>
              </View>
              <View className="mt-4" style={style.horizontalDivider} />
              <InvestmentChart
                flexDirection="column"
                horizontal={BreakpointConstants.isLG(width) ? false : true}
                data={mappedData}
                accountBalance={policyHighlights[0]?.accountBalance}
                showChildRows={false}
                pieChartHeight={191}
                pieChartWidth={191}
                columnWidthArr={columnWidthArrStrategy}
                columnConfig={stratergyColumnConfiguration}
                assetTotal={assetTotal}
                legendTableTitle={{
                  text: 'Account Balance Percentage By Strategy',
                }}
                showPieChart={true}
                showLegendTable={true}
                showAlternateRowColors={true}
                showBorder={true}
              />
              <Components.Link
                className="mt-4"
                onPress={() => {
                  trackCustomEvent(
                    analyticsTags.CaptureMyStrategiesCardViewDetailsTags,
                  );
                  navigateToMyStrategies();
                }}
                accessibilityLabel="View Strategies Details"
                accessibilityRole="button"
                {...linkStyle}>
                View Details
              </Components.Link>
            </View>
          </View>,
        );
      }

      /* Policy Values */
      if (policyValueCardData?.length > 0) {
        eleRow += BreakpointConstants.isLG(width) ? 1 / 4 : 1;
        elements.push(
          <PolicyValueCard
            key={'policy-value-card'}
            row={eleRow}
            className={`w-full md:w-1/2 lg:w-1/4 ${getEleSpacing(eleRow)}`}
            policyValueCardData={policyValueCardData}
            policyValue={policyValue}
            hasTotalDeathBenefit={hasTotalDeathBenefit}
            morePolicyValue={morePolicyValue}
            linkStyle={linkStyle}
            navigateToPolicyDetails={navigateToPolicyDetails}
          />,
        );
      }

      /* Billing & Premiums */
      if (initialPolicyData.length > 0) {
        eleRow += BreakpointConstants.isLG(width) ? 1 / 4 : 1;
        elements.push(
          <BillingAndPremiumCard
            key={'billing-card'}
            row={eleRow}
            className={`w-full md:w-1/2 lg:w-1/4 ${getEleSpacing(eleRow)}`}
            initialPolicyData={initialPolicyData}
            generalPolicyData={generalPolicyData}
            linkStyle={linkStyle}
            moreBillingAndPremium={moreBillingAndPremium}
            navigateToPolicyDetails={navigateToPolicyDetails}
          />,
        );
      }

      /* Loans */
      if (getLoanItem(policyValue)) {
        eleRow += BreakpointConstants.isLG(width) ? 1 / 4 : 1;
        elements.push(
          <LoansCard
            key={'loans-card'}
            className={`w-full md:w-1/2 lg:w-1/4 ${getEleSpacing(eleRow)}`}
            getLoanItem={() => getLoanItem(policyValue)}
            policyValue={policyValue}
            row={eleRow}
          />,
        );
      }

      /* Additional Details */
      eleRow += BreakpointConstants.isLG(width) ? 1 / 4 : 1;
      elements.push(
        <AdditionalDetailCard
          key={'additional-details-card'}
          className={`w-full md:w-1/2 lg:w-1/4 ${getEleSpacing(eleRow)}`}
          row={eleRow}
          {...props}
        />,
      );

      /* Policy Relationships */
      eleRow += BreakpointConstants.isLG(width) ? 1 / 2 : 1;
      elements.push(
        <View
          key={'policy-relationships-card'}
          className={`w-full lg:w-1/2 ${getEleSpacing(eleRow)}`}>
          <View style={[style.cardShadow, eleRow > 1 && style.flex]}>
            <View style={style.cardHeader}>
              <Components.Text.H3 style={[style.cardHeaderTitle, style.flex]}>
                Policy Relationships
              </Components.Text.H3>
              <TouchableHighlight
                underlayColor="#fff"
                accessible={true}
                accessibilityLabel="Policy Relationship Information icon link"
                style={[style.Details]}
                onPress={() => {
                  trackCustomEvent(
                    analyticsTags.CapturePolicyRelationshipsCardInfoModalTags,
                  );
                  getModalDetails();
                }}>
                <View>
                  <React.Fragment> i </React.Fragment>
                </View>
              </TouchableHighlight>
            </View>
            <View className="mt-4" style={style.horizontalDivider} />
            <View style={style.contentWrapper}>
              <View className="mt-4" style={style.policyTableContainer}>
                <Table>
                  {popupData &&
                    popupData.map((item: any, index: number) => (
                      <TableWrapper
                        key={`row-${index}`}
                        style={{
                          ...style.row,
                          backgroundColor:
                            index % 2 === 0 ? '#FFFFFF' : '#F3F3F3',
                        }}>
                        <Cell
                          // @ts-ignore
                          className="flex-1 md:flex-2"
                          style={[
                            index === 0
                              ? style.tableHeadingCell
                              : style.tableCell2,
                          ]}
                          data={
                            <Components.Text
                              className={`text-14${
                                index === 0 ? '' : ' md:text-16'
                              }`}
                              style={
                                index === 0
                                  ? style.tableHeadingCellText
                                  : style.tableCellText
                              }>
                              {item.relationType === 'First Beneficiary'
                                ? 'Primary Beneficiary'
                                : item.relationType === 'Second Beneficiary'
                                ? 'Contingent Beneficiary'
                                : item.relationType}
                            </Components.Text>
                          }
                        />
                        <Cell
                          // @ts-ignore
                          className="flex-1 md:flex-3"
                          style={[
                            index === 0
                              ? style.tableHeadingCell1
                              : style.tableCell1,
                          ]}
                          data={
                            index === 0 ? (
                              <Components.Text
                                className={`text-14`}
                                style={style.tableHeadingCellText}>
                                {truncatedText(
                                  item.personDetails?.name?.first,
                                  20,
                                )}
                              </Components.Text>
                            ) : (
                              <Components.Link
                                accessibilityRole="button"
                                accessibilityLabel={
                                  'Click to see policy details of ' +
                                  item.personDetails?.name?.first
                                }
                                onPress={() => {
                                  trackRelationshipType(item.relationType);
                                  setModalVisibility(true);
                                  setModalViewAllRelationShip(false);
                                  makepolicyRelationShipData(
                                    item.relationType,
                                    policyRelationshipsData,
                                    setModalTitles,
                                    setRelationType,
                                  );
                                }}
                                {...linkStylePRName}>
                                {truncatedText(
                                  item.personDetails?.name?.first,
                                  20,
                                )}
                              </Components.Link>
                            )
                          }
                        />
                      </TableWrapper>
                    ))}
                </Table>
              </View>
              {popupDataAll.length > 5 && (
                <Components.Link
                  accessibilityLabel="View Policy Relationships Details"
                  accessibilityRole="button"
                  className="mt-4"
                  onPress={() => {
                    trackCustomEvent(
                      analyticsTags.CaptureViewAllRelationshipsTags,
                    );
                    navigateToPolicyDetails('PolicyRelationships');
                  }}
                  {...linkStyle}>
                  VIEW ALL RELATIONSHIPS
                </Components.Link>
              )}
            </View>
          </View>
        </View>,
      );
    }
    return elements;
  }, [
    width,
    account,
    policyHighlights,
    policyValueCardData,
    initialPolicyData,
    policyValue,
  ]);

  return (
    <ScreenWrapper
      scrollFooter
      paddingTop
      paddingBottom
      paddingHorizontal={false}>
      {/* POLICY DROPDOWN */}
      <PolicyDropdown
        className={'px-5 md:px-6 lg:px-9 mb-6'}
        accessToken={props.accessToken}
      />
      <ErrorWrapper>
        <View
          className={
            buttonStatus !== 'Terminated' && buttonStatus !== 'Suspended'
              ? 'flex flex-row flex-wrap px-1 md:px-2 lg:px-5'
              : 'flex justify-center items-center px-1 md:px-2 lg:px-5'
          }>
          {renderCards}
        </View>
        {buttonStatus !== 'Terminated' &&
          buttonStatus !== 'Suspended' &&
          policyNotes != null &&
          policyNotes?.length > 0 && (
            <>
              <PolicyNotes policyNotes={policyNotes} shouldShowMargin={true} />
              <View
                style={{
                  flexDirection: 'row',
                  paddingBottom: 32,
                }}>
                <Components.Text
                  style={{
                    fontSize: 20,
                    lineHeight: 20,
                    marginRight: 5,
                    marginLeft: 32,
                  }}>
                  {'\u2022'}
                </Components.Text>
                <Components.Text style={[style.disclaimerText, {flex: 1}]}>
                  OneAmerica Financial<sup style={{fontSize: 9}}>SM&nbsp;</sup>
                  is the marketing name for the companies of OneAmerica
                  Financial. Products issued and underwritten by the following
                  OneAmerica Financial companies: American United Life Insurance
                  Company® (AUL) and The State Life Insurance Company® (State
                  Life), Indianapolis, IN.
                </Components.Text>
              </View>
            </>
          )}
        <PolicyRelationshipsInfoModal
          isInfoModalVisible={isInfoModalVisible}
          setInfoModalVisiblity={setInfoModalVisiblity}
          infoModalContent={infoModalContent}
        />
        <PolicyRelationshipsTypeModal
          relationType={relationType}
          modalTitles={modalTitles}
          isModalVisible={isModalVisible}
          setModalVisibility={setModalVisibility}
        />
        <StrategiesInfoModal
          isVisible={isModalStaretgyVisible}
          toggleModal={handleModalToggle}
        />
      </ErrorWrapper>
    </ScreenWrapper>
  );
};

export default withAuth(Home);

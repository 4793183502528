import {Theme, Font} from '@oneamerica/dxp-ui-components';
import BreakpointConstants from '../../../../constants/BreakpointConstants';

const styles = (width: number) =>
  Theme.Responsive.create({
    linkStyle: {
      color: Theme.Color.oaBlue,
      fontWeight: '600',
      textDecorationLine: 'underline',
      fontSize: 12,
    },
    linkHoverStyle: {
      color: Theme.Color.oaBlue,
      fontSize: 12,
      fontWeight: '600',
      textDecorationLine: 'underline',
    },
    textStyle: {
      fontSize: 14,
      color: '#000000',
      fontWeight: '400',
      fontFamily: Font.italic.fontFamily,
    },
    cardHeaderTitle: {
      fontFamily: Font.soleil.regular.fontFamily,
      fontSize: BreakpointConstants.isMD(width) ? 22 : 16,
      fontWeight: BreakpointConstants.isMD(width) ? '600' : '700',
      marginBottom: 20,
      color: Theme.Color.black,
    },
    informationMessageMargin: {marginTop: 16, marginBottom: 16},
  })();

export default styles;

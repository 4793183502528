import {Font, Theme} from '@oneamerica/dxp-ui-components';
import ColorConstants from '../../../constants/ColorConstants';

const {Responsive, Metrics, Color} = Theme;
export const styles = () =>
  Responsive.create({
    container: {
      width: 350,
    },
    heading: {
      backgroundColor: Color.header.background,
      color: Color.white,
      height: 32,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignContent: 'center',
      alignItems: 'center',
      paddingHorizontal: Metrics.xSmall,
    },
    headingTable: {
      color: ColorConstants.iron,
      fontSize: 16,
      fontFamily: Font.medium.fontFamily,
    },
    headingTextColor: {
      color: Color.white,
    },
    colorLegend: {
      width: 16,
      height: 16,
      paddingTop: Metrics.xSmall,
    },
    cellRight: {
      textAlign: 'right',
    },
    row: {flex: 1, flexDirection: 'row'},

    legendRow: {
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: '#FFFFFF',
    },
    arrowImageView: {
      backgroundColor: '#FFFFFF',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingTop: '2px',
    },
    rowHeader: {
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: '#F3F9FE',
      borderBottomColor: ColorConstants.lightGrey,
      borderTopColor: ColorConstants.lightGrey,
      borderBottomWidth: 1,
      borderTopWidth: 1,
      zIndex: 0,
    },
    rowHeaderFullHeight: {
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: '#F3F9FE',
      borderBottomColor: ColorConstants.lightGrey,
      borderTopColor: ColorConstants.lightGrey,
      borderBottomWidth: 1,
      borderTopWidth: 0,
      zIndex: 0,
    },
    rowInternal: {
      flexDirection: 'row',
      alignItems: 'center',
      borderBottomColor: ColorConstants.lightGrey,
      borderBottomWidth: 1,
    },
    rowStrip: {
      backgroundColor: 'transparent',
      //width: 10,
    },
    legendRows: {
      fontSize: 14,
      fontFamily: Font.regular.fontFamily,
      borderTopColor: ColorConstants.lightGrey,
      borderTopWidth: 1,
      paddingTop: 7,
      paddingBottom: 7,
      paddingLeft: 2,
      paddingRight: 8,
    },
    leftBorder: {
      borderLeftColor: ColorConstants.lightGrey,
      borderLeftWidth: 1,
    },
    topBorder: {
      borderTopColor: ColorConstants.lightGrey,
      borderTopWidth: 1,
    },
    bottomBorder: {
      borderBottomColor: ColorConstants.lightGrey,
      borderBottomWidth: 1,
    },
    bottomBorderBlack: {
      borderBottomColor: ColorConstants.iron,
      borderBottomWidth: 1,
    },
    leftBordergrey: {
      borderLeftColor: ColorConstants.lightGrey,
      borderLeftWidth: 1,
    },
    rightBordergrey: {
      borderRightColor: ColorConstants.lightGrey,
      borderRightWidth: 1,
    },
    assetClassColor: {
      width: 14,
      height: 14,
      textAlignVertical: 'center',
      marginRight: 8,
      marginBottom: 'auto',
      marginTop: 2,
    },
    headingCell: {
      fontSize: 15,
      fontFamily: Font.medium.fontFamily,
      borderTopColor: ColorConstants.lightGrey,
      borderTopWidth: 1,
      backgroundColor: '#F3F9FE',
      paddingTop: 10,
      paddingBottom: 9,
      paddingLeft: 12,
      paddingRight: 8,
    },
    fullHeightHeadingCell: {
      fontSize: 15,
      fontFamily: Font.regular.fontFamily,
      borderWidth: 0,
      backgroundColor: '#FFFFFF',
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 16,
      paddingRight: 16,
      fontWeight: '600',
    },
    childRowHeadingCell: {
      fontSize: 14,
      fontFamily: Font.medium.fontFamily,
      borderTopColor: ColorConstants.lightGrey,
      borderTopWidth: 1,
      backgroundColor: '#EBEBEB',
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 8,
      paddingRight: 8,
    },
    totalCell: {
      fontSize: 14,
      fontFamily: Font.regular.fontFamily,
      fontWeight: '500',
      borderTopColor: ColorConstants.lightGrey,
      borderTopWidth: 1,
      borderBottomColor: ColorConstants.lightGrey,
      borderBottomWidth: 1,
      backgroundColor: '#F3F9FE',
      paddingTop: 10,
      paddingBottom: 8,
      paddingLeft: 2,
      paddingRight: 8,
      borderLeftColor: ColorConstants.grey200,
      borderLeftWidth: 1,
    },
    totalCellFullHeight: {
      fontSize: 15,
      fontFamily: Font.regular.fontFamily,
      borderTopColor: ColorConstants.lightGrey,
      borderTopWidth: 1,
      borderBottomColor: ColorConstants.lightGrey,
      borderBottomWidth: 1,
      backgroundColor: '#EBEBEB',
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 16,
      paddingRight: 16,
      lineHeight: 30,
      borderLeftColor: ColorConstants.grey200,
      borderLeftWidth: 1,
      fontWeight: '600',
    },
    footerRow: {
      backgroundColor: '#F3F9FE',
    },
    chartLegendTable: {
      left: -55,
    },
    chevronArrow: {
      color: ColorConstants.iron,
      fontSize: 16,
      outlineWidth: 0,
    },
    textStyle: {
      fontFamily: Font.regular.fontFamily,
      color: ColorConstants.darkGrey,
      fontSize: 12,
      textAlign: 'left',
      fontWeight: '500',
      paddingLeft: 4,
      lineHeight: '22px',
    },
    textInputStyle: {
      fontFamily: Font.medium.fontFamily,
      color: Color.input.label,
      fontSize: 15,
      outlineStyle: 'none',
      borderStyle: 'solid',
      height: 21,
      borderWidth: 1,
      width: '100%',
      borderRadius: 5,
      borderColor: '#D6D6D6',
      textAlign: 'center',
      fontWeight: '600',
    },
    textErrorStyle: {
      fontFamily: Font.medium.fontFamily,
      color: Color.input.label,
      fontSize: 15,
      outlineStyle: 'none',
      borderStyle: 'solid',
      height: 21,
      borderWidth: 1,
      width: '100%',
      borderRadius: 5,
      borderColor: '#E40032',
      textAlign: 'center',
      fontWeight: '600',
    },
    tableEditCell: {
      paddingLeft: 20,
      paddingTop: 8,
      width: 180,
    },
    faFilePdfIcon: {
      color: ColorConstants.iron,
      fontSize: 15,
      outlineWidth: 0,
      marginRight: 8,
      marginLeft: 14,
    },
    assetName: {
      borderRightWidth: 0,
    },
    rowBackColor: {
      backgroundColor: '#F8F9F9',
    },
    cellPadding: {
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 8,
      paddingBottom: 8,
    },
    paddingRight: {
      paddingRight: 50,
    },
    linkStyle: {
      color: ColorConstants.oaBlueTint,
      fontFamily: Font.regular.fontFamily,
      fontSize: 14,
      alignSelf: 'flex-start',
    },
    linkTextStyle: {
      fontFamily: Font.regular.fontFamily,
      fontSize: 14,
      alignSelf: 'flex-start',
    },
    nolinkTextStyle: {
      fontFamily: Font.regular.fontFamily,
      fontSize: 14,
      alignSelf: 'flex-start',
      marginLeft: 38
    },
    linkHoverStyle: {
      color: ColorConstants.oaBlueTint,
      fontFamily: Font.regular.fontFamily,
      fontSize: 14,
    },
    noVerticalBorder: {
      borderTopWidth: 0,
      borderBottomWidth: 0,
    },
    footerLabel: {
      flex: 1,
      flexDirection: 'row',
    },
    validationMsg: {
      color: Color.oaRedDark,
      fontSize: 10,
      fontFamily: Font.regular.fontFamily,
      fontWeight: '500',
    },
    totalAccBox: {
      backgroundColor: '#F8F9F9',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      lineHeight: 45,
    },
    totalAccFooter1C: {
      textAlign: 'left',
      width: '35%',
      paddingLeft: 10,
      borderLeftColor: '#EBEBEB',
      borderLeftWidth: 1,
    },
    totalAccFooterText: {
      fontFamily: Font.regular.fontFamily,
      fontWeight: '700',
      fontSize: 14,
      color: '#333333',
      marginLeft: 4,
    },
    totalAccFooterTextAmount: {
      fontFamily: Font.regular.fontFamily,
      fontWeight: '700',
      fontSize: 15,
      color: '#333333',
    },
    totalAccFooter2C: {
      width: '23%',
      borderLeftColor: '#EBEBEB',
      borderLeftWidth: 1,
      height: 45,
    },
    totalAccFooter3C: {
      textAlign: 'right',
      color: '#333333',
      width: '15%',
      fontFamily: Font.regular.fontFamily,
      fontWeight: '700',
      fontSize: 14,
      paddingRight: 15,
      borderLeftColor: '#EBEBEB',
      borderLeftWidth: 1,
    },
    totalAccFooter4C: {
      width: '12%',
      borderLeftColor: '#EBEBEB',
      borderLeftWidth: 1,
      height: 45,
    },
    totalAccFooter5C: {
      width: '15%',
      borderLeftColor: '#EBEBEB',
      borderLeftWidth: 1,
      height: 45,
      borderRightColor: '#EBEBEB',
      borderRightWidth: 1,
    },
  })();

export default styles;

export const editErrorMessages = {
  emptyError:
    'One or more of the required fields is empty, please check your input.',
  totalAllocationError: 'Your Total Account Value should equal 100%',
  noChangeError: `You haven't made any changes.`,
  percentAllocationError: 'Percentage left to transfer should equal 0.00%',
  dollarAllocationError: 'Dollars left to transfer should equal $0.00',
  minMaxError:
    'One or more of the required fields contains an error, please check your input.',
};

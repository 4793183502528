import {Theme, Font} from '@oneamerica/dxp-ui-components';
import ColorConstants from '../../../../constants/ColorConstants';

export default () =>
  Theme.Responsive.create({
    cardShadow: {
      borderRadius: 5,
      shadowRadius: 6,
      backgroundColor: Theme.Color.background,
      shadowColor: Theme.Color.backgroundShadow,
      shadowOffset: {width: 0, height: 3},
      paddingHorizontal: Theme.Metrics.small,
      paddingVertical: Theme.Metrics.small,
    },

    cardHeader: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    cardHeaderTitle: {
      color: ColorConstants.iron,
      lineHeight: 36,
      fontFamily: Font.medium.fontFamily,
    },
    flex: {flex: 1},
    horizontalDivider: {
      backgroundColor: ColorConstants.grey200,
      height: 1,
      width: '100%',
    },
  })();

import {Dispatch} from 'react';
import {AuthLogout} from './AuthActions';
import {APP_LOGIN_URL, APP_LOGOUT_URL} from '../config/getEnvironmentVars';
import {Buffer} from 'buffer';
import OktaAuth from '@okta/okta-auth-js';

const handleRedirect = async (authClient: OktaAuth) => {
  try {
    await authClient.handleRedirect();
  } catch (e) {
    // log or display error details
    console.log(e);
  }
};

const generateToken = (authClient: OktaAuth) => {
  //@ts-ignore
  const queryParams = window.location.search;
  const params = new URLSearchParams(queryParams);

  authClient.token.getWithRedirect({
    state: Buffer.from(
      JSON.stringify(Object.fromEntries(params.entries())),
    ).toString('base64'),
  });
};

export const authLogin = async (
  dispatch: Dispatch<any>,
  //   state: AuthProps,
  authClient: OktaAuth,
) => {
  const sessionExists = await authClient.session.exists();
  const isAuthenticated = await authClient.isAuthenticated();

  if (!sessionExists) {
    if (authClient.isLoginRedirect()) {
      handleRedirect(authClient);
    } else if (!isAuthenticated) {
      // Start the browser based oidc flow, then parse tokens from the redirect callback url
      await authClient.signInWithRedirect({
        authorizeUrl: APP_LOGIN_URL,
      });
    } else {
      // User is authenticated
      //   if (sessionExists) {
      //     if (state.user !== null && state.user !== undefined) {
      //       UpdateUser(dispatch);
      //     }
      //   } else {
      authLogout(dispatch, authClient);
      //   }
    }
  } else if (!isAuthenticated && sessionExists) {
    generateToken(authClient);
  }
};

export const authLogout = async (
  dispatch: Dispatch<any>,
  authClient: OktaAuth,
) => {
  await authClient.signOut({
    postLogoutRedirectUri: APP_LOGOUT_URL,
  });
  AuthLogout(dispatch);
};

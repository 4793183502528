import {Theme, Font} from '@oneamerica/dxp-ui-components';
import ColorConstants from '../../constants/ColorConstants';

export default () =>
  Theme.Responsive.create({
    container: {
      width: '100%',
    },
    row: {
      flex: 1,
      flexDirection: 'row',
      borderTopWidth: 1,
      borderTopColor: ColorConstants.lightGrey,
    },
    tableRow: {
      backgroundColor: Theme.Color.table.row.background,
    },
    altRow: {
      backgroundColor: ColorConstants.midGrey,
    },
    tableHeader: {
      backgroundColor: Theme.Color.table.header.blueBackground,
      flex: 1,
      flexDirection: 'row',
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderBottomColor: ColorConstants.lightGrey,
      borderTopColor: ColorConstants.lightGrey,
    },
    tableHeadingCol: {
      textAlign: 'left',
      paddingTop: 10,
      paddingBottom: 10,
      paddingRight: 5,
    },
    tableHeadingColText: {
      color: ColorConstants.iron,
      fontSize: 14,
      fontFamily: Font.medium.fontFamily,
      opacity: 1,
    },
    tableCol: {
      textAlign: 'left',
      paddingTop: 10,
      paddingBottom: 10,
      paddingRight: 5,
    },
    tableColText: {
      fontSize: 14,
      fontFamily: Font.regular.fontFamily,
      opacity: 1,
      color: Theme.Color.black,
    },
    borderBottom: {
      borderBottomWidth: 1,
      borderColor: ColorConstants.lightGrey,
    },
    contentWrapper: {
      flex: 1,
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    columnSeparator: {
      borderRightWidth: 1,
      borderRightColor: ColorConstants.lightGrey,
    },
  })();

import React, {useContext} from 'react';
import responsiveStyle from '../PolicyRelationshipsInfoModal/style';
import {Table, TableWrapper, Cell} from 'react-native-reanimated-table';
import BreakpointConstants from '../../../../constants/BreakpointConstants';
import {Components, Theme} from '@oneamerica/dxp-ui-components';
import RouteConstants from '../../../../constants/RouteConstants';
import {useNavigation} from '@react-navigation/native';
import {View} from 'react-native';
import {analyticsTags} from '../../../../utils/Analytics/AnalyticsTags';
import {trackCustomEvent} from '../../../../utils/Analytics/AdobeAnalyticsUtils';

const style = responsiveStyle();

const PolicyRelationshipsTypeModal = ({
  relationType,
  modalTitles,
  isModalVisible,
  setModalVisibility,
}) => {
  const {width} = useContext(Theme.ResponsiveContext);
  const navigation = useNavigation<any>();

  const filteredRelationType = relationType?.filter(
    (e) => e[0] !== 'Issue Age',
  );
  let modalStyle;
  if (BreakpointConstants.isLG(width)) {
    modalStyle = style.lgModalSize;
  } else if (BreakpointConstants.isMD(width)) {
    modalStyle = style.mdModalSize;
  } else {
    modalStyle = style.smModalSize;
  }
  const navigateToPolicyServiceRequest = () => {
    navigation.navigate(RouteConstants.policyservicerequest);
  };
  const getMinHeight = (rowData) => {
    const nameLength = rowData[1]?.length || 0;
    const addressLength = rowData[4]?.length || 0;
    let minHeight = 30; // Default minimum height
    if (nameLength > 70 || addressLength > 70) {
      minHeight = 65;
    } else if (
      (nameLength > 55 && nameLength < 70) ||
      (addressLength > 55 && addressLength < 70)
    ) {
      minHeight = 50;
    } else if (
      (nameLength < 55 && nameLength > 30) ||
      (addressLength < 55 && addressLength > 30)
    ) {
      minHeight = 40;
    }
    return minHeight;
  };
  return (
    <Components.DialogModal
      style={modalStyle}
      width={'auto'}
      padding={20}
      visible={isModalVisible}
      Header={
        <Components.Text style={style.modalTitle}>
          {modalTitles}
        </Components.Text>
      }
      Footer={
        <View className="flex flex-row items-center justify-start md:justify-end flex-wrap md:mt-[13px]">
          <Components.Text
            className="mr-2 py-4 md:py-0 text-14 md:text-16"
            style={style.needToMakeChange}>
            Need to make a change?
          </Components.Text>
          <Components.Button
            style={[
              !BreakpointConstants.isLG(width) &&
                style.customeServiceRequestBtn,
              style.serviceRequestBtn,
            ]}
            accessibilityRole="button"
            id="btnOutline"
            theme="primary"
            onPress={() => {
              trackCustomEvent(analyticsTags.CapturePSRRelationshipPageTags);
              navigateToPolicyServiceRequest();
              setModalVisibility(false);
            }}>
            Policy Service Request
          </Components.Button>
        </View>
      }
      onClose={() => {
        setModalVisibility(false);
      }}>
      <View style={style.placeholderWrapper}>
        <Table>
          {filteredRelationType?.map((rowData: any, index: number) => (
            <TableWrapper
              key={`row-${index}`}
              style={[
                style.tableRow,
                {
                  minHeight: getMinHeight(rowData),
                },
                index % 2 ? style.evenRow : style.oddRow,
              ]}>
              {rowData.map((cellData, cellIndex) => (
                <Cell
                  style={[
                    style.tableCell,
                    {
                      minHeight: getMinHeight(rowData),
                    },
                    cellIndex % 2 ? style.tableCellRight : style.tableCellLeft,
                  ]}
                  key={cellIndex}
                  data={cellData}
                  textStyle={[
                    {fontSize: BreakpointConstants.isSM(width) ? 16 : 14},
                    cellIndex % 2
                      ? style.tableCellRightText
                      : style.tableCellLeftText,
                  ]}
                />
              ))}
            </TableWrapper>
          ))}
        </Table>
      </View>
    </Components.DialogModal>
  );
};

export default PolicyRelationshipsTypeModal;

export enum EventType {
  PageView = 'page_view',
  CustomEvent = 'custom_event',
}

export enum TargetType {
  Click = 'click',
  Success = 'success',
  Failure = 'failure',
}
export enum PageRoute {
  Home = '/Home',
  PolicyDetails = '/Policy-Details',
  MyInvestment = '/My-Investments',
  MyStrategies = '/My-Strategies',
  ContactUs = '/Contact-Us',
  PolicyServiceRequest = '/Policy-Service-Request',
  MyProfile = '/My-Profile',
  TransferBalance = '/Transfer-Balance',
  PercentTransfer = '/Percent-Transfer',
  DollarTransfer = '/Dollar-Transfer',
  MyInvestmentElections = '/My-Investment-Elections',
}

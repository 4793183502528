import {View, TouchableHighlight} from 'react-native';
import {Components, Theme} from '@oneamerica/dxp-ui-components';
import responsiveStyle from './style';
import ScreenWrapper from '../../components/ScreenWrapper/ScreenWrapper';
import withAuth from '../../auth/withAuth';
import Breadcrumbs from '../../components/Breadcrumbs';
import InvestmentChart from '../../components/InvestmentTable';
import {useHomeSelector} from '../Home/hooks/home';
import React, {useContext, useEffect, useState} from 'react';
import {
  columnWidthArrMob,
  stratergyColumnConfiguration,
  columnWidthArrStrategy,
} from './columnConfig';
import {preparestrategiesData} from '../../utils';
import PolicyDropdown from '../../components/PolicyDropdown';
import moment from 'moment';
import BreakpointConstants from '../../constants/BreakpointConstants';
import {getPieChartSize} from './helpers';
import ErrorWrapper from '../../components/ErrorWrapper';
import StrategiesInfoModal from '../Home/components/StrategiesInfoModal';
import {analyticsTags} from '../../utils/Analytics/AnalyticsTags';
import {
  trackCustomEvent,
  trackPage,
} from '../../utils/Analytics/AdobeAnalyticsUtils';

const style = responsiveStyle();

const MyStrategies = (props) => {
  const [isModalVisible, setModalVisible] = useState(false); // State for modal visibility
  useEffect(() => {
    trackPage(analyticsTags.createMyStrategiesPageViewTags);
  }, []);
  const handleModalToggle = () => {
    setModalVisible(!isModalVisible); // Function to toggle modal visibility
  };
  const {policyHighlights} = useHomeSelector(props);
  const {width} = useContext(Theme.ResponsiveContext);
  const screenType = BreakpointConstants.isLG(width)
    ? 'LG'
    : BreakpointConstants.isMD(width)
    ? 'MD'
    : 'SM';
  const renderCards = React.useMemo(() => {
    const elements: React.JSX.Element[] = [];

    if (policyHighlights[0]?.investments) {
      const investmentFormattedData = preparestrategiesData(
        policyHighlights[0]?.investments,
        policyHighlights[0]?.accountBalance,
        policyHighlights[0]?.assertClass,
      );
      const mappedData = investmentFormattedData.mappedData;
      const assetTotal = investmentFormattedData.assetTotal;
      const totalpercentByValue = investmentFormattedData.totalpercentByVaule;
      const currentDate = policyHighlights[0].indexDate
        ? moment(policyHighlights[0].indexDate).format('MMM DD, YYYY')
        : '-';
      const pieChartSize = getPieChartSize(width);
      const isMobile = screenType === 'MD' || screenType === 'SM';

      const formattedDate = moment(currentDate, 'MMM DD, YYYY').format(
        'MM/DD/YYYY',
      );

      const getNextDayDate = (date) => {
        return moment(date).add(1, 'days').format('MM/DD/YYYY');
      };

      const nextDayDate = policyHighlights[0].indexDate
        ? getNextDayDate(policyHighlights[0].indexDate)
        : '-';

      elements.push(
        <View className="lg:flex-row pt-6 -z-10" key={'investment-tabs'}>
          <View className="w-full p-0 mt-1">
            <View style={[style.cardShadow, style.noPadding]}>
              <Components.CardTabs
                content={[
                  <View style={[style.contentPadding]} key="tab1">
                    <View style={style.componentPadding}>
                      <TouchableHighlight
                        underlayColor="#fff"
                        accessible={true}
                        accessibilityLabel="My strategies Information icon link"
                        style={style.Details}
                        onPress={() => {
                          trackCustomEvent(
                            analyticsTags.CaptureStrategiesInfoModalTags,
                          );
                          handleModalToggle();
                        }}>
                        <View>
                          <React.Fragment> i </React.Fragment>
                        </View>
                      </TouchableHighlight>
                      <View className="mt-4" style={style.horizontalDivider} />
                      <InvestmentChart
                        data={mappedData}
                        accountBalance={policyHighlights[0]?.accountBalance}
                        showChildRows={false}
                        pieChartHeight={pieChartSize.height}
                        pieChartWidth={pieChartSize.width}
                        columnWidthArr={
                          BreakpointConstants.isLG(width)
                            ? columnWidthArrStrategy
                            : columnWidthArrMob
                        }
                        columnConfig={stratergyColumnConfiguration(
                          formattedDate,
                          nextDayDate,
                        )}
                        assetTotal={assetTotal}
                        totalpercentByValue={totalpercentByValue}
                        horizontal={isMobile}
                        showPieChart={true}
                        showLegendTable={true}
                        showBorder={true}
                      />
                    </View>
                  </View>,
                ]}
                tabs={[
                  {
                    key: 'tab1',
                    testID: 'Current And Future Strategy Allocations',
                    title: 'Current And Future Strategy Allocations',
                  },
                ]}
              />
            </View>
          </View>
        </View>,
      );
    }
    return elements;
  }, [policyHighlights, screenType]);

  return (
    <ScreenWrapper scrollFooter paddingTop paddingBottom paddingHorizontal>
      <Breadcrumbs />
      <ErrorWrapper>
        <View style={style.cardHeader}>
          <PolicyDropdown
            className=""
            accessToken={props.accessToken}
            text={'My Strategies'}
          />
        </View>
        {renderCards}
        <StrategiesInfoModal
          isVisible={isModalVisible}
          toggleModal={handleModalToggle}
        />
      </ErrorWrapper>
    </ScreenWrapper>
  );
};

export default withAuth(MyStrategies);

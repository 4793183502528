import moment from 'moment';
import colors from '../components/InvestmentTable/colors.json';
interface investmentClass {
  x: string;
  y: number;
  amount: number;
  classValue: number;
  investmentName: string;
  investmentSheetURL: string;
  units: number;
  unitValues: number;
  childRows: [{}];
  color: string;
  isError: boolean;
  newPercentage: number;
}

// strategies
interface strategiesClass {
  x: string;
  y: number;
  amount: number;
  classValue: number;
  investmentName: string;
  rateType: string;
  currentRate: number;
  units: number;
  unitValues: number;
  childRows: [{}];
  color: string;
  isError: boolean;
  newPercentage: number;
  strategyDate: Date;
}

interface transferBalanceClass {
  x: string;
  y: number;
  z: number;
  amount: number;
  classValue: number;
  investmentName: string; //name - API Variable
  investmentSheetURL: string; // fundPDFName - API Variable
  currentValue: number; //currentValue - API Variable
  units: number;
  unitValues: number;
  childRows: [{}];
  color: string;
  isError: boolean;
  newPercentage?: number; //currentPercentByTotalValue - API Variable
  toValue?: number;
  fromValue?: number;
  minValue?: number;
  maxValue?: number;
  minPercentByTotalValue?: number;
  maxPercentByTotalValue?: number;
}

export const titleCase = (str) => {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
};

export const prepareInvestmentData = (data, accountBalance, assetClassData) => {
  const AllAssets: investmentClass[] = [];
  data?.map((record) => {
    if (record.value > 0) {
      AllAssets.push({
        x: record.assetClassName,
        y: record.percentByTotalValue,
        amount: record.value,
        classValue: record.assetClassValue,
        investmentName: record.investmentName,
        investmentSheetURL: record.investmentSheetURL,
        units: record.units,
        unitValues: record.unitValues,
        childRows: [{}],
        color: '',
        isError: false,
        newPercentage: record.percentByTotalValue,
      });
    }
  });
  const tempObj: investmentClass[] = [];
  const newArrayOfObjects = AllAssets.reduce((b: investmentClass[], a) => {
    const newTempObj = {...a};
    const totalData = assetClassData?.assets.find((classElement) => {
      classElement.assetClassName === newTempObj.x;
    });
    const ind = b.findIndex((e) => e.x === newTempObj.x);
    const c = 'amount';
    const y = 'y';
    const u = 'units';
    const uv = 'unitValues';

    if (ind > -1) {
      b[ind][c] = +b[ind][c] + +newTempObj[c];
      b[ind][y] = Math.round(totalData?.percentByTotalValue * 100 * 100) / 100;
      newTempObj[y] = Math.round(newTempObj[y] * 100) / 100;
      newTempObj[u] = Math.round(newTempObj[u] * 100) / 100;
      newTempObj[uv] = Math.round(newTempObj[uv] * 100) / 100;
      b[ind].childRows.push(newTempObj);
    } else {
      newTempObj[c] = +newTempObj[c] || 0;
      newTempObj[y] = +newTempObj[y] || 0;
      b.push(newTempObj);
      tempObj[0] = {
        x: newTempObj.x,
        y: Math.round((newTempObj.amount / accountBalance) * 100 * 100) / 100,
        classValue: newTempObj.classValue,
        amount: newTempObj.amount,
        investmentName: newTempObj.investmentName,
        investmentSheetURL: newTempObj.investmentSheetURL,
        units: Math.round(newTempObj.units * 100) / 100,
        unitValues: Math.round(newTempObj.unitValues * 100) / 100,
        childRows: [{}],
        color: colors[newTempObj.x],
        isError: false,
        newPercentage: Math.round(newTempObj.amount / accountBalance),
      };
      if (ind === -1) {
        b[b.length - 1].childRows[0] = tempObj[0];
      } else {
        b[ind]?.childRows.push(newTempObj);
      }
    }
    return b;
  }, []);
  let assetTotal = 0;
  newArrayOfObjects.map((record) => {
    record.y = Math.round((record.amount / accountBalance) * 100 * 100) / 100;
    record.amount = Math.round((record.amount + Number.EPSILON) * 100) / 100;
    assetTotal += record.amount;
    //record.childRows.shift();
  });
  const mappedData = newArrayOfObjects.map((item) => ({
    ...item,
    color: colors[item.x] || '#000000', // Default to black if the color is not found
    isExpanded: false,
  }));
  const obj = [...mappedData];
  obj.sort((a, b) => a.classValue - b.classValue);
  const totalpercentByVaule = Math.round(assetClassData.totalpercentByVaule);
  return {mappedData, assetTotal, totalpercentByVaule};
};

export const preparestrategiesData = (data, accountBalance, assetClassData) => {
  const myStrategiesColors = [
    '#002c77',
    '#8BBFF3',
    '#FFCF00',
    '#4A7729',
    '#A07400',
    '#00B0B9',
    '#FF95AC',
    '#EA7600',
    '#E7B2F7',
    '#963821',
  ];
  const AllAssets: strategiesClass[] = [];
  data?.map((record) => {
    if (record.value > 0) {
      AllAssets.push({
        x: record.investmentName,
        y: record.percentByTotalValue,
        rateType: record.indexRateLabel,
        currentRate: record.indexRate,
        amount: record.value,
        strategyDate: record.indexDate,
        classValue: record.assetClassValue,
        investmentName: record.investmentName,
        units: record.units,
        unitValues: record.unitValues,
        childRows: [{}],
        color: '',
        isError: false,
        newPercentage: record.percentByTotalValue,
      });
    }
  });
  const tempObj: strategiesClass[] = [];
  const newArrayOfObjects = AllAssets.reduce((b: strategiesClass[], a) => {
    const newTempObj = {...a};
    const totalData = assetClassData?.assets.find((classElement) => {
      classElement.investmentName === newTempObj.x;
    });
    const ind = b.findIndex((e) => e.x === newTempObj.x);
    const c = 'amount';
    const y = 'y';
    const u = 'units';
    const uv = 'unitValues';

    if (ind > -1) {
      b[ind][c] = +b[ind][c] + +newTempObj[c];
      b[ind][y] = Math.round(totalData?.percentByTotalValue * 100 * 100) / 100;
      newTempObj[y] = Math.round(newTempObj[y] * 100) / 100;
      newTempObj[u] = Math.round(newTempObj[u] * 100) / 100;
      newTempObj[uv] = Math.round(newTempObj[uv] * 100) / 100;
      b[ind].childRows.push(newTempObj);
    } else {
      newTempObj[c] = +newTempObj[c] || 0;
      newTempObj[y] = +newTempObj[y] || 0;
      b.push(newTempObj);
      tempObj[0] = {
        x: newTempObj.x,
        y: Math.round((newTempObj.amount / accountBalance) * 100 * 100) / 100,
        classValue: newTempObj.classValue,
        amount: newTempObj.amount,
        rateType: newTempObj.rateType,
        strategyDate: newTempObj.strategyDate,
        currentRate: newTempObj.currentRate,
        investmentName: newTempObj.investmentName,
        units: Math.round(newTempObj.units * 100) / 100,
        unitValues: Math.round(newTempObj.unitValues * 100) / 100,
        childRows: [{}],
        color: myStrategiesColors[ind],
        isError: false,
        newPercentage: Math.round(newTempObj.amount / accountBalance),
      };
      if (ind === -1) {
        b[b.length - 1].childRows[0] = tempObj[0];
      } else {
        b[ind]?.childRows.push(newTempObj);
      }
    }
    return b;
  }, []);
  let assetTotal = 0;
  newArrayOfObjects.map((record) => {
    record.y = Math.round((record.amount / accountBalance) * 100 * 100) / 100;
    record.amount = Math.round((record.amount + Number.EPSILON) * 100) / 100;
    assetTotal += record.amount;
    //record.childRows.shift();
  });
  const mappedData = newArrayOfObjects.map((item, index) => ({
    ...item,
    color: myStrategiesColors[index] || '#000000', // Default to black if the color is not found
    isExpanded: false,
  }));
  const obj = [...mappedData];
  obj.sort((a, b) => a.classValue - b.classValue);
  const totalpercentByVaule = Math.round(assetClassData.totalpercentByVaule);
  return {mappedData, assetTotal, totalpercentByVaule};
};

export const prepareInvestmentOptionsData = (data) => {
  const AllAssets: investmentClass[] = [];
  let newPercentTotal = 0;
  data?.map((record) => {
    if (record.percentageByContribution >= 0) {
      AllAssets.push({
        x: record.assetClassName,
        y: Math.round(record.percentageByContribution),
        amount: record.value,
        classValue: record.assetClassValue,
        investmentName: record.investmentName,
        investmentSheetURL: record.investmentSheetURL,
        units: record.units,
        unitValues: record.unitValues,
        childRows: [{}],
        color: colors[record.assetClassName],
        isError: false,
        newPercentage: record.percentageByContribution,
      });
      newPercentTotal += record.percentageByContribution;
    }
  });
  const tempObj: investmentClass[] = [];
  const newArrayOfObjects = AllAssets.reduce((b: investmentClass[], a) => {
    const newTempObj = {...a};
    // const totalData = AllAssets.find((classElement) => {
    //   classElement.x === newTempObj.x;
    // });
    const ind = b.findIndex((e) => e.x === newTempObj.x);
    const c = 'amount';
    const y = 'y';
    const u = 'units';
    const uv = 'unitValues';

    if (ind > -1) {
      b[ind][c] = +b[ind][c] + +newTempObj[c];
      //b[ind][y] = Math.round(totalData?.y * 100 * 100) / 100;
      newTempObj[y] = Math.round(newTempObj[y] * 100) / 100;
      newTempObj[u] = Math.round(newTempObj[u] * 100) / 100;
      newTempObj[uv] = Math.round(newTempObj[uv] * 100) / 100;
      //newTempObj[color] = colors[newTempObj.x];
      b[ind].childRows.push(newTempObj);
    } else {
      newTempObj[c] = +newTempObj[c] || 0;
      newTempObj[y] = +newTempObj[y] || 0;
      b.push(newTempObj);
      tempObj[0] = {
        x: newTempObj.x,
        y: newTempObj.y,
        classValue: newTempObj.classValue,
        amount: newTempObj.amount,
        investmentName: newTempObj.investmentName,
        investmentSheetURL: newTempObj.investmentSheetURL,
        units: Math.round(newTempObj.units * 100) / 100,
        unitValues: Math.round(newTempObj.unitValues * 100) / 100,
        childRows: [{}],
        color: colors[newTempObj.x],
        isError: false,
        newPercentage: newTempObj.y,
      };
      if (ind === -1) {
        b[b.length - 1].childRows[0] = tempObj[0];
      } else {
        b[ind]?.childRows.push(newTempObj);
      }
    }
    return b;
  }, []);
  let assetTotal = 0;
  newArrayOfObjects.map((record) => {
    record.y = Math.round(record.y * 100 * 100) / 100;
    record.amount = Math.round((record.amount + Number.EPSILON) * 100) / 100;
    assetTotal += record.amount;
    //record.childRows.shift();
  });
  const mappedData = newArrayOfObjects.map((item) => ({
    ...item,
    color: colors[item.x] || '#000000', // Default to black if the color is not found
    isExpanded: false,
  }));
  const obj = [...mappedData];
  obj.sort((a, b) => a.classValue - b.classValue);
  const totalpercentByVaule = 100;
  const percentRoundTotal = Math.round(newPercentTotal);
  return {
    mappedData,
    assetTotal,
    totalpercentByVaule,
    AllAssets,
    percentRoundTotal,
  };
};

export const prepareTransferInvestmentOptionsData = (
  data,
  isDollarTransfer = false,
) => {
  const AllAssets: transferBalanceClass[] = [];
  data?.map((record) => {
    AllAssets.push({
      x: record.assetClassName,
      ...(isDollarTransfer
        ? {
            y: record.toValue.toFixed(2),
            z: record.fromValue.toFixed(2),
          }
        : {
            y: record.currentPercentByTotalValue,
            z: record.currentPercentByTotalValue,
          }),
      amount: record.minValue,
      classValue: record.assetClassValue,
      investmentName: record.name,
      investmentSheetURL: record.fundPDFName,
      currentValue: record.currentValue,
      units: record.units,
      unitValues: record.unitValues,
      minValue: record.minValue,
      maxValue: record.maxValue,
      minPercentByTotalValue: record.minPercentByTotalValue.toFixed(2),
      maxPercentByTotalValue: record.maxPercentByTotalValue.toFixed(2),
      childRows: [{}],
      color: colors[record.assetClassName],
      isError: false,
      ...(isDollarTransfer
        ? {
            toValue: record.toValue.toFixed(2),
            fromValue: record.fromValue.toFixed(2),
          }
        : {newPercentage: record.reallocPercentByTotalValue.toFixed(2)}),
    });
  });

  const tempObj: transferBalanceClass[] = [];
  const newArrayOfObjects = AllAssets.reduce((b: transferBalanceClass[], a) => {
    const newTempObj = {...a};
    // const totalData = AllAssets.find((classElement) => {
    //   classElement.x === newTempObj.x;
    // });
    const ind = b.findIndex((e) => e.x === newTempObj.x);
    const c = 'amount';
    const y = 'y';
    const u = 'units';
    const uv = 'unitValues';

    if (ind > -1) {
      b[ind][c] = +b[ind][c] + +newTempObj[c];
      //b[ind][y] = Math.round(totalData?.y * 100 * 100) / 100;
      newTempObj[y] = Math.round(newTempObj[y] * 100) / 100;
      newTempObj[u] = Math.round(newTempObj[u] * 100) / 100;
      newTempObj[uv] = Math.round(newTempObj[uv] * 100) / 100;
      //newTempObj[color] = colors[newTempObj.x];
      b[ind].childRows.push(newTempObj);
    } else {
      newTempObj[c] = +newTempObj[c] || 0;
      newTempObj[y] = +newTempObj[y] || 0;
      b.push(newTempObj);
      tempObj[0] = {
        x: newTempObj.x,
        y: newTempObj.y,
        z: newTempObj.z,
        classValue: newTempObj.classValue,
        amount: newTempObj.amount,
        investmentName: newTempObj.investmentName,
        investmentSheetURL: newTempObj.investmentSheetURL,
        currentValue: newTempObj.currentValue,
        units: Math.round(newTempObj.units * 100) / 100,
        unitValues: Math.round(newTempObj.unitValues * 100) / 100,
        minValue: newTempObj.minValue,
        maxValue: newTempObj.maxValue,
        minPercentByTotalValue: newTempObj.minPercentByTotalValue,
        maxPercentByTotalValue: newTempObj.maxPercentByTotalValue,
        childRows: [{}],
        color: colors[newTempObj.x],
        isError: false,
        newPercentage: newTempObj.newPercentage,
      };
      if (ind === -1) {
        b[b.length - 1].childRows[0] = tempObj[0];
      } else {
        b[ind]?.childRows.push(newTempObj);
      }
    }
    return b;
  }, []);
  let assetTotal = 0;
  newArrayOfObjects.map((record) => {
    record.y = Math.round(record.y * 100 * 100) / 100;
    record.amount = Math.round((record.amount + Number.EPSILON) * 100) / 100;
    assetTotal += record.amount;
    //record.childRows.shift();
  });
  const mappedData = newArrayOfObjects.map((item) => ({
    ...item,
    color: colors[item.x] || '#000000', // Default to black if the color is not found
    isExpanded: false,
  }));
  const obj = [...mappedData];
  obj.sort((a, b) => a.classValue - b.classValue);
  const totalpercentByVaule = 100;

  const percentRoundTotal = 0;
  return {
    mappedData,
    assetTotal,
    totalpercentByVaule,
    AllAssets,
    percentRoundTotal,
  };
};
export const prepareCurrentElectionsData = (data, isNewElections) => {
  const AllAssets: investmentClass[] = [];
  let newPercentTotal = 0;
  data?.map((record) => {
    if (record.y > 0 || record.newPercentage) {
      AllAssets.push(record);
      newPercentTotal += parseInt(record.newPercentage);
    }
  });
  const tempObj: investmentClass[] = [];
  const newArrayOfObjects = AllAssets.reduce((b: investmentClass[], a) => {
    const newTempObj = {...a};
    // const totalData = AllAssets.find((classElement) => {
    //   classElement.x === newTempObj.x;
    // });
    const ind = b.findIndex((e) => e.x === newTempObj.x);
    const c = 'amount';
    const y = 'y';
    const u = 'units';
    const uv = 'unitValues';
    const newPercentage = 'newPercentage';
    newTempObj['edited'] =
      newTempObj[newPercentage] !== newTempObj[y] ? true : false;
    if (ind > -1) {
      if (newTempObj['edited'] && isNewElections) {
        b[ind][y] = b[ind][y] + Number(newTempObj[newPercentage]);
      } else {
        b[ind][y] = b[ind][y] + newTempObj[y];
      }
      newTempObj[y] = Math.round(newTempObj[y] * 100) / 100;
      newTempObj[u] = Math.round(newTempObj[u] * 100) / 100;
      newTempObj[uv] = Math.round(newTempObj[uv] * 100) / 100;
      //newTempObj[color] = colors[newTempObj.x];
      b[ind].childRows.push(newTempObj);
    } else {
      newTempObj[c] = +newTempObj[c] || 0;
      if (newTempObj['edited'] && isNewElections) {
        newTempObj[y] = Number(newTempObj[newPercentage]);
      } else {
        newTempObj[y] = newTempObj[y];
      }
      b.push(newTempObj);
      tempObj[0] = {
        x: newTempObj.x,
        y: newTempObj.y,
        classValue: newTempObj.classValue,
        amount: newTempObj.amount,
        investmentName: newTempObj.investmentName,
        investmentSheetURL: newTempObj.investmentSheetURL,
        units: Math.round(newTempObj.units * 100) / 100,
        unitValues: Math.round(newTempObj.unitValues * 100) / 100,
        childRows: [{}],
        color: colors[newTempObj.x],
        isError: false,
        newPercentage: newTempObj.y,
      };
      if (ind === -1) {
        b[b.length - 1].childRows[0] = tempObj[0];
      } else {
        b[ind]?.childRows.push(newTempObj);
      }
    }
    return b;
  }, []);
  let assetTotal = 0;
  newArrayOfObjects.map((record) => {
    record.amount = Math.round((record.amount + Number.EPSILON) * 100) / 100;
    assetTotal += record.amount;
  });
  const mappedData = newArrayOfObjects.map((item) => ({
    ...item,
    color: colors[item.x] || '#000000', // Default to black if the color is not found
    isExpanded: false,
  }));
  const obj = [...mappedData];
  obj.sort((a, b) => a.classValue - b.classValue);
  const totalpercentByVaule = 100;
  const percentRoundTotal = Math.round(newPercentTotal);
  return {
    mappedData,
    assetTotal,
    totalpercentByVaule,
    AllAssets,
    percentRoundTotal,
  };
};

export const prepareTransferBalanceData = (data, isNewAllocations = false) => {
  const AllAssets: transferBalanceClass[] = [];
  let newPercentTotal = 0;

  data?.map((record) => {
    const isInvestmentValidForTotal =
      record.y > 0 || record.currentValue > 0 || record.newPercentage > 0;
    if (isInvestmentValidForTotal) {
      AllAssets.push(record);
      newPercentTotal += record.currentValue;
    }
  });
  const tempObj: transferBalanceClass[] = [];
  const newArrayOfObjects = AllAssets.reduce((b: transferBalanceClass[], a) => {
    const newTempObj = {...a};
    const ind = b.findIndex((e) => e.x === newTempObj.x);
    const c = 'amount';
    const y = 'y';
    const u = 'units';
    const uv = 'unitValues';
    const newPercentage = 'newPercentage';
    newTempObj['edited'] =
      newTempObj[newPercentage] !== newTempObj[y] ? true : false;
    const isEdited = newTempObj['edited'] && isNewAllocations;
    if (ind > -1) {
      if (isEdited) {
        b[ind][y] = b[ind][y] + Number(newTempObj[newPercentage]);
      } else {
        b[ind][y] = b[ind][y] + newTempObj[y];
      }
      newTempObj[y] = Math.round(newTempObj[y] * 100) / 100;
      newTempObj[u] = Math.round(newTempObj[u] * 100) / 100;
      newTempObj[uv] = Math.round(newTempObj[uv] * 100) / 100;
      b[ind].childRows.push(newTempObj);
    } else {
      newTempObj[c] = +newTempObj[c] || 0;
      newTempObj[y] = +newTempObj[y] || 0;
      if (isEdited) {
        newTempObj[y] = Number(newTempObj[newPercentage]);
      } else {
        newTempObj[y] = newTempObj[y];
      }
      b.push(newTempObj);
      tempObj[0] = {
        x: newTempObj.x,
        y: newTempObj.y,
        z: newTempObj.z,
        classValue: newTempObj.classValue,
        amount: newTempObj.amount,
        investmentName: newTempObj.investmentName,
        investmentSheetURL: newTempObj.investmentSheetURL,
        currentValue: newTempObj.currentValue,
        units: Math.round(newTempObj.units * 100) / 100,
        unitValues: Math.round(newTempObj.unitValues * 100) / 100,
        childRows: [{}],
        color: colors[newTempObj.x],
        isError: false,
        newPercentage: newTempObj.y,
      };
      if (ind === -1) {
        b[b.length - 1].childRows[0] = tempObj[0];
      } else {
        b[ind]?.childRows.push(newTempObj);
      }
    }
    return b;
  }, []);
  let assetTotal = 0;
  newArrayOfObjects.map((record) => {
    record.y = Math.round(record.y * 100) / 100;
    record.amount = Math.round((record.amount + Number.EPSILON) * 100) / 100;
    assetTotal += record.amount;
  });
  const mappedData = newArrayOfObjects.map((item) => ({
    ...item,
    color: colors[item.x] || '#000000', // Default to black if the color is not found
    isExpanded: false,
  }));

  const obj = [...mappedData];
  obj.sort((a, b) => a.classValue - b.classValue);
  const totalpercentByVaule = 100;
  const percentRoundTotal = newPercentTotal;
  return {
    mappedData,
    assetTotal: assetTotal,
    totalpercentByVaule,
    AllAssets,
    percentRoundTotal,
  };
};

export const prepareTransferInvestmentOptionsDataReview = (
  data,
  isNewElections,
) => {
  const AllAssets: transferBalanceClass[] = [];
  let newPercentTotal = 0;
  data?.map((record) => {
    if (
      record.y > 0 ||
      record.currentValue > 0 ||
      record.fromValue > 0 ||
      record.toValue > 0
    ) {
      AllAssets.push({
        ...record,
        reallocatedValue: (
          Number(record.currentValue) -
          Number(record.fromValue) +
          Number(record.toValue)
        ).toString(),
      });
      newPercentTotal += record.currentValue;
    }
  });
  const tempObj: transferBalanceClass[] = [];
  const newArrayOfObjects = AllAssets.reduce((b: transferBalanceClass[], a) => {
    const newTempObj = {...a};

    const ind = b.findIndex((e) => e.x === newTempObj.x);
    const c = 'amount';
    const y = 'y';
    const u = 'units';
    const uv = 'unitValues';
    const newPercentage = !isNewElections ? 'currentValue' : 'reallocatedValue';

    // newTempObj['edited'] =
    //   newTempObj['fromValue'] !== newTempObj[y] &&
    //   newTempObj['toValue'] !== newTempObj[y]
    //     ? true
    //     : false;
    if (ind > -1) {
      if (isNewElections) {
        b[ind][y] = b[ind][y] + Number(newTempObj[newPercentage]);
      } else {
        // b[ind][y] = b[ind][y] + newTempObj[y];
        b[ind][y] = b[ind][y] + Number(newTempObj[newPercentage]);
      }
      newTempObj[y] = Math.round(newTempObj[y] * 100) / 100;
      newTempObj[u] = Math.round(newTempObj[u] * 100) / 100;
      newTempObj[uv] = Math.round(newTempObj[uv] * 100) / 100;
      //newTempObj[color] = colors[newTempObj.x];
      b[ind].childRows.push(newTempObj);
    } else {
      newTempObj[c] = +newTempObj[c] || 0;
      newTempObj[y] = +newTempObj[y] || 0;
      if (isNewElections) {
        newTempObj[y] = Number(newTempObj[newPercentage]);
      } else {
        // newTempObj[y] = Number(newTempObj[y]);
        newTempObj[y] = Number(newTempObj[newPercentage]);
      }
      b.push(newTempObj);
      tempObj[0] = {
        x: newTempObj.x,
        y: newTempObj.y,
        z: newTempObj.z,
        classValue: newTempObj.classValue,
        amount: newTempObj.amount,
        investmentName: newTempObj.investmentName,
        investmentSheetURL: newTempObj.investmentSheetURL,
        currentValue: newTempObj.currentValue,
        units: Math.round(newTempObj.units * 100) / 100,
        unitValues: Math.round(newTempObj.unitValues * 100) / 100,
        childRows: [{}],
        color: colors[newTempObj.x],
        isError: false,
        newPercentage: newTempObj.y,
        toValue: newTempObj.y,
        fromValue: newTempObj.z,
      };
      if (ind === -1) {
        b[b.length - 1].childRows[0] = tempObj[0];
      } else {
        b[ind]?.childRows.push(newTempObj);
      }
    }
    return b;
  }, []);
  let assetTotal = 0;
  newArrayOfObjects.map((record) => {
    record.y = Math.round(record.y * 100) / 100;
    record.currentValue =
      Math.round((record.currentValue + Number.EPSILON) * 100) / 100;
    assetTotal += record.currentValue;
  });
  const mappedData = newArrayOfObjects.map((item) => ({
    ...item,
    color: colors[item.x] || '#000000', // Default to black if the color is not found
    isExpanded: false,
  }));
  const obj = [...mappedData];
  obj.sort((a, b) => a.classValue - b.classValue);
  const totalpercentByVaule = 100;
  const percentRoundTotal = newPercentTotal;
  return {
    mappedData,
    assetTotal,
    totalpercentByVaule,
    AllAssets,
    percentRoundTotal,
  };
};

export const formatCurrency = (amount) => {
  const numericAmount = Number(amount);
  return `$${numericAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
};

export const formatDecimal = (amount) => {
  return `${amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
};

function escape(name) {
  return name.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1');
}

export const getCookie = (name) => {
  //@ts-ignore
  let match = document.cookie.match(
    new RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'),
  );
  return match;
};

export const isBackToAccountNavigator = () => {
  let cookie = getCookie('ACCOUNT_DETAILS');
  let isBackToAccntNav = false;

  if (cookie != null)
    isBackToAccntNav = JSON.parse(atob(cookie[1])).BacktoAccNav;

  return isBackToAccntNav;
};

export const isHomeOfficeUser = () => {
  let cookie = getAccountDetailsTokenCookie();
  let isHomeOfcUser = false;

  if (cookie != null) isHomeOfcUser = true;

  return isHomeOfcUser;
};

export const getAccountDetailsTokenCookie = () => {
  let cookie = getCookie('ACCOUNT_DETAILS_TOKEN');
  let AccountDetailsToken = null;

  if (cookie != null) AccountDetailsToken = cookie[1];

  return AccountDetailsToken;
};

export const formatDate = (dateToFormat, dateFormat) => {
  if (dateToFormat) {
    return moment(dateToFormat).format(dateFormat);
  } else {
    return '-';
  }
};

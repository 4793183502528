import {Theme, Font} from '@oneamerica/dxp-ui-components';
import ColorConstants from '../../../../constants/ColorConstants';

export default () =>
  Theme.Responsive.create({
    notesContent: {
      paddingTop: Theme.Metrics.large,
    },
    disclaimerText: {
      fontSize: 15,
      color: ColorConstants.iron,
      fontFamily: Font.regular.fontFamily,
      lineHeight: 21,
    },
    notesText: {
      fontSize: 18,
      color: ColorConstants.iron,
      fontFamily: Font.soleil.regular.fontFamily,
      paddingTop: Theme.Metrics.medium,
    },
    horizontalDivider: {
      backgroundColor: ColorConstants.lightGrayishBlue,
      height: 1,
      width: '100%',
    },
  })();

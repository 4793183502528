import {Theme, Font} from '@oneamerica/dxp-ui-components';
import ColorConstants from '../../../constants/ColorConstants';
import BreakpointConstants from '../../../constants/BreakpointConstants';
export default (width: number) =>
  Theme.Responsive.create({
    mainHeading: {
      marginTop: Theme.Metrics.small,
    },
    cardShadow: {
      borderRadius: 5,
      shadowRadius: 6,
      backgroundColor: Theme.Color.background,
      shadowColor: Theme.Color.backgroundShadow,
      shadowOffset: {width: 0, height: 3},
      paddingHorizontal: Theme.Metrics.small,
      paddingTop: Theme.Metrics.small,
      paddingBottom: Theme.Metrics.medium,
      flex: 1,
    },
    cardPadding: {
      //paddingTop: BreakpointConstants.isLG(width) ? 48 : 25,
      paddingBottom: BreakpointConstants.isLG(width) ? 36 : 25,
      paddingHorizontal: BreakpointConstants.isLG(width) ? 32 : 20,
    },
    investmentElectionText: {
      color: ColorConstants.iron,
      fontSize: 20,
      fontFamily: Font.soleil.regular.fontFamily,
      marginTop: Theme.Metrics.small,
      marginBottom: Theme.Metrics.small,
      textAlign: 'left',
    },
    horizontalDivider: {
      backgroundColor: ColorConstants.grey200,
      height: 1,
      width: '100%',
    },
    buttonFlexDirection: {
      flexDirection: BreakpointConstants.isMD(width) ? 'row' : 'column',
    },
    buttonSpaceLeft: {
      marginLeft: 20,
    },
    buttonPrevious: {
      position: 'absolute',
      marginTop: 0,
      right: 140,
    },
    buttonCancelLeft: {
      marginLeft: 0,
      marginTop: 0,
    },
    buttonWidth: {
      width: BreakpointConstants.isMD(width) ? 'auto' : '290px',
    },
    buttonTop: {
      marginLeft: 0,
      marginTop: 10,
      alignSelf: 'center',
      alignItems: 'center',
    },
    notesTitle: {
      color: ColorConstants.iron,
      fontSize: 16,
      fontFamily: Font.soleil.regular.fontFamily,
      fontWeight: '700',
    },
    showLg: {
      display: BreakpointConstants.isLG(width) ? 'flex' : 'none',
    },
    hideLg: {
      display: BreakpointConstants.isLG(width) ? 'none' : 'flex',
    },
    topMargin: {
      marginTop: Theme.Metrics.medium,
    },
    topMarginReview: {
      marginTop: Theme.Metrics.large,
      marginBottom: Theme.Metrics.small,
    },
    noTopMargin: {
      marginTop: 0,
    },
    reviewPlaceholderMargin: {
      marginTop: Theme.Metrics.small,
      marginBottom: Theme.Metrics.small,
    },
    bulletListStyle: {
      marginTop: BreakpointConstants.isLG(width) ? 15 : 0,
      fontSize: 14,
      fontWeight: '400',
      fontFamily: 'Robto-Regular',
      marginLeft: Theme.Metrics.xSmall,
    },
    accordionBg: {
      backgroundColor: '#F3F9FE',
      borderLeftColor: ColorConstants.darkBlue,
      borderLeftWidth: 4,
      fontSize: 15,
      fontFamily: Font.medium.fontFamily,
      fontWeight: '700',
      color: ColorConstants.iron,
      paddingTop: Theme.Metrics.small,
      paddingBottom: Theme.Metrics.small,
      paddingRight: Theme.Metrics.medium,
      paddingLeft: Theme.Metrics.medium,
    },
    reviewPgTableTitle: {
      color: Theme.Color.black,
      fontSize: 20,
      fontFamily: Font.soleil.regular.fontFamily,
      fontWeight: '700',
    },
    modalTitle: {
      fontFamily: Font.soleil.regular.fontFamily,
      fontSize: 20,
      fontWeight: '400',
      zIndex: -1,
    },
    buttonWidthModal: {
      width: BreakpointConstants.isMD(width) ? '158px' : 'auto',
    },
    buttonKeepEditing: {
      marginLeft: BreakpointConstants.isMD(width) ? '12px' : '10px',
    },
    pieChartColumn: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 0,
      flexShrink: 1,
      height: 'auto',
    },
    piechartHeading: {
      fontSize: 16,
      fontWeight: '700',
      paddingTop: 25,
      color: Theme.Color.black,
      fontFamily: Font.soleil.regular.fontFamily,
      lineHeight: 20,
    },
    placeholderText: {
      color: Theme.Color.black,
      fontSize: 15,
      fontFamily: Font.regular.fontFamily,
      fontWeight: '400',
    },
    buttonAlignRight: {
      marginLeft: 'auto',
    },
    confirmationParagraph: {
      color: ColorConstants.iron,
      fontSize: 15,
      fontFamily: Font.regular.fontFamily,
      marginTop: 12,
    },
    smModalSize: {
      margin: BreakpointConstants.isMD(width) ? '365' : 'auto',
      maxHeight: BreakpointConstants.isMD(width) ? '' : '190px',
      maxWidth: BreakpointConstants.isMD(width) ? '' : '90%',
    },
    cancelModalContent: {
      color: ColorConstants.black,
    },
    informationMessageMargin: {marginTop: 16, marginBottom: 16},
    pdfLink: {
      color: ColorConstants.oaBlueTint,
      //marginTop: 8,
      fontFamily: Font.regular.fontFamily,
      fontSize: 15,
      fontWeight: '700',
      //@ts-ignore
      cursor: 'pointer',
      //textTransform: 'uppercase',
      marginLeft: 5,
    },
    marginTop16: {
      marginTop: 16,
    },
    errorBannerMargin: {
      marginTop: 16,
      marginBottom: 16,
    },
    linkContactUs: {
      color: ColorConstants.oaBlueTint,
      fontSize: 15,
      fontFamily: Font.medium.fontFamily,
      fontWeight: '500',
      //@ts-ignore
      display: 'inline-flex',
    },
    linkContactUsFocus: {
      textDecorationLine: 'underline',
      textDecorationColor: Theme.Color.linkFocus,
    },
    linkContactUsHover: {
      color: ColorConstants.oaBlueTint,
      fontSize: 15,
      fontFamily: Font.medium.fontFamily,
      fontWeight: '500',
    },
  })();

// Tailwind breakpoints as per documentation
// Breakpoint prefix	Minimum width	CSS
// sm	640px	@media (min-width: 640px) { ... }
// md	768px	@media (min-width: 768px) { ... }
// lg	1024px	@media (min-width: 1024px) { ... }
// xl	1280px	@media (min-width: 1280px) { ... }
// 2xl	1536px	@media (min-width: 1536px) { ... }

export default {
  isSM: (width: number) => width >= 640,
  isMD: (width: number) => width >= 768,
  isLG: (width: number) => width >= 1024,
  isXL: (width: number) => width >= 1280,
  is2XL: (width: number) => width >= 1536,
};

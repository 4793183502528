import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import React, {ReactElement} from 'react';
import {Pressable, View} from 'react-native';
import {Components, Theme, Utils} from '@oneamerica/dxp-ui-components';

export interface ProfileMenuProps {
  active?: boolean;
  id?: string;
  label?: string;
  onPress?: (val?: string) => void;
}

const {chevronRight} = Utils.icons;

const ProfileMenuItem = ({
  active,
  id,
  label,
  onPress = () => {},
}: ProfileMenuProps): ReactElement | null => {
  if (!id || !label) {
    return null;
  }
  return (
    <Pressable
      accessibilityRole="button"
      accessibilityLabel={label}
      className="px-6 py-5 border-b"
      style={{
        backgroundColor: active ? Theme.Color.ice1 : Theme.Color.white,
        borderColor:
          id !== 'beneficiaries' ? Theme.Color.rule : Theme.Color.transparent,
      }}
      onPress={() => onPress(id)}
      testID={`profileMenu-${id}`}>
      <View className={`flex-row justify-between`}>
        <View>
          <Components.Text
            font={active ? 'bold' : 'regular'}
            size="H5"
            family="soleil"
            color={Theme.Color.oaBlue}>
            {label}
          </Components.Text>
        </View>
        <View>
          <FontAwesomeIcon
            icon={chevronRight as IconProp}
            size={20}
            color={Theme.Color.oaBlue}
          />
        </View>
      </View>
    </Pressable>
  );
};

export default ProfileMenuItem;

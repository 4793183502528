import {getLoanItem} from './../../Home/helpers';
import {useSelector} from 'react-redux';
import {useState} from 'react';
import {displableBillingAndPremiumKeys} from '../../Home/helpers';
import {
  displableBaseCoverageTitle,
  displableIndexedDividendKeys,
  displableIncomePaymentInformationKeys,
  displableIncomeInfoKeys,
  displableAvailableIncomeInfoKeys,
  displableCobBaseCoverageTitle,
  displableAnnuityRatesKeys,
  DISCLAIMER_NOTE_KEY_TO_HIDE_FOR_COB,
} from '../../Home/constants';
import moment from 'moment';
import {NetworkManager} from '../../../api/NetworkManager';
import aemModalContent from '../../../mock/aemModalContent.json';
import {formatCurrency, formatDecimal} from '../../../utils';

type coverageType = {title: string; value: string}[];
type ltcType = {title: string; value: string}[];
type freeWithdrawalType = {title: string; value: string}[];
type policyValuesType = {title: string; value: string}[];

const getBillingMode = (billingModeType) => {
  switch (billingModeType) {
    case 'APP Monthly':
      return 'Monthly';
    case 'Semi-Annually':
      return 'Semi-Annual';
    default:
      return billingModeType;
  }
};

const getFormattedDate = (date) => {
  return date ? moment(date).format('MMM DD, YYYY') : '';
};

const shouldRenderRates = (key, generalPolicyData) => {
  switch (key) {
    case 'currRate':
      return generalPolicyData?.render?.currRate === true;
    case 'wghtRate':
      return generalPolicyData?.render?.wghtRate === true;
    case 'guaranteedRate':
      return generalPolicyData?.render?.guaranteedRate === true;
    case 'ltcRate':
      return generalPolicyData?.render?.ltcRate === true;
    default:
      return false;
  }
};

export const getPolicyTitleValue = (key, generalPolicyData) => {
  switch (key) {
    case 'billingAmount':
      return {
        title: 'Billing Amount',
        value: `${formatCurrency(generalPolicyData[key])}`,
      };
    case 'billToDate':
      return {
        title: 'Next Payment Due',
        value: generalPolicyData[key]
          ? moment(generalPolicyData[key]).format('MMM DD, YYYY')
          : '',
      };
    case 'billingMode':
      return {
        title: 'Payable',
        // value:
        //   generalPolicyData[key].toString() === 'APP Monthly'
        //     ? 'Monthly'
        //     : generalPolicyData[key].toString() === 'Semi-Annually'
        //     ? 'Semi-Annual'
        //     : generalPolicyData[key].toString(),
        value: getBillingMode(generalPolicyData[key].toString()),
      };
    case 'billingMethod':
      const val = generalPolicyData[key].toString();
      if (val?.toLowerCase() === 'preauthorized check') {
        return {
          title: 'Payment Method',
          value: 'Automatic Draft',
        };
      } else if (val === 'Direct Pay') {
        return {
          title: 'Payment Method',
          value: 'Direct Bill',
        };
      } else {
        return {
          title: 'Payment Method',
          value: val,
        };
      }
    case 'bankDraftDate':
      return {
        title: 'Bank Draft Date',
        value: generalPolicyData[key].toString(),
      };
    case 'bankDraftControlNumber':
      return {
        title: 'Bank Draft Control #',
        value: generalPolicyData[key].toString(),
      };
    case 'paidToDate':
      return {
        title: 'Paid to Date',
        value: generalPolicyData[key]
          ? moment(generalPolicyData[key]).format('MMM DD, YYYY')
          : '',
      };
    case 'lastPremReceived':
      return {
        title: 'Last Premium Received',
        value: generalPolicyData[key]
          ? moment(generalPolicyData[key]).format('MMM DD, YYYY')
          : '',
      };
    case 'billRiderAmt':
      return {
        title: 'PUA Rider Premium',
        value: `${formatCurrency(generalPolicyData[key])}`,
      };
    case 'plannedPDRPremium':
      return {
        title: 'Planned PDR Premium',
        value: `${formatCurrency(generalPolicyData[key])}`,
      };
    case 'totalPremiumPaidToDate':
      return {
        title: 'Total Policy Contributions',
        value: `${formatCurrency(generalPolicyData[key])}`,
      };
    default:
      return {
        title: '',
        value: '',
      };
  }
};

export const getViewIndexedDividendTitleValue = (key, genralIndexedData) => {
  switch (key) {
    case 'dividendOption':
      return {
        title: 'Dividend Option',
        value: genralIndexedData[key].toString(),
      };
    case 'currentAllocationPercent':
      return {
        title: 'Current Allocation Percent',
        value: isNaN(genralIndexedData[key].toString())
          ? 'N/A'
          : `${genralIndexedData[key].toFixed(2)}%`,
      };
    case 'dividendMaximizerRate':
      return {
        title: 'Dividend Maximizer Rate',
        value: isNaN(genralIndexedData[key].toString())
          ? 'N/A'
          : `${genralIndexedData[key].toFixed(2)}%`,
      };
    case 'maximumMultiplier':
      return {
        title: 'Maximum Multiplier',
        value: isNaN(genralIndexedData[key].toString())
          ? 'N/A'
          : `${genralIndexedData[key].toFixed(2)}`,
      };
    case 'scheduledAllocationPercent':
      return {
        title: 'Scheduled Allocation Percent',
        value: isNaN(genralIndexedData[key].toString())
          ? 'N/A'
          : `${genralIndexedData[key].toFixed(2)}%`,
      };
    default:
      return {
        title: '',
        value: '',
      };
  }
};

export const getIncomePaymentInformationValue = (
  key,
  generalIncomePaymentInformationData,
) => {
  switch (key) {
    case 'nextPayment':
      return {
        title: 'Next Planned Income Payment',
        value: `${generalIncomePaymentInformationData[key].replace(
          /\$\s+/g,
          '$',
        )}`,
      };
    case 'nextPaymentDate':
      return {
        title: 'Next Income Payment Date',
        value: generalIncomePaymentInformationData[key]
          ? moment(generalIncomePaymentInformationData[key]).format(
              'MMM DD, YYYY',
            )
          : '',
      };
    case 'lastPayment':
      return {
        title: 'Last Income Payment',
        value: `${formatCurrency(generalIncomePaymentInformationData[key])}`,
      };
    case 'lastPaymentDate':
      return {
        title: 'Last Income Payment Date',
        value: generalIncomePaymentInformationData[key]
          ? moment(generalIncomePaymentInformationData[key]).format(
              'MMM DD, YYYY',
            )
          : '',
      };
    case 'totalPayment':
      return {
        title: 'Total Income Payments Received to Date',
        value: `${formatCurrency(generalIncomePaymentInformationData[key])}`,
      };
    case 'incomeOption':
      return {
        title: 'Settlement/Income Option',
        value: generalIncomePaymentInformationData[key],
      };
    case 'incomeStartDate':
      return {
        title: 'Income Date',
        value: generalIncomePaymentInformationData[key]
          ? moment(generalIncomePaymentInformationData[key]).format(
              'MMM DD, YYYY',
            )
          : '',
      };
    default:
      return {
        title: '',
        value: '',
      };
  }
};

export const getCoverageInformationTitleValue = (key, baseCoveragesData) => {
  switch (key) {
    case 'name':
      return {
        title: 'Plan of Insurance',
        value: baseCoveragesData[key],
      };
    case 'planOfInsurance':
      return {
        title: 'Plan of Insurance',
        value: baseCoveragesData[key],
      };
    case 'annualPremium':
      return {
        title: 'Annual Premium',
        value: `${formatCurrency(baseCoveragesData[key])}`,
      };
    case 'monthlyBenefitAmt':
      return {
        title: 'Montly Benefit Amount',
        value: `${formatCurrency(baseCoveragesData[key])}`,
      };
    case 'baseAnnualPremium':
      return {
        title: 'Base Annual Premium',
        value: `${formatCurrency(baseCoveragesData[key])}`,
      };
    case 'numberMonth':
      return {
        title: 'Number of Months',
        value: baseCoveragesData[key],
      };
    case 'personInsured':
      return {
        title: 'Person Insured',
        value: baseCoveragesData[key],
      };
    case 'coverageBenefit':
      return {
        title: 'Death Benefit',
        value: baseCoveragesData[key],
      };
    case 'deathBenefit':
      return {
        title: 'Death Benefit',
        value: `${formatCurrency(baseCoveragesData[key])}`,
      };
    case 'deathBenefitOption':
      return {
        title: 'Death Benefit Option',
        value: baseCoveragesData[key],
      };
    case 'faceAmount':
      return {
        title: 'Face Amount of Insurance',
        value: `${formatCurrency(baseCoveragesData[key])}`,
      };
    case 'COBMonths':
      return {
        title: 'COB Months',
        value: baseCoveragesData[key],
      };
    case 'monthlyLTCBenefit':
      return {
        title: 'Monthly LTC Benefit',
        value: `${formatCurrency(baseCoveragesData[key])}`,
      };
    case 'monthlyCOBBenefit':
      return {
        title: 'Monthly COB Benefit',
        value: `${formatCurrency(baseCoveragesData[key])}`,
      };

    default:
      return {
        title: '',
        value: '',
      };
  }
};

export const getIncomeInformationTitleValue = (key, incomeInfoData) => {
  switch (key) {
    case 'incomeOption':
      return {
        title: 'Settlement/Income Option',
        value: incomeInfoData[key].toString(),
      };
    case 'incomeStartDate':
      return {
        title: 'Income Date',
        value: incomeInfoData[key]
          ? moment(incomeInfoData[key]).format('MMM DD, YYYY')
          : '',
      };
    case 'incomeSelected':
      return {
        title: 'Income Selected',
        value: incomeInfoData[key].toString().trim().replace(/\$\s+/g, '$'),
      };
    case 'monthly':
      return {
        title: 'Monthly',
        value: `${formatCurrency(incomeInfoData[key])}`,
      };
    case 'quarterly':
      return {
        title: 'Quarterly',
        value: `${formatCurrency(incomeInfoData[key])}`,
      };
    case 'annually':
      return {
        title: 'Annual',
        value: `${formatCurrency(incomeInfoData[key])}`,
      };
    default:
      return {
        title: '',
        value: '',
      };
  }
};

export const getltcSectionTitleValue: (
  key: any,
  ltcSectionData: any,
) => {
  title: string;
  value: string;
} = (key, ltcSectionData) => {
  if (ltcSectionData && key in ltcSectionData && ltcSectionData[key] != null) {
    switch (key) {
      case 'ltcBenefitBalance':
        return {
          title: 'Current LTC Benefit Balance',
          value: `${formatCurrency(ltcSectionData[key])}`,
        };
      case 'ltcTotalWithdrawn':
        return {
          title: 'LTC Total Amount Withdrawn',
          value: `${formatCurrency(ltcSectionData[key])}`,
        };
      case 'ltcMonthlyMaximum':
        return {
          title: 'LTC Monthly Maximum',
          value: `${formatCurrency(ltcSectionData[key])}`,
        };
      default:
        return {
          title: '',
          value: '',
        };
    }
  } else {
    return {
      title: '',
      value: '',
    };
  }
};

export const getPolicyValuesTitleValue = (key, policyValuesData) => {
  switch (key.type) {
    case 'Account Value':
      return {
        title: 'Account Value',
        value: `${formatCurrency(key.amount)}`,
      };
    case 'Current Account Value':
      return {
        title: 'Account Value',
        value: `${formatCurrency(key.amount)}`,
      };
    case 'Total Death Benefit':
      return {
        title: 'Total Death Benefit',
        value: key.amount,
      };
    case 'Dividend on Deposit':
      return {
        title: 'Dividend Accumulation',
        value: `${formatCurrency(key.amount)}`,
      };
    case 'Dividends on Deposit':
      return {
        title: 'Dividend Accumulation',
        value: `${formatCurrency(key.amount)}`,
      };
    case 'Projected Dividend Next Anniversary':
      return {
        title: 'Projected Dividend Next Anniversary',
        value: `${formatCurrency(key.amount)}`,
      };
    case 'Guaranteed Cash Value':
      return {
        title: 'Guaranteed Cash Value',
        value: `${formatCurrency(key.amount)}`,
      };
    case 'Cash Value of Paid Up Additions':
      return {
        title: 'Cash Value of Paid Up Additions',
        value: `${formatCurrency(key.amount)}`,
      };
    case 'Net Cash Value':
      return {
        title: 'Net Cash Value',
        value: `${formatCurrency(key.amount)}`,
      };
    case 'Cash Surrender Value':
      return {
        title: 'Cash Surrender Value',
        value: `${formatCurrency(key.amount)}`,
      };
    case 'Cost Basis:':
      return {
        title: 'Cost Basis',
        value: `${formatCurrency(key.amount)}`,
      };
    case 'Accumulated Value':
      return {
        title: 'Accumulated Value',
        value: `${formatCurrency(key.amount)}`,
      };
    case 'Accumulated Value LTC Fund':
      return {
        title: 'Accumulated Value LTC Fund',
        value: `${formatCurrency(key.amount)}`,
      };
    case 'Continuation of Benefits Maximum Monthly Benefit':
      return {
        title: 'Continuation of Benefits Maximum Monthly Benefit',
        value: `${formatCurrency(key.amount)}`,
      };
    case 'Continuation of Benefits Minimum Benefit Duration':
      return {
        title: 'Continuation of Benefits Minimum Benefit Duration',
        value: `${key.text.toString()}`,
      };
    case 'Cash Value':
      return {
        title: 'Cash Value',
        value: `${formatCurrency(key.amount)}`,
      };
    case 'Premium Deposit Account':
      return {
        title: 'Premium Deposit Account',
        value: `${formatCurrency(key.amount)}`,
      };
    case 'Paid Up Insurance Rider':
      return {
        title: 'Paid Up Insurance Rider',
        value: `${formatCurrency(key.amount)}`,
      };
    case 'Dividend Last Anniversary':
      return {
        title: 'Dividend Last Anniversary',
        value: `${formatCurrency(key.amount)}`,
      };
    case 'Premium Deposit Fund':
      return {
        title: 'Premium Deposit Fund',
        value: `${formatCurrency(key.amount)}`,
      };
    case 'Accelerator ONE':
      return {
        title: 'Accelerator ONE',
        value: `${formatCurrency(key.amount)}`,
      };
    case 'Endowment Coverage':
      return {
        title: 'Endowment Coverage',
        value: `${formatCurrency(key.amount)}`,
      };
    case 'Guaranteed Income Benefit Amount (GIB)':
      return {
        title: 'Guaranteed Income Benefit Amount (GIB)',
        value: `${formatCurrency(key.amount)}`,
      };
    case 'GMWB Benefit Base Amount':
      return {
        title: 'GMWB Benefit Base Amount',
        value: `${formatCurrency(key.amount)}`,
      };
    case 'Guar Annual Withdrawal Amount':
      return {
        title: 'Guar Annual Withdrawal Amount',
        value: `${formatCurrency(key.amount)}`,
      };
    case 'Guar Annual Withdrawal Amount remaining to next anniversary':
      return {
        title: 'Guar Annual Withdrawal Amount remaining to next anniversary',
        value: `${formatCurrency(key.amount)}`,
      };
    case 'Guar Annual Withdrawal Amount (Benefit Base times the 5.00  % GAWA Rate)':
      return {
        title:
          'Guar Annual Withdrawal Amount (Benefit Base times the 5.00  % GAWA Rate)',
        value: `${formatCurrency(key.amount)}`,
      };
    case 'Guar Annual Withdrawal Amount (Benefit Base times the 6.00  % GAWA Rate)':
      return {
        title:
          'Guar Annual Withdrawal Amount (Benefit Base times the 6.00  % GAWA Rate)',
        value: `${formatCurrency(key.amount)}`,
      };
    case 'Guar Annual Withdrawal Amount (Benefit Base times the 7.00  % GAWA Rate)':
      return {
        title:
          'Guar Annual Withdrawal Amount (Benefit Base times the 7.00  % GAWA Rate)',
        value: `${formatCurrency(key.amount)}`,
      };
    case 'Freedom Builder Plus Benefit Base':
      return {
        title: 'Freedom Builder Plus Benefit Base',
        value: `${formatCurrency(key.amount)}`,
      };
    case 'Freedom Builder Plus Income Rate':
      return {
        title: 'Freedom Builder Plus Income Rate',
        value: `${key.percent.toString()}%`,
      };
    case 'Freedom Builder Plus Index Multiplier':
      return {
        title: 'Freedom Builder Plus Index Multiplier',
        value: `${key.decimal.toString()}`,
      };
    case 'Freedom Builder Plus Lifetime Annual Income':
      return {
        title: 'Freedom Builder Plus Lifetime Annual Income',
        value: `${formatCurrency(key.amount)}`,
      };
    case 'ADB for Chronic Illness total lien balance':
      return {
        title: 'ADB for Chronic Illness total lien balance',
        value: `${formatCurrency(key.amount)}`,
      };
    case 'ADB for Terminal Illness total lien balance':
      return {
        title: 'ADB for Terminal Illness total lien balance',
        value: `${formatCurrency(key.amount)}`,
      };
    case 'Term Deposits':
      return {
        title: 'Term Deposits',
        value: `${formatCurrency(key.amount)}`,
      };
    default:
      return {
        title: '',
        value: '',
      };
  }
};

export const getCobGeneralPolicyInfoValue = (key, generalPolicyInfo) => {
  switch (key) {
    case 'affiliate':
      return {
        title: 'Company Name',
        value:
          generalPolicyInfo[key] === 'SL'
            ? 'State Life'
            : generalPolicyInfo[key],
      };
    case 'policyStatus':
      return {
        title: 'Contract Status',
        value: generalPolicyInfo[key],
      };
    case 'issueDate':
      return {
        title: 'Issue Date',
        value: generalPolicyInfo[key]
          ? moment(generalPolicyInfo[key]).format('MMM DD, YYYY')
          : '',
      };
    case 'withdrawals':
      return {
        title: 'Cash Withdrawals',
        value: `${formatCurrency(generalPolicyInfo[key])}`,
      };
    case 'billingMethod':
      const val = generalPolicyInfo[key].toString();
      if (val?.toLowerCase() === 'preauthorized check') {
        return {
          title: 'Payment Method',
          value: 'Automatic Draft',
        };
      } else if (val === 'Direct Pay') {
        return {
          title: 'Payment Method',
          value: 'Direct Pay',
        };
      } else {
        return {
          title: 'Payment Method',
          value: val,
        };
      }
    case 'billingMode':
      return {
        title: 'Payable',
        value: generalPolicyInfo[key],
      };
    case 'bankDraftDate':
      return {
        title: 'Bank Draft Day',
        value: generalPolicyInfo[key],
      };
    case 'bankDraftControlNumber':
      return {
        title: 'Bank Draft Control #',
        value: generalPolicyInfo[key],
      };
    case 'billingAmount':
      return {
        title: 'Billing Amount',
        value: generalPolicyInfo[key]
          ? `${formatCurrency(generalPolicyInfo[key])}`
          : '-',
      };
    case 'billToDate':
      return {
        title: 'Next Payment Due',
        value: generalPolicyInfo[key]
          ? moment(generalPolicyInfo[key]).format('MMM DD, YYYY')
          : '',
      };
    case 'paidToDate':
      return {
        title: 'Paid to Date',
        value: generalPolicyInfo[key]
          ? moment(generalPolicyInfo[key]).format('MMM DD, YYYY')
          : '',
      };
    case 'lastPremReceived':
      return {
        title: 'Last Premium Received',
        value: generalPolicyInfo[key],
      };
    default:
      return {
        title: '',
        value: '',
      };
  }
};

export const getCashWithdrawalTitleValue = (key, freeWithdrawalData) => {
  if (
    freeWithdrawalData &&
    key in freeWithdrawalData &&
    freeWithdrawalData[key] != null
  ) {
    switch (key) {
      case 'freeWithdrawalAmount':
        return {
          title: 'Penalty Free Cash Withdrawal Amount',
          value: `${formatCurrency(freeWithdrawalData[key])}`,
        };
      case 'freeAmountWithdrawn':
        return {
          title: 'Total Cash Amount Withdrawn',
          value: `${formatCurrency(freeWithdrawalData[key])}`,
        };
      case 'freeRemainingWithdrawal':
        return {
          title: 'Remaining Cash Free Out Amount',
          value: `${formatCurrency(freeWithdrawalData[key])}`,
        };
      default:
        return {
          title: '',
          value: '',
        };
    }
  }
};

export const getAnnuityRatesValue = (key, generalPolicyData) => {
  switch (key) {
    case 'currRate':
      if (shouldRenderRates(key, generalPolicyData)) {
        return {
          title: `${generalPolicyData.labels.currRate}`,
          value: `${formatDecimal(generalPolicyData[key])}%`,
        };
      }
      break;
    case 'wghtRate':
      if (shouldRenderRates(key, generalPolicyData)) {
        const value = generalPolicyData[key];
        const dateString = generalPolicyData.labels[key];
        // Extracting start and end dates from the date string
        const dates = dateString.match(/\d{1,2}\/\d{1,2}\/\d{4}/g);
        // Parsing start and end dates
        const startDate = new Date(dates[0]);
        const endDate = new Date(dates[1]);
        // Parsing start and end dates
        const formattedStartDate = getFormattedDate(startDate);
        const formattedEndDate = getFormattedDate(endDate);

        // The final formatted string
        const formattedDateRange = `Weighted Rate for ${formattedStartDate} through ${formattedEndDate}`;
        if (value !== 0) {
          return {
            title: formattedDateRange,
            value: ` ${formatDecimal(value)}%`,
          };
        }
      }
      break;
    case 'guaranteedRate':
      if (shouldRenderRates(key, generalPolicyData)) {
        return {
          title: 'Min Guaranteed Interest Rate',
          value: ` ${formatDecimal(generalPolicyData[key])}%`,
        };
      }
      break;
    case 'ltcRate':
      if (shouldRenderRates(key, generalPolicyData)) {
        return {
          title: 'Long Term Care Rate',
          value: `${formatDecimal(generalPolicyData[key])}%`,
        };
      }
      break;
    default:
      if (key != 'render' && 'labels') {
        return {
          title: '',
          value: '',
        };
      }
      break;
  }
};

export const getPremiumData = (statusInfo, premiums) => {
  const premiumData =
    statusInfo?.policyStatus === 'Active' ? premiums || [] : [];

  return premiumData;
};
export const getIncomePaymentSectionData = (incomePaymentInfo) => {
  const incomePaymentInfofilteredObject = {};
  let incomePaymentInformationData: any = [];
  displableIncomePaymentInformationKeys.forEach((key) => {
    if (
      incomePaymentInfo &&
      (incomePaymentInfo[key] || incomePaymentInfo[key] === '')
    ) {
      incomePaymentInfofilteredObject[key] = incomePaymentInfo[key];
    }
  });
  incomePaymentInformationData = Object.keys(
    incomePaymentInfofilteredObject,
  ).map((item) =>
    getIncomePaymentInformationValue(item, incomePaymentInfofilteredObject),
  );
  return incomePaymentInformationData;
};

export const getBaseCoverageSectionData = (baseCoverages) => {
  const coverageObject = baseCoverages.length > 0 ? baseCoverages[0] : {};
  let coverageInformationData: coverageType = [];
  if (Object.keys(coverageObject).length > 0) {
    const filteredCoverageObject = Object.fromEntries(
      Object.entries(coverageObject).filter(([key, value]) => value !== null),
    );
    const sequentialBaseCoverageObject = {};
    displableBaseCoverageTitle.forEach((key) => {
      if (
        filteredCoverageObject[key] !== undefined &&
        filteredCoverageObject[key] !== null
      ) {
        sequentialBaseCoverageObject[key] = filteredCoverageObject[key];
      }
    });

    coverageInformationData = Object.keys(sequentialBaseCoverageObject).map(
      (item) => getCoverageInformationTitleValue(item, baseCoverages[0]),
    );
  }
  return coverageInformationData;
};

export const getltcSectionData = (ltcSection) => {
  let ltcSectionFormattedData: ltcType = [];
  if (ltcSection !== null && Object.keys(ltcSection).length > 0) {
    ltcSectionFormattedData = Object.keys(ltcSection).map((key) =>
      getltcSectionTitleValue(key, ltcSection),
    );
  }
  return ltcSectionFormattedData;
};

export const getPolicyValuesSection = (addCoverages, policyValues) => {
  let policyValuesFormattedData: policyValuesType = [];
  if (addCoverages !== null && addCoverages.length > 0) {
    const policyValueTotalDeathBenefit = addCoverages.find(
      (item) => item.name === 'Total Death Benefit',
    );
    if (
      policyValueTotalDeathBenefit &&
      policyValues !== null &&
      policyValues.length > 0
    ) {
      const policyValuesSection = [
        ...policyValues,
        {
          type: policyValueTotalDeathBenefit.name,
          amount: policyValueTotalDeathBenefit.coverageBenefit,
        },
      ];
      policyValuesFormattedData = policyValuesSection
        .map((key) => {
          return getPolicyValuesTitleValue(key, policyValues);
        })
        .filter((item) => item.value);
    } else if (policyValues !== null && policyValues.length > 0) {
      policyValuesFormattedData = policyValues
        .map((key) => {
          return getPolicyValuesTitleValue(key, policyValues);
        })
        .filter((item) => item.value);
    }
  } else if (policyValues !== null && policyValues.length > 0) {
    policyValuesFormattedData = policyValues
      .map((key) => {
        return getPolicyValuesTitleValue(key, policyValues);
      })
      .filter((item) => item.value);
  }
  return policyValuesFormattedData;
};

export const getFreeWithDrawalSection = (freeWithdrawal) => {
  let freeWithdrawalFormattedData: freeWithdrawalType = [];
  if (freeWithdrawal !== null && Object.keys(freeWithdrawal).length > 0) {
    freeWithdrawalFormattedData = Object.keys(freeWithdrawal).map((key) =>
      getCashWithdrawalTitleValue(key, freeWithdrawal),
    ) as freeWithdrawalType;
  }
  return freeWithdrawalFormattedData;
};

export const getIncomeInformationSectionData = (
  getPremiumStatus,
  generalPolicyData,
  generalPolicyDataRender,
  incomeOptions,
) => {
  const isIncomeOption =
    getPremiumStatus === 'Active' &&
    (generalPolicyData?.render?.incomeOption === true ||
      generalPolicyData?.render?.incomeStartDate === true ||
      generalPolicyData?.render?.incomeSelected === true);
  const incomeInfoFilteredObject = {};
  let incomeInformation: any = [];

  if (isIncomeOption) {
    displableIncomeInfoKeys.forEach((key) => {
      if (
        (generalPolicyData[key] || generalPolicyData[key] !== null) &&
        generalPolicyDataRender[key]
      ) {
        incomeInfoFilteredObject[key] = generalPolicyData[key];
      }
    });

    displableAvailableIncomeInfoKeys.forEach((key) => {
      if (
        incomeOptions &&
        Object.keys(incomeOptions).length > 0 &&
        incomeOptions[key] !== null
      ) {
        incomeInfoFilteredObject[key] = incomeOptions[key];
      }
    });
  }
  incomeInformation = Object.keys(incomeInfoFilteredObject).map((item) =>
    getIncomeInformationTitleValue(item, incomeInfoFilteredObject),
  );
  return incomeInformation;
};

export const getPolicyRelationshipSectionData = (policyRelationships) => {
  const zeroIndexData = {
    idType: 'SSN',
    relationType: 'Relationship',
    addresses: [
      {
        type: 'MAIL',
        foreignAddress: null,
        foreignAddressIndicator: false,
        line1: 'Address',
        line2: '',
        line3: null,
        city: 'City',
        state: 'State',
        zip: 'Zip',
        region: null,
        country: null,
      },
    ],
    personDetails: {
      age: 'Issue Age',
      dob: 'Date of Birth',
      sex: 'Gender',
      name: {
        first: 'Name',
      },
    },
  };
  const apiResponse = policyRelationships;
  let policyRelationshipData: any[] = [];

  if (Array.isArray(apiResponse)) {
    apiResponse.unshift(zeroIndexData);

    const termOrder = [
      'Primary Insured',
      'Joint Insured',
      'Annuitant',
      'Joint Annuitant',
      'Owner',
      'Payor',
      'Spouse',
      'Beneficiary',
      'First Beneficiary',
      'Second Beneficiary',
      'Insured Beneficiary',
      'Assignee',
      'Eligible Person',
      'Third Party',
      'Dependent',
      'Other Insured',
      'Guardian',
      'Conservator',
      'Employer',
    ];

    const reorderedApiResponse = termOrder
      .flatMap((term) =>
        apiResponse.filter((item) => item.relationType === term),
      )
      .filter((item) => item !== undefined);
    reorderedApiResponse.unshift(zeroIndexData);

    const popupDataAll = reorderedApiResponse.filter(
      (item) => item.relationType != 'Current Servicing Agent',
    );
    policyRelationshipData = popupDataAll;
  }
  return policyRelationshipData;
};

export const getAnnuityRatesSectionData = (generalPolicyData) => {
  const filteredAnuuityRates = {};
  let annuityRatesData: any = [];
  const isAnnuityRateSection =
    generalPolicyData?.render?.currRate === true ||
    generalPolicyData?.render?.wghtRate === true ||
    generalPolicyData?.render?.ltcRate === true ||
    generalPolicyData?.render?.guaranteedRate === true;

  if (isAnnuityRateSection) {
    displableAnnuityRatesKeys.forEach((key) => {
      if (
        generalPolicyData &&
        (generalPolicyData[key] || generalPolicyData[key] === '')
      ) {
        filteredAnuuityRates[key] = generalPolicyData[key];
      }
    });
  }
  annuityRatesData = Object.keys(filteredAnuuityRates).map((item) =>
    getAnnuityRatesValue(item, filteredAnuuityRates),
  );

  return annuityRatesData;
};

export const usePolicyDetailsSelector = (props) => {
  const policyData = useSelector((state: any) => state.policies);
  



  const [infoModalContent, setInfoModalContent] = useState<any>([]);
  const [isInfoModalVisible, setInfoModalVisiblity] = useState(false);

  const maskId = (idNumber) => {
    const idStr = String(idNumber);
    if (idStr.length < 3) {
      return '-';
    }
    return `***-**-${idStr.slice(-4)}`;
  };

  const {
    getPoliciesListData = {},
    isError = false,
    loading = true,
  } = policyData || {};

  const {policyHighlights = []} =
    getPoliciesListData?.data?.policySummary || {};
  const {
    statusInfo = {},
    premiums = [],
    generalPolicyData = {},
    indexedDividend = {},
    incomePaymentInfo = {},
    coverageInformation = {},
    policyValue = {},
    policyRelationships = [],
    incomeOptions = {},
    ltcSection = {},
    freeWithdrawal = {},
    continuationOfBenefit = {},
    disclaimersNotes = [],
  } = policyHighlights[0] || {};

  const {baseCoverages = [], addCoverages = []} = coverageInformation || {};
  const {policyValues = [], valueAsOfDate = ''} = policyValue || {};
  const getPremiumStatus = statusInfo?.policyStatus;
  const filteredObject = {};
  const indexedFilteredObject = {};

  const {
    coverages,
    generalPolicyInfo,
    account: cbAccount,
    statusInfo: cbStatusInfo,
  } = continuationOfBenefit || {};

  const {
    baseCoverages: continuationOfBenefitBaseCoverage = [],
    addCoverages: continuationofBenefitAddCoverage = [],
  } = coverages || {};

  const filteredNotes = disclaimersNotes.filter(
    (notes: any) => notes.disclaimerKey !== DISCLAIMER_NOTE_KEY_TO_HIDE_FOR_COB,
  );

  //Billing And Premium Information
  const {render: generalPolicyDataRender} = generalPolicyData || {};

  displableBillingAndPremiumKeys.forEach((key) => {
    if (generalPolicyData[key] && generalPolicyDataRender[key]) {
      filteredObject[key] = generalPolicyData[key];
    }
  });
  const billingAndPremiumData = Object.keys(filteredObject).map((item) => {
    return getPolicyTitleValue(item, filteredObject);
  });

  //Income Payment Information
  let incomePaymentInformationData: any = [];
  incomePaymentInformationData = getIncomePaymentSectionData(incomePaymentInfo);

  //Base Coverages Information
  let coverageInformationData: any = [];
  coverageInformationData = getBaseCoverageSectionData(baseCoverages);

  let cbData: {title: string; value: string}[] = [];
  if (continuationOfBenefitBaseCoverage.length > 0) {
    const cobBaseCoverages = {};
    displableCobBaseCoverageTitle.forEach((key) => {
      if (continuationOfBenefitBaseCoverage[0][key]) {
        cobBaseCoverages[key] = continuationOfBenefitBaseCoverage[0][key];
      }
    });
    cbData = Object.keys(cobBaseCoverages).map((item) => {
      return getCoverageInformationTitleValue(item, cobBaseCoverages);
    });
  }

  let continuationOfBenefitGeneralInfo: (
    | {title: string; value: string}
    | undefined
  )[] = [];
  const cobContractStatus = cbStatusInfo?.policyStatus ?? '';

  if (
    generalPolicyInfo != undefined &&
    Object.keys(generalPolicyInfo).length > 0
  ) {
    const finalCobPolicyInfo = {
      affiliate: cbAccount.affiliate,
      policyStatus: cbStatusInfo.policyStatus,
      ...generalPolicyInfo,
    };
    continuationOfBenefitGeneralInfo = Object.keys(finalCobPolicyInfo).map(
      (item) => {
        if (finalCobPolicyInfo[item] && item != 'render' && item != 'labels') {
          return getCobGeneralPolicyInfoValue(item, finalCobPolicyInfo);
        }
      },
    );
    continuationOfBenefitGeneralInfo = continuationOfBenefitGeneralInfo.filter(
      (item) => item !== undefined,
    );
  }

  displableIndexedDividendKeys.forEach((key) => {
    if (indexedDividend && indexedDividend[key]) {
      indexedFilteredObject[key] = indexedDividend[key];
    }
  });

  const viewIndexedDividendData = Object.keys(indexedFilteredObject).map(
    (item) => {
      return getViewIndexedDividendTitleValue(item, indexedFilteredObject);
    },
  );

  // Premium Data section
  const premiumData = getPremiumData(statusInfo, premiums);

  // Loan Data Information
  const loanData = getLoanItem(policyValue);

  //LTC Section Information
  let ltcSectionFormattedData: ltcType = [];
  ltcSectionFormattedData = getltcSectionData(ltcSection);

  //Additional Coverages Information
  let addCoveragesData: any = [];
  if (addCoverages !== null && addCoverages.length > 0) {
    if ('riderInfo' in addCoverages[0]) {
      addCoveragesData = addCoverages.map((item) => {
        if ('annualPremium' in item) {
          return {
            ...item,
            annualPremium:
              item.annualPremium === null || item.annualPremium < 0
                ? '-'
                : `${formatCurrency(item.annualPremium)}`,
          };
        } else {
          return item;
        }
      });
    } else {
      addCoveragesData = addCoverages.filter(
        (item) => item.name !== 'Total Death Benefit',
      );
      addCoveragesData = addCoveragesData.map((item) => {
        if ('annualPremium' in item) {
          return {
            ...item,
            annualPremium:
              item.annualPremium === null || item.annualPremium < 0
                ? '-'
                : `${formatCurrency(item.annualPremium)}`,
          };
        } else {
          return item;
        }
      });
    }
  }

  //Policy Values Section in Policy Details Page
  let policyValuesFormattedData: policyValuesType = [];
  policyValuesFormattedData = getPolicyValuesSection(
    addCoverages,
    policyValues,
  );

  // Free Withdrawal Section
  let freeWithdrawalFormattedData: freeWithdrawalType = [];
  freeWithdrawalFormattedData = getFreeWithDrawalSection(freeWithdrawal);

  const dateRangeforWithdrawalDateRange =
    freeWithdrawal !== null && freeWithdrawal.freeWithdrawalDateRange;

  // Income Information Section
  let incomeInformation: any = [];
  incomeInformation = getIncomeInformationSectionData(
    getPremiumStatus,
    generalPolicyData,
    generalPolicyDataRender,
    incomeOptions,
  );

  // Policy Relationship section
  let policyRelationshipData: any[] = [];
  policyRelationshipData =
    getPolicyRelationshipSectionData(policyRelationships);

  const getModalDetails = () => {
    NetworkManager.getPolicyRelationshipModal()
      .then((response) => {
        setInfoModalContent(
          response.data.data.policyRelationshipsModelByPath.item
            .descriptionForPolicyRelationshipInformation.json,
        );
      })
      .catch((error) => {
        console.log('Error fetching policy relationship:', error);
        setInfoModalContent(
          aemModalContent.data.policyRelationshipsModelByPath.item
            .descriptionForPolicyRelationshipInformation[0].json,
        );
      });

    setInfoModalVisiblity(true);
  };

  // Annuity Rates Information
  let annuityRatesData: any = [];
  annuityRatesData = getAnnuityRatesSectionData(generalPolicyData);

  return {
    isError,
    billingAndPremiumData,
    loading,
    premiumData,
    incomeInformation,
    viewIndexedDividendData,
    coverageInformationData,
    incomePaymentInformationData,
    loanData,
    ltcSectionData: ltcSectionFormattedData,
    policyValuesData: policyValuesFormattedData,
    freeWithdrawalData: freeWithdrawalFormattedData,
    policyValueAsOfDate: valueAsOfDate,
    getPremiumStatus,
    policyRelationshipData,
    infoModalContent,
    isInfoModalVisible,
    setInfoModalVisiblity,
    getModalDetails,
    maskId,
    dateRangeforWithdrawalDateRange,
    addCoveragesData,
    cbData,
    continuationOfBenefitGeneralInfo,
    continuationofBenefitAddCoverage,
    annuityRatesData,
    policyNotes: filteredNotes,
    cobContractStatus,
  };
};

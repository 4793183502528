import {Pressable, ScrollView, View} from 'react-native';
import {useState, useEffect, useRef} from 'react';
import ScreenWrapper from '../../../components/ScreenWrapper/ScreenWrapper';
import withAuth from '../../../auth/withAuth';
import PolicyDropdown from '../../../components/PolicyDropdown';
import {
  Components,
  List,
  LoadingIndicator,
  Theme,
  Accordion,
} from '@oneamerica/dxp-ui-components';
import Stepper from '../../../components/Stepper';
import {Button} from '@oneamerica/dxp-ui-components';
import BreakpointConstants from '../../../constants/BreakpointConstants';
import {useNavigation, useRoute} from '@react-navigation/native';
import {DrawerNavigationProp} from '@react-navigation/drawer';
import RouteConstants from '../../../constants/RouteConstants';
import React, {useContext} from 'react';
import notes from '../../../mock/notes.json';
import {useSelector} from 'react-redux';
import responsiveStyle from '../PercentTransfer/style';
import {AlertLevel} from '../../../constants/UtilsConstants';
import {getTransferDollarRequest} from '../../../redux/TransferBalance/action';
import {useDispatch} from 'react-redux';
import EditDollarTransfer from './EditDollarTransfer';
import ReviewDollarTransfer from './ReviewDollarTransfer';
import ConfirmDollarTransfer from './ConfirmDollarTransfer';
import {NetworkManager} from '../../../api/NetworkManager';
import {getPoliciesList} from '../../../redux/policies/action';
import {isHomeOfficeUser} from '../../../utils';
import ErrorWrapper from '../../../components/ErrorWrapper';
import {apiErrorMessages} from '../../MyInvestmentsElections/constants';
import {
  trackCustomEvent,
  trackPage,
} from '../../../utils/Analytics/AdobeAnalyticsUtils';
import {analyticsTags} from '../../../utils/Analytics/AnalyticsTags';
interface RouteParams {
  account: any;
}
const DollarTransfer = (props) => {
  const dispatch = useDispatch();
  const route = useRoute();
  const {account} = route?.params as RouteParams;
  const {width} = useContext(Theme.ResponsiveContext);
  const style = responsiveStyle(width);
  const steps = ['Transfer', 'Review', 'Complete'];
  const navigation = useNavigation<DrawerNavigationProp<any>>();
  const [currentStep, setCurrentStep] = useState(1);
  const [showSuccess, setShowSuccess] = useState(false);
  const isLoading = useSelector((state: any) => state.transferBalance.loading);
  const dollarTransferData = useSelector((state: any) => state.transferBalance);
  const [errorInTable, setErrorInTable] = useState('');
  const [isTableDirty, setIsTableDirty] = useState(false);
  const [editedData, setEditedData] = useState([]);
  const [previousClicked, setPreviousClicked] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [confirmClicked, setConfirmClicked] = useState(1);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [confirmationNumber, setConfirmationNumber] = useState('');
  const isHomeOfcUser = isHomeOfficeUser();
  const policyLists = useSelector((state: any) => state.policyLists);

  const transferBalanceResponseData = useSelector(
    (state: any) => state.transferBalance,
  );
  const scrollViewRef = useRef<ScrollView>(null);
  const [showProcessingTroubleModal, setShowProcessingTroubleModal] =
    useState(false);

  let dollarTransferChangesData =
    transferBalanceResponseData?.getDollarTransferData?.data?.items[0]
      ?.investmentOptionChanges;
  const isError = transferBalanceResponseData?.isError;

  useEffect(() => {
    trackPage(analyticsTags.createDollarTransferPageViewTags);
    if (dollarTransferChangesData === undefined) {
      dispatch(
        getTransferDollarRequest(
          props?.accessToken?.accessToken,
          account,
          'dollar',
        ),
      );
    }
    return () => {
      setEditedData([]);
      dollarTransferChangesData = [];
    };
  }, []);

  const getEditedData = (data) => {
    setEditedData(data);
  };

  const setIsErrorInTable = (isError) => {
    setErrorInTable(isError);
    if (isError.length > 0) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
  };
  const scrollToTop = () => {
    scrollViewRef?.current?.scrollTo({y: 0, animated: true});
  };
  const setIsDirty = (isDirty) => {
    setIsTableDirty(isDirty);
  };
  const linkStyleContactUs = {
    style: style.linkContactUs,
    linkStyle: style.linkContactUs,
    linkHoverStyle: style.linkContactUs,
    hoverStyle: style.linkContactUsHover,
    focusStyle: style.linkContactUsFocus,
  };
  const changeState = () => {
    setCurrentStep(currentStep + 1);
    setPreviousClicked(false);
    scrollToTop();
  };
  const closeButton = () => {
    setErrorInTable('');
  };
  const navigateToContactUs = () => {
    navigation.navigate(RouteConstants.contactus);
  };

  const confirmInformation = () => {
    setConfirmClicked(confirmClicked + 1);
    if (confirmClicked === 1) {
      trackCustomEvent(analyticsTags.CaptureDollarTransferConfirmOneButtonTags);
      setShowInfo(true);
    } else if (confirmClicked === 2) {
      trackCustomEvent(
        analyticsTags.CaptureDollarTransferConfirmFinalButtonTags,
      );
      setShowInfo(false);
      changeState();
    }
    function mergeAndReplaceValues(originalArray, newArray) {
      for (let i in originalArray) {
        if (originalArray[i].reallocValue === originalArray[i].currentValue) {
          originalArray[i].reallocValue =
            originalArray[i].reallocValue -
            +newArray[i]?.fromValue +
            +newArray[i]?.toValue;
        }
      }
      return originalArray;
    }
    const modifiedArray = mergeAndReplaceValues(
      dollarTransferChangesData,
      editedData,
    );
    if (confirmClicked === 2) {
      setShowLoader(true);
      NetworkManager.sendTransferTransaction({
        token: dollarTransferData?.token,
        policyNumber: account,
        transactiontype: 'dollar',
        payload: {investmentOptionChanges: modifiedArray},
      })
        .then((response) => {
          const {data} = response;
          dispatch(
            getPoliciesList(
              policyLists?.selectedPolicy?.policyPlanNumber,
              props?.accessToken?.accessToken,
            ),
          );
          setConfirmationNumber(data?.items[0]?.confirmationNumber);
          setShowLoader(false);
          scrollToTop();
          setCurrentStep(currentStep + 1);
          setShowSuccess(true);
        })
        .catch((error) => {
          let errorText = '';
          setShowLoader(false);
          errorText = error?.data
            ? error?.data?.errorInfo
              ? error?.data?.errorInfo[0]?.detail
              : error?.data?.error
            : 'System Error';
          if (errorText === apiErrorMessages.backendErrorText) {
            scrollToTop();
            setShowErrorModal(true);
          } else {
            processingTroubleErrorModal();
            setShowProcessingTroubleModal(true);
          }
        });
    }
  };
  const cancelEditModal = () => (
    <Components.DialogModal
      style={style.smModalSize}
      backgroundColor="#F3F9FE"
      width={365}
      padding={20}
      visible={showModal}
      Header={
        <Components.Text style={style.modalTitle}>Leave Page?</Components.Text>
      }
      Footer={
        <View className="flex flex-row mt-[13px]">
          <Button
            style={[style.buttonWidthModal]}
            accessibilityRole="button"
            id="btnOutline"
            outline
            theme="primary"
            onPress={() => {
              if (currentStep === 1) {
                trackCustomEvent(
                  analyticsTags.CaptureDollarTransferEditPageCancelEditModalTags,
                );
              } else {
                trackCustomEvent(
                  analyticsTags.CaptureDollarTransferReviewPageCancelEditModalTags,
                );
              }
              setShowModal(false);
              setCurrentStep(1);
              setPreviousClicked(false);
              setShowInfo(false);
              navigation.navigate(RouteConstants.myinvestments, {
                tab: 'Current-Balance',
              });
            }}>
            Leave
          </Button>
          <Button
            style={[style.buttonWidthModal, style.buttonKeepEditing]}
            accessibilityRole="button"
            id="btnOutline"
            theme="primary"
            onPress={() => {
              if (currentStep === 1) {
                trackCustomEvent(
                  analyticsTags.CaptureDollarTransferEditPageKeepEditingButtonTags,
                );
              } else {
                trackCustomEvent(
                  analyticsTags.CaptureDollarTransferReviewPageKeepEditingButtonTags,
                );
              }
              setShowModal(false);
            }}>
            Keep Editing
          </Button>
        </View>
      }
      onClose={() => setShowModal(false)}>
      <Components.Text
        className="grow-0 mt-[16px]"
        style={style.cancelModalContent}>
        By leaving this page, you will lose any changes you have made.
      </Components.Text>
    </Components.DialogModal>
  );
  const onCloseProcessingModal = () => {
    setShowProcessingTroubleModal(false);
    navigation.navigate(RouteConstants.transferbalance);
  };
  const processingTroubleErrorModal = () => (
    <Components.DialogModal
      style={style.smModalSize}
      backgroundColor="#F3F9FE"
      width={365}
      padding={20}
      visible={showProcessingTroubleModal}
      Header={
        <Components.Text style={style.modalTitle}>
          Processing trouble
        </Components.Text>
      }
      onClose={() => onCloseProcessingModal()}>
      <Components.Text className="grow-0 mt-[16px]">
        {'Apologies, we’re having some trouble processing your request.\n\n'}
        {
          'If you have any questions, please reference the Policy Services section on the '
        }
        <Components.Link
          onPress={() => {
            trackCustomEvent(
              analyticsTags.CaptureContactUsDollarTransferTroubleTags,
            );
            navigateToContactUs();
          }}
          accessibilityLabel="Contact Us"
          accessibilityRole="button"
          {...linkStyleContactUs}>
          Contact Us
        </Components.Link>{' '}
        {'page.'}
      </Components.Text>
    </Components.DialogModal>
  );
  const onCancel = () => {
    if (currentStep === 1) {
      trackCustomEvent(
        analyticsTags.CaptureDollarTransferEditPageCancelButtonTags,
      );
    } else {
      trackCustomEvent(
        analyticsTags.CapturenDollarTransferReviewPageCancelButtonTags,
      );
    }
    if (isTableDirty) {
      setShowModal(true);
    } else {
      navigation.navigate(RouteConstants.myinvestments, {
        tab: 'Current-Balance',
      });
    }
  };

  const printConfirmTable = () => {
    //@ts-ignore
    const printWindow = window.open('', '_blank');
    //@ts-ignore
    const sucessBanner = window.document.getElementById('confirmsucessbanner');
    //@ts-ignore
    const printTable = window.document.getElementById('confirmpagetable');
    //@ts-ignore
    printWindow.document.write('<html>');
    printWindow.document.write(
      '<head><style>.r-alignItems-1awozwy .css-view-175oi2r{display: flex} #para2>div{dispaly: flex; flex-direction: column}\n.css-view-175oi2r.r-flex-13awgt0.r-flexDirection-18u37iz{display: flex; width:100%;text-align:left}\n.css-view-175oi2r.r-flex-13awgt0.r-flexDirection-18u37iz div:nth-child(2){display: flex;text-align: left !important}\n.css-view-175oi2r.r-flex-13awgt0.r-flexDirection-18u37iz div {text-align: left !important;border-bottom: solid 1px; margin-bottom: 2px}\n.r-elevation-1quu1zo div {border: none !important;margin-left: 5px}\n.r-elevation-1quu1zo {border: solid 1px;margin: 20px 0;padding: 20px 5px 0 5px;}\n.css-view-175oi2r r-display-xoduu5 r-cursor-1loqt21 r-touchAction-1otgn73{margin-left: 5px}\n.css-view-175oi2r.r-flex-13awgt0.r-flexDirection-18u37iz div:nth-child(4){width: 10% !important}\n .css-view-175oi2r.r-flex-13awgt0.r-flexDirection-18u37iz div:nth-child(5){width: 10% !important}\n .css-view-175oi2r.r-flex-13awgt0.r-flexDirection-18u37iz div:nth-child(6){width: 14% !important}</style></head><body>',
    );
    printWindow.document.write(sucessBanner?.innerHTML || '');
    printWindow.document.write(printTable?.innerHTML || '');
    printWindow.document.write('</body></html>');
    printWindow.print();
  };

  const renderStepHeaderText = () => {
    return (
      <Components.Text.H2
        style={[
          style.reviewPgTableTitle,
          BreakpointConstants.isLG(width)
            ? style.topMarginReview
            : style.noTopMargin,
        ]}>
        {currentStep === 1
          ? 'Transfer Your Balance'
          : currentStep === 2
          ? 'Review Your Changes'
          : ''}
      </Components.Text.H2>
    );
  };
  const renderStepSubHeaderText = () => {
    return (
      currentStep === 2 && (
        <Components.Text
          style={[
            style.placeholderText,
            BreakpointConstants.isLG(width)
              ? style.noTopMargin
              : style.reviewPlaceholderMargin,
          ]}>
          Review the details of your current balance below to confirm that it is
          set as you intended.
        </Components.Text>
      )
    );
  };
  const renderConfirmationSuccesBanner = () => {
    return (
      currentStep == 3 &&
      showSuccess && (
        <div id="confirmsucessbanner">
          <Components.AlertMessage
            title={`YOUR CONFIRMATION NUMBER IS:  ${confirmationNumber}`}
            className="my-5 mt-6 mb-6 flex-1 py-4"
            alertLevel={AlertLevel.SUCCESS}
            onCloseMessage={closeButton}
            fontSize={BreakpointConstants.isLG(width) ? 16 : 15}
            iconSize={BreakpointConstants.isLG(width) ? 52.36 : 24}>
            <div id={'para2'}>
              <View style={style.row}>
                <Components.Text style={[style.confirmationParagraph]}>
                  We’ve successfully received your request. A written
                  confirmation of your changes will be sent via regular mail
                  service. Please record your confirmation number or print this
                  page for future reference.
                </Components.Text>
                <Components.Text style={[style.confirmationParagraph]}>
                  If you have any questions, please reference the Policy
                  Services section on the
                  <Pressable
                    onPress={() => {
                      trackCustomEvent(
                        analyticsTags.CaptureContactUsDollarTransferPDFLinkTags,
                      );
                      navigateToContactUs();
                    }}
                    accessibilityRole="button"
                    accessibilityLabel="Contact Us">
                    <Components.Text style={[style.pdfLink]}>
                      Contact Us
                    </Components.Text>
                  </Pressable>
                  &nbsp;page.
                </Components.Text>
              </View>
            </div>
          </Components.AlertMessage>
        </div>
      )
    );
  };
  const renderTabletAndMobileNotes = () => {
    return (
      currentStep !== 3 && (
        <View style={[style.hideLg, style.topMargin]}>
          <Accordion open title="Notes" style={style.accordionBg}>
            <List
              textSize={14}
              style={style.bulletListStyle}
              flatList={
                currentStep === 1
                  ? notes?.data?.notesTranferBalanceDollar?.item
                  : [
                      dollarTransferData?.getTransferBalanceData?.data?.items[0]
                        ?.generalDisclaimers[0]?.disclaimerText,
                      ...notes?.data?.notesTranferBalanceDollar?.item,
                    ]
              }
            />
          </Accordion>
        </View>
      )
    );
  };
  const renderDesktopNotes = () => {
    return (
      currentStep !== 3 && (
        <View style={style.showLg}>
          <Components.Text.H3 style={[style.notesTitle, style.topMargin]}>
            Notes
          </Components.Text.H3>
          <List
            textSize={14}
            style={style.bulletListStyle}
            flatList={
              currentStep === 1
                ? notes?.data?.notesTranferBalanceDollar?.item
                : [
                    dollarTransferData?.getTransferBalanceData?.data?.items[0]
                      ?.generalDisclaimers[0]?.disclaimerText,
                    ...notes?.data?.notesTranferBalanceDollar?.item,
                  ]
            }
          />
        </View>
      )
    );
  };
  const renderConfirmAlert = () => {
    return (
      showInfo &&
      !previousClicked && (
        <Components.AlertMessage
          alertLevel={AlertLevel.INFORMATION}
          testID="alertComponent"
          showCloseButton={false}
          fontSize={16}
          style={style.informationMessageMargin}
          title="Once confirmed, you won't be able to make edits until these changes are processed. Click confirm to continue."
        />
      )
    );
  };
  const renderHomeOfcAlert = () => {
    return (
      currentStep == 2 &&
      isHomeOfcUser && (
        <View>
          <Components.AlertMessage
            className="my-4 flex-1"
            style={style.marginTop16}
            alertLevel={AlertLevel.INFORMATION}
            testID="alertComponent"
            fontSize={16}
            showCloseButton={false}
            title="Submitting investment updates on behalf of the user is not allowed due to home office access."
          />
        </View>
      )
    );
  };
  return (
    <ScreenWrapper
      scrollFooter
      paddingTop
      paddingBottom
      paddingHorizontal
      ref={scrollViewRef}>
      <ErrorWrapper hasError={isError || showErrorModal} isLoading={isLoading}>
        {' '}
        <View style={style.mainHeading}>
          <PolicyDropdown
            className=""
            accessToken={props.accessToken}
            text={'Dollar Transfer'}
          />
          <View className="w-full p-0 mt-1">
            <Stepper steps={steps} currentStep={currentStep} />
            {isLoading ? (
              <LoadingIndicator loading={isLoading || showLoader} />
            ) : (
              <View style={[style.cardShadow, style.cardPadding]}>
                <View>
                  {renderStepHeaderText()}
                  {renderStepSubHeaderText()}
                  {renderConfirmationSuccesBanner()}
                </View>
                {/* Tablet and Mobile Notes */}
                {renderTabletAndMobileNotes()}

                <View>
                  {currentStep === 1 && (
                    <EditDollarTransfer
                      getEditedData={getEditedData}
                      previousData={editedData}
                      setIsErrorInTable={setIsErrorInTable}
                      setIsDirty={setIsDirty}
                      previousClicked={previousClicked}
                      totalValue={
                        transferBalanceResponseData?.getDollarTransferData?.data
                          ?.items[0]?.totalAcctValue
                      }
                    />
                  )}
                  {currentStep === 2 && (
                    <ReviewDollarTransfer
                      previousData={getEditedData}
                      editedData={editedData}
                      setIsErrorInTable={setIsErrorInTable}
                      setIsDirty={setIsDirty}
                    />
                  )}
                  {currentStep === 3 && (
                    <ConfirmDollarTransfer
                      previousData={getEditedData}
                      editedData={editedData}
                      setIsErrorInTable={setIsErrorInTable}
                      setIsDirty={setIsDirty}
                    />
                  )}
                  {errorInTable.length > 0 && (
                    <Components.AlertMessage
                      title={errorInTable}
                      className="my-5 flex-1"
                      style={style.errorBannerMargin}
                      alertLevel={AlertLevel.ERROR}
                      fontSize={16}
                      showCloseButton
                      onCloseMessage={closeButton}
                    />
                  )}
                </View>
                {/* Desktop Notes */}
                {renderDesktopNotes()}
                {renderConfirmAlert()}
                {renderHomeOfcAlert()}
                <View
                  className="flex mt-[25px] mb-0"
                  style={style.buttonFlexDirection}>
                  {currentStep <= 2 && (
                    <Button
                      style={[
                        BreakpointConstants.isMD(width)
                          ? style.buttonCancelLeft
                          : style.buttonTop,
                        style.buttonWidth,
                      ]}
                      accessibilityRole="button"
                      id="btnOutline"
                      outline
                      theme="primary"
                      onPress={() => onCancel()}>
                      Cancel
                    </Button>
                  )}
                  {currentStep === 1 && (
                    <Button
                      style={[
                        BreakpointConstants.isMD(width)
                          ? style.buttonAlignRight
                          : style.buttonTop,
                        style.buttonWidth,
                      ]}
                      accessibilityRole="button"
                      onPress={changeState}
                      disabled={!isTableDirty || disableNext}
                      id="btnNext"
                      theme="primary">
                      Next
                    </Button>
                  )}
                  {currentStep === 2 && (
                    <>
                      <Button
                        style={[
                          BreakpointConstants.isMD(width)
                            ? style.buttonAlignRight
                            : style.buttonTop,
                          style.buttonWidth,
                        ]}
                        accessibilityRole="button"
                        onPress={() => {
                          setCurrentStep(currentStep - 1);
                          setPreviousClicked(true);
                        }}
                        disabled={false}
                        id="btnDisabled"
                        theme="tertiary">
                        Previous
                      </Button>
                      <Button
                        style={[
                          BreakpointConstants.isMD(width)
                            ? style.buttonSpaceLeft
                            : style.buttonTop,
                          style.buttonWidth,
                        ]}
                        accessibilityRole="button"
                        onPress={confirmInformation}
                        id="btnPrimary"
                        disabled={isHomeOfcUser}
                        selected={false}
                        theme="primary">
                        Confirm
                      </Button>
                    </>
                  )}
                  {currentStep === 3 && (
                    <>
                      <Button
                        style={[
                          BreakpointConstants.isMD(width)
                            ? style.buttonAlignRight
                            : style.buttonTop,
                          style.buttonWidth,
                        ]}
                        accessibilityRole="button"
                        disabled={false}
                        id="btnDisabled"
                        onPress={() => {
                          trackCustomEvent(
                            analyticsTags.CaptureDollarTransferPrintButtonTags,
                          );
                          printConfirmTable();
                        }}
                        theme="tertiary">
                        Print
                      </Button>
                      <Button
                        style={[
                          BreakpointConstants.isMD(width)
                            ? style.buttonSpaceLeft
                            : style.buttonTop,
                          style.buttonWidth,
                        ]}
                        accessibilityRole="button"
                        onPress={() => {
                          trackCustomEvent(
                            analyticsTags.CaptureDollarTransferDoneButtonTags,
                          );
                          navigation.navigate(RouteConstants.myinvestments, {
                            tab: 'Current-Balance',
                          });
                        }}
                        id="btnPrimary"
                        selected={false}
                        theme="primary">
                        Done
                      </Button>
                    </>
                  )}
                </View>
              </View>
            )}
          </View>
        </View>
        {showModal && cancelEditModal()}
        {showProcessingTroubleModal && processingTroubleErrorModal()}
      </ErrorWrapper>
    </ScreenWrapper>
  );
};

export default withAuth(DollarTransfer);

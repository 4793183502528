import React from 'react';
import {View} from 'react-native';
import responsiveStyle from './style';
const style = responsiveStyle();
import {Components, Font} from '@oneamerica/dxp-ui-components';
import InfoWrapper from '../../../../components/InfoWrapper/InfoWrapper';
import ColorConstants from '../../../../constants/ColorConstants';
import moment from 'moment';
import {formatCurrency} from '../../../../utils';

interface LoanDataProps {
  amount: number;
  type: string;
}
interface LtcSectionDataProps {
  title: string;
  value: string;
}
interface freeWithdrawalDataProps {
  title: string;
  value: string;
}
interface policyValuesDataProps {
  title: string;
  value: string;
}

const PolicyValue = ({
  loanData,
  ltcSectionData,
  policyValueAsOfDate,
  freeWithdrawalData,
  policyValuesData,
  dateRangeforWithdrawalDateRange,
}: {
  loanData: LoanDataProps;
  ltcSectionData: LtcSectionDataProps[];
  policyValuesData: policyValuesDataProps[];
  freeWithdrawalData: freeWithdrawalDataProps[];
  dateRangeforWithdrawalDateRange: string;
  policyValueAsOfDate: string;
}) => {
  const hasLoanData = Object.keys(loanData).length > 0;
  return (
    <View className="lg:flex-row">
      <View className="w-full p-0 mt-8">
        <View style={style.cardShadow}>
          <View style={style.cardHeader}>
            <Components.Text.H3 style={[style.cardHeaderTitle, style.flex]}>
              Policy Values
            </Components.Text.H3>
            <Components.Text.H6
              style={{
                fontSize: 14,
                fontFamily: Font.regular.fontFamily,
                color: ColorConstants.iron,
              }}>
              As of{' '}
              {policyValueAsOfDate
                ? moment(policyValueAsOfDate).format('MMM DD, YYYY')
                : '-'}{' '}
            </Components.Text.H6>
          </View>
          <View className="mt-4 mb-4" style={style.horizontalDivider} />
          <View className="flex flex-col md:flex-row">
            <View style={{flex: 2}}>
              {policyValuesData && policyValuesData.length > 0 && (
                <>
                  <Components.Text.H6 style={style.innerCardHeaderTitle}>
                    Policy Values
                  </Components.Text.H6>
                  <View
                    style={{
                      flex: 1,
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                    }}
                    className="w-full">
                    {policyValuesData.map((data, index) => {
                      if (data.title) {
                        return (
                          <View
                            key={index}
                            style={{
                              paddingBottom: 20,
                            }}
                            className={
                              hasLoanData
                                ? 'w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/3'
                                : 'w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4'
                            }>
                            <InfoWrapper
                              title={data.title}
                              subTitle
                              information={
                                data.value !== null && data.value !== undefined
                                  ? data.value
                                  : '-'
                              }
                              infoTextStyle={{
                                fontWeight: '500',
                              }}
                            />
                          </View>
                        );
                      }
                    })}
                  </View>
                </>
              )}
              {freeWithdrawalData && freeWithdrawalData.length > 0 && (
                <>
                  <Components.Text.H6
                    style={[
                      style.innerCardHeaderTitle,
                      {
                        marginBottom: 16,
                      },
                    ]}>
                    {`Cash Withdrawal Values from ${dateRangeforWithdrawalDateRange}`}
                  </Components.Text.H6>
                  <View
                    style={{
                      flex: 1,
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                    }}
                    className="w-full">
                    {freeWithdrawalData.map((data, index) => {
                      if (data.title) {
                        return (
                          <View
                            key={index}
                            style={{
                              paddingBottom: 20,
                            }}
                            className="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4">
                            <InfoWrapper
                              title={data.title}
                              titleTextStyle={{
                                paddingBottom: 8,
                              }}
                              subTitle
                              information={
                                data.value !== null && data.value !== undefined
                                  ? data.value
                                  : '-'
                              }
                              infoTextStyle={{
                                fontWeight: '500',
                              }}
                            />
                          </View>
                        );
                      }
                    })}
                  </View>
                </>
              )}

              {ltcSectionData.length > 0 && (
                <>
                  <Components.Text.H6
                    style={[
                      style.innerCardHeaderTitle,
                      {
                        marginBottom: 16,
                      },
                    ]}>
                    LTC Values
                  </Components.Text.H6>
                  <View
                    style={{
                      flex: 1,
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      // paddingBottom: 16,
                    }}
                    className="w-full">
                    {ltcSectionData.map((data, index) => {
                      return (
                        <View
                          key={index}
                          style={{
                            paddingBottom: 20,
                          }}
                          className="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4">
                          <InfoWrapper
                            title={data.title}
                            titleTextStyle={{
                              paddingBottom: 8,
                            }}
                            subTitle
                            information={
                              data.value !== null && data.value !== undefined
                                ? data.value
                                : '-'
                            }
                            infoTextStyle={{
                              fontWeight: '500',
                            }}
                          />
                        </View>
                      );
                    })}
                  </View>
                </>
              )}
            </View>
            {Object.keys(loanData).length > 0 && (
              <View style={{flex: 1, flexDirection: 'row'}} className="pb-6 ">
                <View
                  className="hidden md:flex"
                  style={{
                    width: 1,
                    backgroundColor: ColorConstants.grey200,
                    marginRight: 4,
                  }}
                />
                <View style={{flex: 1}}>
                  <Components.Text.H6
                    style={[
                      style.innerCardHeaderTitle,
                      {
                        paddingLeft: 7,
                        marginLeft: 2,
                        marginBottom: 8,
                      },
                    ]}>
                    Loans
                  </Components.Text.H6>
                  <InfoWrapper
                    title={loanData.type}
                    titleTextStyle={{paddingBottom: 8}}
                    subTitle
                    information={`${formatCurrency(loanData.amount)}`}
                    infoTextStyle={{
                      fontWeight: '500',
                    }}
                  />
                </View>
              </View>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

export default PolicyValue;

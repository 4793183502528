import {Theme, Font} from '@oneamerica/dxp-ui-components';
import ColorConstants from '../../constants/ColorConstants';

export default () =>
  Theme.Responsive.create({
    cardShadow: {
      borderRadius: 5,
      shadowRadius: 6,
      backgroundColor: Theme.Color.background,
      shadowColor: Theme.Color.backgroundShadow,
      shadowOffset: {width: 0, height: 3},
      paddingHorizontal: Theme.Metrics.small,
      paddingVertical: Theme.Metrics.small,
    },
    paddingHorizontalXSmall: {
      paddingHorizontal: Theme.Metrics.xSmall,
    },
    horizontalDivider: {
      backgroundColor: ColorConstants.grey200,
      height: 1,
      width: '100%',
    },
    policyNumberTitle: {
      fontFamily: Font.regular.fontFamily,
      color: Theme.Color.gray6,
      fontSize: 14,
    },
    policyNumberText: {
      fontFamily: Font.medium.fontFamily,
      color: Theme.Color.gray6,
      fontSize: 16,
      marginTop: Theme.Metrics.xSmall,
    },
    contactUs: {
      fontSize: 15,
      fontFamily: Font.medium.fontFamily,
      color: ColorConstants.oaBlue,
    },
    contactUsText: {
      fontSize: 15,
      fontFamily: Font.regular.fontFamily,
      color: Theme.Color.gray6,
      textAlign: 'center',
      marginTop: Theme.Metrics.small,
      paddingHorizontal: '8%',
    },
    linkContactUs: {
      color: ColorConstants.oaBlueTint,
      fontSize: 16,
      fontFamily: Font.medium.fontFamily,
      //@ts-ignore
      display: 'inline-flex',
    },
    linkContactUsFocus: {
      textDecorationLine: 'underline',
      textDecorationColor: Theme.Color.linkFocus,
    },
    linkContactUsHover: {
      color: ColorConstants.oaBlueTint,
      fontSize: 16,
      fontFamily: Font.medium.fontFamily,
    },
  })();

import {Theme, Font} from '@oneamerica/dxp-ui-components';
import ColorConstants from '../../constants/ColorConstants';

export default () =>
  Theme.Responsive.create({
    cardShadow: {
      borderRadius: 5,
      shadowRadius: 6,
      backgroundColor: Theme.Color.background,
      shadowColor: Theme.Color.backgroundShadow,
      shadowOffset: {width: 0, height: 3},
      paddingHorizontal: Theme.Metrics.small,
      paddingVertical: Theme.Metrics.small,
      flex: 1,
    },
    cardHeader: {
      marginHorizontal: -Theme.Metrics.medium,
      marginTop: Theme.Metrics.small,
      paddingHorizontal: Theme.Metrics.medium,
      borderRadius: 4,
    },
    cardHeaderTitle: {color: Theme.Color.oaBlue},
    contentTitle: {
      color: ColorConstants.iron,
      fontSize: 20,
      fontFamily: Font.medium.fontFamily,
      marginTop: 24,
    },
    firstParagraph: {
      color: ColorConstants.iron,
      fontSize: 14,
      fontFamily: Font.regular.fontFamily,
      marginTop: 16,
    },
    pdfLink: {
      color: ColorConstants.oaBlueTint,
      marginTop: 8,
      fontFamily: Font.regular.fontFamily,
      fontSize: 16,
      //@ts-ignore
      cursor: 'pointer',
      textTransform: 'uppercase',
    },
    noPadding: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingHorizontal: 0,
    },
    tabStyle: {
      color: ColorConstants.darkRed,
    },
    horizontalDivider: {
      backgroundColor: ColorConstants.grey200,
      height: 1,
      width: '100%',
    },
    investmentCardDate: {
      color: ColorConstants.iron,
      fontSize: 16,
      lineHeight: 27,
      fontFamily: Font.regular.fontFamily,
      alignSelf: 'flex-start',
      flex: 1,
    },
    transferBal: {
      color: ColorConstants.oaBlueTint,
      lineHeight: 27,
      fontFamily: Font.regular.fontFamily,
      fontSize: 16,
      alignSelf: 'flex-start',
      display: 'flex',
    },
    editFutureElections: {
      color: ColorConstants.oaBlueTint,
      lineHeight: 27,
      fontFamily: Font.regular.fontFamily,
      fontSize: 16,
      alignSelf: 'flex-start',
      flexDirection: 'row',
    },
    componentPadding: {
      paddingHorizontal: 16,
      paddingVertical: 16,
    },
    outlineNone: {
      outlineWidth: 0,
      marginLeft: 10,
      marginTop: 5,
    },
    headingTable: {
      color: ColorConstants.iron,
      fontSize: 14,
      fontFamily: Font.regular.fontFamily,
    },
    Details: {
      height: 16,
      width: 16,
      borderRadius: 50,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: ColorConstants.darkBlue,
      color: '#002C77',
      fontWeight: 'bold',
      fontSize: 12,
      marginRight: 4,
      alignSelf: 'flex-end',
    },
  })();

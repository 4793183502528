import React, {ReactElement, useContext, useEffect, useState} from 'react';
import {Linking, TouchableOpacity, View} from 'react-native';
import {Components, Theme} from '@oneamerica/dxp-ui-components';
import SaveChanges from '../SaveChanges';
import {SelectOptions} from '@oneamerica/dxp-ui-components/lib/typescript/src/components/SelectInput';
import withAuth from '../../../../auth/withAuth';
import {ProfileCardProps} from '../../types';
import responsiveStyle from './style';
import {useProfileDetailsSelector} from '../../hooks/profileDetails';
import {ErrorListProps} from '@oneamerica/dxp-ui-components/lib/typescript/src/components/TextInput';
import {AlertLevel} from '../../../../constants/UtilsConstants';
import {useDispatch} from 'react-redux';
import {
  updateProfileData,
  updateProfileDataReset,
} from '../../../../redux/profileDetails/action';
import BreakpointConstants from '../../../../constants/BreakpointConstants';

const DisplaySecurityQuestion = (
  props: ProfileCardProps,
): ReactElement | null => {
  const {showMenu, activeMenu, scrollToTop} = props;
  const {isError, loading, getProfileResponsiveData, isProfileUpdated} =
    useProfileDetailsSelector(props);
  const {width} = useContext(Theme.ResponsiveContext);
  const styles = responsiveStyle(width);
  const [answer, setAnswer] = useState('');
  const [selectedSecurityQuestion, setSelectedSecurityQuestion] =
    useState<SelectOptions | null>(null);
  const [password, setPassword] = useState<string>('');
  const [arr] = useState<SelectOptions[]>([]);
  const dispatch = useDispatch();

  if (arr.length === 0) {
    getProfileResponsiveData?.data?.securityQuestions?.forEach(
      (element, index) => {
        const obj = {label: element.question, value: element.questionAttribute};
        arr.push(obj);
      },
    );
  }
  const onSave = () => {
    const dataValue = {
      password: password,
      userQuestion: {
        question: selectedSecurityQuestion?.label,
        questionAttribute: selectedSecurityQuestion?.value,
        answer: answer,
      },
    };
    dispatch(updateProfileData(dataValue, props?.accessToken?.accessToken));
  };

  useEffect(() => {
    if (isProfileUpdated) {
      setSelectedSecurityQuestion(null);
      setAnswer('');
    }
  }, [isProfileUpdated]);

  const onSetValue = (value) => {
    setPassword(value);
  };

  const resetFields = () => {
    setSelectedSecurityQuestion(null);
    setAnswer('');
    typeof showMenu === 'function' && showMenu('contact');
    closeButton();
  };

  const defaultInputValue = () => {
    const cutoff = BreakpointConstants.isMD(width) ? 38 : 32;
    const label = selectedSecurityQuestion?.label;
    if (!label) {
      return '';
    }
    const hasNaturalEnd =
      label?.slice(0, cutoff).includes('?') && label?.indexOf('?') < cutoff;
    const formattedLabel = hasNaturalEnd
      ? label?.slice(0, label?.indexOf('?') + 1)
      : label?.length > cutoff
      ? label?.slice(0, cutoff) + '...'
      : label;
    return formattedLabel;
  };

  const answerErrors: ErrorListProps[] = [];
  if (selectedSecurityQuestion && answer.length < 2) {
    answerErrors.push({
      id: 0,
      errorItem: 'Please enter your answer.',
    });
  } else {
    answerErrors.pop();
  }

  const closeButton = () => {
    dispatch(updateProfileDataReset());
  };
  const getLabel = () => {
    const question = getProfileResponsiveData?.data?.userQuestion?.question;
    if (selectedSecurityQuestion) {
      return 'Security Question';
    }
    const formattedQuestion =
      question && question.length > 41
        ? question.slice(0, 41).includes('?') && question.indexOf('?') <= 40
          ? question.slice(0, question.indexOf('?') + 1)
          : question.slice(0, 41) + '...'
        : question;
    return formattedQuestion;
  };
  return (
    <Components.Card>
      {loading && <Components.LoadingOverlay loading={loading} />}
      <Components.Text
        color={Theme.Color.black}
        family="soleil"
        size="H2"
        className="mb-4 lg:mt-2"
        style={styles.headerStyle}>
        Security Question
      </Components.Text>
      <View
        className="flex-1 justify-between my-4 lg:flex-row "
        style={{zIndex: 100}}>
        <View className="lg:w-2/5">
          <View style={{zIndex: 100}} className="min-h-12">
            <Components.SelectInput
              dropdownHeight={200}
              className="mb-4 w-full z-50 lg:mb-0"
              label={getLabel()}
              options={arr}
              onPress={(selectedAnswer) => {
                const answer = arr.find(
                  (securityAnswer) => securityAnswer.value === selectedAnswer,
                );
                if (answer) {
                  setSelectedSecurityQuestion(answer);
                }
              }}
              value={defaultInputValue()}
            />
          </View>
          <Components.TextInput
            className="mt-4"
            onChangeText={setAnswer}
            autoComplete="off"
            autoCorrect={false}
            value={answer}
            label={'Answer'}
            maxLength={50}
            ErrorList={answerErrors}
          />
        </View>
        <View className="-z-10 lg:w-3/5 lg:px-5 xl:px-10 mt-8 lg:mt-0">
          <Components.Text
            family="soleil"
            size="H5"
            font="bold"
            color={Theme.Color.oaBlue}
            className="mb-4">
            OneAmerica Financial<sup style={{fontSize: 9}}>SM&nbsp;</sup> is
            keeping your information secure.
          </Components.Text>
          <Components.Text
            family="roboto"
            size="Body"
            className="mb-4"
            color={Theme.Color.black}>
            Your information is important, and protecting the confidentiality
            and integrity of that information is our priority. We value your
            trust and have multiple ways in which we protect you and your
            information.
          </Components.Text>
          <View className="mt-4 lg:flex-row">
            <Components.Text
              family="roboto"
              size="Body"
              className="mb-1 lg:mb-4"
              color={Theme.Color.black}>
              To learn more, view our{'  '}
            </Components.Text>
            <TouchableOpacity
              onPress={() =>
                Linking.openURL('https://www.oneamerica.com/legal/security')
              }>
              <Components.Text
                family="roboto"
                size="Body"
                className="mb-4"
                color={Theme.Color.oaBlue}>
                Information Security Program.
              </Components.Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
      <View>
        {isProfileUpdated ? (
          <Components.AlertMessage
            title="You successfully updated your Security Question."
            className="mb-4"
            fontSize={16}
            testID="security-alertBanner"
            onCloseMessage={closeButton}
          />
        ) : (
          isError && (
            <Components.AlertMessage
              title="The password you entered is incorrect."
              className="mb-4"
              fontSize={16}
              testID="security-alertBanner"
              alertLevel={AlertLevel.ERROR}
              onCloseMessage={closeButton}
            />
          )
        )}
        <Components.Divider />
        <SaveChanges
          activeMenu={activeMenu}
          disabled={!(answer.length > 0 && selectedSecurityQuestion != null)}
          onCancel={() => {
            resetFields();
            scrollToTop();
          }}
          onSave={onSave}
          setValue={onSetValue}
        />
      </View>
    </Components.Card>
  );
};
export default withAuth(DisplaySecurityQuestion);

import React, {forwardRef, useEffect, useContext, useState} from 'react';
import {Keyboard, View} from 'react-native';
import {Components, Theme, Utils} from '@oneamerica/dxp-ui-components';
import SaveChanges from '../../../Profile/components/SaveChanges';
import {ProfileUserIdType} from '../../../Profile/types';
import responsiveStyle from './style';
import {Formik, FormikProps} from 'formik';
import * as Yup from 'yup';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import BreakpointConstants from '../../../../constants/BreakpointConstants';
import {useIdSelector} from './hooks/userId';
import {useSelector} from 'react-redux';
import {AlertLevel} from '../../../../constants/UtilsConstants';
import {PROFILE_LOGOUT_TIME} from '../../../../config/getEnvironmentVars';
import {
  DefaultUserIDValidationObj,
  UseridValidationObjectType,
  validateUsernameField,
} from '../utils';

export interface ChangeUserIdProps {
  content?: ProfileUserIdType;
  onCancel: () => void;
  accessToken: any;
  authLogout: () => void;
}

const ChangeUserId = forwardRef((props: ChangeUserIdProps, _ref) => {
  const {content, onCancel, accessToken, authLogout} = props;
  const {width} = useContext(Theme.ResponsiveContext);
  const styles = responsiveStyle(width);
  const [newUserId, setNewUserId] = useState('');
  const [apiCalled, setApiCalled] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState(true);
  const [password, setPassword] = useState<string>('');
  const [validationError, setValidationError] = useState<string>('');
  const [userSuccess, setUserSuccess] = useState(false);
  const isMobile = !BreakpointConstants.isMD(width);
  const {circleXMarkSolid, circleCheck} = Utils.icons;
  const profileData = useSelector(
    (state: any) => state.profileData.getProfileResponsiveData,
  );

  const [requirementsCheck, setRequirementsCheck] =
    useState<UseridValidationObjectType>(DefaultUserIDValidationObj);

  const NewUserIdChangeText = (text: string) => {
    const rules = validateUsernameField(text);
    setRequirementsCheck(rules);
    setNewUserId(text);
    validate(requirementsCheck);
  };

  const {
    userAvailable,
    isLoading,
    getUserDetails,
    getUserIdDetails,
    isError,
    setIsError,
    postApi,
  } = useIdSelector(props);

  const userSchema = Yup.object().shape({
    newId: Yup.string().required('Please enter New User ID'),
    confirmNewId: Yup.string()
      .oneOf([Yup.ref('newId')], 'New User ID and Confirm User ID must match')
      .required('Confirm User ID is required'),
  });

  const validate = (checked) => {
    if (!checked.min) {
      console.log('error');
      setValidationError('Username must be at least 6 characters');
    } else if (!checked.max) {
      setValidationError('Username must be no more than 64 characters');
    } else if (!checked.endWithLetterOrNumber) {
      setValidationError('Username must end with a letter or number');
    } else if (!checked.allowableSpecialCharacters) {
      setValidationError('Username can only contain -_.@ special characters');
    } else if (!checked.notNineNumbers) {
      setValidationError('Username cannot contain nine or more digits');
    } else if (!checked.noSpaces) {
      setValidationError('Username cannot contain spaces');
    } else {
      setValidationError('');
    }
  };

  const Icon = userAvailable ? circleCheck : circleXMarkSolid;
  const iconColor = userAvailable ? '#336633' : '#CC0033';

  const cancelChanges = (resetForm) => {
    resetForm();
    onCancel();
    setApiCalled(false);
    setIsError(false);
    setUserSuccess(false);
    closeButton();
  };

  const resetFormValue = (formikProps) => {
    if ( postApi) {
      formikProps.resetForm();
    }
  };
  const onButtonClick = (resetForm, formikProps) => {
    Keyboard.dismiss();
    setIsError(false);
    setApiCalled(false);
    setUserSuccess(false);
    if (formikProps.values.newId && formikProps.values.confirmNewId) {
      setUserSuccess(true);
      getUserIdDetails(password, formikProps.values.newId, accessToken);
      setIsError(false);
    }
    resetFormValue(formikProps);
  };

  const checkUserIdAvailability = () => {
    setApiCalled(true);
    getUserDetails(accessToken, newUserId);
  };

  const onSetValue = (value) => {
    setPassword(value);
  };

  const closeButton = () => {
    setIsError(false);
    setUserSuccess(false);
  };

  if (postApi && !isError) {
    setTimeout(() => {
      authLogout();
    }, Number(PROFILE_LOGOUT_TIME));
  }

  const getErrorList = (formikErrors, validationError) => {
    if (formikErrors.newId) {
      return [{errorItem: formikErrors.newId}];
    } else if (validationError) {
      return [{errorItem: validationError}];
    }
    return [];
  };

  const getConfirmErrorList = (confirmError) => {
    if (!confirmError) {
      return [];
    }
    return [{errorItem: confirmError}];
  };

  function getUserAvailabilityMessage(userAvailable) {
    return userAvailable ? 'User ID available' : 'User ID not available';
  }

  function getTextStyle(requirementCheck, newUserId) {
    const font: any = requirementCheck && newUserId ? 'bold' : 'regular';
    const color: any =
      newUserId && !requirementCheck ? Theme.Color.oaRed : Theme.Color.black;
    return {font, color};
  }
  const alertMessageProps = (value) => {
    setShowAlertMessage(value);
  };
  useEffect(() => {
    const timerId = setTimeout(() => {
      if (newUserId.length >= 6 && validationError === '') {
        checkUserIdAvailability();
      } else {
        setApiCalled(false);
      }
    }, 500);
    return () => clearTimeout(timerId);
  }, [newUserId]);
  return (
    <Components.Card>
      <Components.Text.H2 style={styles.cardHeaderTitle} className="lg:mt-2">
        User ID
      </Components.Text.H2>
      {isLoading && <Components.LoadingOverlay loading={isLoading} />}

      <Formik
        validateOnChange={true}
        initialValues={{
          currentId: profileData?.data?.logonID,
          newId: '',
          confirmNewId: '',
        }}
        validationSchema={userSchema}
        onSubmit={(values, {setSubmitting}) => {
          setSubmitting(false);
        }}>
        {(formikProps: FormikProps<PasswordFormValues>) => (
          <View>
            <View
              className="justify-between my-4 flex-col lg:flex-row"
              style={{zIndex: 100}}>
              <View className="lg:flex-1 lg:pr-12 xl:max-w-[380px]">
                <View style={{zIndex: 100}} className="mb-4">
                  <Components.TextInput
                    onChangeText={formikProps.handleChange('currentId')}
                    value={formikProps.values.currentId}
                    label={content?.currentUserIdLabel}
                    editable={false}
                  />
                </View>
                <View className="mb-4">
                  <Components.TextInput
                    onChangeText={(text) => {
                      NewUserIdChangeText(text);
                      formikProps.handleChange('newId')(text);
                      setIsError(false);
                    }}
                    value={formikProps.values.newId}
                    label={content?.newUserIdLabel}
                    ErrorList={getErrorList(
                      formikProps.errors,
                      validationError,
                    )}
                  />
                </View>
                <View className="lg:flex-row flex-row justify-between items-center mb-4">
                  {apiCalled && !isLoading && (
                    <View className="flex-row justify-between items-center">
                      <FontAwesomeIcon
                        color={iconColor}
                        icon={Icon as IconProp}
                        size={20}
                      />
                      <Components.Text
                        className="pl-2"
                        style={styles.textStyle}>
                        {getUserAvailabilityMessage(userAvailable)}
                      </Components.Text>
                    </View>
                  )}
                </View>
                <View className="mb-6">
                  <Components.TextInput
                    onChangeText={formikProps.handleChange('confirmNewId')}
                    value={formikProps.values.confirmNewId}
                    label={content?.confirmUserIdLabel}
                    ErrorList={getConfirmErrorList(
                      formikProps.errors.confirmNewId,
                    )}
                  />
                </View>
              </View>
              <View className="-z-10 lg:flex-[1]">
                {!isMobile && (
                  <Components.Text
                    family="soleil"
                    size="H5"
                    font="bold"
                    color={Theme.Color.oaBlue}
                    className="mb-4">
                    {content?.title}
                  </Components.Text>
                )}

                <Components.Text
                  {...getTextStyle(requirementsCheck?.min, newUserId)}
                  family="roboto"
                  size="Body"
                  className="mb-4">
                  &bull; {content?.useridRequirementsTextLine1}
                </Components.Text>
                <Components.Text
                  {...getTextStyle(requirementsCheck?.max, newUserId)}
                  family="roboto"
                  size="Body"
                  className="mb-4">
                  &bull; {content?.useridRequirementsTextLine2}
                </Components.Text>
                <Components.Text
                  {...getTextStyle(
                    requirementsCheck?.endWithLetterOrNumber,
                    newUserId,
                  )}
                  family="roboto"
                  size="Body"
                  className="mb-4">
                  &bull; {content?.useridRequirementsTextLine3}
                </Components.Text>
                <Components.Text
                  {...getTextStyle(
                    requirementsCheck?.allowableSpecialCharacters,
                    newUserId,
                  )}
                  family="roboto"
                  size="Body"
                  className="mb-4">
                  &bull; {content?.useridRequirementsTextLine4}
                </Components.Text>
                <Components.Text
                  {...getTextStyle(
                    requirementsCheck?.notNineNumbers,
                    newUserId,
                  )}
                  family="roboto"
                  size="Body"
                  className="mb-4">
                  &bull; {content?.useridRequirementsTextLine5}
                </Components.Text>
                <Components.Text
                  {...getTextStyle(requirementsCheck?.noSpaces, newUserId)}
                  family="roboto"
                  size="Body"
                  className="mb-4">
                  &bull; {content?.useridRequirementsTextLine6}
                </Components.Text>
              </View>
            </View>
            <View>
              {!showAlertMessage && (
                <View>
                  <Components.AlertMessage
                    alertLevel={AlertLevel.INFORMATION}
                    testID="alertComponent"
                    showCloseButton={false}
                    fontSize={16}
                    style={styles.informationMessageMargin}
                    title="To ensure your account’s security, you will be logged out when you save your user ID."
                  />
                </View>
              )}
              {postApi && !isError ? (
                <Components.AlertMessage
                  title="Your User ID has been successfully updated. Please Login again."
                  className="mb-5 flex-1"
                  onCloseMessage={closeButton}
                />
              ) : (
                isError && (
                  <Components.AlertMessage
                    title="The password you entered is incorrect."
                    className="mb-5 flex-1 mt-3"
                    alertLevel={AlertLevel.ERROR}
                    onCloseMessage={closeButton}
                  />
                )
              )}
              <Components.Divider />
              <SaveChanges
                handleClick={alertMessageProps}
                disabled={
                  !formikProps.isValid ||
                  !formikProps.values.newId ||
                  !formikProps.values.confirmNewId ||
                  !userAvailable ||
                  password.length < 7 ||
                  !apiCalled
                }
                onCancel={() => {
                  cancelChanges(formikProps.resetForm);
                  setShowAlertMessage(true);
                  setPassword('');
                }}
                onSave={() => onButtonClick(formikProps.resetForm, formikProps)}
                setValue={onSetValue}
                isError={isError}
                userSuccess={userSuccess}
              />
            </View>
          </View>
        )}
      </Formik>
    </Components.Card>
  );
});

export default ChangeUserId;

interface PasswordFormValues {
  currentId: string;
  newId: string;
  confirmNewId: string;
}

import {Theme} from '@oneamerica/dxp-ui-components';

export default (width: number) =>
  Theme.Responsive.create({
    oaButtonStyle: {
      textTransform: 'none',
    },
    buttonStyle: {
      color: Theme.Color.white,
    },
    divider: {
      backgroundColor: Theme.Color.white,
      height: 0.5,
    },
    linkHoverStyle: {
      textDecorationLine: 'none',
      textDecorationColor: Theme.Color.white,
    },
    spacingLeft: {
      paddingLeft: '20px',
    },
  })();

import {Theme, Font} from '@oneamerica/dxp-ui-components';
import ColorConstants from '../../constants/ColorConstants';

export default () =>
  Theme.Responsive.create({
    contentWrapper: {
      flex: 1,
      width: '100%',
      flexDirection: 'column',
      height: '100%',
      minHeight: 0,
    },
    row: {flex: 1, flexDirection: 'row'},
    flex: {flex: 1},
    infoWrapperTitle: {
      fontSize: 14,
      color: ColorConstants.iron,
      fontWeight: 'normal',
      marginBottom: 7,
    },
    infoWrapperInformation: {
      fontSize: 16,
      color: ColorConstants.iron,
      fontFamily: Font.medium.fontFamily,
      lineHeight: 22,
    },
    infoWrapperInformationBold: {
      fontSize: 32,
      color: ColorConstants.iron,
      fontFamily: Font.medium.fontFamily,
      lineHeight: 'auto',
      textAlign: 'center',
    },
    subTextInformation: {
      fontSize: 16,
      color: ColorConstants.iron,
      fontFamily: Font.medium.fontFamily,
    },
    infoWrapperInformationSub: {
      fontSize: 16,
      color: ColorConstants.iron,
      fontFamily: Font.medium.fontFamily,
    },
    button: {
      alignSelf: 'flex-start',
      borderRadius: 50,
      borderWidth: 1,
      paddingHorizontal: Theme.Metrics.medium,
      paddingVertical: Theme.Metrics.xxSmall,
      marginTop: -Theme.Metrics.xxSmall,
    },
    buttonText: {
      color: ColorConstants.iron,
    },
    policyStatusBtnWrapper: {
      alignItems: 'flex-start',
      maxWidth: 150,
    },
    policyStatusButton: {
      paddingHorizontal: 8,
      textAlign: 'center',
      paddingVertical: 4,
      borderRadius: 4,
      borderWidth: 1,
      borderStyle: 'solid',
      fontSize: 14,
    },
    statusReason: {
      fontSize: 14,
      color: ColorConstants.iron,
      fontFamily: Font.regular.fontFamily,
    },
  })();

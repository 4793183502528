import React from 'react';
import {View} from 'react-native';
import responsiveStyle from '../PremiumsReceived/style';
const style = responsiveStyle();
import {Components} from '@oneamerica/dxp-ui-components';
import InfoWrapper from '../../../../components/InfoWrapper/InfoWrapper';

const BillingAndPremium = ({billingAndPremiumData}) => {
  return (
    <View className="lg:flex-row">
      <View className="w-full p-0 mt-8">
        <View style={style.cardShadow}>
          <View style={style.cardHeader}>
            <Components.Text.H3 style={[style.cardHeaderTitle, style.flex]}>
              Billing & Premiums
            </Components.Text.H3>
          </View>
          <View className="mt-4" style={style.horizontalDivider} />
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
            className="mt-4 w-full">
            {billingAndPremiumData.map((data, index) => {
              return (
                <View
                  key={`${data.title}-${index}`}
                  style={{
                    paddingBottom: 20,
                  }}
                  className="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4">
                  <InfoWrapper
                    title={data.title}
                    subTitle
                    information={data.value || '-'}
                    infoTextStyle={{
                      fontWeight: '500',
                    }}
                  />
                </View>
              );
            })}
          </View>
        </View>
      </View>
    </View>
  );
};

export default BillingAndPremium;

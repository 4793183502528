import {
  AccessToken,
  IDToken,
  RefreshToken,
  UserClaims,
} from '@okta/okta-auth-js';

export type AuthProps = {
  isAuthenticated: boolean;
  accessToken: AccessToken | undefined;
  idToken: IDToken | undefined;
  refreshToken: RefreshToken | undefined;
  user: UserClaims | undefined;
  initialState: any;
};

export const AuthState = {
  isAuthenticated: false,
  accessToken: undefined,
  idToken: undefined,
  refreshToken: undefined,
  user: undefined,
  initialState: undefined,
};

export const ActionTypes = {
  AUTHENTICATION_SUCCESS: 'AUTHENTICATION_SUCCESS',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_STATE: 'UPDATE_STATE',
  AUTHENTICATION_LOGOUT: 'AUTHENTICATION_LOGOUT',
};

const getUpdateParams = (state: AuthProps, queryParams) => {
  const {initialState} = state;
  if (!initialState) {
    return queryParams;
  } else if (Object.keys(initialState).length > 0) {
    return initialState;
  } else {
    return {};
  }
};

const AuthReducer = (state: AuthProps, action) => {
  switch (action.type) {
    case ActionTypes.AUTHENTICATION_SUCCESS: {
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        accessToken: action.accessToken,
        idToken: action.idToken,
        refreshToken: action.refreshToken,
        user: action.user,
        initialState: getUpdateParams(state, action.initialState),
      };
    }
    case ActionTypes.UPDATE_USER: {
      return {
        ...state,
        user: action.user,
      };
    }
    case ActionTypes.UPDATE_STATE: {
      return {
        ...state,
        initialState: action.initialState,
      };
    }
    case ActionTypes.AUTHENTICATION_LOGOUT: {
      return {
        ...state,
        isAuthenticated: false,
        accessToken: undefined,
        idToken: undefined,
        user: undefined,
      };
    }
    default:
      return state;
  }
};

export default AuthReducer;

import {Theme, Font} from '@oneamerica/dxp-ui-components';
import ColorConstants from '../../constants/ColorConstants';
import BreakpointConstants from '../../constants/BreakpointConstants';

const styles = (width) =>
  Theme.Responsive.create({
    stepperContainer: {
      color: Theme.Color.white,
      backgroundColor: Theme.Color.oaBlue,
      fontFamily: Font.regular.fontFamily,
      paddingTop: 32,
      paddingBottom: 16,
      paddingHorizontal: 16,
    },
    stepItem: {
      position: 'relative',
    },
    stepName: {
      fontSize: BreakpointConstants.isMD(width) ? 18 : 13,
      color: Theme.Color.white,
      fontFamily: Font.soleil.regular.fontFamily,
      fontWeight: '400',
      marginTop: 9,
    },
    stepNumber: {
      backgroundColor: Theme.Color.white,
      color: Theme.Color.oaBlue,
      fontSize: BreakpointConstants.isMD(width) ? 16 : 14,
      fontWeight: '900',
      padding: 10,
      fontFamily: Font.soleil.regular.fontFamily,
    },
    activeNumber: {
      backgroundColor: ColorConstants.darkBlue,
    },
    activeBorder: {
      borderTopColor: ColorConstants.darkBlue,
    },
    leftBar: {
      position: 'absolute',
      top: 40 / 2.22,
      left: 0,
      right: 62 + 8,
      borderStyle: 'solid',
      borderTopWidth: 3,
      borderTopColor: Theme.Color.white,
      marginRight: BreakpointConstants.isMD(width) ? 40 / 2 + 2 : 8,
    },
    rightBar: {
      position: 'absolute',
      top: 40 / 2.22,
      right: 0,
      left: 62 + 8,
      borderStyle: 'solid',
      borderTopWidth: 3,
      borderTopColor: Theme.Color.white,
      marginLeft: BreakpointConstants.isMD(width) ? 40 / 2 + 2 : 8,
    },
  })();

export default styles;

import {OktaAuth} from '@okta/okta-auth-js';
import {OKTA_CLIENT_ID, OKTA_ISSUER_URL} from '../config/getEnvironmentVars';

const authClient = new OktaAuth({
  issuer: OKTA_ISSUER_URL,
  clientId: OKTA_CLIENT_ID,
  redirectUri: '/',
  scopes: [
    'openid',
    'profile',
    'email',
    'oa_read_api',
    'oa_write_api',
    'offline_access',
  ],
  responseType: ['id_token', 'token', 'refresh_token'],
});

export default authClient;

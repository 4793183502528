import {Theme} from '@oneamerica/dxp-ui-components';
import BreakpointConstants from '../../constants/BreakpointConstants';
import ColorConstants from '../../constants/ColorConstants';

const styles = (width: number) =>
  Theme.Responsive.create({
    outlineNone: {
      outlineWidth: 0,
    },
    cardShadow: {
      borderRadius: BreakpointConstants.isMD(width) ? 5 : 0,
      shadowRadius: 6,
      backgroundColor: BreakpointConstants.isMD(width)
        ? '#EEF7FC'
        : Theme.Color.oaBlueLight,
      shadowColor: Theme.Color.backgroundShadow,
      shadowOffset: {width: 0, height: 3},
      paddingHorizontal: Theme.Metrics.medium,
      paddingVertical: Theme.Metrics.small,
    },
    menuItem: {
      position: 'absolute',
      top: '100%',
      right: '0%',
      width: BreakpointConstants.isMD(width) ? 'max-content' : '100%',
    },
    left0: {
      left: '0%',
    },
    horizontalDivider: {
      backgroundColor: ColorConstants.grey200,
      height: 1,
      width: '100%',
    },
    cursorPointer: {
      //@ts-ignore
      cursor: 'pointer',
    },
    mobileDropdownWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: Theme.Color.oaBlueLight,
      marginTop: -Theme.Metrics.small,
      paddingHorizontal: 20,
      paddingVertical: 12,
    },
    mobileText: {
      fontFamily: 'SoleilSb',
      color: Theme.Color.white,
      textTransform: 'uppercase',
    },
    row: {flexDirection: 'row', alignItems: 'center'},
    colorBlue: {
      color: Theme.Color.oaBlue,
      marginTop: BreakpointConstants.isMD(width)
        ? 'none'
        : Theme.Metrics.medium,
    },
    iconWrapper: {
      backgroundColor: Theme.Color.corn4,
      width: 40,
      height: 40,
      padding: 12,
      borderRadius: 24,
    },
  })();

export default styles;

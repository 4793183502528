import {Theme, Font} from '@oneamerica/dxp-ui-components';
import ColorConstants from '../../constants/ColorConstants';

export default () =>
  Theme.Responsive.create({
    cardShadow: {
      borderRadius: 5,
      shadowRadius: 6,
      backgroundColor: Theme.Color.background,
      shadowColor: Theme.Color.backgroundShadow,
      shadowOffset: {width: 0, height: 3},
      paddingHorizontal: Theme.Metrics.small,
      paddingVertical: Theme.Metrics.small,
    },
    paddingHorizontalXSmall: {
      paddingHorizontal: Theme.Metrics.xSmall,
    },
    cardHeader: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    investmentCardDate: {
      color: ColorConstants.iron,
      fontSize: 14,
      lineHeight: 32,
      fontFamily: Font.light.fontFamily,
      alignSelf: 'flex-start',
    },
    contentWrapper: {
      flex: 1,
      width: '100%',
      flexDirection: 'column',
      height: '100%',
      minHeight: 0,
    },
    verticalDivider: {
      backgroundColor: ColorConstants.grey200,
      width: 1,
      height: '100%',
    },
    horizontalDivider: {
      backgroundColor: ColorConstants.grey200,
      height: 1,
      width: '100%',
    },
    cardHeaderTitle: {
      color: ColorConstants.iron,
      lineHeight: 36,
      fontFamily: Font.medium.fontFamily,
    },
    cardActionButton: {
      color: ColorConstants.oaBlueTint,
      fontSize: 14,
      fontFamily: Font.medium.fontFamily,
      alignSelf: 'flex-end',
      textTransform: 'uppercase',
      fontWeight: '500',
    },
    contentHeader: {
      color: ColorConstants.iron,
      fontFamily: Font.medium.fontFamily,
    },
    row: {flex: 1, flexDirection: 'row'},
    flex: {flex: 1},
    button: {
      alignSelf: 'flex-start',
      borderRadius: 50,
      borderWidth: 1,
      paddingHorizontal: Theme.Metrics.medium,
      paddingVertical: Theme.Metrics.xxSmall,
      marginTop: -Theme.Metrics.xxSmall,
    },
    buttonText: {
      color: ColorConstants.iron,
    },
    statusButton: {
      fontSize: 14,
      fontFamily: Font.regular.fontFamily,
    },
    additionalDetailsBtn: {
      color: Theme.Color.link,
      fontSize: 14,
      fontFamily: Font.medium.fontFamily,
      textTransform: 'uppercase',
      fontWeight: '500',
    },

    insideContentWrapper: {
      marginTop: Theme.Metrics.xSmall,
      textAlign: 'center',
    },
    innerContent: {
      fontFamily: Font.medium.fontFamily,
    },
    linkStyle: {
      color: Theme.Color.oaBlue,
      fontSize: 14,
      fontFamily: Font.medium.fontFamily,
      alignSelf: 'flex-end',
    },
    headeRow: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      backgroundColor: '#FOFOFO',
      padding: 5,
      fontFamily: 'Roboto',
    },
    dataCell: {
      padding: 5,
      textAlign: 'center',
      fontSize: 15,
      fontFamily: 'Roboto',
    },
    container: {
      width: 554,
      height: 368,
      backgroundColor: '#FFFFFF',
      shadowColor: '#000',
      shadowOffset: {width: 0, height: 3},
      shadowOpacity: 0.15,
      shadowRadius: 6,
      marginTop: 100,
      marginLeft: 15,
      borderRadius: 1,
      marginBottom: 30,
    },
    headingContainer: {
      width: '100%',
      height: 50,
      borderRadius: 5,
      textAlign: 'left',
      marginBottom: 0,
      paddingVertical: 12,
      paddingLeft: 24,
      alignSelf: 'flex-start',
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderBottomWidth: 1,
      borderBottomColor: '#00000026',
    },
    detailContainer: {
      flexDirection: 'row',
      display: 'flex',
      flex: 1,
    },
    headingText: {
      fontWeight: '600',
      fontSize: 20,
      fontFamily: 'Roboto',
      color: '#023D69',
    },
    tableHeadingCellText: {
      fontFamily: Font.medium.fontFamily,
      fontWeight: '600',
      color: ColorConstants.iron,
    },
    titleContainer: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    Details: {
      height: 16,
      width: 16,
      borderRadius: 50,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: ColorConstants.darkBlue,
      color: '#002C77',
      fontWeight: 'bold',
      fontSize: 12,
      // marginTop: 8,
      marginRight: 4,
    },
    tableHeadingCell: {
      borderRightWidth: 1,
      borderRightColor: '#EAEAEA',
      paddingHorizontal: 5,
      textAlign: 'left',
      fontFamily: Font.medium.fontFamily,
      fontWeight: '600',
      paddingTop: 20,
      padding: 8,
      marginRight: 20,
      color: ColorConstants.iron,
    },
    tableHeadingCell1: {
      paddingHorizontal: 5,
      textAlign: 'left',
      fontFamily: Font.medium.fontFamily,
      fontWeight: '600',
      paddingTop: 20,
      padding: 8,
      color: ColorConstants.iron,
    },
    tableCell1: {
      borderRightWidth: 1,
      borderRightColor: '#EAEAEA',
      paddingHorizontal: 5,
      textAlign: 'left',
      fontFamily: Font.regular.fontFamily,
      padding: 8,
      color: Theme.Color.link,
    },
    tableCell2: {
      borderRightWidth: 1,
      borderRightColor: '#EAEAEA',
      paddingHorizontal: 5,
      textAlign: 'left',
      fontFamily: Font.regular.fontFamily,
      padding: 8,
      marginRight: 20,
      color: ColorConstants.iron,
    },
    tableCellText1: {
      fontFamily: Font.regular.fontFamily,
      color: ColorConstants.oaBlueTint,
    },
    tableCellText: {
      fontFamily: Font.regular.fontFamily,
      color: ColorConstants.iron,
    },
    primaryCardStyle: {
      flex: 1,
      backgroundColor: '#FFFFFF',
      borderRadius: 5,
      opacity: 1,
      fontFamily: Font.medium.fontFamily,
      marginHorizontal: -Theme.Metrics.xSmall,
      minHeight: 351,
    },
    policyTableContainer: {
      flexWrap: 'wrap',
      width: '100%',
    },
    placeholderText: {
      fontFamily: Font.regular.fontFamily,
      fontSize: 14,
      color: ColorConstants.iron,
    },
    textstartStyle: {
      fontSize: 14,
      fontWeight: 'bold',
      fontFamily: 'Roboto',
    },
    focusStyle: {
      textDecorationLine: 'none',
      borderColor: 'red',
      backgroundColor: 'red',
      color: 'red',
    },
    hoverStyle: {
      textDecorationLine: 'none',
      borderColor: 'red',
      backgroundColor: 'red',
      color: 'red',
    },
    tableRow: {
      backgroundColor: Theme.Color.table.row.background,
      flex: 1,
      flexDirection: 'row',
    },
    borderRow: {
      borderBottomWidth: 1,
      borderColor: Theme.Color.table.cell.borderColor,
    },
    evenRow: {backgroundColor: Theme.Color.table.row.background},
    oddRow: {
      backgroundColor: Theme.Color.table.row.oddBackground,
    },
    tableCell: {
      paddingVertical: Theme.Metrics.xxSmall,
      paddingHorizontal: Theme.Metrics.xSmall,
    },
    tableCellLeft: {
      flex: 1,
      borderColor: Theme.Color.table.cell.borderColor,
      borderRightWidth: 1,
    },
    tableCellRight: {
      flex: 2,
    },
    tableCellLeftText: {
      color: ColorConstants.iron,
      fontFamily: Font.regular.fontFamily,
    },
    tableCellRightText: {
      fontFamily: Font.medium.fontFamily,
      color: ColorConstants.iron,
    },
    additionalDetailLink: {
      color: Theme.Color.oaBlueLight,
      fontSize: 14,
      fontFamily: Font.medium.fontFamily,
      alignSelf: 'flex-start',
      textTransform: 'uppercase',
      marginBottom: Theme.Metrics.medium,
    },
    contactUsContentText: {
      fontSize: 14,
      color: ColorConstants.iron,
      fontFamily: Font.regular.fontFamily,
    },
    contactUsContentTextBold: {
      fontSize: 16,
      color: ColorConstants.iron,
      fontFamily: Font.medium.fontFamily,
    },
    suspendedContent: {
      fontSize: 16,
      fontFamily: Font.regular.fontFamily,
      color: ColorConstants.iron,
      paddingTop: Theme.Metrics.xSmall,
    },
    contactUs: {
      fontSize: 16,
      fontFamily: Font.medium.fontFamily,
      color: ColorConstants.oaBlue,
    },
    terminatedContent: {
      fontSize: 16,
      fontFamily: Font.regular.fontFamily,
      color: ColorConstants.iron,
    },
    cardActionButtonFocus: {
      textDecorationLine: 'underline',
      textDecorationColor: Theme.Color.linkFocus,
    },
    linkContactUs: {
      color: ColorConstants.oaBlueTint,
      fontSize: 16,
      fontFamily: Font.medium.fontFamily,
      //@ts-ignore
      display: 'inline-flex',
    },
    linkContactUsFocus: {
      textDecorationLine: 'underline',
      textDecorationColor: Theme.Color.linkFocus,
    },
    linkContactUsHover: {
      color: ColorConstants.oaBlueTint,
      fontSize: 16,
      fontFamily: Font.medium.fontFamily,
    },
    placeholderBold: {
      fontSize: 14,
      fontWeight: 'bold',
      fontFamily: Font.regular.fontFamily,
      color: ColorConstants.iron,
    },
    placeholderItalic: {
      fontSize: 14,
      fontFamily: Font.regular.fontFamily,
      fontStyle: 'italic',
      color: ColorConstants.iron,
    },
    placeholderParagraphText: {
      fontSize: 14,
      lineHeight: 20,
      marginVertical: 8,
      fontFamily: Font.regular.fontFamily,
      fontWeight: 'normal',
      color: ColorConstants.iron,
    },
    modalTitle: {
      fontFamily: Font.regular.fontFamily,
      fontSize: 20,
      fontWeight: '400',
      zIndex: -1,
      padding: 2,
    },
    disclaimerText: {
      fontSize: 15,
      color: ColorConstants.iron,
      fontFamily: Font.regular.fontFamily,
      lineHeight: 21,
    },
  })();

import {Theme} from '@oneamerica/dxp-ui-components';

export default () =>
  Theme.Responsive.create({
    cardShadow: {
      borderRadius: 5,
      shadowRadius: 6,
      backgroundColor: Theme.Color.background,
      shadowColor: Theme.Color.backgroundShadow,
      shadowOffset: {width: 0, height: 3},
      paddingHorizontal: Theme.Metrics.small,
      paddingVertical: Theme.Metrics.small,
      flex: 1,
    },
    cardHeader: {
      marginHorizontal: -Theme.Metrics.medium,
      marginTop: Theme.Metrics.small,
      marginBottom: Theme.Metrics.medium,
      paddingHorizontal: Theme.Metrics.medium,
      borderRadius: 4,
    },
  })();

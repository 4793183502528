import React, {useContext, useEffect, useState} from 'react';
import {View} from 'react-native';
import {Components, Theme} from '@oneamerica/dxp-ui-components';
import responsiveStyle from './style';
import BreakpointConstants from '../../../../constants/BreakpointConstants';
import {useDispatch, useSelector} from 'react-redux';
import data from '../../../../mock/aemStrategiesModalContent.json';
import {GET_STRATEGY_MODAL_DATA} from '../../../../redux/strategiesInfo/types';

const style = responsiveStyle();

const StrategiesInfoModal = ({isVisible, toggleModal}) => {
  const dispatch = useDispatch();
  const {width} = useContext(Theme.ResponsiveContext);
  const state = useSelector((state: any) => state.StrategiesModal);
  const [content, setContent] = useState<React.ReactNode[]>([]); // Explicitly type content as React.ReactNode[]
  const policyData = useSelector((state: any) => state.policies);
  const {getPoliciesListData = {}} = policyData || {};
  const affiliate = getPoliciesListData?.data?.policySummary.account.affiliate;
  let modalStyle;

  if (BreakpointConstants.isLG(width)) {
    modalStyle = style.lgModalSize;
  } else if (BreakpointConstants.isMD(width)) {
    modalStyle = style.mdModalSize;
  } else {
    modalStyle = style.smModalSize;
  }

  const parseAemContent = (jsonData) => {
    const contentItems: React.ReactNode[] = [];

    // Check if strategyDescription is present and is an object
    if (
      jsonData?.strategyDescription &&
      typeof jsonData.strategyDescription === 'object'
    ) {
      const strategyDescriptionContent =
        jsonData.strategyDescription.json || [];
      strategyDescriptionContent.forEach((paragraph, paragraphIndex) => {
        contentItems.push(
          <View
            style={style.placeholderWrapper}
            key={`strategyDescription_${paragraphIndex}`}>
            <Components.Text style={style.placeholderParagraphText}>
              {paragraph.content.map((textElement, textIndex) => {
                if (textElement.format?.variants.includes('bold')) {
                  return (
                    <Components.Text
                      key={`strategyDescription_${paragraphIndex}_${textIndex}`}
                      style={style.placeholderBold}>
                      {textElement.value}
                    </Components.Text>
                  );
                } else if (textElement.format?.variants.includes('italic')) {
                  return (
                    <Components.Text
                      key={`strategyDescription_${paragraphIndex}_${textIndex}`}
                      style={style.placeholderItalic}>
                      {textElement.value}
                    </Components.Text>
                  );
                } else {
                  return (
                    <Components.Text
                      key={`strategyDescription_${paragraphIndex}_${textIndex}`}>
                      {textElement.value}
                    </Components.Text>
                  );
                }
              })}
            </Components.Text>
          </View>,
        );
      });
    }
    // Check if strategyDisclaimerText is present and is an object
    let disclaimerText: any = null;
    if (
      affiliate === 'SL' &&
      jsonData?.indexedAnnuityCareDisclaimerText &&
      typeof jsonData.indexedAnnuityCareDisclaimerText === 'object'
    ) {
      disclaimerText = jsonData.indexedAnnuityCareDisclaimerText;
    } else if (
      affiliate === 'AUL' &&
      jsonData?.freedomBuilderDisclaimerText &&
      typeof jsonData.freedomBuilderDisclaimerText === 'object'
    ) {
      disclaimerText = jsonData.freedomBuilderDisclaimerText;
    } // Render disclaimer text content if available
    if (disclaimerText) {
      const strategyDisclaimerTextContent = disclaimerText.json || [];
      strategyDisclaimerTextContent.forEach((paragraph, paragraphIndex) => {
        contentItems.push(
          <View
            style={style.placeholderWrapper}
            key={`strategyDisclaimerText_${paragraphIndex}`}>
            <Components.Text style={style.disclaimerText}>
              {paragraph.content.map((textElement, textIndex) => {
                if (textElement.format?.variants.includes('italic')) {
                  return (
                    <Components.Text
                      key={`strategyDisclaimerText_${paragraphIndex}_${textIndex}`}
                      style={style.placeholderItalic}>
                      {textElement.value}
                    </Components.Text>
                  );
                } else {
                  return (
                    <Components.Text
                      key={`strategyDisclaimerText_${paragraphIndex}_${textIndex}`}
                      style={style.disclaimerText}>
                      {textElement.value}
                    </Components.Text>
                  );
                }
              })}
            </Components.Text>
          </View>,
        );
      });
    }
    setContent(contentItems);
  };

  useEffect(() => {
    dispatch({type: GET_STRATEGY_MODAL_DATA});
  }, [dispatch]);

  useEffect(() => {
    parseAemContent(
      state?.data
        ? state?.data?.data.strategyInformationModelByPath.item
        : data.data.strategyInformationModelByPath.item,
    );
  }, [state]);

  return (
    <Components.DialogModal
      style={modalStyle}
      backgroundColor="#FFFF"
      width={365}
      padding={20}
      Header={
        <Components.Text style={style.modalTitle}>
          Strategy Information
        </Components.Text>
      }
      visible={isVisible}
      onClose={toggleModal}>
      {content}
    </Components.DialogModal>
  );
};

export default StrategiesInfoModal;

import {Pressable, ScrollView, View} from 'react-native';
import {
  Components,
  Theme,
  LoadingIndicator,
  List,
  Button,
} from '@oneamerica/dxp-ui-components';
import responsiveStyle from './style';
import ScreenWrapper from '../../components/ScreenWrapper/ScreenWrapper';
import withAuth from '../../auth/withAuth';
import PolicyDropdown from '../../components/PolicyDropdown';
import Stepper from '../../components/Stepper';
import {DrawerNavigationProp} from '@react-navigation/drawer';
import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import React, {useContext, useRef, useState, useEffect} from 'react';
import BreakpointConstants from '../../constants/BreakpointConstants';
import {AlertLevel} from '../../constants/UtilsConstants';
import notes from '../../mock/notes.json';
import ReviewNewAllocations from './components/ReviewNewAllocations';
import EditElections from './components/EditElections';
import {NetworkManager} from '../../api/NetworkManager';
import {useDispatch, useSelector} from 'react-redux';
import {isHomeOfficeUser} from '../../utils';
interface RouteParams {
  account: any;
}
import ConfirmAllocations from './components/ConfirmAllocations';
import RouteConstants from '../../constants/RouteConstants';
import {
  getInvestmentOptions,
  setTransactionConfirmedTrue,
  setCancelClikedTrue,
  setCancelClikedFalse,
} from '../../redux/investmentoptions/action';
import {getPoliciesList} from '../../redux/policies/action';
import ErrorWrapper from '../../components/ErrorWrapper';
import {apiErrorMessages} from './constants';
import {
  trackCustomEvent,
  trackPage,
} from '../../utils/Analytics/AdobeAnalyticsUtils';
import {analyticsTags} from '../../utils/Analytics/AnalyticsTags';
const MyInvestmentsElections = (props) => {
  const dispatch = useDispatch();
  const {width} = useContext(Theme.ResponsiveContext);
  const style = responsiveStyle(width);
  const steps = ['Edit Elections', 'Review', 'Complete'];
  const navigation = useNavigation<DrawerNavigationProp<any>>();
  const [currentStep, setCurrentStep] = useState(1);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorInTable, setErrorInTable] = useState('');
  const [isTableDirty, setIsTableDirty] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [editedData, setEditedData] = useState([]);
  const [previousClicked, setPreviousClicked] = useState(false);
  const [cancelClicked, setCancelClicked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorResponseText, _setErrorResponseText] = useState('');
  const route = useRoute();
  const {account} = route?.params as RouteParams;
  const investmentData = useSelector((state: any) => state?.investments);
  let originalData =
    investmentData?.getInvestmentOptionData?.data?.items[0]?.investments;
  const [showInfo, setShowInfo] = useState(false);
  const {isError, loading} = useSelector((state: any) => state.investments);
  const [showLoader, setShowLoader] = useState(false);
  const [confirmationNumber, setConfirmationNumber] = useState('');
  const policyLists = useSelector((state: any) => state.policyLists);
  const isHomeOfcUser = isHomeOfficeUser();
  const [showProcessingTroubleModal, setShowProcessingTroubleModal] =
    useState(false);
  const [showPendingErrorModal, setShowPendingErrorModal] = useState(false);
  const [postApiError, setPostApiError] = useState(false);

  const isLargeScreen = BreakpointConstants.isLG(width);
  const isMediumScreen = BreakpointConstants.isMD(width);
  let screenType;
  if (isLargeScreen) {
    screenType = 'LG';
  } else if (isMediumScreen) {
    screenType = 'MD';
  } else {
    screenType = 'SM';
  }

  const linkStyleContactUs = {
    style: style.linkContactUs,
    linkStyle: style.linkContactUs,
    linkHoverStyle: style.linkContactUs,
    hoverStyle: style.linkContactUsHover,
    focusStyle: style.linkContactUsFocus,
  };
  let errorText = '';
  const scrollViewRef = useRef<ScrollView>(null);

  useFocusEffect(
    React.useCallback(() => {
      setTimeout(() => {
        scrollToTop();
      }, 300);
      dispatch(getInvestmentOptions(account, props?.accessToken?.accessToken));
      return () => {
        setTimeout(() => {
          if (investmentData.cancelClicked) {
            setCancelClick(false);
            setEditedData([]);
            originalData = [];
          }
        }, 200);
      };
    }, []),
  );

  useEffect(() => {
    trackPage(analyticsTags.createMyInvestmentElectionsPageViewTags);
    if (showPendingErrorModal) {
      setTimeout(() => {
        setShowPendingErrorModal(false);
      }, 10000);
    }
  }, [showPendingErrorModal]);

  const setCancelClick = (flag) => {
    if (flag) {
      dispatch(setCancelClikedTrue(true));
    } else {
      dispatch(setCancelClikedFalse(false));
    }
  };
  const scrollToTop = () => {
    scrollViewRef?.current?.scrollTo({y: 0, animated: true});
  };

  const setTransactionConfirmed = (flag) => {
    dispatch(setTransactionConfirmedTrue(flag));
  };
  const closeButton = () => {
    setErrorInTable('');
  };

  const getEditedData = (data) => {
    setEditedData(data);
  };
  const setIsDirty = (isDirty) => {
    setIsTableDirty(isDirty);
  };
  const setIsErrorInTable = (isError) => {
    setErrorInTable(isError);
    if (isError.length > 0) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
  };
  const changeState = () => {
    setCurrentStep(currentStep + 1);
    scrollToTop();
  };
  const formatError = (error) => {
    if (error?.data?.errorInfo) {
      return error?.data?.errorInfo[0]?.detail;
    } else if (error?.data?.error) {
      return error?.data?.error;
    } else {
      return 'System Error';
    }
  };

  const confirmInformation = () => {
    trackCustomEvent(analyticsTags.CaptureFutureInvestmentConfirmButtonOneTags);
    if (!showInfo) {
      setShowInfo(true);
    } else {
      trackCustomEvent(
        analyticsTags.CaptureFutureInvestmentConfirmButtonFinalTags,
      );
      let payloadObj: any = {};
      editedData.map((ele: any, index) => {
        if (Number(ele.newPercentage) !== ele.y) {
          originalData[index].percentageByContribution = ele.newPercentage;
          originalData[index].futureIndexScheduledAllocationPercent =
            ele.newPercentage;
        }
      });
      payloadObj.investmentOptionChanges = [...originalData];
      setShowLoader(true);
      NetworkManager.sendInvestmentOptionElection({
        token: investmentData?.token,
        policyNumber: account,
        payload: payloadObj,
      })
        .then((response) => {
          const {data} = response;
          dispatch(
            getPoliciesList(
              policyLists?.selectedPolicy?.policyPlanNumber,
              props?.accessToken?.accessToken,
            ),
          );
          setConfirmationNumber(data?.items[0]?.confirmationNumber);
          setShowLoader(false);
          setCurrentStep(currentStep + 1);
          scrollToTop();
          setShowInfo(false);
          setShowSuccess(true);
        })
        .catch((error) => {
          errorText = formatError(error);
          setShowLoader(false);
          setShowInfo(false);
          if (errorText.includes(apiErrorMessages.pendingErrorText)) {
            setCurrentStep(currentStep - 1);
            pendingTransactionModal();
            setShowPendingErrorModal(true);
            setPreviousClicked(true);
          } else if (
            errorText.includes(apiErrorMessages.systemUnavailableErrorText) ||
            errorText.includes(apiErrorMessages.timeOutErrorText)
          ) {
            scrollToTop();
            setPostApiError(true);
          } else {
            processingTroubleErrorModal();
            setShowProcessingTroubleModal(true);
          }
        });
    }
  };
  const cancelEditModal = () => (
    <Components.DialogModal
      style={style.smModalSize}
      backgroundColor="#F3F9FE"
      width={365}
      padding={20}
      visible={showModal}
      Header={
        <Components.Text style={style.modalTitle}>Leave page?</Components.Text>
      }
      Footer={
        <View className="flex flex-row mt-[13px]">
          <Button
            style={[style.buttonWidthModal]}
            accessibilityRole="button"
            id="btnOutline"
            outline
            theme="primary"
            onPress={() => {
              if (currentStep === 1) {
                trackCustomEvent(
                  analyticsTags.CaptureFutureInvestmentEditPageCancelEditModalTags,
                );
              } else {
                trackCustomEvent(
                  analyticsTags.CaptureFutureInvestmentReviewPageCancelEditModalTags,
                );
              }
              navigation.navigate(RouteConstants.myinvestments, {
                tab: 'Future-Elections',
              });
              setShowModal(false);
              setCurrentStep(currentStep - 1);
              setCancelClick(true);
            }}>
            Leave
          </Button>
          <Button
            style={[
              style.buttonWidthModal,
              isMediumScreen
                ? style.buttonKeepEditingMD
                : style.buttonKeepEditing,
            ]}
            accessibilityRole="button"
            id="btnOutline"
            theme="primary"
            onPress={() => {
              if (currentStep === 1) {
                trackCustomEvent(
                  analyticsTags.CaptureFutureInvestmentEditPageKeepEditingButtonTags,
                );
              } else {
                trackCustomEvent(
                  analyticsTags.CaptureFutureInvestmentKeepEditingReviewPageButtonTags,
                );
              }
              setShowModal(false);
            }}>
            Keep Editing
          </Button>
        </View>
      }
      onClose={() => setShowModal(false)}>
      <Components.Text className="grow-0 mt-[16px]">
        By leaving this page, you will lose any changes you have made.
      </Components.Text>
    </Components.DialogModal>
  );

  const navigateToContactUs = () => {
    navigation.navigate(RouteConstants.contactus);
  };

  const pendingTransactionModal = () => (
    <Components.DialogModal
      style={style.pendingModalSize}
      backgroundColor="#F3F9FE"
      width={365}
      padding={20}
      visible={showPendingErrorModal}
      Header={
        <Components.Text style={style.modalTitle}>
          Processing trouble
        </Components.Text>
      }
      onClose={() => setShowPendingErrorModal(false)}>
      <Components.Text className="grow-0 mt-[16px]">
        {'Apologies, we’re having some trouble confirming your request.\n\n'}
        {
          'If you’ve recently made a transaction you won’t be able to make new edits until those have been processed. If you have any questions, please reference the Policy Services section on the '
        }
        <Components.Link
          onPress={() => {
            trackCustomEvent(
              analyticsTags.CaptureContactUsInvestmentIOETroubleTags,
            );
            navigateToContactUs();
          }}
          accessibilityLabel="Contact Us"
          accessibilityRole="button"
          {...linkStyleContactUs}>
          Contact Us
        </Components.Link>{' '}
        {'page.'}
      </Components.Text>
    </Components.DialogModal>
  );

  const processingTroubleErrorModal = () => (
    <Components.DialogModal
      style={style.smModalSize}
      backgroundColor="#F3F9FE"
      width={365}
      padding={20}
      visible={showProcessingTroubleModal}
      Header={
        <Components.Text style={style.modalTitle}>
          Processing trouble
        </Components.Text>
      }
      onClose={() => setShowProcessingTroubleModal(false)}>
      <Components.Text className="grow-0 mt-[16px]">
        {'Apologies, we’re having some trouble processing your request.\n\n'}
        {
          'If you have any questions, please reference the Policy Services section on the '
        }
        <Components.Link
          onPress={() => {
            trackCustomEvent(
              analyticsTags.CaptureContactUsInvestmentIOETroubleTags,
            );
            navigateToContactUs();
          }}
          accessibilityLabel="Contact Us"
          accessibilityRole="button"
          {...linkStyleContactUs}>
          Contact Us
        </Components.Link>{' '}
        {'page.'}
      </Components.Text>
    </Components.DialogModal>
  );
  const onCancel = () => {
    if (currentStep === 1) {
      trackCustomEvent(
        analyticsTags.CaptureFutureInvestmentEditPageCancelButtonTags,
      );
    } else {
      trackCustomEvent(
        analyticsTags.CaptureFutureInvestmentReviewPageCancelButtonTags,
      );
    }
    if (isTableDirty) {
      setShowModal(true);
    } else {
      navigation.navigate(RouteConstants.myinvestments, {
        tab: 'Future-Elections',
      });
    }
  };

  const printConfirmTable = () => {
    //@ts-ignore
    const printWindow = window.open('', '_blank');
    //@ts-ignore
    const sucessBanner = window.document.getElementById('confirmsucessbanner');
    //@ts-ignore
    const printTable = window.document.getElementById('confirmpagetable');
    //@ts-ignore
    printWindow.document.write('<html>');
    printWindow.document.write(
      '<head><style>.r-alignItems-1awozwy .css-view-175oi2r{display:flex}\n.css-view-175oi2r.r-flex-13awgt0.r-flexDirection-18u37iz{display: flex; width:100%;text-align:left;margin-bottom:10px;}\n.css-view-175oi2r.r-flex-13awgt0.r-flexDirection-18u37iz div:nth-child(2){text-align: center !important;}\n.css-view-175oi2r.r-flex-13awgt0.r-flexDirection-18u37iz div {text-align: left !important;border-bottom: solid 1px;}\n.r-elevation-1quu1zo div {border: none !important;}\n.r-elevation-1quu1zo {border: solid 1px;margin: 20px 0;padding: 20px 5px 0 5px;}</style></head><body>',
    );
    printWindow.document.write(sucessBanner.innerHTML);
    printWindow.document.write(printTable.innerHTML);
    printWindow.document.write('</body></html>');
    printWindow.print();
  };
  // const navigateContactUs = () => {
  //   navigation.navigate(RouteConstants.contactus);
  // };
  return (
    <ScreenWrapper
      scrollFooter
      paddingTop
      paddingBottom
      paddingHorizontal
      ref={scrollViewRef}>
      <ErrorWrapper hasError={isError || postApiError} isLoading={loading}>
        <View style={style.cardHeader}>
          <PolicyDropdown
            className=""
            accessToken={props.accessToken}
            text={'My Investment Elections'}
          />
          <View className="w-full p-0 mt-1">
            <Stepper steps={steps} currentStep={currentStep} />
            <View style={[style.cardShadow, style.cardPadding]}>
              <LoadingIndicator loading={showLoader} />
              {currentStep === 3 && showSuccess && (
                <div id="confirmsucessbanner">
                  <Components.AlertMessage
                    title={
                      `YOUR CONFIRMATION NUMBER IS: ` +
                      confirmationNumber +
                      `\n`
                    }
                    className="my-5 mt-6 mb-6 flex-1"
                    alertLevel={AlertLevel.SUCCESS}
                    onCloseMessage={closeButton}
                    fontSize={BreakpointConstants.isLG(width) ? 16 : 15}
                    iconSize={BreakpointConstants.isLG(width) ? 52.36 : 24}>
                    <View style={style.row}>
                      <Components.Text style={[style.firstParagraph]}>
                        We've successfully received your request. Please record
                        your confirmation number or print this page for future
                        reference. If you have any questions, please reference
                        the Policy Services section on the
                        <Pressable
                          onPress={() => {
                            trackCustomEvent(
                              analyticsTags.CaptureContactUsInvestmentIOEPDFLinkTags,
                            );
                            navigateToContactUs();
                          }}
                          accessibilityRole="button"
                          accessibilityLabel="Contact Us">
                          <Components.Text style={[style.pdfLink]}>
                            Contact Us
                          </Components.Text>
                        </Pressable>
                        &nbsp;page.
                      </Components.Text>
                    </View>
                  </Components.AlertMessage>
                </div>
              )}
              {currentStep == 1 && (
                <EditElections
                  getEditedData={getEditedData}
                  previousData={editedData}
                  setIsErrorInTable={setIsErrorInTable}
                  setIsDirty={setIsDirty}
                  previousClicked={previousClicked}
                  cancelClicked={cancelClicked}
                />
              )}

              {currentStep === 2 && (
                <ReviewNewAllocations
                  previousData={getEditedData}
                  editedData={editedData}
                  setIsErrorInTable={setIsErrorInTable}
                  setIsDirty={setIsDirty}
                />
              )}
              {currentStep === 3 && (
                <ConfirmAllocations
                  previousData={getEditedData}
                  editedData={editedData}
                  setIsErrorInTable={setIsErrorInTable}
                  setIsDirty={setIsDirty}
                />
              )}
              {errorInTable.length > 0 && (
                <Components.AlertMessage
                  title={errorInTable}
                  className="my-5 flex-1"
                  alertLevel={AlertLevel.ERROR}
                  onCloseMessage={closeButton}
                  showCloseButton
                  fontSize={16}
                />
              )}
              {currentStep === 1 && (
                //Desktop Notes
                <View style={style.showLg}>
                  <Components.Text.H3
                    style={[style.notesTitle, style.topMargin]}>
                    Notes
                  </Components.Text.H3>
                  <List
                    textSize={14}
                    style={style.bulletListStyle}
                    flatList={notes.data.notesListIOE.item}
                  />
                </View>
              )}
              {currentStep === 2 && (
                <View style={style.showLg}>
                  <Components.Text.H3
                    style={[style.notesTitle, style.topMargin]}>
                    Notes
                  </Components.Text.H3>
                  <List
                    textSize={14}
                    style={style.bulletListStyle}
                    flatList={notes.data.noteslistReviewIOE.item}
                  />
                </View>
              )}
              {showInfo && (
                <Components.AlertMessage
                  className="mt-5 flex-1"
                  alertLevel={AlertLevel.INFORMATION}
                  testID="alertComponent"
                  fontSize={16}
                  title="Once confirmed, you won't be able to make edits until these changes are processed. Click confirm to continue."
                />
              )}
              {currentStep === 2 && isHomeOfcUser && (
                <View>
                  <Components.AlertMessage
                    className="my-4 flex-1"
                    style={style.marginTop16}
                    alertLevel={AlertLevel.INFORMATION}
                    testID="alertComponent"
                    fontSize={16}
                    showCloseButton={false}
                    title="Submitting investment updates on behalf of the user is not allowed due to home office access."
                  />
                </View>
              )}
              <View
                className="flex mt-[25px] mb-0"
                style={style.buttonFlexDirection}>
                {currentStep <= 2 && (
                  <Button
                    style={[
                      BreakpointConstants.isMD(width)
                        ? style.buttonCancelLeft
                        : style.buttonTop,
                      style.buttonWidth,
                    ]}
                    accessibilityRole="button"
                    selected={false}
                    id="btnOutline"
                    outline
                    theme="primary"
                    onPress={onCancel}>
                    Cancel
                  </Button>
                )}
                {currentStep === 1 && (
                  <Button
                    style={[
                      BreakpointConstants.isMD(width)
                        ? style.buttonAlignRight
                        : style.buttonTop,
                      style.buttonWidth,
                    ]}
                    accessibilityRole="button"
                    onPress={() => {
                      trackCustomEvent(
                        analyticsTags.CaptureFutureInvestmentNextButtonTags,
                      );
                      changeState();
                    }}
                    disabled={!isTableDirty || disableNext}
                    id="btnDisabled"
                    theme="primary">
                    Next
                  </Button>
                )}
                {currentStep === 2 && (
                  <>
                    <Button
                      style={[
                        BreakpointConstants.isMD(width)
                          ? style.buttonAlignRight
                          : style.buttonTop,
                        style.buttonWidth,
                      ]}
                      accessibilityRole="button"
                      onPress={() => {
                        trackCustomEvent(
                          analyticsTags.CaptureFutureInvestmentPreviousButtonTags,
                        );
                        setCurrentStep(currentStep - 1);
                        setPreviousClicked(true);
                        setShowInfo(false);
                      }}
                      disabled={false}
                      id="btnDisabled"
                      theme="tertiary">
                      Previous
                    </Button>
                    <Button
                      style={[
                        BreakpointConstants.isMD(width)
                          ? style.buttonSpaceLeft
                          : style.buttonTop,
                        style.buttonWidth,
                      ]}
                      accessibilityRole="button"
                      onPress={confirmInformation}
                      id="btnPrimary"
                      disabled={isHomeOfcUser}
                      selected={false}
                      theme="primary">
                      Confirm
                    </Button>
                  </>
                )}
                {currentStep === 3 && (
                  <>
                    <Button
                      style={[
                        BreakpointConstants.isMD(width)
                          ? style.buttonAlignRight
                          : style.buttonTop,
                        style.buttonWidth,
                      ]}
                      accessibilityRole="button"
                      disabled={false}
                      id="btnDisabled"
                      onPress={() => {
                        trackCustomEvent(
                          analyticsTags.CaptureFutureInvestmentPrintButtonTags,
                        );
                        printConfirmTable();
                      }}
                      theme="tertiary">
                      Print
                    </Button>
                    <Button
                      style={[
                        BreakpointConstants.isMD(width)
                          ? style.buttonSpaceLeft
                          : style.buttonTop,
                        style.buttonWidth,
                      ]}
                      accessibilityRole="button"
                      onPress={() => {
                        trackCustomEvent(
                          analyticsTags.CaptureFutureInvestmentDoneButtonTags,
                        );
                        setTransactionConfirmed(true),
                          setCurrentStep(1),
                          scrollToTop(),
                          navigation.navigate(RouteConstants.myinvestments, {
                            tab: 'Future-Elections',
                          });
                      }}
                      id="btnPrimary"
                      selected={false}
                      theme="primary">
                      Done
                    </Button>
                  </>
                )}
              </View>
            </View>
          </View>
        </View>
        {showModal && cancelEditModal()}
        {showProcessingTroubleModal && processingTroubleErrorModal()}
        {showPendingErrorModal && pendingTransactionModal()}
      </ErrorWrapper>
    </ScreenWrapper>
  );
};

export default withAuth(MyInvestmentsElections);

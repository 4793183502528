import {useEffect, useRef} from 'react';
import {AppState} from 'react-native';

const useSessionTimeout = (INTERVAL_IN_MINUTES = 0, authLogout: Function) => {
  const INTERVAL_IN_MILLISECONDS = INTERVAL_IN_MINUTES * 60 * 1000;
  const lastLoginRef = useRef<Date | null>(null);

  useEffect(() => {
    const appStateListener = AppState.addEventListener(
      'change',
      (nextAppState) => {
        if (nextAppState === 'background') {
          lastLoginRef.current = new Date(); //saving the time when app is going to background
        }
        if (nextAppState === 'inactive') {
          lastLoginRef.current = new Date(); //saving the time when app is going to inactive state
        }
        if (nextAppState === 'active') {
          if (lastLoginRef.current) {
            let diff = +new Date() - +lastLoginRef.current; //comparing current time and lastActiveLogin time(saved when app going to background)
            if (diff >= INTERVAL_IN_MILLISECONDS) {
              //difference is more than or equal to given time
              authLogout?.();
            }
          }
        }
      },
    );

    return () => {
      appStateListener.remove(); //Remove appStateListener when comp unmounting
      lastLoginRef.current = null;
    };
  }, []);
};

export default useSessionTimeout;

import React, {useContext} from 'react';
import {View} from 'react-native';
import {Components, Theme} from '@oneamerica/dxp-ui-components';
import BreakpointConstants from '../../../../constants/BreakpointConstants';
import responsiveStyle from './style';
import RouteConstants from '../../../../constants/RouteConstants';
import {useNavigation} from '@react-navigation/native';
import {trackCustomEvent} from '../../../../utils/Analytics/AdobeAnalyticsUtils';
import {analyticsTags} from '../../../../utils/Analytics/AnalyticsTags';
const style = responsiveStyle();

const PolicyRelationshipsInfoModal = ({
  isInfoModalVisible,
  setInfoModalVisiblity,
  infoModalContent,
}) => {
  const {width} = useContext(Theme.ResponsiveContext);
  const navigation = useNavigation<any>();
  let modalStyle;
  if (BreakpointConstants.isLG(width)) {
    modalStyle = style.lgModalSize;
  } else if (BreakpointConstants.isMD(width)) {
    modalStyle = style.mdModalSize;
  } else {
    modalStyle = style.smModalSize;
  }
  const navigateToPolicyServiceRequest = () => {
    navigation.navigate(RouteConstants.policyservicerequest);
  };

  return (
    <Components.DialogModal
      style={modalStyle}
      width={'auto'}
      padding={20}
      Header={
        <Components.Text style={style.modalTitle}>
          Policy Relationships Information
        </Components.Text>
      }
      visible={isInfoModalVisible}
      onClose={() => setInfoModalVisiblity(false)}
      Footer={
        <View className="flex flex-row items-center justify-start md:justify-end flex-wrap">
          <Components.Text
            className="mr-2 py-4 md:py-0 text-14 md:text-16"
            style={style.needToMakeChange}>
            Need to make a change?
          </Components.Text>
          <Components.Button
            style={[
              !BreakpointConstants.isLG(width) &&
                style.customeServiceRequestBtn,
              style.serviceRequestBtn,
            ]}
            accessibilityRole="button"
            id="btnOutline"
            theme="primary"
            onPress={() => {
              trackCustomEvent(analyticsTags.CapturePSRRelationshipTags);
              navigateToPolicyServiceRequest();
              setInfoModalVisiblity(false);
            }}>
            Policy Service Request
          </Components.Button>
        </View>
      }>
      <View style={style.placeholderWrapper}>
        {infoModalContent?.map((paragraph: any, index) => {
          let finalText = '';
          return (
            <View
              key={index}
              style={{flexDirection: 'row', flexWrap: 'wrap', padding: 2.5}}>
              {paragraph.content.map(
                (textElement: {value: string}, textIndex: number) => {
                  finalText += textElement.value;
                  if (textIndex > 0) {
                    const data = finalText.split('–');
                    return (
                      <Components.Text
                        style={style.placeholderText}
                        key={textIndex}>
                        <Components.Text
                          style={[{fontWeight: '600'}, style.placeholderText]}>
                          {data[0]}-
                        </Components.Text>
                        {data.slice(1).join(' ')}
                      </Components.Text>
                    );
                  }
                },
              )}
            </View>
          );
        })}
      </View>
    </Components.DialogModal>
  );
};

export default PolicyRelationshipsInfoModal;

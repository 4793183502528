import {useEffect} from 'react';
import authClient from './config';

const useAuthRefresh = (INTERVAL_IN_MINUTES = 0) => {
  const INTERVAL_IN_MILLISECONDS = INTERVAL_IN_MINUTES * 60 * 1000;

  const refreshSession = () => {
    authClient.session.refresh().catch((err) => console.warn(err));
  };

  const refreshToken = () => {
    authClient.token
      .renewTokens()
      .then((newToken) => {
        authClient.tokenManager.setTokens(newToken);
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      refreshSession();
      refreshToken();
    }, INTERVAL_IN_MILLISECONDS);
    return () => {
      clearInterval(interval);
    };
  }, []);
};

export default useAuthRefresh;

import React from 'react';
import {View} from 'react-native';
import {Components} from '@oneamerica/dxp-ui-components';
import responsiveStyle from '../../style';
import InfoWrapper from '../../../../components/InfoWrapper/InfoWrapper';
import {getPolicyTitleValue} from '../../helpers';
import {analyticsTags} from '../../../../utils/Analytics/AnalyticsTags';
import {trackCustomEvent} from '../../../../utils/Analytics/AdobeAnalyticsUtils';

const style = responsiveStyle();

export const BillingAndPremiumCard = ({
  className,
  initialPolicyData,
  generalPolicyData,
  linkStyle,
  moreBillingAndPremium,
  navigateToPolicyDetails,
  row,
}) => {
  return initialPolicyData.length > 0 ? (
    <View className={className}>
      <View style={[style.cardShadow, row > 1 && style.flex]}>
        <View style={style.cardHeader}>
          <Components.Text.H3 style={[style.cardHeaderTitle, style.flex]}>
            Billing & Premiums
          </Components.Text.H3>
        </View>
        <View className="mt-4" style={style.horizontalDivider} />
        <View style={style.contentWrapper}>
          <View className="py-2" style={style.row}>
            <View className="items-center" style={style.flex}>
              {initialPolicyData.map((item, index) => {
                return (
                  <View key={index}>
                    <InfoWrapper
                      title={
                        getPolicyTitleValue(generalPolicyData, item).title || ''
                      }
                      information={
                        getPolicyTitleValue(generalPolicyData, item).value || ''
                      }
                      boldInfo={index === 0 ? true : false}
                      subTitle={index > 0 ? true : false}
                      centerAlign
                    />
                    {index === 0 && (
                      <View className="my-2" style={style.horizontalDivider} />
                    )}
                  </View>
                );
              })}
            </View>
          </View>
        </View>
        {moreBillingAndPremium.length > 3 && (
          <Components.Link
            accessibilityLabel="View Billing and Premium Details"
            accessibilityRole="button"
            onPress={() => {
              trackCustomEvent(
                analyticsTags.CaptureBillingAndPremiumsCardViewDetailsTags,
              );
              navigateToPolicyDetails('BillingandPremium');
            }}
            {...linkStyle}>
            View Details
          </Components.Link>
        )}
      </View>
    </View>
  ) : null;
};

import moment from 'moment';
import React, {ReactElement} from 'react';
import {View} from 'react-native';
import ColorConstants from '../../constants/ColorConstants';
import {titleCase} from '../../utils';

export const displableBillingAndPremiumKeys = [
  'billingAmount',
  'billToDate',
  'billingMode',
  'totalPremiumPaidToDate',
  'billingMethod',
  'bankDraftDate',
  'bankDraftControlNumber',
  'paidToDate',
  'lastPremReceived',
  'billRiderAmt',
  'plannedPDRPremium',
];

export const termOrder = [
  'Primary Insured',
  'Joint Insured',
  'Annuitant',
  'Joint Annuitant',
  'Owner',
  'Payor',
  'Spouse',
  'Beneficiary',
  'First Beneficiary',
  'Second Beneficiary',
  'Insured Beneficiary',
  'Assignee',
  'Eligible Person',
  'Third Party',
  'Dependent',
  'Other Insured',
  'Guardian',
  'Conservator',
  'Employer',
];

export const getButtonColors = (buttonText) => {
  switch (buttonText) {
    case 'Active':
    case 'Reduced Paid-Up':
    case 'Paid-Up':
    case 'Income Payment':
      return {
        backgroundColor: ColorConstants.lightGreen,
        borderColor: ColorConstants.darkGreen,
      };
    case 'Disability':
    case 'Reinstatement pendin':
    case 'Reinstatement pending':
    case 'Extended Insurance':
    case 'Non-Forfeiture':
    case 'Premium Waiver':
      return {
        backgroundColor: ColorConstants.lightBlue,
        borderColor: ColorConstants.darkBlue,
      };
    case 'Back Billed':
      return {
        backgroundColor: ColorConstants.lightRed,
        borderColor: ColorConstants.darkRed,
      };
    case 'Terminated':
    case 'Suspended':
      return {
        backgroundColor: ColorConstants.lightGrey,
        borderColor: ColorConstants.darkGrey,
      };
    default:
      return {backgroundColor: 'white', borderColor: 'black'};
  }
};

export const getAffiliate = (affiliate: string | undefined) => {
  switch (affiliate) {
    case 'AUL':
      return 'American United Life';
    case 'SL':
      return 'State Life';
    case 'PML':
      return 'Pioneer Mutual Life';
    default:
      return '';
  }
};
export const getType = (
  type: string | undefined,
  affiliate: string | undefined,
) => {
  switch (type) {
    case 'WL':
      if (affiliate == 'SL') {
        return 'Asset Care Whole Life Insurance';
      } else {
        return 'Whole Life Insurance';
      }
    case 'TERM':
      return 'Term Life Insurance';
    case 'HEALTH':
      return 'Health';
    case 'RA':
      return 'Retirement Annuity';
    case 'ISL':
      return 'Interest Sensitive Life Insurance';
    case 'SPVA':
      return 'Variable Annuity';
    case 'FPVA':
      return 'Variable Annuity';
    case 'FPVUL':
      return 'Variable Universal Life Insurance';
    case 'SPVUL':
      return 'Variable Universal Life Insurance';
    case 'FDIA':
      return 'Flexible Deferred Income Annuity';
    case 'SPFA':
      if (affiliate == 'SL') {
        return 'Asset Care Annuity';
      } else {
        return 'Fixed Annuity';
      }
    case 'FPFA':
      return 'Fixed Annuity';
    case 'UL':
      return 'Universal Life Insurance';
    case 'INXAN':
      return 'Indexed Annuity';
    default:
      return '';
  }
};

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match[1] + '-' + match[2] + '-' + match[3];
  }
  return '';
};

export const getTerminatedReason = (terminatedReason: string) => {
  switch (terminatedReason) {
    case 'Surrender':
      return 'Surrendered';
    case 'Lapse':
      return 'Lapsed';
    case 'Maturity':
      return 'Matured';
    case 'Death':
      return 'Death Claim';
    case 'Free Look':
    case 'Not Taken':
      return 'Coverage Not Accepted';
    case 'Expired':
      return 'Expired';
    case 'Converted':
      return 'Converted';
    case 'Replaced':
      return 'Replaced';
    default:
      return '';
  }
};

export const validateElement = (
  condition: boolean | null | undefined | string,
  ele: ReactElement,
  empty: ReactElement = <View className="py-2 px-2 flex-1" />,
) => {
  if (condition) {
    return ele;
  } else {
    return empty;
  }
};

export const getPolicyTitleValue = (generalPolicyData, key) => {
  switch (key) {
    case 'billingAmount':
      return {
        title: 'Billing Amount',
        value: `$${generalPolicyData[key].toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
      };
    case 'billToDate':
      return {
        title: 'Next Payment Due',
        value: generalPolicyData[key]
          ? moment(generalPolicyData[key]).format('MMM DD, YYYY')
          : '',
      };
    case 'billingMode':
      return {
        title: 'Payable',
        value:
          generalPolicyData[key].toString() === 'APP Monthly'
            ? 'Monthly'
            : generalPolicyData[key].toString() === 'Semi-Annually'
            ? 'Semi-Annual'
            : generalPolicyData[key].toString(),
      };
    case 'billingMethod':
      const val = generalPolicyData[key].toString();
      if (val === 'Preauthorized Check' || val === 'Preauthorized check') {
        return {
          title: 'Payment Method',
          value: 'Automatic Draft',
        };
      } else if (val === 'Direct Pay') {
        return {
          title: 'Payment Method',
          value: 'Direct Bill',
        };
      } else {
        return {
          title: 'Payment Method',
          value: val,
        };
      }
    case 'bankDraftDate':
      return {
        title: 'Bank Draft Date',
        value: generalPolicyData[key]
          ? moment(generalPolicyData[key]).format('MMM DD, YYYY')
          : '',
      };
    case 'bankDraftControlNumber':
      return {
        title: 'Bank Draft Control #',
        value: generalPolicyData[key].toString(),
      };
    case 'paidToDate':
      return {
        title: 'Paid to Date',
        value: generalPolicyData[key]
          ? moment(generalPolicyData[key]).format('MMM DD, YYYY')
          : '',
      };
    case 'lastPremReceived':
      return {
        title: 'Last Premium Received',
        value: generalPolicyData[key]
          ? moment(generalPolicyData[key]).format('MMM DD, YYYY')
          : '',
      };
    case 'billRiderAmt':
      return {
        title: 'PUA Rider Premium',
        value: `$${generalPolicyData[key].toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
      };
    case 'plannedPDRPremium':
      return {
        title: 'Planned PDR Premium',
        value: `$${generalPolicyData[key].toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
      };
    case 'totalPremiumPaidToDate':
      return {
        title: 'Total Policy Contributions',
        value: `$${generalPolicyData[key].toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
      };
    default:
      return {
        title: '',
        value: '',
      };
  }
};
export const getPolicyNotes = (policyNotes: string | undefined) => {
  switch (policyNotes) {
    case 'Claim Pending':
      return 'Claim Pending - Online financial transactions are restricted for policy records with death claim pending status.';
    case 'ACCOUNTING INCOMPLETE':
      return 'Financial Update Pending - We are in the process of performing financial updates on this policy.';
    case 'Assignment':
      return 'Policy is assigned.';
    case ', ASSIGNMENT':
      return 'Policy is assigned.';
    case 'Restricted Policy':
      return 'Restricted Policy - Policy has some type of restriction, eg. Juvenile restriction, etc.';
    case 'Incomplete Financials':
      return 'Financial Update Pending - We are in the process of performing financial updates on this policy.';
  }
};

export const getRepresentative = (policyHighlights) => {
  try {
    const relationships: Array<any> = policyHighlights[0]?.policyRelationships;
    const csa = relationships.find(
      (obj, idx) => obj?.relationType === 'Current Servicing Agent',
    );
    if (csa !== undefined && csa !== null) {
      return csa;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const formatAddress = (policyHighlights) => {
  const address = getRepresentative(policyHighlights)?.addresses[0];
  if (address) {
    let formattedAddress = '';
    if (address?.line1) {
      formattedAddress += address?.line1;
    }
    if (address?.line2) {
      formattedAddress += `\n${address?.line2}`;
    }
    if (address?.city) {
      formattedAddress += `,\n${titleCase(address?.city)}`;
    }
    if (address?.state) {
      formattedAddress += `, ${address.state}`;
    }
    if (address?.zip) {
      formattedAddress += ` ${titleCase(address?.zip)}`;
    }
    if (getRepresentative(policyHighlights)?.phoneNumbers[0]?.number) {
      formattedAddress += `\n${formatPhoneNumber(
        getRepresentative(policyHighlights)?.phoneNumbers[0]?.number,
      )}`;
    }
    return formattedAddress;
  } else {
    return '';
  }
};

export const getTaxQualification = (policyHighlights) => {
  try {
    const {
      taxQualification,
      taxQualificationType,
      render: generalPolicyDataRender,
    } = policyHighlights[0]?.generalPolicyData;
    if (
      generalPolicyDataRender.taxQualification &&
      taxQualification === 'Qualified' &&
      generalPolicyDataRender.taxQualificationType
    ) {
      return `${taxQualification} | ${taxQualificationType}`;
    } else if (generalPolicyDataRender.taxQualification) {
      return `${taxQualification}`;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const makepolicyRelationShipData = (
  data: string,
  policyRelationshipsData: string | undefined[] | PolicyRelationship,
  setModalTitles: React.Dispatch<React.SetStateAction<string>>,
  setRelationType: React.Dispatch<
    React.SetStateAction<PolicyRelationshipClick | undefined>
  >,
): void => {
  const title: string =
    data === 'First Beneficiary'
      ? 'Primary Beneficiary'
      : data === 'Second Beneficiary'
      ? 'Contingent Beneficiary'
      : data;

  if (policyRelationshipsData) {
    setModalTitles(title + ' Personal Details');
    const policyRelationshipResponse: any = (
      policyRelationshipsData as PolicyRelationship
    ).find((rel: PolicyRelationship | undefined) => {
      const relationType = rel && rel.relationType;

      return relationType === data;
    });

    const data2 = [
      [
        'Name',
        `${
          policyRelationshipResponse?.personDetails?.name?.first === null
            ? '-'
            : policyRelationshipResponse?.personDetails?.name?.first
        }`,
      ],
      [
        'Issue Age',
        `${
          policyRelationshipResponse?.personDetails?.age === null
            ? '-'
            : policyRelationshipResponse?.personDetails?.age
        }`,
      ],
      [
        'Date of Birth',
        `${
          policyRelationshipResponse?.personDetails?.dob === null
            ? '-'
            : moment(policyRelationshipResponse?.personDetails?.dob).format(
                'MMM DD, YYYY',
              )
        }`,
      ],
      [
        'Gender',
        `${
          policyRelationshipResponse?.personDetails?.sex === null
            ? '-'
            : policyRelationshipResponse?.personDetails?.sex
        }`,
      ],
      [
        'SSN',
        `${
          policyRelationshipResponse?.id === null
            ? '-'
            : maskId(policyRelationshipResponse?.id)
        }`,
      ],
      [
        'Address',
        `${
          policyRelationshipResponse?.addresses === null ||
          policyRelationshipResponse?.addresses?.[0]?.line1 === null
            ? '-'
            : policyRelationshipResponse?.addresses?.[0]?.line1
        }`,
      ],
      [
        'City',
        `${
          policyRelationshipResponse?.addresses === null ||
          policyRelationshipResponse?.addresses?.[0]?.city === null
            ? '-'
            : policyRelationshipResponse?.addresses?.[0]?.city
        }`,
      ],
      [
        'State',
        `${
          policyRelationshipResponse?.addresses === null ||
          policyRelationshipResponse?.addresses?.[0]?.state === null
            ? '-'
            : policyRelationshipResponse?.addresses?.[0]?.state
        } `,
      ],
      [
        'Zip',
        `${
          policyRelationshipResponse?.addresses === null ||
          policyRelationshipResponse?.addresses?.[0]?.zip === null
            ? '-'
            : policyRelationshipResponse?.addresses?.[0]?.zip
        }`,
      ],
    ];
    setRelationType(data2);
  }
};

export const maskId = (idNumber) => {
  const idStr = String(idNumber);

  if (idStr.length < 4) {
    return 'ID too short!';
  }

  return `***-**-${idStr.slice(-4)}`;
};

export const getLoanItem = (policyValue) => {
  return policyValue?.policyValues?.filter(
    (value) => value.type === 'Loan Balance',
  )[0];
};

export const truncatedText = (
  text: string,
  maxLength: number = Number.MAX_SAFE_INTEGER,
): string => {
  const shouldTruncate: boolean = text?.length > maxLength;
  return shouldTruncate ? `${text.slice(0, maxLength)} ...` : text;
};

export const truncatedTextWithLineBreaks = (
  text: string,
  lineLength: number,
): string => {
  const shouldTruncate: boolean = text?.length > lineLength;

  if (shouldTruncate) {
    let truncatedText = text;

    const regex = new RegExp(`.{1,${lineLength}}`, 'g');
    truncatedText = truncatedText.replace(regex, (match, offset) =>
      offset === 0 ? match : `\n${match}`,
    );

    return truncatedText;
  } else {
    return text;
  }
};

export const setDOBMonth = (data: any) => {
  data.forEach((item: any, index: number) => {
    if (index > 0) {
      const dobString = item.personDetails?.dob;
      if (dobString != null) {
        const dobDate: Date = new Date(dobString);
        const monthNames: string[] = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];

        const month: string = monthNames[dobDate.getUTCMonth()];
        const day: number = dobDate.getUTCDate();
        const year: number = dobDate.getUTCFullYear();

        const formattedDay: string = day < 10 ? `0${day}` : `${day}`;

        const formattedDOB: string = `${month} ${formattedDay}, ${year}`;
        item.personDetails.dob = formattedDOB;
      }
    }
  });
  return data;
};

export const getLimitWidthArray = (data: any) => {
  const truncateCharLimit = 30;

  const relationshipTypes: string[] = [];
  const names: string[] = [];
  const issueAges: (string | number)[] = [];
  const dobs: string[] = [];
  const genders: string[] = [];
  const ssns: (string | null)[] = [];
  const addresses: string[] = [];
  const cities: string[] = [];
  const states: string[] = [];
  const zips: string[] = [];
  data.forEach((item: any) => {
    relationshipTypes.push(
      truncatedTextWithLineBreaks(
        item.relationType === 'First Beneficiary'
          ? 'Primary Beneficiary'
          : item.relationType === 'Second Beneficiary'
          ? 'Contingent Beneficiary'
          : item.relationType,
        truncateCharLimit,
      ) || '',
    );

    names.push(
      truncatedTextWithLineBreaks(
        item.personDetails?.name?.first,
        truncateCharLimit,
      ) || '',
    );

    issueAges.push(
      truncatedTextWithLineBreaks(
        item.personDetails?.age === 0 || item.personDetails?.age === null
          ? '0'
          : item.personDetails?.age,
        truncateCharLimit,
      ) || '',
    );

    dobs.push(
      truncatedTextWithLineBreaks(
        item.personDetails?.dob === 0 || item.personDetails?.dob === null
          ? '-'
          : item.personDetails?.dob,
        truncateCharLimit,
      ) || '',
    );

    genders.push(
      truncatedTextWithLineBreaks(
        item.personDetails?.sex === null ? '-' : item.personDetails?.sex,
        truncateCharLimit,
      ) || '',
    );

    ssns.push(
      truncatedTextWithLineBreaks(
        item.id === null ? '-' : maskId(item?.id),
        truncateCharLimit,
      ) || null,
    );

    addresses.push(
      truncatedTextWithLineBreaks(
        item?.addresses === null ? '-' : item?.addresses[0]?.line1,
        truncateCharLimit,
      ) || '',
    );

    cities.push(
      truncatedTextWithLineBreaks(
        item?.addresses === null ? '-' : item?.addresses[0]?.city,
        truncateCharLimit,
      ) || '',
    );

    states.push(
      truncatedTextWithLineBreaks(
        item?.addresses === null ? '-' : item?.addresses[0]?.state,
        truncateCharLimit,
      ) || '',
    );

    zips.push(
      truncatedTextWithLineBreaks(
        item?.addresses === null ? '-' : item?.addresses[0]?.zip,
        truncateCharLimit,
      ) || '',
    );
  });

  const newData = [
    relationshipTypes,
    names,
    issueAges,
    dobs,
    genders,
    ssns,
    addresses,
    cities,
    states,
    zips,
  ];

  const widthArr: number[] = [];
  newData.forEach((columnData) => {
    const maxLength = Math.max(
      ...columnData.map((value) => String(value).length),
    );
    maxLength > truncateCharLimit
      ? widthArr.push(truncateCharLimit * 6.5 + 33)
      : widthArr.push(maxLength * 9.2 + 40);
  });
  return widthArr;
};
export const getEleSpacing = (row) => {
  const marginSpacing = 4; //tailwind margin spacing, 4 -> 16 px

  return row > 1 ? `px-${marginSpacing} mt-6 lg:mt-8` : `px-${marginSpacing}`;
};
export const aulOrPmWLPolicies = (addCoverages = [], policyValue) => {
  const excludedTypes = [
    'Guaranteed Cash Value',
    'Cash Surrender Value',
    'Net Cash Value',
  ];
  return {
    totalDeathBenefit:
      addCoverages &&
      addCoverages.length > 0 &&
      getCoverageBenefit('Total Death Benefit', addCoverages),
    guaranteedCashValue: getPolicyValue('Guaranteed Cash Value', policyValue),
    totalCashSurrenderValue: getPolicyValue(
      'Cash Surrender Value',
      policyValue,
    ),
    netCashValue: getPolicyValue('Net Cash Value', policyValue),
    otherFields: getOtherFields(policyValue, excludedTypes),
  };
};
export const slWLPolicies = (baseCoverages: any = [], policyValue) => {
  const excludedTypes = ['Current Account Value', 'Cash Surrender Value'];
  return {
    monthlyLTCBenefit:
      baseCoverages &&
      baseCoverages.length > 0 &&
      baseCoverages[0].monthlyLTCBenefit,
    deathBenefit:
      baseCoverages &&
      baseCoverages.length > 0 &&
      baseCoverages[0].deathBenefit,
    currentAccountValue: getPolicyValue('Current Account Value', policyValue),
    totalCashSurrenderValue: getPolicyValue(
      'Cash Surrender Value',
      policyValue,
    ),
    otherFields: getOtherFields(policyValue, excludedTypes),
  };
};

export const annuityPolicies = (ltcSection: any = {}, policyValue) => {
  const excludedTypes = ['Account Value', 'Cash Surrender Value'];
  return {
    ltcBenefitBalance: ltcSection && ltcSection.ltcBenefitBalance,
    ltcMonthlyMaximum: ltcSection && ltcSection.ltcMonthlyMaximum,
    accountValue: getPolicyValue('Account Value', policyValue),
    totalCashSurrenderValue: getPolicyValue(
      'Cash Surrender Value',
      policyValue,
    ),
    otherFields: getOtherFields(policyValue, excludedTypes),
  };
};
export const otherPolicies = (policyValue) => {
  const excludedTypes = ['Account Value', 'Net Cash Value'];
  return {
    accountValue: getPolicyValue('Account Value', policyValue),
    netCashValue: getPolicyValue('Net Cash Value', policyValue),
    otherFields: getOtherFields(policyValue, excludedTypes),
  };
};
function getCoverageBenefit(coverageType, addCoverages) {
  const coverage = addCoverages.find((c) => c.name === coverageType);
  return coverage ? coverage.coverageBenefit : null;
}
function getPolicyValue(valueType, policyValue) {
  const policyValueData = policyValue.policyValues.find(
    (v) => v.type === valueType,
  );
  return policyValueData ? policyValueData.amount : null;
}
const getOtherFields = (policyValue, excludedTypes) => {
  const otherFields = {};
  policyValue.policyValues.forEach((value) => {
    if (!excludedTypes.includes(value.type)) {
      otherFields[value.type] = value.amount;
    }
  });
  return otherFields;
};
export const flattenObject = (obj, parentKey = '') => {
  let result = {};

  for (const key in obj) {
    const newKey = parentKey ? `${parentKey}.${key}` : key;
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      result = {...result, ...flattenObject(obj[key], newKey)};
    } else {
      result[newKey] = obj[key];
    }
  }
  return result;
};
export const removeFalsyFromObject = (object) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, value]) => value !== null),
  );
};

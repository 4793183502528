export default {
  grey200: '#D6D6D6',
  iron: '#333333',
  lightGreen: '#DBE4D4',
  darkGreen: '#4A7729',
  sun90: 'rgba(244, 181, 49, 0.9)',
  sun30: 'rgba(244, 181, 49, 0.3)',
  modalBG: '#474747CC',
  grey: '#FAFAFA',
  darkRed: '#E40032',
  lightRed: '#FACCD6',
  darkBlue: '#8BBFF3',
  lightBlue: '#D1E5FA',
  lightGrey: '#EBEBEB',
  midGrey: '#F3F3F3',
  darkGrey: '#5C5C5C',
  oaBlueTint: '#2B4F8E',
  oaBlue: '#002c77',
  drawerBG: '#023D69',
  lightIceBlue: '#E6F3FB',
  lightGrayishBlue: '#F3F9FE',
  iceBlue: '#3F73A6',
  lime: '#8BC400',
  ironTint: '#D6D6D6',
  black: '#000000',
};

import RobotoBold from './assets/fonts/Roboto-Bold.ttf';
import RobotoBoldItalic from './assets/fonts/Roboto-BoldItalic.ttf';
import RobotoItalic from './assets/fonts/Roboto-Italic.ttf';
import RobotoLight from './assets/fonts/Roboto-Light.ttf';
import RobotoLightItalic from './assets/fonts/Roboto-LightItalic.ttf';
import RobotoMedium from './assets/fonts/Roboto-Medium.ttf';
import RobotoMediumItalic from './assets/fonts/Roboto-MediumItalic.ttf';
import Roboto from './assets/fonts/Roboto-Regular.ttf';

import SoleilBold from './assets/fonts/Soleil-Bold.otf';
import SoleilBoldItalic from './assets/fonts/Soleil-BoldItalic.otf';
import SoleilItalic from './assets/fonts/Soleil-Italic.otf';
import Soleil from './assets/fonts/Soleil.otf';
import SoleilBookItalic from './assets/fonts/SoleilBk-Italic.otf';
import SoleilBook from './assets/fonts/SoleilBk.otf';
import SoleilLightItalic from './assets/fonts/SoleilLt-Italic.otf';
import SoleilLight from './assets/fonts/SoleilLt.otf';
import SoleilSemiboldItalic from './assets/fonts/SoleilSb-Italic.otf';
import SoleilSemibold from './assets/fonts/SoleilSb.otf';

const AppFonts = `@font-face {
  font-family: 'Roboto-Regular';
  src: url(${Roboto});
}
@font-face {
  font-family: 'Roboto-Italic';
  src: url(${RobotoItalic});
}
@font-face {
  font-family: 'Roboto-Light';
  src: url('${RobotoLight}');
}
@font-face {
  font-family: 'Roboto-LightItalic';
  src: url('${RobotoLightItalic}');
}
@font-face {
  font-family: 'Roboto-Bold';
  src: url('${RobotoBold}');
}
@font-face {
  font-family: 'Roboto-BoldItalic';
  src: url('${RobotoBoldItalic}');
}
@font-face {
  font-family: 'Roboto-Medium';
  src: url('${RobotoMedium}');
}
@font-face {
  font-family: 'Roboto-MediumItalic';
  src: url('${RobotoMediumItalic}');
}
@font-face {
  font-family: 'Soleil';
  src: url(${Soleil});
}
@font-face {
  font-family: 'Soleil-Italic';
  src: url(${SoleilItalic});
}
@font-face {
  font-family: 'SoleilLt';
  src: url(${SoleilLight});
}
@font-face {
  font-family: 'SoleilLt-Italic';
  src: url(${SoleilLightItalic});
}
@font-face {
  font-family: 'SoleilBk';
  src: url(${SoleilBook});
}
@font-face {
  font-family: 'SoleilBk-Italic';
  src: url(${SoleilBookItalic});
}
@font-face {
  font-family: 'SoleilSb';
  src: url('${SoleilSemibold}');
}
@font-face {
  font-family: 'SoleilSb-Italic';
  src: url('${SoleilSemiboldItalic}');
}
@font-face {
  font-family: 'Soleil-Bold';
  src: url('${SoleilBold}');
}
@font-face {
  font-family: 'Soleil-BoldItalic';
  src: url('${SoleilBoldItalic}');
}
`;

const appendFontStyle = (fontStyle) => {
  const baseStyle = document.createElement('style');

  if (baseStyle.styleSheet) {
    baseStyle.styleSheet.cssText = fontStyle;
  } else {
    baseStyle.appendChild(document.createTextNode(fontStyle));
  }

  document.head.appendChild(baseStyle);
};

appendFontStyle(AppFonts);

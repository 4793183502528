import BreakpointConstants from '../../constants/BreakpointConstants';

export const getPieChartSize = (width) => {
  if (BreakpointConstants.isLG(width)) {
    return {height: 364.1, width: 363.81};
  } else if (BreakpointConstants.isMD(width)) {
    return {height: 233.81, width: 233.99};
  }
  return {height: 191, width: 191};
};

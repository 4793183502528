import React from 'react';
import {ScrollView, TouchableHighlight, View} from 'react-native';
import {Components} from '@oneamerica/dxp-ui-components';
import responsiveStyle from '../PremiumsReceived/style';
const style = responsiveStyle();
import {Table, TableWrapper, Cell} from 'react-native-reanimated-table';
import PolicyRelationshipsInfoModal from '../../../Home/components/PolicyRelationshipsInfoModal';
import {usePolicyDetailsSelector} from '../../hooks/policyDetails';
import {
  getLimitWidthArray,
  setDOBMonth,
  truncatedTextWithLineBreaks,
} from '../../../Home/helpers';
import {trackCustomEvent} from '../../../../utils/Analytics/AdobeAnalyticsUtils';
import {analyticsTags} from '../../../../utils/Analytics/AnalyticsTags';

const PolicyRelationships = ({...props}) => {
  const {
    policyRelationshipData,
    infoModalContent,
    isInfoModalVisible,
    setInfoModalVisiblity,
    getModalDetails,
    maskId,
  } = usePolicyDetailsSelector(props);

  const newData: any[] = setDOBMonth(policyRelationshipData);

  const truncateCharLimit: number = 30;

  const widthArr: number[] = getLimitWidthArray(newData);

  const getFormattedDOB = (index, item) => {
    return truncatedTextWithLineBreaks(
      index === 0
        ? item.personDetails?.dob
        : item.personDetails?.dob === 0 || item.personDetails?.dob === null
        ? '-'
        : item.personDetails?.dob,
      truncateCharLimit,
    );
  };
  const getFormattedSex = (index, item) => {
    return truncatedTextWithLineBreaks(
      index === 0
        ? item.personDetails?.sex
        : item.personDetails?.sex === null
        ? '-'
        : item.personDetails?.sex,
      truncateCharLimit,
    );
  };
  const getFormattedIdType = (index, item) => {
    return truncatedTextWithLineBreaks(
      index === 0 ? item?.idType : item.id === null ? '-' : maskId(item?.id),
      truncateCharLimit,
    );
  };
  const getAddress = (item) => {
    return item.addresses === null || item?.addresses?.[0]?.line1 === null
      ? '-'
      : item?.addresses?.[0]?.line1;
  };
  const getCity = (item) => {
    return truncatedTextWithLineBreaks(
      item.addresses === null || item?.addresses?.[0]?.city === null
        ? '-'
        : item?.addresses?.[0]?.city,
      truncateCharLimit,
    );
  };
  const getState = (item) => {
    return truncatedTextWithLineBreaks(
      item.addresses === null || item?.addresses?.[0]?.state === null
        ? '-'
        : item?.addresses?.[0]?.state,
      truncateCharLimit,
    );
  };
  const getZipcode = (item) => {
    return truncatedTextWithLineBreaks(
      item.addresses === null || item?.addresses?.[0]?.zip === null
        ? '-'
        : item?.addresses?.[0]?.zip,
      truncateCharLimit,
    );
  };

  const createTableCell = (index, data, width) => {
    return (
      <Cell
        style={[
          index === 0 ? style.tableHeadingCell1 : style.tableCell1,
          {width: width},
        ]}
        data={data}
        textStyle={
          index === 0 ? style.tableHeadingCellText : style.tableCellText1
        }
      />
    );
  };

  return (
    <View className="flex-col lg:flex-row pt-6">
      <View className="w-full p-0">
        <View style={style.cardShadow}>
          <View style={style.cardHeader}>
            <Components.Text.H3 style={[style.cardHeaderTitle, style.flex]}>
              Policy Relationships
            </Components.Text.H3>
            <TouchableHighlight
              underlayColor="#fff"
              accessible={true}
              accessibilityLabel="Policy Relationships Information icon link"
              style={style.Details}
              onPress={() => {
                trackCustomEvent(
                  analyticsTags.CapturePolicyRelationshipsInfoModalTags,
                );
                getModalDetails();
              }}>
              <View>
                <React.Fragment> i </React.Fragment>
              </View>
            </TouchableHighlight>
          </View>
          <View className="mt-4" style={style.horizontalDivider} />
          <ScrollView style={style.contentWrapper1} horizontal={true}>
            <View className="mt-4">
              <Table>
                {newData &&
                  newData?.map((item: any, index: number) => (
                    <TableWrapper
                      key={`row-${index}`}
                      style={{
                        ...style.row,
                        flexDirection: 'row',
                        backgroundColor:
                          index % 2 === 0 ? '#FFFFFF' : '#F3F3F3',
                      }}>
                      <Cell
                        style={[
                          index === 0
                            ? style.tableHeadingCell
                            : style.tableCell2,
                          {width: widthArr[0]},
                        ]}
                        data={truncatedTextWithLineBreaks(
                          item.relationType === 'First Beneficiary'
                            ? 'Primary Beneficiary'
                            : item.relationType === 'Second Beneficiary'
                            ? 'Contingent Beneficiary'
                            : item.relationType,
                          truncateCharLimit,
                        )}
                        textStyle={
                          index === 0
                            ? style.tableHeadingCellText
                            : style.tableCellText
                        }
                      />
                      {createTableCell(
                        index,
                        item.personDetails?.name?.first,
                        widthArr[1],
                      )}
                      {createTableCell(
                        index,
                        getFormattedDOB(index, item),
                        widthArr[3],
                      )}
                      {createTableCell(
                        index,
                        getFormattedSex(index, item),
                        widthArr[4],
                      )}
                      {createTableCell(
                        index,
                        getFormattedIdType(index, item),
                        widthArr[5],
                      )}
                      {createTableCell(index, getAddress(item), widthArr[6])}
                      {createTableCell(index, getCity(item), widthArr[7])}
                      {createTableCell(index, getState(item), widthArr[8])}
                      {createTableCell(index, getZipcode(item), widthArr[9])}
                    </TableWrapper>
                  ))}
              </Table>
            </View>
          </ScrollView>
        </View>
      </View>

      <PolicyRelationshipsInfoModal
        isInfoModalVisible={isInfoModalVisible}
        setInfoModalVisiblity={setInfoModalVisiblity}
        infoModalContent={infoModalContent}
      />
    </View>
  );
};

export default PolicyRelationships;

import RouteConstants from '../constants/RouteConstants';

const getLinks = () => {
  const linking = {};
  for (const [_key, value] of Object.entries(RouteConstants)) {
    linking[value] = value;
  }
  return linking;
};
export default getLinks;

import React from 'react';
import {View, ViewStyle} from 'react-native';
import {Components, Theme} from '@oneamerica/dxp-ui-components';
import responsiveStyle from '../../style';
import {DrawerNavigationProp} from '@react-navigation/drawer';
import {useNavigation} from '@react-navigation/native';
// import {useSelector} from 'react-redux';
import RouteConstants from '../../../../constants/RouteConstants';
import {usePolicyDetailsSelector} from '../../../PolicyDetails/hooks/policyDetails';
import {trackCustomEvent} from '../../../../utils/Analytics/AdobeAnalyticsUtils';
import {analyticsTags} from '../../../../utils/Analytics/AnalyticsTags';
// import IncomePaymentInformation from '../../../PolicyDetails/components/IncomePaymentInformation';

const style = responsiveStyle();

export const AdditionalDetailCard = ({row, ...props}) => {
  const navigation = useNavigation<DrawerNavigationProp<any>>();
  const {
    viewIndexedDividendData,
    coverageInformationData,
    premiumData,
    getPremiumStatus,
    incomeInformation,
    incomePaymentInformationData,
    ltcSectionData,
    freeWithdrawalData,
    annuityRatesData,
  } = usePolicyDetailsSelector(props);
  const detailLinks = [
    'INDEXED DIVIDEND INFORMATION',
    'COVERAGE INFORMATION',
    'INCOME PAYMENT INFORMATION',
    'INCOME INFORMATION',
    'PREMIUMS RECEIVED',
    'LTC VALUES',
    'CASH WITHDRAWAL VALUES',
    'ANNUITY RATES',
  ];
  const trackCustomEventHandler = (detailLinks) => {
    if (detailLinks === 'INDEXED DIVIDEND INFORMATION') {
      trackCustomEvent(analyticsTags.CaptureViewIndexedDividendTags);
    } else if (detailLinks === 'COVERAGE INFORMATION') {
      trackCustomEvent(analyticsTags.CaptureCoverageInformationTags);
    } else if (detailLinks === 'INCOME PAYMENT INFORMATION') {
      trackCustomEvent(analyticsTags.CaptureIncomePaymentInformationTags);
    } else if (detailLinks === 'INCOME INFORMATION') {
      trackCustomEvent(analyticsTags.CaptureIncomeInformationTags);
    } else if (detailLinks === 'PREMIUMS RECEIVED') {
      trackCustomEvent(analyticsTags.CapturePremiumReceivedValueTags);
    } else if (detailLinks === 'LTC VALUES') {
      trackCustomEvent(analyticsTags.CaptureLTCValueTags);
    } else if (detailLinks === 'CASH WITHDRAWAL VALUES') {
      trackCustomEvent(analyticsTags.CaptureCashWithdrawalValuesTags);
    } else if (detailLinks === 'ANNUITY RATES') {
      trackCustomEvent(analyticsTags.CaptureAnnuityRatesTags);
      navigateToPolicyDetails('AnnuityRates');
    }
  };

  const linkStyles = {
    linkHoverStyle: [
      style.additionalDetailLink,
      {marginBottom: 0},
    ] as ViewStyle,
    hoverStyle: style.additionalDetailLink,
    linkStyle: style.additionalDetailLink,
    focusStyle: [
      style.cardActionButtonFocus,
      {marginBottom: Theme.Metrics.medium},
    ] as ViewStyle,
  };

  const navigateToPolicyDetails = (cardName: string) => {
    navigation.navigate(RouteConstants.policydetails, {target: cardName});
  };

  const linkComponent = (item, policyDetailsCard) => {
    return (
      <Components.Link
        key={item}
        accessibilityLabel={item}
        accessibilityRole="button"
        {...linkStyles}
        onPress={() => {
          trackCustomEventHandler(item);
          navigateToPolicyDetails(policyDetailsCard);
        }}>
        {item}
      </Components.Link>
    );
  };

  return (
    <View className={props.className}>
      <View style={[style.cardShadow, row > 1 && style.flex]}>
        <View style={style.cardHeader}>
          <Components.Text.H3 style={[style.cardHeaderTitle, style.flex]}>
            Additional Details
          </Components.Text.H3>
        </View>
        <View className="mt-4" style={style.horizontalDivider} />
        <View className="mt-4">
          {detailLinks.map((item) => {
            const booleanMapping = {
              'PREMIUMS RECEIVED':
                getPremiumStatus === 'Active' && premiumData?.length > 0,
              'INDEXED DIVIDEND INFORMATION':
                viewIndexedDividendData?.length > 0,
              'INCOME PAYMENT INFORMATION':
                incomePaymentInformationData?.length > 0,
              'COVERAGE INFORMATION': coverageInformationData.length > 0,
              'LTC VALUES': ltcSectionData.length > 0,
              'INCOME INFORMATION': incomeInformation.length > 0,
              'CASH WITHDRAWAL VALUES': freeWithdrawalData.length > 0,
              'ANNUITY RATES': annuityRatesData?.length > 0,
            };

            const linkMapping = {
              'PREMIUMS RECEIVED': 'PremiumReceived',
              'INDEXED DIVIDEND INFORMATION': 'ViewIndexedDividend',
              'INCOME PAYMENT INFORMATION': 'IncomePaymentInformation',
              'COVERAGE INFORMATION': 'CoverageInformation',
              'LTC VALUES': 'PolicyValue',
              'INCOME INFORMATION': 'IncomeInformation',
              'CASH WITHDRAWAL VALUES': 'PolicyValue',
              'ANNUITY RATES': 'AnnuityRates',
            };

            if (booleanMapping[item]) {
              return linkComponent(item, linkMapping[item]);
            }
            return null;
          })}
        </View>
      </View>
    </View>
  );
};

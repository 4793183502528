import {Theme, Font} from '@oneamerica/dxp-ui-components';
import ColorConstants from '../../../../constants/ColorConstants';

export default () =>
  Theme.Responsive.create({
    cardShadow: {
      borderRadius: 5,
      shadowRadius: 6,
      backgroundColor: Theme.Color.background,
      shadowColor: Theme.Color.backgroundShadow,
      shadowOffset: {width: 0, height: 3},
      paddingHorizontal: Theme.Metrics.small,
      paddingVertical: Theme.Metrics.small,
    },
    cardHeader: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    horizontalDivider: {
      backgroundColor: ColorConstants.grey200,
      height: 1,
      width: '100%',
    },
    cardHeaderTitle: {
      color: ColorConstants.iron,
      lineHeight: 36,
      fontFamily: Font.medium.fontFamily,
    },
    row: {flex: 1, flexDirection: 'row'},
    flex: {flex: 1},
    headingContainer: {
      width: '100%',
      height: 50,
      borderRadius: 5,
      textAlign: 'left',
      marginBottom: 0,
      paddingVertical: 12,
      paddingLeft: 24,
      alignSelf: 'flex-start',
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderBottomWidth: 1,
      borderBottomColor: '#00000026',
    },
    detailContainer: {
      flexDirection: 'row',
      display: 'flex',
      flex: 1,
    },
  })();

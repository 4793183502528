import {AccessToken, IDToken, UserClaims} from '@okta/okta-auth-js';
import React, {createContext, useEffect, useReducer} from 'react';
import {AuthLogin, UpdateState} from './AuthActions';
import AuthReducer, {AuthState, AuthProps} from './AuthReducer';
import authClient from './config';
import {Buffer} from 'buffer';
import {authLogin, authLogout} from './AuthHelpers';

export const AuthContext = createContext({
  authClient,
  isAuthenticated: false,
  accessToken: undefined as AccessToken | undefined,
  authLogin: () => {},
  authLogout: () => {},
  idToken: undefined as IDToken | undefined,
  user: undefined as UserClaims | undefined,
  initialState: undefined,
});

const AuthProvider = ({children}) => {
  const [state, dispatch] = useReducer(AuthReducer, AuthState as AuthProps);

  const isTokenValid = async () => {
    try {
      const getSession = await authClient.session.get();
      const sessionUser: any = await getSession?.user?.();

      if (
        state.user?.preferred_username &&
        sessionUser?.profile?.login &&
        state.user?.preferred_username !== sessionUser?.profile?.login
      ) {
        //user1 user2 & user@oa.com user2@oa.com
        //user1 user2 & user@oa.com user@oa.com
        authClient.tokenManager.clear();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        location.reload();
      }
    } catch (error) {
      //invalid session
      console.log(error);
    }
  };

  const setAuthListeners = async () => {
    // Subscribe to authState change event.
    authClient.authStateManager.subscribe((authState) => {
      // Logic based on authState is done here.
      if (!authState.isAuthenticated) {
        // render unathenticated view
        return;
      }

      // Render authenticated view
      AuthLogin(dispatch, authState);
    });

    // Handle callback
    if (authClient.token.isLoginRedirect()) {
      const {state, tokens} = await authClient.token.parseFromUrl(); // remember to "await" this async call
      authClient.tokenManager.setTokens(tokens);
      UpdateState(dispatch, Buffer.from(state, 'base64').toString());
    }

    // if (!authClient.isLoginRedirect()) {
    //   // Trigger an initial authState change event when the app startup
    //   authClient.authStateManager.updateAuthState();
    // }

    // normal app startup
    authClient.start(); // will update auth state and call event listeners
  };

  useEffect(() => {
    setAuthListeners();
    return () => {
      authClient.stop();
    };
  }, []);

  useEffect(() => {
    if (state.user !== undefined) {
      isTokenValid();
    }
  }, [state.user]);

  const authContextValues = React.useMemo(
    () => ({
      authClient,
      authLogin: () => authLogin(dispatch /*, state*/, authClient),
      authLogout: () => authLogout(dispatch, authClient),
      ...state,
    }),
    [state],
  );

  return (
    <AuthContext.Provider value={authContextValues}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

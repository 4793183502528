export const columnWidthArrMob = [
  '18%',
  '12%',
  '10%',
  '18%',
  '12%',
  '15%',
  '15%',
];
export const columnWidthArr = ['73%', '12%', '15%'];
export const stratergyColumnConfiguration = (indexDate, nextDate) => {
  return [
    {
      columnName: 'Strategy',
      format: 'string',
      showColumn: true,
      isTotal: false,
      textAlign: 'left',
      isCurrency: false,
      isPercentage: false,
    },
    {
      columnName: 'Rate Type',
      format: 'string',
      showColumn: true,
      isTotal: false,
      textAlign: 'left',
      isCurrency: false,
      isPercentage: false,
    },
    {
      columnName: 'Current Rate',
      format: 'string',
      showColumn: true,
      isTotal: false,
      textAlign: 'right',
      isCurrency: false,
      isPercentage: false,
    },
    {
      columnName: 'Account Balance Percentage',
      format: 'string',
      showColumn: true,
      isTotal: false,
      textAlign: 'right',
      isCurrency: true,
      isPercentage: false,
    },
    {
      columnName: 'Account Balance',
      format: 'string',
      showColumn: true,
      isTotal: true,
      textAlign: 'right',
      isCurrency: false,
      isPercentage: true,
    },
    {
      columnName: `Current Allocations for Index Period ${indexDate}`,
      format: 'string',
      showColumn: true,
      isTotal: true,
      textAlign: 'right',
      isCurrency: false,
      isPercentage: true,
    },
    {
      columnName: `Scheduled Allocations for Index Period ${nextDate}`,
      format: 'string',
      showColumn: true,
      isTotal: true,
      textAlign: 'right',
      isCurrency: false,
      isPercentage: true,
    },
  ];
};

export const columnWidthArrStrategy = [
  '18%',
  '12%',
  '10%',
  '18%',
  '12%',
  '15%',
  '15%',
];

export default {
  grey200: '#D6D6D6',
  iron: '#333333',
  lightGreen: '#EFF4E7',
  darkGreen: '#438300',
  sun90: 'rgba(244, 181, 49, 0.9)',
  sun30: 'rgba(244, 181, 49, 0.3)',
  modalBG: '#474747CC',
  grey: '#FAFAFA',
  darkRed: '#E23D28',
  lightRed: '#F8D3CF',
  darkBlue: '#69869A',
  lightBlue: '#EEF7FC',
  lightGrey: '#EAEAEA',
  darkGrey: '#707070',
  oaBlueTint: '#2B4F8E',
  oaBlue: '#002c77',
  oaIceBlue: '#8BBFF3',
};

import {Theme} from '@oneamerica/dxp-ui-components';
import BreakpointConstants from '../../../../constants/BreakpointConstants';

const styles = (width: number) =>
  Theme.Responsive.create({
    headerStyle: {
      fontSize: BreakpointConstants.isMD(width) ? 22 : 16,
      fontWeight: BreakpointConstants.isMD(width) ? '600' : '700',
    },
  })();

export default styles;

import React, {useRef} from 'react';
import {View, Platform, ViewStyle} from 'react-native';
import {Components, Theme, Font} from '@oneamerica/dxp-ui-components';
import {
  FontAwesomeIcon,
  FontAwesomeIconStyle,
} from '@fortawesome/react-native-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import responsiveStyles from './styles';
import {faAngleDown} from '@fortawesome/pro-solid-svg-icons/faAngleDown';
import {useDispatch, useSelector} from 'react-redux';
import {getPoliciesList} from '../../redux/policies/action';
import {setSelectedPolicy} from '../../redux/policylists/policyListsSlice';
import BreakpointConstants from '../../constants/BreakpointConstants';
import {useNavigation} from '@react-navigation/core';
import RouteConstants from '../../constants/RouteConstants';
import {CommonActions} from '@react-navigation/native';
import {getDecodedCookie, getGreetings} from '../../utils/cookies';
import {trackCustomEvent} from '../../utils/Analytics/AdobeAnalyticsUtils';
import {analyticsTags} from '../../utils/Analytics/AnalyticsTags';

const PolicyDropdown = ({
  className,
  accessToken,
  text,
}: {
  className: string;
  accessToken: string | any;
  text?: string | undefined;
}) => {
  const [isHovered, setHovered] = React.useState(false);
  const {width} = React.useContext(Theme.ResponsiveContext);
  const styles = responsiveStyles(width);
  const state = useSelector((state: any) => state.policyLists);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigation<any>();

  const linkStyle = (selected = false) => {
    const style = [
      {
        fontSize: 14,
        fontFamily: selected ? Font.bold.fontFamily : Font.regular.fontFamily,
        color: BreakpointConstants.isMD(width)
          ? Theme.Color.text
          : Theme.Color.white,
      },
      styles.cursorPointer,
    ] as ViewStyle;
    return {
      hoverStyle: style,
      linkHoverStyle: style,
      linkStyle: style,
    };
  };

  const webEvents = {
    onMouseEnter: () => {
      !isHovered && setHovered(true);
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    },
    onMouseLeave: () => {
      timerRef.current = setTimeout(() => {
        isHovered && setHovered(false);
      }, 250);
    },
  };

  const touchEvents = {
    onClick: () => {
      setHovered(!isHovered);
    },
  };

  const getSelectedPolicy = () =>
    `${state.selectedPolicy.policyPlanName} (${state.selectedPolicy.policyPlanNumber})`;

  const user = getDecodedCookie('ACCOUNT_DETAILS');
  const firstName = user?.firstName || '';
  const greetingsMessage = getGreetings(firstName);

  const DropDownView = () => (
    <View
      className="z-[100]"
      style={[
        styles.menuItem,
        !BreakpointConstants.isMD(width) && styles.left0,
      ]}>
      <View style={styles.cardShadow}>
        {state.list.map((policy) => (
          <View key={policy.policyPlanNumber}>
            <Components.Link
              onPress={() => {
                if (
                  state.selectedPolicy.policyPlanNumber !==
                  policy.policyPlanNumber
                ) {
                  trackCustomEvent(
                    analyticsTags.CapturePolicySelectorDropdownTags,
                  );
                  dispatch(setSelectedPolicy(policy));
                  dispatch(
                    getPoliciesList(
                      policy.policyPlanNumber,
                      accessToken?.accessToken,
                    ),
                  );
                  navigate.dispatch(
                    CommonActions.reset({
                      index: 0,
                      routes: [
                        {
                          name: RouteConstants.home,
                          params: {
                            account: policy.policyPlanNumber,
                          },
                        },
                      ],
                    }),
                  );
                }
              }}
              className="py-2"
              {...linkStyle(
                state.selectedPolicy.policyPlanNumber ===
                  policy.policyPlanNumber,
              )}
              accessibilityRole="button"
              accessibilityLabel={`Select policy plan: ${policy.policyPlanNumber} with plan number ${policy.policyPlanNumber}`}>
              {`${policy.policyPlanName} (${policy.policyPlanNumber})`}
            </Components.Link>
            <View style={styles.horizontalDivider} />
          </View>
        ))}
      </View>
    </View>
  );

  return (
    <>
      {Array.isArray(state.list) && (state.list as Array<any>).length > 1 && (
        <View
          {...(Platform.OS === 'web' && webEvents && touchEvents)}
          className="flex md:hidden z-50"
          style={styles.mobileDropdownWrapper}>
          <Components.Text className="text-14 flex-1" style={styles.mobileText}>
            {getSelectedPolicy()}
          </Components.Text>
          <FontAwesomeIcon
            style={[
              styles.outlineNone as FontAwesomeIconStyle,
              {
                transform: [{rotateX: isHovered ? '180deg' : '0deg'}],
              },
            ]}
            icon={faAngleDown as IconProp}
            size={16}
            color={Theme.Color.white}
          />
          {isHovered && <DropDownView />}
        </View>
      )}
      <View className={`z-auto md:z-50 ${className}`} style={styles.row}>
        <Components.Text.H1
          className="flex-1 text-sm md:text-lg"
          style={styles.colorBlue}>
          {text ?? greetingsMessage}
        </Components.Text.H1>
        {text !== "My Profile" && Array.isArray(state.list) && (state.list as Array<any>).length > 1 && (
          <View className="hidden md:flex" style={styles.row}>
            <Components.Text
              className="text-14 mr-3"
              style={[styles.mobileText, styles.colorBlue]}>
              {getSelectedPolicy()}
            </Components.Text>
            <View
              style={styles.cursorPointer}
              {...(Platform.OS === 'web' && webEvents)}>
              <View style={styles.iconWrapper}>
                <FontAwesomeIcon
                  style={styles.outlineNone as FontAwesomeIconStyle}
                  icon={faAngleDown as IconProp}
                  size={16}
                  color={Theme.Color.oaBlue}
                />
              </View>
              {isHovered && <DropDownView />}
            </View>
          </View>
        )}
      </View>
    </>
  );
};

export default PolicyDropdown;

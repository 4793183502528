import {Theme, Font} from '@oneamerica/dxp-ui-components';
import ColorConstants from '../../constants/ColorConstants';

export default () =>
  Theme.Responsive.create({
    ironFont: {color: Theme.Color.gray6},
    oaBlueTintFont: {color: Theme.Color.oaBlueLight},
    robotoRegular: {fontFamily: Font.regular.fontFamily},
    robotoMedium: {fontFamily: Font.medium.fontFamily},
    marginTop16: {marginTop: Theme.Metrics.small},
    marginBottom16: {marginBottom: Theme.Metrics.small},
    marginVertical16: {marginVertical: Theme.Metrics.small},
    horizontalDivider: {
      height: 1,
      width: '100%',
      backgroundColor: Theme.Color.gray1,
    },
    font16: {fontSize: 16},
    font15: {fontSize: 15},
    font14: {fontSize: 14},
    contactUsText: {
      fontSize: 16,
      fontFamily: Font.regular.fontFamily,
      color: Theme.Color.gray6,
    },
    contactUs: {
      fontSize: 16,
      fontFamily: Font.medium.fontFamily,
      color: ColorConstants.oaBlue,
    },
    textUnderline: {
      textDecorationLine: 'underline',
    },
    linkContactUs: {
      color: ColorConstants.oaBlueTint,
      fontSize: 16,
      fontFamily: Font.medium.fontFamily,
      //@ts-ignore
      display: 'inline-flex',
    },
    linkContactUsFocus: {
      textDecorationLine: 'underline',
      textDecorationColor: Theme.Color.linkFocus,
    },
    linkContactUsHover: {
      color: ColorConstants.oaBlueTint,
      fontSize: 16,
      fontFamily: Font.medium.fontFamily,
    },
  })();

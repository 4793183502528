import {Theme} from '@oneamerica/dxp-ui-components';
import {Platform} from 'react-native';

const styles = () =>
  Theme.Responsive.create({
    wrapper: {
      backgroundColor: Theme.Color.ice0,
      flex: 1,
    },
    scrollView: {
      width: Platform.OS === 'web' ? '100vw' : '100%',
      height: Platform.OS === 'web' ? '100vh' : '100%',
    },
    proxyRefreshControl: {
      opacity: 0,
      backgroundColor: 'transparent',
    },
    outlineNone: {
      outlineWidth: 0,
    },
  })();

export default styles;

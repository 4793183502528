import {passwordValidationRegex} from '../../../constants/UtilsConstants';

const numCheckReg = /\d/;
const capsCheckReg = /[A-Z]/;
const lowerCaseCheckReg = /[a-z]/;
const specialCharCheckReg = /[-S~!@#%^&()_+=]/;
const spaceCheckReg = / /;

export interface ValidationObjectType {
  min: boolean;
  max: boolean;
  uppercase: boolean;
  lowercase: boolean;
  num: boolean;
  special: boolean;
  userid: boolean;
  current: boolean;
  spaces: boolean;
  validChar: boolean;
}

export interface UseridValidationObjectType {
  min: boolean;
  max: boolean;
  endWithLetterOrNumber: boolean;
  allowableSpecialCharacters: boolean;
  notNineNumbers: boolean;
  noSpaces: boolean;
}

export const defaultPasswordValidation: ValidationObjectType = {
  min: false,
  max: false,
  uppercase: false,
  lowercase: false,
  num: false,
  special: false,
  userid: false,
  current: false,
  spaces: false,
  validChar: false,
};

export const validateNewPasswordField = (
  newPassword: string,
  userIdCheck: string,
  currentPassword: string,
) => {
  const validationObj = defaultPasswordValidation;

  validationObj.min = newPassword.length >= 8;
  validationObj.max = newPassword.length <= 20;
  validationObj.uppercase = capsCheckReg.test(newPassword);
  validationObj.lowercase = lowerCaseCheckReg.test(newPassword);
  validationObj.num = numCheckReg.test(newPassword);
  validationObj.special = specialCharCheckReg.test(newPassword);
  validationObj.userid =
    userIdCheck.length > 0 &&
    !newPassword.toUpperCase().includes(userIdCheck.toUpperCase());
  validationObj.current = newPassword !== currentPassword;
  validationObj.spaces = !spaceCheckReg.test(newPassword);
  validationObj.validChar = passwordValidationRegex.test(newPassword);

  return validationObj;
};

export const DefaultUserIDValidationObj: UseridValidationObjectType = {
  min: false,
  max: false,
  endWithLetterOrNumber: false,
  allowableSpecialCharacters: false,
  notNineNumbers: false,
  noSpaces: false,
};

export const validateUsernameField = (username: string) => {
  const validationObj = DefaultUserIDValidationObj;

  const allowableSpecialCharactersReg = /^[a-zA-Z0-9_.@-]*$/;
  const endWithLetterOrNumberReg = /[a-zA-Z0-9]$/;
  const notNineDigitsReg = /^(\D*\d){0,8}\D*$/;
  const noSpacesReg = /^\S*$/;

  validationObj.min = username.length >= 6;
  validationObj.max = username.length <= 64;
  validationObj.endWithLetterOrNumber = endWithLetterOrNumberReg.test(username);
  validationObj.allowableSpecialCharacters =
    allowableSpecialCharactersReg.test(username);
  validationObj.notNineNumbers = notNineDigitsReg.test(username);
  validationObj.noSpaces = noSpacesReg.test(username);

  return validationObj;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {forwardRef, useEffect, useState} from 'react';
import {Keyboard, View} from 'react-native';
import {Components, Theme} from '@oneamerica/dxp-ui-components';
import SaveChanges from '../../Profile/components/SaveChanges';
import {ProfilePasswordType} from '../../Profile/types';
import {
  ValidationObjectType,
  defaultPasswordValidation,
  validateNewPasswordField,
} from './utils';
import {ErrorListProps} from '@oneamerica/dxp-ui-components/lib/typescript/src/components/TextInput';
import {useProfileDetailsSelector} from '../../Profile/hooks/profileDetails';

export interface ChangePasswordProps {
  confirmPasswordTestID?: string;
  content?: ProfilePasswordType;
  currentPasswordTestID?: string;
  hideCurrentPasswordField?: boolean;
  newPasswordTestID?: string;
  onSubmit: (currentVal: string, newVal: string) => void;
  onCancel: () => void;
  passwordMaxLength?: number;
  passwordMinLength?: number;
  showStrengthMeter?: boolean;
  userId: string;
  showMenu?: (value: string | void) => void;
  activeMenu: string;
  Index: number;
  modalTitleText: string;
  success: boolean;
  alertLevel: any;
  isProfileUpdatedValue: boolean;
  isError: boolean;
  closeButton: () => void;
}

const ChangePassword = forwardRef((props: ChangePasswordProps, _ref) => {
  const {
    confirmPasswordTestID,
    content,
    currentPasswordTestID,
    hideCurrentPasswordField = true,
    newPasswordTestID,
    onCancel,
    onSubmit,
    passwordMaxLength = 20,
    passwordMinLength = 8,
    showStrengthMeter = true,
    userId,
    activeMenu,
    Index,
    modalTitleText,
    success,
    alertLevel,
    isProfileUpdatedValue,
    isError,
    closeButton,
  } = props;

  const [requirementsCheck, setRequirementsCheck] =
    useState<ValidationObjectType>(defaultPasswordValidation);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [valid, setValid] = useState(false);
  const [matchedRules, setMatchedRules] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [hideCurrentPassword, setHideCurrentPassword] = useState(true);
  const [hideNewPassword, setHideNewPassword] = useState(true);
  const [hideConfirmNewPassword, setHideConfirmNewPassword] = useState(true);
  const [ruleScore, setRuleScore] = useState(0);
  const userIdCheck = userId ?? '';
  const {isProfileUpdated} = useProfileDetailsSelector(props);

  useEffect(() => {
    if (
      currentPassword.length > 7 &&
      newPassword.length > 7 &&
      confirmNewPassword.length > 0 &&
      newPassword === confirmNewPassword &&
      ruleScore > 2 &&
      matchedRules
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [currentPassword, newPassword, confirmNewPassword, matchedRules]);

  const NewPasswordChangeText = (text: string) => {
    const rules = validateNewPasswordField(text, userIdCheck, currentPassword);
    setRequirementsCheck(rules);

    // Two different scores. One for the "Must contain at least 3" bullet and the
    // other is used by the StrengthMeter.
    let passwordScore = 0;
    if (rules.lowercase) {
      passwordScore++;
    }
    if (rules.uppercase) {
      passwordScore++;
    }
    if (rules.num) {
      passwordScore++;
    }
    if (rules.special) {
      passwordScore++;
    }
    setRuleScore(passwordScore);
    setMatchedRules(
      rules.min &&
        rules.max &&
        rules.spaces &&
        rules.userid &&
        rules.validChar &&
        passwordScore > 2,
    );

    setNewPassword(text);
  };

  const confirmNewPasswordChangeText = (text: string) => {
    setConfirmNewPassword(text);
  };

  const onButtonClick = () => {
    Keyboard.dismiss();
    if (onSubmit) {
      onSubmit(newPassword, currentPassword);
    }
  };

  useEffect(() => {
    if (isProfileUpdated) {
      resetForm();
    }
  }, [isProfileUpdated]);

  const resetForm = () => {
    // Reset input fields
    setCurrentPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
    // Reset show/hide password inputs
    setHideCurrentPassword(true);
    setHideNewPassword(true);
    setHideConfirmNewPassword(true);
    // Reset calculations/validation checks
    setMatchedRules(false);
    setRequirementsCheck(defaultPasswordValidation);
    setRuleScore(0);
  };

  const cancelChanges = () => {
    resetForm();
    onCancel();
    typeof props.showMenu === 'function' && props.showMenu('contact');
  };

  function checkPasswordEquality(
    currentPassword: string,
    newPassword: string,
    errors: ErrorListProps[],
  ) {
    if (
      currentPassword.length > 0 &&
      newPassword.length > 0 &&
      currentPassword === newPassword
    ) {
      errors.push({
        id: 0,
        errorItem: 'Cannot be same as current password',
      });
    }
  }

  function checkPasswordLength(newPassword: string, errors: ErrorListProps[]) {
    if (
      newPassword.length > 0 &&
      newPassword.length < 8 &&
      currentPassword !== newPassword
    ) {
      errors.push({
        id: 0,
        errorItem: 'Password must be minimum 8 characters',
      });
    }
  }

  function checkPasswordConfirmation(
    newPassword: string,
    confirmNewPassword: string,
    confirmErrors: ErrorListProps[],
    content: any,
  ) {
    if (
      newPassword.length > 0 &&
      confirmNewPassword.length > 0 &&
      confirmNewPassword !== newPassword
    ) {
      confirmErrors.push({
        id: 0,
        errorItem: content?.passwordDoesNotMatchText ?? '',
      });
    }
  }

  // Usage
  const newErrors: ErrorListProps[] = [];
  checkPasswordEquality(currentPassword, newPassword, newErrors);
  checkPasswordLength(newPassword, newErrors);

  const confirmErrors: ErrorListProps[] = [];
  checkPasswordConfirmation(
    newPassword,
    confirmNewPassword,
    confirmErrors,
    content,
  );

  // Assuming FontTypes is something like 'bold' | 'regular'
  type FontTypes = 'bold' | 'regular';

  // Function to determine font style based on a condition
  function determineFontStyle(condition: boolean): FontTypes {
    return condition ? 'bold' : 'regular';
  }

  // Function to determine color based on conditions
  function determineColor(
    isErrorCondition: boolean,
    newPassword: string,
  ): string {
    return newPassword && isErrorCondition
      ? Theme.Color.oaRed
      : Theme.Color.black;
  }

  return (
    <>
      <View className="lg:flex-row">
        <View className="lg:flex-1 lg:pr-12 xl:max-w-[380px]">
          {!hideCurrentPasswordField && (
            <View className="mt-2">
              <Components.TextInput
                testID={currentPasswordTestID}
                autoComplete="off"
                autoCorrect={false}
                accessibilityLabel={content?.currentPasswordLabel}
                label={content?.currentPasswordLabel}
                secureTextEntry={hideCurrentPassword}
                value={currentPassword}
                textContentType="password"
                returnKeyType="next"
                onChangeText={(text) => setCurrentPassword(text)}
              />
            </View>
          )}
          <View className="mt-4">
            <Components.TextInput
              testID={newPasswordTestID}
              label={content?.newPasswordLabel}
              accessibilityLabel={content?.newPasswordLabel}
              autoComplete="off"
              autoCorrect={false}
              ErrorList={newErrors}
              secureTextEntry={hideNewPassword}
              onChangeText={(text) => NewPasswordChangeText(text)}
              value={newPassword}
              maxLength={passwordMaxLength}
              textContentType="password"
              passwordRules={`minlength: ${passwordMinLength}; maxlength: ${passwordMaxLength}; required: lower; required: upper; required: digit; required: [!#%&()+=@^_~];`}
              returnKeyType="next"
            />
            {showStrengthMeter && newPassword.length > 0 && (
              <View className="mt-2">
                <Components.StrengthMeter
                  requirementCheked={matchedRules}
                  password={newPassword}
                />
              </View>
            )}
          </View>
          <View className="mt-4">
            <Components.TextInput
              autoCorrect={false}
              autoComplete="off"
              ErrorList={confirmErrors}
              testID={confirmPasswordTestID}
              accessibilityLabel={content?.confirmNewPasswordLabel}
              label={content?.confirmNewPasswordLabel}
              secureTextEntry={hideConfirmNewPassword}
              value={confirmNewPassword}
              onChangeText={(text) => confirmNewPasswordChangeText(text)}
              maxLength={passwordMaxLength}
              textContentType="password"
            />
          </View>
        </View>
        <View className="mt-5 lg:mt-0 lg:flex-[1]">
          <Components.Text
            font="bold"
            family="soleil"
            size={16}
            color={Theme.Color.oaBlue}
            className="mb-3">
            {content?.passwordRequirementsHeader}
          </Components.Text>
          <Components.Text
            font={determineFontStyle(
              requirementsCheck?.min && newPassword.length > 0,
            )}
            className="mb-2"
            color={determineColor(!requirementsCheck?.min, newPassword)}
            testID="password-min">
            &bull; {content?.passwordRequirementsTextLine1}
          </Components.Text>

          <Components.Text
            font={determineFontStyle(
              requirementsCheck?.max && newPassword.length > 0,
            )}
            className="mb-2"
            color={determineColor(!requirementsCheck?.max, newPassword)}
            testID="password-max">
            &bull; {content?.passwordRequirementsTextLine2}
          </Components.Text>

          <Components.Text
            font={determineFontStyle(ruleScore > 2)}
            className="mb-2"
            color={Theme.Color.black}>
            &bull; {content?.passwordRequirementsTextLine3}
          </Components.Text>

          <Components.Text
            font={determineFontStyle(
              requirementsCheck?.uppercase && newPassword.length > 0,
            )}
            className="ml-5 mb-2"
            testID="password-uppercase"
            color={Theme.Color.black}>
            - {content?.passwordRequirementsTextLine4}
          </Components.Text>

          <Components.Text
            font={determineFontStyle(
              requirementsCheck?.lowercase && newPassword.length > 0,
            )}
            className="ml-5 mb-2"
            testID="password-lowercase"
            color={Theme.Color.black}>
            - {content?.passwordRequirementsTextLine5}
          </Components.Text>

          <Components.Text
            font={determineFontStyle(
              requirementsCheck?.num && newPassword.length > 0,
            )}
            className="ml-5 mb-2"
            testID="password-num"
            color={Theme.Color.black}>
            - {content?.passwordRequirementsTextLine6}
          </Components.Text>

          <Components.Text
            font={determineFontStyle(
              requirementsCheck?.special && newPassword.length > 0,
            )}
            className="ml-5 mb-2"
            testID="password-special"
            color={Theme.Color.black}>
            - {content?.passwordRequirementsTextLine7}
          </Components.Text>

          <Components.Text
            font={determineFontStyle(
              requirementsCheck?.validChar && newPassword.length > 0,
            )}
            className="mb-2"
            color={determineColor(!requirementsCheck?.validChar, newPassword)}
            testID="password-validChar">
            &bull; {content?.passwordRequirementsTextLine8}
          </Components.Text>

          <Components.Text
            font={determineFontStyle(
              requirementsCheck?.userid && newPassword.length > 0,
            )}
            className="mb-2"
            color={determineColor(!requirementsCheck?.userid, newPassword)}
            testID="password-userid">
            &bull; {content?.passwordRequirementsTextLine9}
          </Components.Text>

          <Components.Text
            font={determineFontStyle(
              requirementsCheck?.spaces && newPassword.length > 0,
            )}
            className="mb-2"
            color={determineColor(!requirementsCheck?.spaces, newPassword)}
            testID="password-spaces">
            &bull; {content?.passwordRequirementsTextLine10}
          </Components.Text>
        </View>
      </View>
      <View>
        {success && isProfileUpdatedValue ? (
          <Components.AlertMessage
            title={modalTitleText}
            className="mb-5 flex-1 mt-3"
            onCloseMessage={closeButton}
          />
        ) : (
          isError && (
            <Components.AlertMessage
              className="mb-4 mt-3"
              title="The password you entered is incorrect."
              alertLevel={alertLevel}
              onCloseMessage={closeButton}
            />
          )
        )}
        <Components.Divider />
        <SaveChanges
          Index={Index}
          activeMenu={activeMenu}
          showChangePassword={false}
          disabled={!valid}
          onSave={() => onButtonClick()}
          onCancel={() => cancelChanges()}
        />
      </View>
    </>
  );
});

export default ChangePassword;

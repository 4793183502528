import React, {useEffect, useRef, useState} from 'react';
import {ScrollView, View} from 'react-native';
import {Theme} from '@oneamerica/dxp-ui-components';
import responsiveStyle from './style';
import ScreenWrapper from '../../components/ScreenWrapper/ScreenWrapper';
import withAuth from '../../auth/withAuth';
import ContactInfoCard from './components/ContactInfoCard';
import ProfileMenuItem from './components/MenuItem';
import PasswordCard from './components/PasswordCard';
import BreakpointConstants from '../../constants/BreakpointConstants';
import DisplaySecurityQuestion from './components/DisplaySecurityQuestion';
import PolicyDropdown from '../../components/PolicyDropdown';
import {updateProfileDataReset} from '../../redux/profileDetails/action';
import {useDispatch} from 'react-redux';
import Breadcrumbs from '../../components/Breadcrumbs';
import {
  trackCustomEvent,
  trackPage,
} from '../../utils/Analytics/AdobeAnalyticsUtils';
import {analyticsTags} from '../../utils/Analytics/AnalyticsTags';

const style = responsiveStyle();

const Profile = (props) => {
  const [activeMenu, setActiveMenu] = useState('contact');
  const [hideMenu, setHideMenu] = useState(false);
  const {width} = React.useContext(Theme.ResponsiveContext);
  const dispatch = useDispatch();
  const scrollViewRef = useRef<ScrollView>(null);

  const setNavigation = (item?: string) => {
    item && item !== activeMenu && setActiveMenu(item);
    dispatch(updateProfileDataReset());
    if (!BreakpointConstants.isLG(width)) {
      setHideMenu(true);
    }
  };
  const scrollToTop = () => {
    scrollViewRef?.current?.scrollTo({y: 0, animated: true});
  };
  const onHideMenu = () => {
    setHideMenu(false);
    if (!BreakpointConstants.isLG(width)) {
      setActiveMenu('contact');
    }
  };
  useEffect(() => {
    trackPage(analyticsTags.createMyProfilePageViewTags);
    const onMobileResize = () => {
      if (!BreakpointConstants.isLG(width)) {
        setActiveMenu('');
      }
    };
    onMobileResize();
  }, []);

  return (
    <ScreenWrapper
      scrollFooter
      paddingTop
      paddingBottom
      paddingHorizontal
      ref={scrollViewRef}>
      <Breadcrumbs />
      <View style={style.cardHeader}>
        <PolicyDropdown
          className=""
          accessToken={props.accessToken}
          text={'My Profile'}
        />
      </View>
      <View className="lg:flex-row -z-10">
        <View
          className={`${
            hideMenu ? 'hidden' : ''
          } w-full lg:w-[292px] lg:mr-6 mb-8`}>
          <ProfileMenuItem
            label="Contact Information"
            onPress={(item) => {
              trackCustomEvent(analyticsTags.CaptureContactInformationTags);
              setNavigation(item);
            }}
            id="contact"
            active={activeMenu === 'contact'}
          />
          <ProfileMenuItem
            label="Password & User ID"
            onPress={(item) => {
              trackCustomEvent(analyticsTags.CapturePasswordAndUserIDTags);
              setNavigation(item);
            }}
            id="password"
            active={activeMenu === 'password'}
          />
          <ProfileMenuItem
            label="Security Question"
            onPress={(item) => {
              trackCustomEvent(analyticsTags.CaptureSecurityQuestionTags);
              setNavigation(item);
            }}
            id="security"
            active={activeMenu === 'security'}
          />
        </View>
        <View className="flex-auto">
          {activeMenu === 'contact' && (
            <ContactInfoCard
              showMenu={() => onHideMenu()}
              activeMenu={activeMenu}
              scrollToTop={scrollToTop}
            />
          )}
          {activeMenu === 'password' && (
            <PasswordCard
              activeMenu={activeMenu}
              accessToken={props.accessToken}
              scrollToTop={scrollToTop}
              showMenu={(value: React.SetStateAction<string>) => {
                onHideMenu();
                if (!BreakpointConstants.isLG(width)) {
                  setActiveMenu('password');
                }
              }}
            />
          )}
          {activeMenu === 'security' && (
            <DisplaySecurityQuestion
              activeMenu={activeMenu}
              scrollToTop={scrollToTop}
              showMenu={(value: React.SetStateAction<string>) => {
                onHideMenu();
                if (!BreakpointConstants.isLG(width)) {
                  setActiveMenu('security');
                }
              }}
            />
          )}
        </View>
      </View>
    </ScreenWrapper>
  );
};

export default withAuth(Profile);

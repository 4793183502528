import React, {
  useState,
  forwardRef,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useImperativeHandle,
} from 'react';
import {
  Animated,
  ImageBackground,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  RefreshControl,
  ScrollView,
  View,
  ViewStyle,
  Dimensions,
  TouchableOpacity,
} from 'react-native';
import {AvoidSoftInput} from 'react-native-avoid-softinput';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import responsiveStyles from './styles';
import {
  FontAwesomeIcon,
  FontAwesomeIconStyle,
} from '@fortawesome/react-native-fontawesome';
import {faAnglesUp} from '@fortawesome/pro-solid-svg-icons/faAnglesUp';
import {Components, Theme} from '@oneamerica/dxp-ui-components';
import {Images} from '../../../assets';
import Footer from '../Footer';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

export type ScreenWrapperProps = {
  children?: ReactNode;
  paddingTop?: boolean;
  paddingBottom?: boolean;
  paddingHorizontal?: boolean;
  useMarginTop?: boolean;
  metrics?: boolean;
  scrollFooter?: boolean;
  ContentHeader?: () => ReactNode;
  ContentFooter?: () => ReactNode;
  onScroll?: (value: number) => void;
  onMomentumScrollEnd?: (
    event: NativeSyntheticEvent<NativeScrollEvent>,
  ) => void;
  onRefresh?: () => void;
  style?: ViewStyle;
  scrollStyle?: ViewStyle;
  scrollEnabled?: boolean;
  contentStyle?: ViewStyle;
  stickyHeaderIndices?: number[];
  ignoreHeader?: boolean;
  ignoreFooter?: boolean;
  refreshing?: boolean;
  loader?: boolean;
  testID?: string;
  yOffset?: Animated.Value;
};

export type ScrollRef = ScrollView;

const SVGBackground = ({children}) => {
  const styles = responsiveStyles();
  if (Platform.OS === 'web') {
    return (
      <ImageBackground
        source={Images.pageBG}
        style={styles.wrapper}
        resizeMode="cover"
        imageStyle={{width: '100%', height: 1722, top: -35}}>
        {children}
      </ImageBackground>
    );
  }
  return (
    <>
      <View className="absolute -z-20 bottom-[300px] left-0 w-screen h-screen">
        <Images.pageBG />
      </View>
      <View className="-mt-4 sm:-mt-8">{children}</View>
    </>
  );
};

const ScreenWrapper = forwardRef<ScrollRef, ScreenWrapperProps>(
  (props: ScreenWrapperProps, ref): ReactElement | null => {
    const {
      paddingTop = true,
      paddingBottom = true,
      paddingHorizontal = true,
      useMarginTop = false,
      scrollFooter = false,
      stickyHeaderIndices,
      ignoreHeader = false,
      ignoreFooter = false,
      onScroll,
      onMomentumScrollEnd,
      onRefresh,
      refreshing = false,
      scrollStyle,
      scrollEnabled = true,
      contentStyle,
      loader = true,
      yOffset = new Animated.Value(0),
    } = props;

    const styles = responsiveStyles();

    const {top, bottom} = useSafeAreaInsets();
    const {headerHeight, footerHeight} = {
      headerHeight: 280,
      footerHeight: 280,
    };
    const [isScrollToTopVisible, setScrollToTopVisiblity] = useState(false);
    const {height} = Dimensions.get('window');
    const scrollViewRef = useRef(null);
    useImperativeHandle(ref, () => scrollViewRef.current!, []);

    /*
    NOTE there is a RefreshControl that acts as a proxy to detect the pull-to-refresh gesture
    but it is dismissed immediately to show the custom UI for the refreshing state (LoadingIndicator)
    */
    const proxyOnRefresh = useCallback(() => {
      if (!refreshing) {
        onRefresh?.();
      }
    }, [onRefresh, refreshing]);

    const displayHeaderHeight = ignoreHeader ? 0 : (headerHeight as number);
    const displayFooterHeight = ignoreFooter ? 0 : (footerHeight as number);

    const topPadding = top;
    const bottomPadding = bottom;

    const dynamicStyle = {
      marginTop: useMarginTop ? topPadding : 0,
      paddingTop: useMarginTop ? 0 : topPadding,
      paddingBottom: bottomPadding + (useMarginTop ? topPadding : 0),
    };

    yOffset.addListener(({value}) => {
      //callback
      onScroll?.(value);
    });

    useEffect(() => {
      AvoidSoftInput.setAvoidOffset(-(bottom + displayFooterHeight));
    }, [bottom, footerHeight]);

    let paddingClasses = '';
    if (paddingTop && paddingBottom) {
      paddingClasses = 'py-4 md:py-6';
    } else if (paddingTop && !paddingBottom) {
      paddingClasses = 'pt-4 md:pt-6';
    } else if (!paddingTop && paddingBottom) {
      paddingClasses = 'pb-4 md:pb-6';
    }

    const toggleScrollButton = (verticalOffset: number) => {
      if (verticalOffset >= height * 0.25) {
        !isScrollToTopVisible && setScrollToTopVisiblity(true);
      } else {
        isScrollToTopVisible && setScrollToTopVisiblity(false);
      }
    };

    return (
      <View className="flex-1">
        <SVGBackground>
          <Animated.ScrollView
            ref={scrollViewRef}
            keyboardDismissMode="interactive"
            keyboardShouldPersistTaps="handled"
            stickyHeaderIndices={stickyHeaderIndices}
            scrollEnabled={scrollEnabled}
            showsVerticalScrollIndicator={true}
            className="overflow-y-auto"
            style={[styles.scrollView, scrollStyle]}
            {...(Platform.OS === 'web' && {accessibilityRole: 'none'})}
            contentContainerStyle={[contentStyle, dynamicStyle]}
            onScroll={(event) => {
              toggleScrollButton(event.nativeEvent.contentOffset.y);
              Animated.event([{nativeEvent: {contentOffset: {y: yOffset}}}], {
                useNativeDriver: true,
              });
            }}
            onMomentumScrollEnd={onMomentumScrollEnd}
            refreshControl={
              <RefreshControl
                style={styles.proxyRefreshControl}
                refreshing={false}
                onRefresh={proxyOnRefresh}
                progressBackgroundColor={'transparent'}
              />
            }>
            <View
              className={`max-w-7xl w-full lg:mx-auto relative z-10 ${
                paddingHorizontal && 'px-4 md:px-8'
              } ${paddingClasses} ${Platform.OS === 'ios' && '-mt-10'} ${
                Platform.OS === 'android' && '-mt-1'
              }`}>
              {props.children}
            </View>
            {scrollFooter && <Footer />}
          </Animated.ScrollView>

          {isScrollToTopVisible && (
            <View className="absolute bottom-0 right-0 mr-4 mb-4 md:mr-8 md:mb-8">
              <TouchableOpacity
                activeOpacity={0.8}
                style={styles.scrollToTop}
                accessibilityLabel="Scroll to Top"
                onPress={() => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  scrollViewRef?.current?.scrollTo({
                    offset: 0,
                    animated: true,
                  });
                }}>
                <View
                  style={{
                    backgroundColor: Theme.Color.ice4,
                    width: 48,
                    height: 48,
                    padding: 16,
                    borderRadius: 24,
                  }}>
                  <FontAwesomeIcon
                    style={styles.outlineNone as FontAwesomeIconStyle}
                    icon={faAnglesUp as IconProp}
                    size={16}
                    color={Theme.Color.oaBlue}
                  />
                </View>
              </TouchableOpacity>
            </View>
          )}

          {loader && (
            <View
              className="w-full h-[50px] justify-center absolute z-[1000]"
              style={{top: top + displayHeaderHeight}}
              pointerEvents="none">
              <Components.LoadingOverlay loading={refreshing} />
            </View>
          )}
        </SVGBackground>
      </View>
    );
  },
);

export default ScreenWrapper;

// Define a function to create event tags
export const createCustomEventTags = (name, value, type, target) => {
  return {name, value, type, target};
};

const createPageViewEventTags = (
  name,
  type,
  section,
  section1,
  section2,
  section3,
  lob,
  persona,
  productCategory,
  productType,
  language,
  siteType,
) => {
  return {
    name,
    type,
    section,
    section1,
    section2,
    section3,
    lob,
    persona,
    productCategory,
    productType,
    language,
    siteType,
  };
};

export const analyticsTags = {
  createHomePageViewTags: createPageViewEventTags(
    'Policyholder | Home dashboard | Landing Page',
    'Portal',
    'Policyholder',
    'Home dashboard',
    'Landing Page',
    'N/A',
    'ILFS',
    'Individual',
    'N/A',
    'N/A',
    'english',
    'PolicyHolder Portal',
  ),
  createPolicyDetailsViewTags: createPageViewEventTags(
    'Policyholder | Policy Details | Landing Page',
    'Portal',
    'Policyholder',
    'Policy Details',
    'Landing Page',
    'N/A',
    'ILFS',
    'Individual',
    'N/A',
    'N/A',
    'english',
    'PolicyHolder Portal',
  ),
  createMyInvestmentPageViewTags: createPageViewEventTags(
    'Policyholder | My Investment | Landing Page',
    'Portal',
    'Policyholder',
    'My Investment',
    'Landing Page',
    'N/A',
    'ILFS',
    'Individual',
    'N/A',
    'N/A',
    'english',
    'PolicyHolder Portal',
  ),
  createMyTransferBalancePageViewTags: createPageViewEventTags(
    'Policyholder | Transfer Balance | Landing Page',
    'Portal',
    'Policyholder',
    'Transfer Balance',
    'Landing Page',
    'N/A',
    'ILFS',
    'Individual',
    'N/A',
    'N/A',
    'english',
    'PolicyHolder Portal',
  ),
  createPercentTransferPageViewTags: createPageViewEventTags(
    'Policyholder | Percent Transfer | Landing Page',
    'Portal',
    'Policyholder',
    'Percent Transfer',
    'Landing Page',
    'N/A',
    'ILFS',
    'Individual',
    'N/A',
    'N/A',
    'english',
    'PolicyHolder Portal',
  ),
  createDollarTransferPageViewTags: createPageViewEventTags(
    'Policyholder | Dollar Transfer | Landing Page',
    'Portal',
    'Policyholder',
    'Dollar Transfer',
    'Landing Page',
    'N/A',
    'ILFS',
    'Individual',
    'N/A',
    'N/A',
    'english',
    'PolicyHolder Portal',
  ),
  createMyInvestmentElectionsPageViewTags: createPageViewEventTags(
    'Policyholder | My Investment Elections | Landing Page',
    'Portal',
    'Policyholder',
    'My Investment Elections',
    'Landing Page',
    'N/A',
    'ILFS',
    'Individual',
    'N/A',
    'N/A',
    'english',
    'PolicyHolder Portal',
  ),
  createMyEditFutureElectionPageViewTags: createPageViewEventTags(
    'Policyholder | Edit Future Election | Landing Page',
    'Portal',
    'Policyholder',
    'Edit Future Election',
    'Landing Page',
    'N/A',
    'ILFS',
    'Individual',
    'N/A',
    'N/A',
    'english',
    'PolicyHolder Portal',
  ),
  createMyStrategiesPageViewTags: createPageViewEventTags(
    'Policyholder | My Strategies | Landing Page',
    'Portal',
    'Policyholder',
    'My Strategies',
    'Landing Page',
    'N/A',
    'ILFS',
    'Individual',
    'N/A',
    'N/A',
    'english',
    'PolicyHolder Portal',
  ),
  createContactusPageViewTags: createPageViewEventTags(
    'Policyholder | Contact Us | Landing Page',
    'Contact',
    'Policyholder',
    'Contact Us',
    'Landing Page',
    'N/A',
    'ILFS',
    'Individual',
    'N/A',
    'N/A',
    'english',
    'PolicyHolder Portal',
  ),
  createPolicyServiceRequestPageViewTags: createPageViewEventTags(
    'Policyholder | Policy Service Request | Landing Page',
    'Portal',
    'Policyholder',
    'Policy Service Request',
    'Landing Page',
    'N/A',
    'ILFS',
    'Individual',
    'N/A',
    'N/A',
    'english',
    'PolicyHolder Portal',
  ),
  createMyProfilePageViewTags: createPageViewEventTags(
    'Policyholder | My Profile | Landing Page',
    'Portal',
    'Policyholder',
    'My Profile',
    'Landing Page',
    'N/A',
    'ILFS',
    'Individual',
    'N/A',
    'N/A',
    'english',
    'PolicyHolder Portal',
  ),
  CapturePolicyDetailsIconTags: createCustomEventTags(
    'Policy Details',
    'PolicyHolder Portal Header',
    'click',
    null,
  ),
  CaptureContactUsTags: createCustomEventTags(
    'Contact Us',
    'PolicyHolder Portal Header',
    'click',
    null,
  ),
  CaptureContactUsPSRTags: createCustomEventTags(
    'Contact Us',
    'Policy Service Request Page',
    'click',
    null,
  ),
  CaptureContactUsDrawerContentTags: createCustomEventTags(
    'Contact Us',
    'PolicyHolder Drawer Content',
    'click',
    null,
  ),
  CaptureContactUsErrorsMessageTags: createCustomEventTags(
    'Contact Us',
    'Error Message Page',
    'click',
    null,
  ),
  CaptureContactUsCOBTags: createCustomEventTags(
    'Contact Us',
    'COB Page',
    'click',
    null,
  ),
  CaptureContactUsTerminatedCOBTags: createCustomEventTags(
    'Contact Us',
    'Terminated COB Page',
    'click',
    null,
  ),
  CaptureContactUsSuspendedCOBTags: createCustomEventTags(
    'Contact Us',
    'Suspended COB Page',
    'click',
    null,
  ),
  CaptureContactUsPolicyHighlightsTags: createCustomEventTags(
    'Contact Us',
    'PolicyHighlights Card Home',
    'click',
    null,
  ),
  CaptureContactUsPolicyHighlightsTerminatedTags: createCustomEventTags(
    'Contact Us',
    'PolicyHighlights Card Terminated Home',
    'click',
    null,
  ),
  CaptureContactUsInvestmentPendingTransactionTags: createCustomEventTags(
    'Contact Us',
    'Investment Pending Transaction',
    'click',
    null,
  ),
  CaptureContactUsInvestmentMultipleOwnersTags: createCustomEventTags(
    'Contact Us',
    'Investment Multiple Owners',
    'click',
    null,
  ),
  CaptureContactUsInvestmentScheduledFinancialEventTags: createCustomEventTags(
    'Contact Us',
    'Investment Scheduled Financial Event',
    'click',
    null,
  ),
  CaptureContactUsInvestmentFreeLookTags: createCustomEventTags(
    'Contact Us',
    'Investment Free Look Period',
    'click',
    null,
  ),
  CaptureContactUsInvestmentIOETroubleTags: createCustomEventTags(
    'Contact Us',
    'Investment IOE Trouble Confirming Request',
    'click',
    null,
  ),
  CaptureContactUsInvestmentIOEPDFLinkTags: createCustomEventTags(
    'Contact Us',
    'Investment IOE PDF Link',
    'click',
    null,
  ),
  CaptureContactUsDollarTransferTroubleTags: createCustomEventTags(
    'Contact Us',
    'Dollar Transfer Trouble Processing',
    'click',
    null,
  ),
  CaptureContactUsDollarTransferPDFLinkTags: createCustomEventTags(
    'Contact Us',
    'Dollar Transfer PDF Link',
    'click',
    null,
  ),
  CaptureContactUsPercentTransferTroubleTags: createCustomEventTags(
    'Contact Us',
    'Percent Transfer Trouble Processing',
    'click',
    null,
  ),
  CaptureContactUsPercentTransferPDFLinkTags: createCustomEventTags(
    'Contact Us',
    'Percent Transfer PDF Link',
    'click',
    null,
  ),
  CaptureHomeIconTags: createCustomEventTags(
    'Home Icon',
    'PolicyHolder Portal Header',
    'click',
    null,
  ),
  CaptureHomeInvestmentViewDetailsTags: createCustomEventTags(
    'Investment',
    'PolicyHolder Portal Header',
    'click',
    null,
  ),
  CaptureMyStrategiesTags: createCustomEventTags(
    'My Strategies',
    'PolicyHolder Portal Header',
    'click',
    null,
  ),
  CapturePolicyServiceRequestTags: createCustomEventTags(
    'Policy Service Request',
    'PolicyHolder Portal Header',
    'click',
    null,
  ),
  CapturePSRRelationshipTags: createCustomEventTags(
    'Policy Service Request',
    'PSR Policy Relationship Home',
    'click',
    null,
  ),
  CapturePSRRelationshipPageTags: createCustomEventTags(
    'Policy Service Request',
    'PSR Policy Relationship Page',
    'click',
    null,
  ),
  CaptureMyProfileIconTags: createCustomEventTags(
    'My Profile Icon',
    'PolicyHolder Portal Header',
    'click',
    null,
  ),
  CapturePolicyRelationshipsCardInfoModalTags: createCustomEventTags(
    'Info Icon',
    'Policy Relationships Card Home Page',
    'click',
    null,
  ),
  CapturePolicyRelationshipsInfoModalTags: createCustomEventTags(
    'Info Icon',
    'Policy Relationships Policy Details Page',
    'click',
    null,
  ),
  CaptureViewAllRelationshipsTags: createCustomEventTags(
    'VIEW ALL RELATIONSHIPS',
    'Policy Relationships Card Home Page',
    'click',
    null,
  ),
  CaptureRelationshipsAnnuitantTags: createCustomEventTags(
    'Annuitant',
    'Policy Relationships Card Home Page',
    'click',
    null,
  ),
  CaptureRelationshipsPrimaryInsuredTags: createCustomEventTags(
    'Primary Insured',
    'Policy Relationships Card Home Page',
    'click',
    null,
  ),
  CaptureRelationshipsPrimaryBeneficiaryTags: createCustomEventTags(
    'Primary Beneficiary',
    'Policy Relationships Card Home Page',
    'click',
    null,
  ),
  CaptureRelationshipsOwnerTags: createCustomEventTags(
    'Owner',
    'Policy Relationships Card Home Page',
    'click',
    null,
  ),
  CaptureRelationshipsPayorTags: createCustomEventTags(
    'Payor',
    'Policy Relationships Card Home Page',
    'click',
    null,
  ),
  CaptureRelationshipsBeneficiaryTags: createCustomEventTags(
    'Beneficiary',
    'Policy Relationships Card Home Page',
    'click',
    null,
  ),

  CaptureStrategiesInfoModalTags: createCustomEventTags(
    'Info Icon',
    'My Strategy Page',
    'click',
    null,
  ),
  CaptureMyStrategiesCardInfoModalTags: createCustomEventTags(
    'Info Icon',
    'My Strategy Card Home Page',
    'click',
    null,
  ),
  CaptureInvestmentCardViewDetailsTags: createCustomEventTags(
    'View Details',
    'Investment Card Home Page',
    'click',
    null,
  ),
  CaptureMyStrategiesCardViewDetailsTags: createCustomEventTags(
    'View Details',
    'MyStrategies Card Home Page',
    'click',
    null,
  ),
  CaptureBillingAndPremiumsCardViewDetailsTags: createCustomEventTags(
    'View Details',
    'Billing And Premiums Card Home Page',
    'click',
    null,
  ),
  CaptureCOBCardViewDetailsTags: createCustomEventTags(
    'View Details',
    'COB Card Home Page',
    'click',
    null,
  ),
  CapturePolicyValueCardViewDetailsTags: createCustomEventTags(
    'View Details',
    'Policy Value Card Home Page',
    'click',
    null,
  ),
  CaptureCurrentBalanceTags: createCustomEventTags(
    'Current Balance',
    'PolicyHolder Portal Header',
    'click',
    null,
  ),
  CaptureFutureElectionTags: createCustomEventTags(
    'Future Election',
    'PolicyHolder Portal Header',
    'click',
    null,
  ),
  CaptureOneAmericaFinanciallogoTags: createCustomEventTags(
    'OneAmerica Financial logo',
    'PolicyHolder Portal Header',
    'click',
    null,
  ),
  CaptureLogoutIconTags: createCustomEventTags(
    'Logout Icon',
    'PolicyHolder Portal',
    'click',
    null,
  ),
  CaptureLogoutSuccessTags: createCustomEventTags(
    'Logout',
    'PolicyHolder Portal',
    'Success',
    null,
  ),
  CapturePolicySelectorDropdownTags: createCustomEventTags(
    'Policy Selector Dropdown',
    'PolicyHolder Portal home Page',
    'click',
    null,
  ),
  CaptureContactUsAULPhoneNumberTags: createCustomEventTags(
    'Phone',
    'American United Life Insurance Company Contact Us Page',
    'click',
    null,
  ),
  CaptureContactUsStateLifeInsurancePhoneNumberTags: createCustomEventTags(
    'Phone',
    'The State Life Insurance Company Contact Us Page',
    'click',
    null,
  ),
  CaptureContactUsStateLifeInsuranceLTCPhoneNumberTags: createCustomEventTags(
    'Phone',
    'The State Life Insurance Company LTC Contact Us Page ',
    'click',
    null,
  ),
  CaptureContactUsAULTollFreeNumberTags: createCustomEventTags(
    'Toll-free',
    'American United Life Insurance Company Contact Us Page',
    'click',
    null,
  ),
  CaptureContactUsStateLifeInsuranceTollFreeNumberTags: createCustomEventTags(
    'Toll-free',
    'The State Life Insurance Company Contact Us Page',
    'click',
    null,
  ),
  CaptureContactUsStateLifeInsuranceLTCTollFreeNumberTags:
    createCustomEventTags(
      'Toll-free',
      'The State Life Insurance Company LTC Contact Us Page ',
      'click',
      null,
    ),
  CaptureMailingAddressEmailHyperlinksTags: createCustomEventTags(
    'Email Policy Service',
    'Mailing Address Contact Us Page',
    'click',
    null,
  ),
  CapturePolicyChangeEmailHyperlinksTags: createCustomEventTags(
    'Email Policy Change',
    'Policy Change Contact Us Page',
    'click',
    null,
  ),
  CaptureContactInformationTags: createCustomEventTags(
    'Contact Information',
    'My Profile Page',
    'click',
    null,
  ),
  CaptureCancelMyProfileContactInformationTags: createCustomEventTags(
    'Cancel',
    'My Profile Page - Contact Information Cancel Button',
    'click',
    null,
  ),
  CaptureSaveMyProfileContactInformationTags: createCustomEventTags(
    'Save',
    'My Profile Page - Contact Information Save Button',
    'click',
    null,
  ),
  CaptureContactInformationSubmitSuccessEventTags: createCustomEventTags(
    'Submit',
    'My Profile Page - Contact Information Save Button',
    'success',
    null,
  ),
  CaptureContactInformationSubmitFailureEventTags: createCustomEventTags(
    'Submit',
    'My Profile Page - Contact Information Save Button',
    'failure',
    null,
  ),
  CaptureSecurityQuestionTags: createCustomEventTags(
    'Security Question',
    'My Profile Page',
    'click',
    null,
  ),
  CaptureCancelMyProfileSecurityQuestionTags: createCustomEventTags(
    'Cancel',
    'My Profile Page - Security Question Cancel Button',
    'click',
    null,
  ),
  CaptureSaveMyProfileSecurityQuestionTags: createCustomEventTags(
    'Save',
    'My Profile Page - Security Question Save Button',
    'click',
    null,
  ),
  CaptureSecurityQuestionSubmitSuccessEventTags: createCustomEventTags(
    'Submit',
    'My Profile Page - Security Question Save Button',
    'success',
    null,
  ),
  CaptureSecurityQuestionSubmitFailureEventTags: createCustomEventTags(
    'Submit',
    'My Profile Page - Security Question Save Button',
    'failure',
    null,
  ),
  CapturePasswordAndUserIDTags: createCustomEventTags(
    'Password And User ID',
    'My Profile Page',
    'click',
    null,
  ),
  CaptureChangePasswordIDTags: createCustomEventTags(
    'Change Password',
    'My Profile Page - Change Password User ID Tab',
    'click',
    null,
  ),
  CaptureCancelMyProfileChangePasswordIDTags: createCustomEventTags(
    'Cancel',
    'My Profile Page - Change Password Cancel Button',
    'click',
    null,
  ),
  CaptureSaveMyProfileChangePasswordIDTags: createCustomEventTags(
    'Save',
    'My Profile Page - Change Password Save Button',
    'click',
    null,
  ),
  CaptureChangePasswordSubmitSuccessEventTags: createCustomEventTags(
    'Submit',
    'My Profile Page - Change Password Save Button',
    'success',
    null,
  ),
  CaptureChangePasswordSubmitFailureEventTags: createCustomEventTags(
    'Submit',
    'My Profile Page - Change Password Save Button',
    'failure',
    null,
  ),
  CaptureChangeUserIDTags: createCustomEventTags(
    'Change User ID',
    'My Profile Page - Password User ID Tab',
    'click',
    null,
  ),
  CaptureChangeUserIDCancelTags: createCustomEventTags(
    'Cancel',
    'My Profile Page - Password User ID Tab',
    'click',
    null,
  ),
  CaptureChangeUserIDSaveTags: createCustomEventTags(
    'Save',
    'My Profile Page - Change User Id Save Button',
    'click',
    null,
  ),
  CaptureChangeUserIDSubmitSuccessEventTags: createCustomEventTags(
    'Submit',
    'My Profile Page - Change User Id Save Button',
    'success',
    null,
  ),
  CaptureChangeUserIDSubmitFailureEventTags: createCustomEventTags(
    'Submit',
    'My Profile Page - Change User Id Save Button',
    'failure',
    null,
  ),
  CaptureStartRequestLinkTags: createCustomEventTags(
    'Start Request',
    'PSR Page Start Request Link',
    'click',
    null,
  ),
  CapturePSRButtonTags: createCustomEventTags(
    'PSR Button',
    'PSR Page',
    'click',
    null,
  ),
  CapturePercentTransferStartButton: createCustomEventTags(
    'Start',
    'Percent Transfer Start Button',
    'click',
    null,
  ),
  CaptureDollarTransferStartButton: createCustomEventTags(
    'Start',
    'Dollar Transfer Start Button',
    'click',
    null,
  ),
  CaptureTransferBalanceNextButtonTags: createCustomEventTags(
    'Next',
    'Transfer Balance Next Button',
    'click',
    null,
  ),
  CaptureFutureInvestmentNextButtonTags: createCustomEventTags(
    'Next',
    'Future Investment Next Button',
    'click',
    null,
  ),
  CaptureFutureInvestmentOptionElectionsTabTags: createCustomEventTags(
    'Future Investment Option Elections',
    'Investment Page Tab',
    'click',
    null,
  ),
  CaptureCurrentBalanceAssetClassTags: createCustomEventTags(
    'Current Balance by Asset Class',
    'Investment Page Tab',
    'click',
    null,
  ),
  CapturePercentTransferKeepEditingEditPageButtonTags: createCustomEventTags(
    'Keep Editing',
    'Percent Transfer Edit Page Keep Editing Button Cancel Modal',
    'click',
    null,
  ),
  CapturePercentTransferReviewPageKeepEditingButtonTags: createCustomEventTags(
    'Keep Editing',
    'Percent Transfer Review Page Keep Editing Button Cancel Modal',
    'click',
    null,
  ),
  CaptureDollarTransferEditPageKeepEditingButtonTags: createCustomEventTags(
    'Keep Editing',
    'Dollar Transfer Edit Page Keep Editing Button Cancel Modal',
    'click',
    null,
  ),
  CaptureDollarTransferReviewPageKeepEditingButtonTags: createCustomEventTags(
    'Keep Editing',
    'Dollar Transfer Review Page Keep Editing Button Cancel Modal',
    'click',
    null,
  ),
  CaptureFutureInvestmentEditPageKeepEditingButtonTags: createCustomEventTags(
    'Keep Editing',
    'Future Investment Edit Page Keep Editing Button Cancel Modal',
    'click',
    null,
  ),
  CaptureFutureInvestmentKeepEditingReviewPageButtonTags: createCustomEventTags(
    'Keep Editing',
    'Future Investment Review Page Keep Editing Button Cancel Modal',
    'click',
    null,
  ),
  CapturePercentTransferEditPageCancelEditModalTags: createCustomEventTags(
    'Leave',
    'Percent Transfer Edit Page Leave Button Cancel Edit Modal',
    'click',
    null,
  ),
  CapturePercentTransferReviewPageCancelEditModalTags: createCustomEventTags(
    'Leave',
    'Percent Transfer Review Page Leave Button Cancel Edit Modal',
    'click',
    null,
  ),
  CaptureDollarTransferEditPageCancelEditModalTags: createCustomEventTags(
    'Leave',
    'Dollar Transfer Edit Page Leave Button Cancel Edit Modal',
    'click',
    null,
  ),
  CaptureDollarTransferReviewPageCancelEditModalTags: createCustomEventTags(
    'Leave',
    'Dollar Transfer Review Page Leave Button Cancel Edit Modal',
    'click',
    null,
  ),

  CaptureFutureInvestmentEditPageCancelEditModalTags: createCustomEventTags(
    'Leave',
    'Future Investment Edit Page Leave Button Cancel Edit Modal',
    'click',
    null,
  ),
  CaptureFutureInvestmentReviewPageCancelEditModalTags: createCustomEventTags(
    'Leave',
    'Future Investment Review Page Leave Button Cancel Edit Modal',
    'click',
    null,
  ),
  CaptureDollarTransferDoneButtonTags: createCustomEventTags(
    'Done',
    'Dollar Transfer',
    'click',
    null,
  ),
  CapturePercentTransferDoneButtonTags: createCustomEventTags(
    'Done',
    'Percent Transfer',
    'click',
    null,
  ),
  CaptureFutureInvestmentDoneButtonTags: createCustomEventTags(
    'Done',
    'Future Investment',
    'click',
    null,
  ),
  CapturePercentTransferEditPageCancelButtonTags: createCustomEventTags(
    'Cancel',
    'Percent Transfer Edit Page Cancel Button',
    'click',
    null,
  ),
  CapturenPercentTransferReviewPageCancelButtonTags: createCustomEventTags(
    'Cancel',
    'Percent Transfer Review Page Cancel Button',
    'click',
    null,
  ),
  CaptureDollarTransferEditPageCancelButtonTags: createCustomEventTags(
    'Cancel',
    'Dollar Transfer Edit Page Cancel Button',
    'click',
    null,
  ),
  CapturenDollarTransferReviewPageCancelButtonTags: createCustomEventTags(
    'Cancel',
    'Dollar Transfer Review Page Cancel Button',
    'click',
    null,
  ),
  CaptureFutureInvestmentEditPageCancelButtonTags: createCustomEventTags(
    'Cancel',
    'Future Investment Edit Page Cancel Button',
    'click',
    null,
  ),
  CaptureFutureInvestmentReviewPageCancelButtonTags: createCustomEventTags(
    'Cancel',
    'Future Investment Review Page Cancel Button',
    'click',
    null,
  ),
  CaptureTransferBalancePreviousButtonTags: createCustomEventTags(
    'Previous',
    'Transfer Balance Previous Button',
    'click',
    null,
  ),
  CaptureFutureInvestmentPreviousButtonTags: createCustomEventTags(
    'Previous',
    'Future Investment Previous Button',
    'click',
    null,
  ),
  CapturePercentTransferConfirmOneButtonTags: createCustomEventTags(
    'Confirm',
    'Percent Transfer Confirm First Click Button',
    'click',
    null,
  ),
  CaptureDollarTransferConfirmOneButtonTags: createCustomEventTags(
    'Confirm ',
    'Dollar Transfer Confirm First Click Button',
    'click',
    null,
  ),
  CaptureFutureInvestmentConfirmButtonOneTags: createCustomEventTags(
    'Confirm',
    'Future Investment Confirm First Click Button',
    'click',
    null,
  ),
  CapturePercentTransferConfirmTwoButtonTags: createCustomEventTags(
    'Confirm',
    'Percent Transfer Confirm Final Click Button',
    'click',
    null,
  ),
  CaptureDollarTransferConfirmFinalButtonTags: createCustomEventTags(
    'Confirm',
    'Dollar Transfer Confirm Final Click Button',
    'click',
    null,
  ),
  CaptureFutureInvestmentConfirmButtonFinalTags: createCustomEventTags(
    'Confirm Final',
    'Future Investment Confirm Final Click Button',
    'click',
    null,
  ),
  CaptureTransferBalancePageLinkTags: createCustomEventTags(
    'Transfer Balance',
    'Transfer Balance Page Link',
    'click',
    null,
  ),
  CapturePercentTransferTypeTags: createCustomEventTags(
    'Percent Transfer',
    'Transfer Balance Percent Transfer Page Link',
    'click',
    null,
  ),
  CaptureDollarTransferTypeTags: createCustomEventTags(
    'Dollar Transfer',
    'Transfer Balance Dollar Transfer Page Link',
    'click',
    null,
  ),
  CaptureEditFutureElectionsButtonTags: createCustomEventTags(
    'Edit Future Elections',
    'My Investment Future Investment Button',
    'click',
    null,
  ),
  CaptureDollarTransferPrintButtonTags: createCustomEventTags(
    'Print',
    'Dollar Transfer Print Button',
    'click',
    null,
  ),
  CapturePercentTransferPrintButtonTags: createCustomEventTags(
    'Print',
    'Percent Transfer Print Button',
    'click',
    null,
  ),
  CaptureFutureInvestmentPrintButtonTags: createCustomEventTags(
    'Print',
    'Future Investment Print Button',
    'click',
    null,
  ),
  CaptureVULPDFHyperlinkTags: createCustomEventTags(
    'FLEXIBLE PREMIUM VUL PDF',
    'Investment PDF Link',
    'click',
    null,
  ),
  CaptureAccountNavigatorIconTags: createCustomEventTags(
    'Account Navigator Icon',
    'PolicyHolder Portal Header',
    'click',
    null,
  ),
  CaptureCoverageInformationTags: createCustomEventTags(
    'Coverage Information',
    'Additional Details Card Home Page',
    'click',
    null,
  ),
  CaptureViewIndexedDividendTags: createCustomEventTags(
    'View Indexed Dividend',
    'Additional Details Card Home Page',
    'click',
    null,
  ),
  CaptureIncomePaymentInformationTags: createCustomEventTags(
    'Income Payment Information',
    'Additional Details Card Home Page',
    'click',
    null,
  ),
  CapturePolicyValueTags: createCustomEventTags(
    'Policy Value',
    'Additional Details Card Home Page',
    'click',
    null,
  ),
  CaptureLTCValueTags: createCustomEventTags(
    'LTC Value',
    'Additional Details Card Home Page',
    'click',
    null,
  ),
  CapturePremiumReceivedValueTags: createCustomEventTags(
    'Premium Received',
    'Additional Details Card Home Page',
    'click',
    null,
  ),
  CaptureIncomeInformationTags: createCustomEventTags(
    'Income Information',
    'Additional Details Card Home Page',
    'click',
    null,
  ),
  CaptureAnnuityRatesTags: createCustomEventTags(
    'Annuity Rates',
    'Additional Details Card Home Page',
    'click',
    null,
  ),
  CaptureCashWithdrawalValuesTags: createCustomEventTags(
    'CASH WITHDRAWAL VALUES',
    'Additional Details Card Home Page',
    'click',
    null,
  ),
  CaptureExpandCollapseTags: createCustomEventTags(
    'Expand Collapse',
    'My Investment Page',
    'click',
    null,
  ),
};

export const editErrorMessages = {
  emptyError:
    'One or more of the required fields is empty, please check your input.',
  totalAllocationError: 'Your Total Account Value should equal 100%',
  noChangeError: `You haven't made any changes.`,
  percentAllocationError: 'Percentage left to transfer should equal 0%',
  dollarAllocationError: 'Dollars left to transfer should equal $0.00',
};

export const apiErrorMessages = {
  pendingErrorText:
    'Account Services was unable to confirm your request. Your request has been submitted for processing.',
  systemUnavailableErrorText: 'Error Returned from BackEnd Admin System - null',
  timeOutErrorText: 'Time out exceeded from back end api',
  backendErrorText:
    'Error Returned from BackEnd Admin System - null: Cyberlife System is unavailable',
};

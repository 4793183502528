import {Theme, Font} from '@oneamerica/dxp-ui-components';
import ColorConstants from '../../constants/ColorConstants';
import BreakpointConstants from '../../constants/BreakpointConstants';
import {Platform} from 'react-native';

export default (width: number) =>
  Theme.Responsive.create({
    cardShadow: {
      borderRadius: 5,
      shadowRadius: 6,
      backgroundColor: Theme.Color.background,
      shadowColor: Theme.Color.backgroundShadow,
      shadowOffset: {width: 0, height: 3},
      paddingTop: Theme.Metrics.medium,
      marginTop: Theme.Metrics.small,
      flex: 1,
    },
    cardHeader: {
      marginHorizontal: -Theme.Metrics.medium,
      marginTop: Theme.Metrics.small,
      paddingHorizontal: Theme.Metrics.medium,
      borderRadius: 4,
      fontFamily: Font.soleil.regular.fontFamily,
    },
    cardHeaderTitle: {color: Theme.Color.oaBlue},
    tableTitle: {
      color: ColorConstants.iron,
      fontSize: 20,
      fontFamily: Font.soleil.regular.fontFamily,
      fontWeight: '700',
    },
    selectTransferText: {
      color: ColorConstants.iron,
      fontSize: 22,
      fontFamily: Font.soleil.regular.fontFamily,
      fontWeight: '600',
      textAlign: 'center',
      lineHeight: 28,
      marginTop: Theme.Metrics.small,
    },
    transferText: {
      color: ColorConstants.iron,
      fontSize: 15,
      fontFamily: Font.regular.fontFamily,
      fontWeight: '400',
      marginTop: 15,
    },
    percentDollarTransferBoxes: {
      justifyContent: 'center',
      flex: 1,
      alignItems: 'center',
      flexDirection: 'row',
      marginTop: Theme.Metrics.medium,
    },
    percentDollarTransferBox: {
      backgroundColor: ColorConstants.lightGrayishBlue,
      marginRight: Theme.Metrics.medium,
      width: 147,
      height: 140,
      borderRadius: 5,
      padding: 16,
      marginBottom: 10,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
    },
    selectedTransferBox: {
      backgroundColor: ColorConstants.lightGrayishBlue,
      marginRight: Theme.Metrics.medium,
      width: 150,
      height: 140,
      borderRadius: 5,
      padding: 16,
      marginBottom: 10,
      justifyContent: 'center',
      alignItems: 'center',
      borderWidth: 2,
      borderColor: '#8BBFF3',
      borderStyle: 'solid',
    },
    percentDollarTransferText: {
      textAlign: 'center',
      fontSize: 15,
      color: ColorConstants.oaBlue,
      fontFamily: Font.regular.fontFamily,
      fontWeight: '400',
    },
    outlineNone: {
      outlineWidth: 0,
      marginBottom: Theme.Metrics.small,
    },
    dollarTransferInstBox: {
      borderWidth: 1,
      borderColor: ColorConstants.ironTint,
      shadowColor: '#000000',
      shadowOpacity: 0.08,
      shadowRadius: 12,
      marginTop: Theme.Metrics.medium,
      marginHorizontal: Theme.Metrics.medium,
      paddingLeft: Theme.Metrics.small,
      paddingRight: Theme.Metrics.medium,
      borderLeftWidth: 8,
      borderLeftColor: ColorConstants.darkBlue,
    },
    dollarTransferText: {
      color: ColorConstants.oaBlue,
      fontSize: 20,
      fontFamily: Font.soleil.bold.fontFamily,
      marginVertical: Theme.Metrics.medium,
      textAlign: 'left',
    },
    dollarTransferContent: {
      fontSize: 14,
      color: ColorConstants.iron,
      fontFamily: Font.regular.fontFamily,
      fontWeight: '400',
      marginBottom: Theme.Metrics.small,
    },
    startButton: {
      width: BreakpointConstants.isMD(width) ? 'auto' : '300px',
      fontWeight: '700',
      fontFamily: 'Roboto',
      fontSize: 15,
      // web-specifc style
      ...Platform.select({
        web: {
          WebkitFontSmoothing: 'antialiased',
        },
      }),
    },
    ruleText: {
      color: ColorConstants.iron,
      fontSize: 16,
      fontFamily: Font.soleil.bold.fontFamily,
      marginBottom: Theme.Metrics.small,
      marginTop: Theme.Metrics.large,
    },
    rulesContent: {
      color: ColorConstants.iron,
      fontSize: 14,
      fontFamily: Font.regular.fontFamily,
      fontWeight: '400',
      marginBottom: 4,
    },
    bulletPoint: {
      flexDirection: 'row',
      marginBottom: 5,
      marginLeft: 5,
    },
    bulletSymbol: {
      fontSize: 20,
      lineHeight: 20,
      marginRight: 5,
    },
    transferBalanceRulesBox: {
      backgroundColor: ColorConstants.lightGrayishBlue,
      paddingHorizontal: Theme.Metrics.large,
      marginTop: Theme.Metrics.small,
      paddingBottom: Theme.Metrics.large,
    },
  })();

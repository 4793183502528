import React, {useState} from 'react';
import {
  Dimensions,
  FlatList,
  LayoutAnimation,
  View,
  ScrollView,
} from 'react-native';
import responsiveStyles from './styles';
import {Components} from '@oneamerica/dxp-ui-components';
import {
  FontAwesomeIcon,
  FontAwesomeIconStyle,
} from '@fortawesome/react-native-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {faChevronUp} from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import {faChevronDown} from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import ColorConstants from '../../../constants/ColorConstants';
import {
  showAssetClassnameColumn,
  createChildRows,
  getChildRowHeaderItems,
  createHeader,
  createFooter,
  showOtherAssetColumns,
  isTextInputEditable,
  getTextInputValue,
} from './helpers';
import {editErrorMessages} from '../constants';
import {useRoute} from '@react-navigation/native';
import RouteConstants from '../../../constants/RouteConstants';
import {formatCurrency} from '../../../utils';
import {analyticsTags} from '../../../utils/Analytics/AnalyticsTags';
import {trackCustomEvent} from '../../../utils/Analytics/AdobeAnalyticsUtils';

const styles = responsiveStyles();

const ChartLegend = ({
  mappedData,
  assetTotal,
  totalPercentageByValue,
  legendTableTitle,
  showChildRows,
  columnConfig,
  childRowsColumnConfig,
  columnWidthArr,
  horizontal,
  showAlternateRowColors,
  showBorder,
  fullHeight,
  setIsErrorInTable,
  setIsDirty,
  getEditedData,
  footerRibbonText,
  fromPreviewscreen,
  isMobileTableFooter,
  showPercentLeftToTransfer,
  isTotalAccountValueVisible,
  totalValue,
}: TableProps) => {
  const [data, setData] = useState(JSON.parse(JSON.stringify(mappedData)));
  const [isExpanded, setIsExpanded] = useState(false);
  const [rowWidth, setRowWidth] = useState('100%');
  const [isAllocationTotalError, setIsAllocationTotalError] = useState(false);
  const [tableTotal, setTableTotal] = useState(0);
  const [editedRows, setEditedRows] = useState<{}[]>([]);

  const route = useRoute();

  const checkValidations = (currentItem, tableTotal) => {
    const isDollarTransfer = getTransferType() === 'DOLLAR';
    const showPercentLeftToTransfer = getTransferType() === 'PERCENT';
    if (
      (!isDollarTransfer &&
        Number(currentItem.newPercentage) !== currentItem.y) ||
      (isDollarTransfer &&
        (Number(currentItem.toValue) != currentItem.y ||
          Number(currentItem.fromValue) != currentItem.z))
    ) {
      setIsDirty(true);
    }

    if (currentItem?.newPercentage === '') {
      currentItem.isError = true;
      setIsAllocationTotalError(true);
      setIsErrorInTable(editErrorMessages.emptyError);
    } else {
      let isEmpty = false;
      currentItem.isError = false;
      let anyChange = false;
      let minMaxValidations = false;
      data.map((element, index) => {
        if (
          (!isDollarTransfer && element.y !== Number(element.newPercentage)) ||
          (isDollarTransfer &&
            (element.toValue != Number(mappedData[index]?.toValue) ||
              element.fromValue != Number(mappedData[index]?.fromValue)))
        ) {
          anyChange = true;
        }

        if (
          !showPercentLeftToTransfer &&
          tableTotal === 100 &&
          element.newPercentage !== ''
        ) {
          element.isError = false;
        } else if (element.newPercentage === '') {
          isEmpty = true;
        } else if (showPercentLeftToTransfer) {
          if (
            (element.minPercentByTotalValue ===
              element.maxPercentByTotalValue &&
              Number(element.newPercentage) !==
                Number(element.minPercentByTotalValue)) ||
            (element.minPercentByTotalValue !==
              element.maxPercentByTotalValue &&
              (Number(element.newPercentage) <
                Number(element.minPercentByTotalValue) ||
                Number(element.newPercentage) >
                  Number(element.maxPercentByTotalValue)))
          ) {
            element.isError = true;
            minMaxValidations = true;
          } else if (Number(tableTotal) === 0 && element.newPercentage !== '') {
            element.isError = false;
            minMaxValidations = false;
          }
        } else if (isDollarTransfer) {
          if (
            (isTextInputEditable('Transfer from', element) &&
              getTextInputValue('Transfer from', element) === '') ||
            (isTextInputEditable('Transfer to', element) &&
              getTextInputValue('Transfer to', element) === '')
          ) {
            isEmpty = true;
          }

          if (
            (Number(element.fromValue) > 0 &&
              Number(element.fromValue) < Number(element.minValue)) ||
            Number(element.fromValue) > Number(element.currentValue) ||
            Number(element.toValue) > Number(element.maxValue)
          ) {
            element.isError = true;
            minMaxValidations = true;
          }
        }
      });

      if (!isDollarTransfer) {
        if (isEmpty) {
          setIsAllocationTotalError(true);
          setIsErrorInTable(editErrorMessages.emptyError);
        } else if (
          !showPercentLeftToTransfer &&
          (tableTotal < 100 || tableTotal > 100)
        ) {
          setIsAllocationTotalError(true);
          setIsErrorInTable(editErrorMessages.totalAllocationError);
        } else if (showPercentLeftToTransfer && minMaxValidations) {
          setIsAllocationTotalError(true);
          setIsErrorInTable(editErrorMessages.minMaxError);
        } else if (
          showPercentLeftToTransfer &&
          Number(tableTotal) !== 0 &&
          !minMaxValidations
        ) {
          setIsAllocationTotalError(true);
          setIsErrorInTable(editErrorMessages.percentAllocationError);
        } else if (!anyChange) {
          setIsAllocationTotalError(false);
          setEditedRows([]);
          setIsErrorInTable('');
          setIsDirty(false);
        } else {
          setIsAllocationTotalError(false);
          setIsErrorInTable('');
          getEditedData(data);
        }
      } else if (isEmpty) {
        setIsAllocationTotalError(true);
        setIsErrorInTable(editErrorMessages.emptyError);
      } else if (anyChange) {
        if (Number(tableTotal) !== 0 && !minMaxValidations) {
          !isAllocationTotalError && setIsAllocationTotalError(true);
          setIsErrorInTable(editErrorMessages.dollarAllocationError);
        } else if (minMaxValidations) {
          setIsAllocationTotalError(true);
          setIsErrorInTable(editErrorMessages.minMaxError);
        } else {
          setIsAllocationTotalError(false);
          setIsErrorInTable('');
          getEditedData(data);
        }
      } else {
        setIsAllocationTotalError(false);
        setIsErrorInTable('');
        setEditedRows([]);
        setIsDirty(false);
      }
    }
  };

  const getTransferType = (): TransfersType => {
    switch (route.name) {
      case RouteConstants.dollartransfer:
        return 'DOLLAR';
      case RouteConstants.percenttransfer:
        return 'PERCENT';
      default:
        return null;
    }
  };

  const handleEdit = (currentItem, text, index, objectKey) => {
    const isDollarTransfer = getTransferType() === 'DOLLAR';
    const prevData = [...data];
    prevData[index][objectKey] = text;
    setData(prevData);

    let total: any = getTransferType()
      ? Number(getTransfersTotal(prevData)).toFixed(2)
      : getTransfersTotal(prevData);
    setTableTotal(total);

    const prevState = editedRows;
    const idx = prevState.indexOf(index);
    if (
      (!isDollarTransfer &&
        currentItem[objectKey] != mappedData[index][objectKey]) ||
      (isDollarTransfer &&
        (currentItem.toValue != mappedData[index].toValue ||
          currentItem.fromValue != mappedData[index].fromValue))
    ) {
      if (idx === -1) {
        prevState.push(index);
      }
      setEditedRows(prevState);
    } else if (idx !== -1) {
      prevState.splice(idx, 1);
      setEditedRows(prevState);
    }
    checkValidations(currentItem, total);
  };

  const getTransfersTotal = (mappedData) => {
    let total = 0;

    const isPercentTransfer = getTransferType() === 'PERCENT';
    const isTransferTypeNull = getTransferType() === null;

    mappedData.forEach((item) => {
      total +=
        isPercentTransfer || isTransferTypeNull
          ? Number(item.newPercentage)
          : (Number(item?.fromValue) || 0) - (Number(item?.toValue) || 0);
    });

    return isPercentTransfer ? Number(100 - total).toFixed(2) : total;
  };

  const returnArrowImage = (item) => {
    const isExpanded = item?.isExpanded;
    return (
      <Components.Link
        onPress={() => {
          trackCustomEvent(analyticsTags.CaptureExpandCollapseTags);
          toggleExpand(item);
        }}
        testID={isExpanded ? 'accordionUpArrowLink' : 'accordionDownArrowLink'}
        className="mr-2"
        accessibilityRole="button"
        accessibilityLabel={isExpanded ? 'Collapse section' : 'Expand section'}>
        <FontAwesomeIcon
          testID={isExpanded ? 'arrowUp' : 'arrowDown'}
          style={styles.chevronArrow as FontAwesomeIconStyle}
          icon={(isExpanded ? faChevronUp : faChevronDown) as IconProp}
          color={ColorConstants.iron}
        />
      </Components.Link>
    );
  };

  const toggleExpand = (item) => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    if (isExpanded) {
      item.isExpanded = false;
    } else {
      item.isExpanded = true;
    }
    setIsExpanded(!isExpanded);
  };

  const onItemLayout = (index) => (event) => {
    if (index === 0) {
      if (
        !fullHeight &&
        !showChildRows &&
        data.length > 5 &&
        Dimensions.get('window').width >= 1024
      ) {
        setRowWidth(event.nativeEvent.layout.width + 0.25);
      } else {
        setRowWidth('100%');
      }
    }
  };

  const createDataRow = (item, idx, showBorder) => {
    return columnConfig.map((arr, index) => {
      if (arr.columnName === 'Asset Class' || arr.columnName === 'Strategy') {
        const columnParameters = {
          item,
          arr,
          styles,
          showChildRows,
          index,
          returnArrowImage,
          fullHeight,
          showBorder,
        };
        return showAssetClassnameColumn(columnParameters);
      } else {
        const transferType = getTransferType();
        const isDollarOrPercent =
          transferType !== 'DOLLAR' && transferType !== 'PERCENT';
        const showOtherAssetColumnsParams = {
          arr,
          columnWidthArr,
          styles,
          item,
          index,
          fullHeight,
          idx,
          showBorder,
          handleEdit,
          isDollarOrPercent,
        };
        return showOtherAssetColumns(showOtherAssetColumnsParams);
      }
    });
  };

  const getRowItem = ({item, index}) => {
    return (
      <View
        onLayout={onItemLayout(index)}
        style={{width: '100%'}}
        testID="rowItem">
        <View
          style={[
            styles.rowInternal,
            !showBorder && styles.noVerticalBorder,
            showAlternateRowColors &&
              index % 2 == 0 && {...styles.rowBackColor},
          ]}>
          <View style={styles.rowStrip} />
          <View style={{...styles.row}}>
            {createDataRow(item, index, showBorder)}
          </View>
        </View>
        {item.isExpanded && (
          <View key={item.columnName}>
            {item.childRows.map((item, index) => {
              const childRow = createChildRows(
                showChildRows,
                columnWidthArr,
                columnConfig,
                item,
                childRowsColumnConfig,
              );

              if (index == 0) {
                return (
                  <>
                    {getChildRowHeaderItems(
                      childRowsColumnConfig,
                      columnWidthArr,
                      columnConfig,
                      showChildRows,
                    )}
                    {childRow}
                  </>
                );
              }
              return childRow;
            })}
          </View>
        )}
      </View>
    );
  };

  const TableHeader = React.useCallback(
    ({columnConfig, fullHeight}) => (
      <View testID="legendTableHeader">
        <View
          style={fullHeight ? styles.rowHeaderFullHeight : styles.rowHeader}>
          <View style={styles.rowStrip} />
          <View
            //@ts-ignore
            style={{width: rowWidth}}>
            <View style={styles.row}>
              {createHeader(columnConfig, columnWidthArr, fullHeight)}
            </View>
          </View>
        </View>
      </View>
    ),
    [rowWidth, columnWidthArr],
  );

  const getTotalValue = () => {
    if (
      (getTransferType() === 'PERCENT' || getTransferType() === 'DOLLAR') &&
      footerRibbonText
    ) {
      return Number(tableTotal).toFixed(2);
    } else if (tableTotal > 0) {
      return tableTotal;
    } else {
      return assetTotal;
    }
  };

  const TableFooter = React.useCallback(() => {
    const totalValue = getTotalValue();
    const transferType = getTransferType();
    const editedRowsLength = editedRows.length;
    const isDollarTransfer = transferType === 'DOLLAR';
    const showPercentLeftToTransfer = transferType === 'PERCENT';
    const footerParameters = {
      columnConfig,
      columnWidthArr,
      totalValue,
      totalPercentageByValue,
      fullHeight,
      isAllocationTotalError,
      editedRowsLength,
      footerRibbonText,
      isDollarTransfer,
      showAlternateRowColors,
      isMobileTableFooter,
      showPercentLeftToTransfer,
      fromPreviewscreen,
    };
    return (
      <View testID="legendTableFooter">
        <View
          style={{
            ...styles.rowInternal,
            ...styles.footerRow,
          }}>
          <View style={styles.rowStrip} />
          <View
            //@ts-ignore
            style={{width: rowWidth}}>
            <View style={styles.row}>{createFooter(footerParameters)}</View>
          </View>
        </View>
      </View>
    );
  }, [
    tableTotal,
    isAllocationTotalError,
    editedRows.length,
    rowWidth,
    columnWidthArr,
  ]);

  const getDataRowStyle = () => {
    if (data.length > 5) {
      if (fullHeight || showChildRows) {
        return '';
      } else {
        return 'lg:max-h-[190px]';
      }
    } else {
      return '';
    }
  };

  return (
    <>
      <View>
        <Components.Text
          className="mt-4"
          style={[styles.headingTable, legendTableTitle?.titleStyle]}
          testID="legendTableHeading">
          {legendTableTitle?.text}
        </Components.Text>
        <ScrollView
          testID="legendTable"
          className={'mt-4'}
          horizontal={horizontal}
          contentContainerStyle={[
            horizontal && {flexDirection: 'column', minWidth: '100%'},
          ]}
          persistentScrollbar={true}
          showsHorizontalScrollIndicator={true}>
          <TableHeader columnConfig={columnConfig} fullHeight={fullHeight} />
          <FlatList
            data={data}
            renderItem={getRowItem}
            keyExtractor={(_item, index) => index.toString()}
            className={getDataRowStyle()}
            persistentScrollbar={true}
          />
          {isTotalAccountValueVisible && (
            <View
              style={styles.totalAccBox}
              className={isMobileTableFooter ? 'mb-4' : ''}>
              <View style={styles.totalAccFooter1C}>
                <Components.Text style={styles.totalAccFooterText}>
                  Total Account Value
                </Components.Text>
              </View>
              <View style={styles.totalAccFooter2C}></View>
              <View style={styles.totalAccFooter3C}>
                <Components.Text style={styles.totalAccFooterTextAmount}>
                  {formatCurrency(totalValue)}
                </Components.Text>
              </View>
              <View style={styles.totalAccFooter4C}></View>
              <View style={styles.totalAccFooter5C}></View>
            </View>
          )}
          {!isMobileTableFooter && <TableFooter />}
        </ScrollView>
      </View>
      {isMobileTableFooter && (
        <View className={isTotalAccountValueVisible ? 'mt-1' : 'mt-2'}>
          <TableFooter />
        </View>
      )}
    </>
  );
};

export default ChartLegend;

export const columnConfiguration = [
  {
    columnName: 'Investment',
    format: 'string',
    showColumn: true,
    isTotal: true,
    textAlign: 'left',
    isCurrency: false,
    isPercentage: false,
    isEditable: false,
    showPdfIcon: true,
  },
  {
    columnName: 'Asset Class',
    format: 'string',
    showColumn: true,
    isTotal: false,
    textAlign: 'left',
    isCurrency: false,
    isPercentage: false,
    isEditable: false,
  },
  {
    columnName: 'Current %',
    format: 'string',
    showColumn: true,
    isTotal: false,
    textAlign: 'right',
    isCurrency: false,
    isPercentage: true,
    isEditable: false,
  },
  {
    columnName: 'New %',
    format: 'string',
    showColumn: true,
    isTotal: true,
    textAlign: 'center',
    isCurrency: false,
    isPercentage: true,
    isEditable: true,
  },
];

export const columnWidthArrMob = ['40%', '33%', '12%', '15%'];
export const columnWidthArr = ['40%', '33%', '12%', '15%'];

export const childRowsColumnConfig = {
  childRowsParentWidths: ['60%', '20%', '20%'],
  columnWidths: ['60%', '18%', '22%'],
  childTableColumnHeadings: ['Investment Name', 'Units', 'Unit Value'],
  isHyperlink: [true, false, false],
  showChildColumns: [true, true, true],
};

export const childRowsColumnConfigWithoutUnit = {
  childRowsParentWidths: ['60%', '20%', '20%'],
  columnWidths: ['100%', '18%', '22%'],
  childTableColumnHeadings: ['investment Name', 'Units', 'Unit Value'],
  isHyperlink: [true, false, false],
  showChildColumns: [true, false, false],
};

export const columnConfigWithHyperLinkAndWithoutAmount = [
  {
    columnName: 'Asset Class',
    columnWidth: '50%',
    format: 'string',
    showColumn: true,
    isTotal: true,
    textAlign: 'left',
    isCurrency: false,
    isPercentage: false,
  },
  {
    columnName: '%',
    columnWidth: '22%',
    format: 'string',
    showColumn: true,
    isTotal: false,
    textAlign: 'right',
    isCurrency: true,
    isPercentage: false,
  },
  {
    columnName: '$',
    columnWidth: '30%',
    format: 'string',
    showColumn: false,
    isTotal: true,
    textAlign: 'right',
    isCurrency: false,
    isPercentage: true,
  },
];
export default {
  grey200: '#D6D6D6',
  iron: '#333333',
  lightGreen: '#EFF4E7',
  darkGreen: '#438300',
  sun90: 'rgba(244, 181, 49, 0.9)',
  sun30: 'rgba(244, 181, 49, 0.3)',
  modalBG: '#474747CC',
  grey: '#FAFAFA',
  darkRed: '#E23D28',
  lightRed: '#F8D3CF',
  darkBlue: '#69869A',
  lightBlue: '#EEF7FC',
  lightGrey: '#EAEAEA',
  darkGrey: '#707070',
  oaBlueTint: '#2B4F8E',
  oaBlue: '#002c77',
  oaIceBlue: '#8BBFF3',
};
